import React from 'react'
import { T } from '@nv/rc/Components'
import { Text } from '@app/components/Text'
import { Icon } from '@app/components/Icon'
import { colors, fonts } from '@app/themes'

import { NumberLabel, VolumeHeader } from './styles'

export const VolumeLabel = ({ icon, label, count }) => (
  <VolumeHeader>
    <Icon background={colors.paleGrey} icon={icon} size={12} wrapperMargin={12} />
    <Text letterSpacing={fonts.LETTER_SPACING} color={colors.LabelTextColor} size='subHeading'>
      <T id={label} />
      <NumberLabel>{count}</NumberLabel>
    </Text>
  </VolumeHeader>
)
