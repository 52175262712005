import { apiClients, apiClientsNoTransform } from '@app/services/api/clients'
import { CORE_GRANULAR_STATUS_BACKEND, ADDITIONAL_AADP_GRANULAR_STATUS, ADDITIONAL_AADP_GRANULAR_STATUS_BACKEND } from '@app/utils/constants'
import { OVERVIEW_TYPE } from '@app/containers/History/constants'
import { getApiClient } from '@app/utils/apiUtils'

const fetchReservationsApi = (
  country, fromDate, toDate, query, filters, page, pageSize, isCoreGranularStatusFiltersEnabled
) => {
  const client = getApiClient(apiClients, country)
  const timespanParam = `from_date=${fromDate}&to_date=${toDate}`
  const queryParam = getQueryParam(query)
  const filterParam = isCoreGranularStatusFiltersEnabled
    ? getCoreGranStatusFilterParam(filters) : getFilterParam(filters)
  const paginationParam = getPaginationParam(page, pageSize)
  const url = `1.2/reservations/parcel?${timespanParam}${queryParam}${filterParam}${paginationParam}`
  return client.get(url)
}

export const getHistoryPagePaymentApi = (country, page, pageSize, fromDate, toDate, query, filters) => {
  const client = getApiClient(apiClients, country)
  const filtersQuery = filters ? getFilterParam(filters) : ''
  const urlString =
    `1.2/reservations/payment?from_date=${fromDate}&to_date=${toDate}` +
    `&query=${query}${filtersQuery}&page=${page}&page_size=${pageSize}`
  return client.get(urlString)
}

/**
 * Fetch payment overview CSV file from DP service
 *
 * Must pass in 'text/csv' in 'Accept' request headers,
 * otherwise, DP service will not return correctly
 */
const downloadReportApi = (
  country,
  fromDate,
  toDate,
  orderType,
  query,
  filters,
  isCoreGranularStatusFiltersEnabled
) => {
  const downloadReservationsApi = `1.0/report/dp-parcels/volumes?from_date=${fromDate}&to_date=${toDate}&query=${query}`
  const downloadPaymentsApi = `1.0/report/dp-parcels/payment?from_date=${fromDate}&to_date=${toDate}&query=${query}`
  const urlString = (orderType === OVERVIEW_TYPE.parcel) ? downloadReservationsApi : downloadPaymentsApi
  const filterParam = isCoreGranularStatusFiltersEnabled
    ? getCoreGranStatusFilterParam(filters) : getFilterParam(filters)
  const url = filters ? urlString.concat(filterParam) : urlString
  const config = {
    headers: {
      Accept: 'text/csv'
    }
  }
  return apiClientsNoTransform[country].get(url, null, config)
}

function getFilterParam (filters) {
  if (filters.length < 1) {
    return ''
  }
  return filters.reduce((accumulator, filter) => {
    const param = `&filter=${filter.toUpperCase()}`
    return accumulator + param
  }, '')
}

function getCoreGranStatusFilterParam (filters) {
  if (filters.length < 1) {
    return ''
  }

  // To retrieve all 'in_store' parcels(both 'expired' and 'not_expired'), when the 'in_store' option is chosen without 'expired' or 'not_expired'.
  // Update filterParams by including both 'expired' and 'not_expired' while removing the 'in_store' filter.
  // To send 'expired' and 'not_expired' as additional filter param to GET /1.2/reservation/parcels API.
  const filterParams = [...filters]
  if (filterParams.includes(ADDITIONAL_AADP_GRANULAR_STATUS.inStore)) {
    if (!filterParams.includes(ADDITIONAL_AADP_GRANULAR_STATUS.expired) && !filterParams.includes(ADDITIONAL_AADP_GRANULAR_STATUS.notExpired)) {
      filterParams.push(ADDITIONAL_AADP_GRANULAR_STATUS.expired)
      filterParams.push(ADDITIONAL_AADP_GRANULAR_STATUS.notExpired)
    }
    filterParams.splice(filters.indexOf(ADDITIONAL_AADP_GRANULAR_STATUS.inStore), 1)
  }

  return filterParams.reduce((accumulator, filter) => {
    if (filter === ADDITIONAL_AADP_GRANULAR_STATUS.expired || filter === ADDITIONAL_AADP_GRANULAR_STATUS.notExpired) {
      const additionalFilter = `&additional_filter=${ADDITIONAL_AADP_GRANULAR_STATUS_BACKEND[filter.toString()]}`
      return accumulator + additionalFilter
    }
    const param = `&filter=${CORE_GRANULAR_STATUS_BACKEND[filter.toString()]}`
    return accumulator + param
  }, '')
}

function getPaginationParam (page, pageSize) {
  let paginationParam = ''
  if (page > 0) {
    paginationParam += `&page=${page}`
  }
  if (pageSize > 0) {
    paginationParam += `&page_size=${pageSize}`
  }
  return paginationParam
}

function getQueryParam (query) {
  if (!query) {
    return ''
  }
  return `&query=${query}`
}

export default {
  fetchReservationsApi,
  downloadReportApi
}
