import { faChartLine } from '@fa-pro-solid/faChartLine'
import { faHome } from '@fa-pro-solid/faHome'
import { faCog } from '@fa-pro-solid/faCog'
import { faPowerOff } from '@fa-pro-solid/faPowerOff'
import { faWrench } from '@fa-pro-solid/faWrench'
import { faClock } from '@fa-pro-solid/faClock'
import { ROUTES } from '@app/utils/constants'
import { isAccepted, isRestricted } from '@app/utils/settingUtils'
import { colors } from '@app/themes'

const navConfig = [
  {
    ...ROUTES.home,
    render: {
      icon: faHome,
      label: 'home_page'
    }
  },
  {
    ...ROUTES.sendParcel,
    render: {
      dot: colors.Green,
      label: 'send_your_parcels'
    }
  },
  {
    ...ROUTES.driverHandover,
    render: {
      dot: colors.NinjaRed,
      label: 'driver_handover'
    }
  },
  {
    ...ROUTES.toDriver,
    render: {
      dot: colors.Pink,
      label: 'to_ninja'
    }
  },
  {
    ...ROUTES.fromDriver,
    render: {
      dot: colors.Purple,
      label: 'from_ninja'
    }
  },
  {
    ...ROUTES.collect,
    render: {
      dot: colors.Blue,
      label: 'collect'
    }
  },
  {
    ...ROUTES.businessAnalytics,
    render: {
      icon: faChartLine,
      label: 'business_analytics'
    }
  },
  {
    ...ROUTES.history,
    render: {
      icon: faClock,
      label: 'history'
    }
  },
  {
    render: {
      icon: faWrench,
      label: 'admin_tools'
    },
    children: [
      {
        ...ROUTES.rateCalculator,
        render: {
          label: 'rate_calculator'
        }
      },
      {
        ...ROUTES.shipperSearch,
        render: {
          label: 'shipper_search'
        }
      }
    ]
  }
]

const navConfigV2 = [
  {
    ...ROUTES.home,
    render: {
      iconImg: 'HomeIcon',
      label: 'home'
    }
  },
  {
    ...ROUTES.sendParcel,
    render: {
      iconImg: 'BoxIcon',
      label: 'drop_off_and_return'
    }
  },
  {
    ...ROUTES.driverHandover,
    render: {
      iconImg: 'TruckIcon',
      label: 'driver_handover_label'
    }
  },
  {
    ...ROUTES.collect,
    render: {
      iconImg: 'DeliveryIcon',
      label: 'customer_collect_label'
    }
  },
  {
    ...ROUTES.history,
    render: {
      iconImg: 'SearchIcon',
      label: 'parcel_tracking'
    }
  },
  {
    ...ROUTES.settings,
    render: {
      iconImg: 'SettingIcon',
      label: 'settings'
    }
  }
]

const bottomNavConfig = [
  {
    ...ROUTES.settings,
    render: {
      icon: faCog,
      label: 'settings'
    }
  },
  {
    ...ROUTES.login,
    render: {
      icon: faPowerOff,
      label: 'logout'
    }
  }
]

const userSubNavConfig = [
  {
    ...ROUTES.login,
    render: {
      iconImg: 'OutIcon',
      label: 'log_out'
    }
  }
]

export const getNavConfigs = info => {
  return {
    navConfig: getNavConfig(navConfig, info),
    bottomNavConfig: getNavConfig(bottomNavConfig, info)
  }
}

/**
 * Constructs and returns the navigation configurations for the new side navigation bar.
 * @param {*} info 
 * @returns the navigation configurations object.
 */
export const getNavConfigsV2 = info => {
  return {
    navConfig: getNavConfig(navConfigV2, info),
    userSubNavConfig: getNavConfig(userSubNavConfig, info)
  }
}

const getNavConfig = (config, info) => {
  return config
    .map(item => {
      const { children } = item
      return {
        ...item,
        children: children && getNavConfig(children, info)
      }
    })
    .filter(item => {
      const { hide, accepted, rejected, children } = item
      if (children && children.length === 0) {
        return false
      }

      const shouldHide = hide || !isAccepted(accepted, info) || isRestricted(rejected, info)

      return !shouldHide
    })
}