import React from 'react'
import { injectIntl } from 'react-intl'
import _ from 'lodash'
import { ROUTES } from '@app/utils/constants'
import { ACCEPTED_FILE_TYPES } from '@app/components/BulkUploadOrderForm/constants'
import { FileUpload, DropdownButton, Link } from '@nv/react-akira'
import { BulkUploadOrdersFormWrapper, FormItemWrapper, FileUploadWrapper, StyledText } from './styles'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'

export const BulkUploadOrderForm = (props) => {

  const redirectToScanParcelsPage = () => {
    const { history, location } = props
    history.push(ROUTES.newScanParcel.path, { prevPath: location.pathname })
  }

  const renderDownloadDropDown = () => {
    const { intl, samples } = props
    const dropdownItems = samples.map((s, index) => (
      {
        label: <Link data-testId={`custom-dragger-menu-item-link-${index}`} href={_.get(s, 'link')} download className="custom-dragger-menu-item-link">
          {intl.formatMessage({ id: `${_.get(s, 'title')}_label` })}
        </Link>,
        value: _.get(s, 'link')
      }
    ))
    return (
      <FormItemWrapper>
        <StyledText textId='download_the_template_below'/>
        <DropdownButton
          label={intl.formatMessage({ id: 'download_file_template_label' })}
          listItems={dropdownItems}
          secondaryVariant="default"
          size="md"
          data-testId='custom-dragger-dropdown'
          styleType="secondary"
          variant="default"
        />
      </FormItemWrapper>
    )
  }

  const onChange = (files) =>{
    const { handleFileUpload } = props
    handleFileUpload(files[0])
    // Resetting the field value to null. As of now there is no prop to reset the value of akira file upload field. 
    document.getElementById('upload-file').value = null
  }

  const renderUploadFileField = () => {
    const { intl } = props
    return (
      <FileUploadWrapper className="flex-row w-full" data-testid='custom-dragger'>
        <FileUpload
          className="h-28 w-full"
          fileList={[]}
          fileType={ACCEPTED_FILE_TYPES}
          headline={intl.formatMessage({ id: 'click_or_drag_file' })}
          helperText={intl.formatMessage({ id: 'supported_file_formats' })}
          multiple={false}
          onChange={onChange}
        />
      </FileUploadWrapper>
    )
  }

  return (
    <BulkUploadOrdersFormWrapper>
      {renderUploadFileField()}
      {renderDownloadDropDown()}
      <FormItemWrapper className='switch-to-scanning-link'>
        <Link
          color="primary"
          size="sm"
          data-testId="switch-to-scanning"
          onClick={redirectToScanParcelsPage}
        >
          {props.intl.formatMessage({ id: 'switch_to_scanning' })}
        </Link>
      </FormItemWrapper>
    </BulkUploadOrdersFormWrapper>
  )
}

export default compose(injectIntl, withRouter)(BulkUploadOrderForm)