import { apiClientsNoTransform } from '@app/services/api/clients'

export default (country, dpId, shipperId, startDate, endDate) => {
  const urlString =
    `/1.0/dps/${dpId}/report/shipper-volumes?shipper_id=${shipperId}` + `&start_date=${startDate}&end_date=${endDate}`
  const config = {
    headers: {
      Accept: 'text/csv'
    }
  }
  return apiClientsNoTransform[country].get(urlString, null, config)
}
