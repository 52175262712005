/**
 *
 * Asynchronously loads the component for ReviewOrder
 *
 */

import ReactLoadable from 'react-loadable'

export default ReactLoadable({
  loader: () => import('./index'),
  loading: () => null
})
