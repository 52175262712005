/* eslint-disable max-len */

import { generateApiClients, getApiClient } from '@app/utils/apiUtils'
import { DELIVERY_TYPES } from '@app/utils/constants'

const { apiClients, apiClientsNoTransform } = generateApiClients('dp')
/**
 * Parse user's oauth email and return user 2FA status 
 * @param {*} country - country of current NPv3 user
 * @param {*} email - current NPv3 oauth email
 * @returns if user has set up 2FA or not
 */
const fetchUser2FAStatus = (country, email) => getApiClient(apiClients, country).post(`1.0/login/oauth/2fa-status/search`, { email })

const getDpZones = country => apiClients[country].get(`1.0/dp-zones`)

/*
* Parse given address string and return list of associated L1/L2/L3/postcode
* @param {string} country - country of current NPv3 user
* @param {string} address - address need to be parsed.
* @returns {*} returns array of locations ex: [{l1_address_value:'',l2_address_value:'',l3_address_value:'',postcode:''}]
 */
const getParsedAddresses = (country, address) => getApiClient(apiClients, country).post(`1.0/addresses/parse`, { address })
/**
 * Retrieve list of division (L1/L2/L3/postcode)
 * @param {string} country - country of current NPv3 user
 * @param {string} l1address (optional)
 * @param {string} l2address (optional)
 * @returns {*} If neither L1 and L2 addresses are given, return array of address objects with L1 addresses populated.
 * If only L1 address is given, return array of address objects with L1 and L2 addresses populated.
 * If both L1 and L2 addresses are given, return array of address objects with all L1/L2/L3 addresses and postcode populated.
 * 
 * Sample address object format: [{l1_address_value:'', l1_address_display_value: '', l2_address_value:'',l2_address_display_value:'', l3_address_value:'', l3_address_display_value:'',postcode:''}]
 */
const getDivisions = (country, l1address, l2address) => getApiClient(apiClients, country).get(`1.0/divisions`, {l1_address: l1address, l2_address: l2address})

const getDashboardOrders = (country, payload) => apiClients[country].post(`1.1/reservations`, payload)

const getMarketplaceSellerDetails = (country, ref) =>
  apiClients[country].get(`1.0/dp-marketplace-sellers`, { external_reference_id: ref })
const createMarketplaceSeller = (country, payload) => apiClients[country].post(`1.0/dp-marketplace-sellers`, payload)

const getPriceFromPostCode = (country, shipperId, postcode, payload) =>
  apiClients[country].post(`1.0/shippers/${shipperId}/pricing-script/run?postcode=${postcode}`, payload)

const validateStampId = (country, stampId) => apiClients[country].get(`1.0/orders/stamp-validity?stamp_id=${stampId}`)

// No Transform for AWB and Receipts, because transformation will corrupt the file
const oldGenerateReceipt = (country, payload) =>
  apiClientsNoTransform[country].post(`1.0/receipts`, payload, { responseType: 'arraybuffer' })
const oldGetReceipt = (country, batchId) =>
  apiClientsNoTransform[country].get(`1.0/receipts`, { batch_id: batchId }, { responseType: 'arraybuffer' })

const getOrderDetails = (country, trackingId) => apiClients[country].get(`1.0/orders?scan=${trackingId}`)

const getReturnOrderDetails = (country, trackingId) =>
  apiClients[country].get(`1.0/return-orders?tracking_id=${trackingId}`)

const completeParcelHandover = (country, payload) => apiClients[country].put(`1.1/orders`, payload)

const uploadImage = (country, payload) => {
  const formData = new FormData()
  const file = new File([payload], 'uploaded_file.gz') // eslint-disable-line no-undef
  formData.append('image', file)
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }

  return apiClientsNoTransform[country].post(`1.0/upload-pack-parcel-image`, formData, config)
}

const uploadShipperAccountProof = (country, shipperId, payload) => {
  const formData = new FormData()
  const file = new File([payload], `account_proof_${shipperId}.gz`) // eslint-disable-line no-undef
  formData.append('image', file)
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  return apiClientsNoTransform[country].put(`/2.0/shippers/${shipperId}/upload-bank-account-proof`, formData, config)
}

const createShipper = (country, payload) => apiClients[country].post(`2.1/shippers`, payload)
const searchShipperByContact = (country, contact, searchAllShippers = false) =>
  apiClients[country].get(`2.0/shippers`, { contact_no: contact, all: searchAllShippers })
const searchShipperByLegacyShipperId = (country, legacyShipperId) =>
  apiClients[country].get(`2.0/shippers/${legacyShipperId}`)
const setDefaultShipper = (country, id) => apiClients[country].put(`2.1/shippers/${id}/default`)
const createReturnOrder = (country, type, payload) =>
  apiClientsNoTransform[country].post(`/1.0/reservations/return/${type}`, payload)
const updatePostOrder = (country, payload) => apiClientsNoTransform[country].put(`1.0/reservations/post`, payload)
const generateReceipt = (country, params) =>
  apiClients[country].post(`2.0/receipts/${params.id}?is_shipper_prepaid=${params.isPrepaid}`)
const getReceipt = (country, id) => apiClients[country].get(`2.0/receipts/${id}`)
const triggerNotification = (country, id) => apiClients[country].put(`2.0/receipts/${id}/notify`)

// Bulk Upload for orders from single shipper
const getPrices = (country, legacyShipperId, payload) =>
  getApiClient(apiClients, country).post(`2.1/shipper-legacy-ids/${legacyShipperId}/price`, payload)

// Bulk Upload for orders from single shipper with optionalStampId
const getPricesWithOptionalStampId = (country, legacyShipperId, payload) =>
  getApiClient(apiClients, country).post(`2.2/shipper-legacy-ids/${legacyShipperId}/price`, payload)

// Regenerate all receipts for given orderId 
const regenerateOrderReceipts = (country, params) =>
  apiClients[country].post(`2.0/orders/${params.orderId}/receipts/regenerate`)

export default {
  fetchUser2FAStatus,
  getDpZones,
  getParsedAddresses,
  getDivisions,
  getDashboardOrders,
  getMarketplaceSellerDetails,
  createMarketplaceSeller,
  getPriceFromPostCode,
  validateStampId,
  oldGenerateReceipt,
  oldGetReceipt,
  getOrderDetails,
  getReturnOrderDetails,
  completeParcelHandover,
  uploadImage,
  uploadShipperAccountProof,
  createShipper,
  searchShipperByContact,
  searchShipperByLegacyShipperId,
  setDefaultShipper,
  createReturnOrder,
  updatePostOrder,
  generateReceipt,
  getReceipt,
  triggerNotification,
  getPrices,
  getPricesWithOptionalStampId,
  regenerateOrderReceipts,

  // First Mile
  createPostOrder: (country, isDraft, isBulk, payload) =>
    getApiClient(apiClientsNoTransform, country).post(
      `1.0/reservations/post?is_draft=${isDraft}&is_bulk=${isBulk}`,
      payload
    ),
  getPrice: (country, shipperId, payload) => {
    // this is needed since pricing engine expects the underscore
    if (payload.deliveryType === 'NEXTDAY') {
      payload.deliveryType = DELIVERY_TYPES.NEXT_DAY
    }

    return getApiClient(apiClients, country).post(`2.0/shippers/${shipperId}/price`, payload)
  },

  // Order Details (Events)
  getEventList: (country, orderId) => getApiClient(apiClients, country).get(`1.0/orders/${orderId}/events`),

  // Customer Collect
  resendSMS: (country, payload) => getApiClient(apiClients, country).post(`1.0/drop-off-sms/resend`, payload),

  // Resend Customer Collect OTP
  resendCustomerCollectSMS: (country, payload) => getApiClient(apiClients, country).post(`1.0/sms/driver-drop-off/resend`, payload),

  // Home Page
  getSummary: (country, dpId) => getApiClient(apiClients, country).get(`2.0/dps/${dpId}/summary`),
  generateAwb: (country, payload) =>
    getApiClient(apiClientsNoTransform, country).post(`1.0/airway-bills`, payload, { responseType: 'arraybuffer' })
}
