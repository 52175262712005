import _ from 'lodash'

export function isAccepted (accepted, info) {
  if (accepted) {
    const keys = Object.keys(accepted)
    return (
      keys.filter(k => {
        const acceptedValues = _.get(accepted, k)
        if (acceptedValues) {
          return _.isArray(info[k])
            ? _.intersection(acceptedValues, info[k]).length > 0
            : _.includes(acceptedValues, info[k])
        } else {
          return true
        }
      }).length === keys.length
    )
  } else {
    return true
  }
}

export const isRestricted = (restrictions, info) => {
  if (restrictions) {
    const keys = Object.keys(restrictions)
    return (
      keys.filter(k => {
        const restrictedValues = _.get(restrictions, k)
        if (restrictedValues) {
          return _.isArray(info[k])
            ? _.intersection(restrictedValues, info[k]).length > 0
            : _.includes(restrictedValues, info[k])
        } else {
          return true
        }
      }).length > 0
    )
  } else {
    return false
  }
}
