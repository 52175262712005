import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'
import { set, setLoading } from 'utils/reducerUtils'

export const { Types: volumeByTypeTypes, Creators: volumeByTypeCreators } = createActions(
  {
    requestGetVolumeByType: ['startDate', 'endDate'],
    requestGetVolumeByPerformanceType: ['startDate', 'endDate'],
    successGetVolumeByType: ['volumes'],
    successGetVolumeByPerformanceType: ['volumes'],
    failGetVolumeByType: [],
    failGetVolumeByPerformanceType: []
  },
  { prefix: 'VolumeByType/' }
)

export const INITIAL_STATE = fromJS({
  loading: {},
  volumes: []
})

export default createReducer(INITIAL_STATE, {
  [volumeByTypeTypes.REQUEST_GET_VOLUME_BY_TYPE]: state => setLoading(state, 'getVolumeByType', true),
  [volumeByTypeTypes.REQUEST_GET_VOLUME_BY_PERFORMANCE_TYPE]: state =>
    setLoading(state, 'getVolumeByPerformanceType', true),
  [volumeByTypeTypes.SUCCESS_GET_VOLUME_BY_TYPE]: (state, action) => {
    state = setLoading(state, 'getVolumeByType', false)
    return set(state, 'volumes', action.volumes)
  },
  [volumeByTypeTypes.SUCCESS_GET_VOLUME_BY_PERFORMANCE_TYPE]: (state, action) => {
    state = setLoading(state, 'getVolumeByPerformanceType', false)
    return set(state, 'volumes', action.volumes)
  },
  [volumeByTypeTypes.FAIL_GET_VOLUME_BY_TYPE]: state => {
    state = setLoading(state, 'getVolumeByType', false)
    return set(state, 'volumes', INITIAL_STATE)
  },
  [volumeByTypeTypes.FAIL_GET_VOLUME_BY_PERFORMANCE_TYPE]: state => {
    state = setLoading(state, 'getVolumeByPerformanceType', false)
    return set(state, 'volumes', INITIAL_STATE)
  }
})
