import React from 'react'
import PropTypes from 'prop-types'
import { CustomModal } from '@app/components/CustomModal'
import { COUNTRY_DATA_KEYS, getCountryData } from '@app/utils/constants'
import { T } from '@nv/rc/Components'

export const ErrorCodModal = props => {
  const { intl, country, visible, closeModal } = props
  return (
    <CustomModal
      title={intl.formatMessage({ id: 'unable_to_process_cod' })}
      visible={visible}
      onCancel={closeModal}
      type='error'
      message={renderMessage(country)}
    />
  )
}

const renderMessage = country => {
  return (
    <T
      id={'set_aside_cod_parcel'}
      values={{ supportHotline: getCountryData(country, COUNTRY_DATA_KEYS.supportHotline) }}
    />
  )
}

ErrorCodModal.propTypes = {
  visible: PropTypes.bool,
  closeModal: PropTypes.func,
  country: PropTypes.string
}

ErrorCodModal.defaultProps = {
  visible: false,
  closeModal: () => {},
  country: ''
}

export default ErrorCodModal
