/**
 *
 * ImagePreview
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CustomModal } from '@app/components/CustomModal'
import { display } from '@app/themes'

const StyledImagePreview = styled.div`
  margin: 0 auto;
  height: 200px;
  width: 200px;
`
const Preview = styled(CustomModal)`
  && {
    .ant-modal-body {
      ${display.flex('center', 'center')};
    }
  }
`
const Thumbnail = styled.img`
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

class ImagePreview extends React.Component {
  state = {
    showPreviewModal: false
  }
  showPreviewModal = () => {
    this.setState({ showPreviewModal: true })
  }
  closePreviewModal = () => {
    this.setState({ showPreviewModal: false })
  }
  handleOk = () => {
    const { onOk } = this.props
    this.closePreviewModal()
    onOk && onOk()
  }
  handleCancel = () => {
    const { previewOnly, onCancel } = this.props
    this.closePreviewModal()
    !previewOnly && onCancel && onCancel()
  }
  renderPreviewModal () {
    const { showPreviewModal } = this.state
    const { previewOnly, okText, cancelText, cancelIcon, image, showPreview } = this.props
    return (
      <Preview
        maskClosable={false}
        visible={showPreviewModal || showPreview}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
        okText={!previewOnly && okText}
        cancelText={!previewOnly && cancelText}
        cancelIcon={cancelIcon}
        closable={previewOnly}
      >
        <PreviewImage src={image} />
      </Preview>
    )
  }

  render () {
    const { image } = this.props
    return (
      <StyledImagePreview {...this.props}>
        <Thumbnail data-testid='image-preview-thumbnail' src={image} onClick={this.showPreviewModal} />
        {this.renderPreviewModal()}
      </StyledImagePreview>
    )
  }
}

ImagePreview.propTypes = {
  children: PropTypes.node
}

ImagePreview.defaultProps = {
  children: 'ImagePreview',
  previewOnly: false
}

export { ImagePreview }
