import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash'
import XLSX from 'xlsx'
import Papa from 'papaparse'
import { downloadFile, NOTIFICATIONS, ROUTES } from '@app/utils/constants'
import injectReducer from '@app/utils/injectReducer'
import {
  selectBulkPostOrders,
  selectBulkSendStatus,
  selectBulkUploadLoadingStatus,
  selectIsBulkOCLoading
} from '@app/selectors/BulkUpload'
import {
  makeSelectFirstMileCurrentSession
} from '@app/containers/FirstMile/selectors'
import { bulkUploadCreators, bulkUploadReducer } from '@app/reducers/BulkUpload'
import { firstMileCreators } from '@app/containers/FirstMile/redux'
import {
  isValidOrderTypeForCountry, toastConfig
} from '@app/utils/OrderUtils'
import { baseCreators } from '@app/containers/Base/redux'
import { reservations } from '@app/constants'
import colors from '@app/themes/colors'
import { injectCountry } from '@app/components'
import { selectDpInfo, selectError } from '@app/containers/Base/selectors'
import { MODALS } from '@app/containers/FirstMile/constants'
import { Text } from '@app/components/index'
import BulkUploadOrderForm from '@app/components/BulkUploadOrderForm'
import { BulkSendStatusModal } from '@app/components/BulkSendStatusModal'
import { OverlayLoader } from '@app/components/OverlayLoader'
import { FILE_SAMPLES } from '@app/containers/BulkUpload/constants'
import { UPLOAD_FILE_TYPES, firstMileSheetParser } from '@app/utils/fileUtils'
import { BulkUploadOrdersWrapper } from './styles'
import { showNotification } from '@app/utils/notify'
import { useToastContext } from '@nv/react-akira';
import { OptionsContainerWrapper, OptionsCardContainer } from '@app/components/DropOffOptions/styles.js'
import { ContentWrapper } from 'components/ContentWrapper'
import  { Content }  from '@app/pages/SendParcelPageV2/styles.js'

const { ORDER_TYPES } = reservations
const BulkUploadOrders = (props) => {
  const { addToast, removeToast } = useToastContext()
  const [bulkUploadModalStatus, setBulkUploadModalStatus] = useState(null)
 
  useEffect(() => {
    const { resetSession } = props
    resetSession()
    return () => {
      removeToast('toast-container')
    }
  }, [])

  useEffect(() => {
    const { error, clearError } = props
    if (!!error) {
      (error?.title) ? showToaster(error.title.toLowerCase(), true) : showToaster(error, false)
      clearError()
    }
  }, [props.error])

  useEffect(() => {
    const { bulkSendStatus, showModal, history, currentSession } = props
    if(currentSession) {
      if (bulkSendStatus.failedOrders.length > 0) {
        setBulkUploadModalStatus(MODALS.bulkSendHandover)
      } else if(bulkSendStatus.successfulOrders > 0) {
        history.push({ pathname: ROUTES.newConfirmOrder.path, isBulkSend: true})
      }
    }
  }, [props.bulkSendStatus])

  const showToaster = (errorMessage, shouldTranslate = true) => {
    const { intl } = props
    const errorConfig = toastConfig('error', (shouldTranslate) ? intl.formatMessage({ id: errorMessage }) : errorMessage)
    addToast(errorConfig)
  }

  /**
  * 
  * Returns the list of sample template configs to construct the download dropdown template options 
  * Post order templates are excluded, as post order creation is currently unavailable in SG.
  * 
  */
  const getFileSamples = () => {
    const fileSamples = [...FILE_SAMPLES]
    const postOcFileSamples = ['download_post_csv_template', 'download_post_excel_template']
    const samples = fileSamples.filter((fileSample) => !postOcFileSamples.includes(fileSample.title))
    return samples
  }

  const resetSession = () => {
    props.resetSession()
    props.closeModal()
  }

  const handleDownloadFailedOrders = () => {
    const { intl, bulkSendStatus } = props
    const failedOrders = _.get(bulkSendStatus, 'failedOrders', [])
    const tidHeader = intl.formatMessage({ id: 'tracking_id' })
    const reasonHeader = intl.formatMessage({ id: 'failure_reason' })
    const headers = [tidHeader, reasonHeader]
    const formattedData = failedOrders.map(o => [_.get(o, 'trackingId'), _.get(o, 'reason')])
    downloadFile(Papa.unparse([headers, ...formattedData]), 'failed_orders.csv')
  }

  const sheetProcessor = (type, data) => {
    const { dpInfo, updateCurrentSession, requestBulkSend } = props

    if (!isValidOrderTypeForCountry(dpInfo, type)) {
      showToaster('unsupported_order_type')
      return
    }

    switch (type) {
      case ORDER_TYPES.send:
        const trackingIds = _.uniq(data.join(',').split(','))
        updateCurrentSession(type)
        requestBulkSend(trackingIds)
        break
      case ORDER_TYPES.post:
      case ORDER_TYPES.bulkPost:
        // We are not implementing the logic to process post and bulk post orders at this time, as our focus is solely on migrating to the Akira Shipper Drop Off build in SG.
        // We will need to incorporate this logic when we extend the rollout to countries where post orders are enabled.
        showToaster('unsupported_order_type')
        break
      default:
        showToaster('invalid_csv_file')
    }
  }

  const handleExcelFileUpload = (file) => {
    const { intl, dpInfo } = props
    const excelProcessor = sheetProcessor
    const excelReader = new FileReader()
    excelReader.readAsArrayBuffer(file)
    excelReader.onload = function (e) {
      const sourceData = new Uint8Array(e.target.result)
      const workbook = XLSX.read(sourceData, { type: 'array' })
      const worksheet = workbook.Sheets[workbook.SheetNames[0]]
      const workSheetArray = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
      if (!workSheetArray || workSheetArray.length < 1) {
        showNotification(intl.formatMessage({ id: 'parsing_excel_error' }), NOTIFICATIONS.ERROR)
        return false
      }
      const result = workSheetArray
        .filter(row => _.get(row, 'length', 0))
        .map(row => row.map(el => (_.isNumber(el) ? el.toString() : el)))
      firstMileSheetParser(excelProcessor, result, dpInfo.isBulkPostOcAddressLevelsSupported)
    }
  }

  const handleFileUpload = file => {
    const { dpInfo } = props
    switch (file.type) {
      case UPLOAD_FILE_TYPES.CSV.type:
        Papa.parse(file, {
          skipEmptyLines: true,
          delimiter: ',',
          complete: result => firstMileSheetParser(sheetProcessor, result.data, dpInfo.isBulkPostOcAddressLevelsSupported)
        })
        break
      case UPLOAD_FILE_TYPES.MS_EXCEL.type:
      case UPLOAD_FILE_TYPES.EXCEL.type: {
        handleExcelFileUpload(file)
        break
      }
      default:
        showToaster('wrong_file_format')
        return false
    }
  }

  const isLoading = () => {
    const { isBulkOCLoading, loadingStatus } = props
    const isLoading = isBulkOCLoading || _.get(loadingStatus, 'bulkPost') || _.get(loadingStatus, 'bulkSend')
    return (isLoading)
  }

  const renderBulkSendStatusModal = () => {
    const {
      modalStatus,
      loadingStatus,
      bulkSendStatus
    } = props
    const successfulOrders = _.get(bulkSendStatus, 'successfulOrders', 0)
    const failedOrders = _.get(bulkSendStatus, 'failedOrders', [])
    const totalFailedOrders = failedOrders.length
    const isAkiraDesignForShipperDropOffEnabled = true 
    
    return <BulkSendStatusModal
      visible={!loadingStatus?.bulkSend && modalStatus === MODALS.bulkSendHandover}
      closeModal={resetSession}
      successOrderCount={successfulOrders}
      failedOrderCount={totalFailedOrders}
      handleDownloadFailedOrders={handleDownloadFailedOrders}
      isAkiraDesignForShipperDropOffEnabled={isAkiraDesignForShipperDropOffEnabled}
    />
  }

  return (
    <ContentWrapper padded={props.isDesktop} background='transparent' showDropOffAndReturnBackgroundImage={true} noMinHeight>
      <Content>
        <OptionsContainerWrapper>
          <div>
            <Text color={colors.DarkGrayTextColor} type='title' size='big' textId='drop_off_and_return' data-testid='drop-off-and-return'  />
            <OptionsCardContainer>
              <BulkUploadOrdersWrapper>
                <Text color={colors.DarkGrayTextColor} type='title' size='medium' textId='upload_orders' data-testid='upload-orders-title' />
                <BulkUploadOrderForm
                  samples={getFileSamples()}
                  handleFileUpload={handleFileUpload}
                />
                {renderBulkSendStatusModal()}
                {isLoading() && <OverlayLoader />}
              </BulkUploadOrdersWrapper>
            </OptionsCardContainer>
          </div>
        </OptionsContainerWrapper>
      </Content>
    </ContentWrapper>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    resetSession: () => {
      dispatch(bulkUploadCreators.resetSession())
    },
    updateCurrentSession: session => {
      dispatch(firstMileCreators.updateCurrentSession(session))
      dispatch(bulkUploadCreators.updateCurrentSession(session))
    },
    requestBulkSend: trackingIds => dispatch(bulkUploadCreators.requestBulkSend(trackingIds)),
    clearError: () => dispatch(baseCreators.showError(''))
  }
}

const mapStateToProps = createStructuredSelector({
  loadingStatus: selectBulkUploadLoadingStatus(),
  isBulkOCLoading: selectIsBulkOCLoading(),
  bulkPostOrders: selectBulkPostOrders(),
  bulkSendStatus: selectBulkSendStatus(),
  currentSession: makeSelectFirstMileCurrentSession(),
  dpInfo: selectDpInfo(),
  error: selectError()
})

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)
const withReducer = injectReducer({
  key: 'bulkUpload',
  reducer: bulkUploadReducer
})


export default compose(
  injectIntl,
  withRouter,
  injectCountry,
  withReducer,
  withConnect
)(BulkUploadOrders)