import styled from 'styled-components'
import { colors, display, fonts } from '@app/themes'
import { Button, Modal } from '@nv/react-akira'
import { Text } from 'components/Text'

const Description = styled.div`
  ${display.flex('center', 'center', 'column')};
  ${fonts.size.medium};
  color: ${colors.pinkishGrey};
`

const StyledModal = styled(Modal)`
  padding: 24px;
  font-family: "Noto Sans";
  color: ${colors.DarkGrayTextColor};
  && {
    h3 {
      color: ${colors.DarkGrayTextColor};
      margin-bottom: 0px !important;
    }
  }
`

const StyledDownloadErrorsModalContainer = styled.div`
  ${display.flex('center', 'center', 'column')};  
  margin-top: 16px;
`

const StyledDownloadErrorsButton = styled(Button)`
  align-self: end;
`

const StyledErrorDescription = styled(Text)`
  margin: 20px 0 10px;
  font-size: 14px;
  line-height: 22px;
`

const StyledErrorAccountManagerHelpText = styled(Text)`
  align-self: start;
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 30px;
`

export {
  Description, StyledModal, StyledDownloadErrorsModalContainer, StyledDownloadErrorsButton, StyledErrorDescription, StyledErrorAccountManagerHelpText
}
