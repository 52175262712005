import { Card } from 'antd'
import styled from 'styled-components'
import { colors, display, fonts } from '@app/themes'

export const StyledCustomCard = styled(Card)`
  && {
    ${display.flex('stretch', 'flex-start', 'column')};
    width: 100%;
    max-width: ${props => props.maxWidth};
    border-radius: 4px;
    overflow: hidden;
    cursor: ${props => (props.onClick ? 'pointer' : 'default')};
    .ant-card-head {
      background: ${props => props.headerBackground || colors.aliceBlue};
      padding: ${props => props.headerPadding || '0 16'}px;
      border: ${props => props.border};
    }
    .ant-card-head-title {
      ${fonts.style.bold};
      letter-spacing: ${props => props.letterSpacing}px;
      color: ${props => props.headerColor || colors.greyishBrown};
      text-transform: ${props => props.headercase};
    }
    .ant-card-body {
      ${display.flex('stretch', 'flex-start', 'column')};
      background-color: ${props => props.backgroundColor};
      padding: ${props => props.padding}px;
      height: 100%;
    }
    ${props =>
    props.shadow
      ? `
      box-shadow: 0 2px 4px 0 ${colors.semiTransparent};
    `
      : null};
  }
  &.ant-card-bordered {
    border-color: ${props => props.borderColor || colors.whiteFour};
  }
`
