import { colors } from '@app/themes'
import { faCheckCircle } from '@fa-pro-solid/faCheckCircle'
import { faTimesCircle } from '@fa-pro-solid/faTimesCircle'

export const ICONS = {
  success: {
    icon: faCheckCircle,
    color: colors.emerald
  },
  error: {
    icon: faTimesCircle,
    color: colors.nvPriRed
  }
}
