import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'
import { set, setLoading } from '@app/utils/reducerUtils'

export const { Types: orderDetailsTypes, Creators: orderDetailsCreators } = createActions(
  {
    reset: [],
    requestGetOrderDetails: ['country', 'trackingId', 'onSuccess'],
    successGetOrderDetails: ['order'],
    failGetOrderDetails: [],
    requestGetEventList: ['country', 'orderId'],
    successGetEventList: ['events', 'warning'],
    failGetEventList: []
  },
  { prefix: 'OrderDetails/' }
)

const INITIAL_STATE = fromJS({
  loading: {},
  order: {},
  events: [],
  warning: {}
})

export default createReducer(INITIAL_STATE, {
  [orderDetailsTypes.RESET]: state => state.set(INITIAL_STATE),
  [orderDetailsTypes.REQUEST_GET_ORDER_DETAILS]: state => setLoading(state, 'getOrderDetails', true),
  [orderDetailsTypes.SUCCESS_GET_ORDER_DETAILS]: (state, action) => {
    state = setLoading(state, 'getOrderDetails', false)
    return set(state, 'order', action.order)
  },
  [orderDetailsTypes.FAIL_GET_ORDER_DETAILS]: state => setLoading(state, 'getOrderDetails', false),
  [orderDetailsTypes.REQUEST_GET_EVENT_LIST]: state => setLoading(state, 'getEventList', true),
  [orderDetailsTypes.SUCCESS_GET_EVENT_LIST]: (state, action) => {
    state = setLoading(state, 'getEventList', false)
    state = set(state, 'events', action.events)
    return set(state, 'warning', action.warning)
  },
  [orderDetailsTypes.FAIL_GET_EVENT_LIST]: state => setLoading(state, 'getEventList', false)
})
