/**
 *
 * FormItemWithAddon
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FormItem } from '../FormItem'

// Added extra rules to cater for addonBefore
const FormItemWithAddonWrapper = styled(FormItem)`
  &&& {
    :last-child.ant-input-group-addon,
    .ant-input-group-addon {
      padding: 0;
      border: 0;
      background: transparent;
      padding-left: 10px;
    }
    :first-child.ant-input {
      border-radius: 5px;
    }
    .ant-input {
      border-radius: 0 5px 5px 0;
    }
  }
`

class FormItemWithAddon extends React.Component {
  render () {
    return <FormItemWithAddonWrapper {...this.props} />
  }
}

FormItemWithAddon.propTypes = {
  children: PropTypes.node
}

FormItemWithAddon.defaultProps = {
  children: 'FormItemWithAddon'
}

export { FormItemWithAddon }
