import React from 'react'
import { Banner } from '@nv/rc-2/Components'
import { injectIntl } from 'react-intl'
import { StyledLink } from './styles'

const NotifBanner = (props) => {
  const { intl, message, linkText, bannerType, link } = props
  const text =
    <div>
      {intl.formatMessage({ id: message })}
      <StyledLink href={link} target='_blank'>{intl.formatMessage({id: linkText})}</StyledLink>
    </div>
  return <Banner message={text} showIcon closable banner type={bannerType} />
}

const Banner_ = injectIntl(NotifBanner)
export { Banner_ as Banner }
