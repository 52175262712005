import PropTypes from 'prop-types'
import React from 'react'
import { Link as RRLink } from 'react-router-dom'
import styled from 'styled-components'
import { Icon } from '@nv/rc/Components'
import { colors, fonts } from '@app/themes'
import { trackCustomEvent } from '@app/utils/trackCustomEvent'

const StyledLink = styled(RRLink)`
  && {
    ${fonts.style.bold};
    color: ${props => props.color || colors.greyishBrown};
    ${props => props.disabled && `color: ${colors.paleGrey};`};
    font-size: ${props => props.size || fonts.size.subHeading}px;
    &:hover {
      text-decoration: underline;
      color: ${colors.nvPriRed};
    }
  }
`
const StyledA = StyledLink.withComponent('a')
const StyledLeftIcon = styled(Icon).attrs(() => ({ gap: 8 }))``
const StyledRightIcon = styled(Icon)`
  margin-right: 0;
  margin-left: 8px;
`

const Link = ({ dataTestId, onClick, leftIcon, rightIcon, children, ...props }) => {
  const L = props.to ? StyledLink : StyledA
  return (
    <L
      data-testid={dataTestId}
      onClick={event => {
        trackCustomEvent('linkclick', { dataTestId })
        onClick(event)
      }}
      {...props}
    >
      {leftIcon && <StyledLeftIcon color='inherit' disabled={props.disabled} icon={leftIcon} />}
      {children}
      {rightIcon && <StyledRightIcon color='inherit' disabled={props.disabled} icon={rightIcon} />}
    </L>
  )
}

Link.defaultProps = {
  onClick: () => {}
}

Link.propTypes = {
  children: PropTypes.node,
  dataTestId: PropTypes.string.isRequired,
  leftIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  rightIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export { Link }
