import { css } from 'styled-components'
import { media } from './media'

export const LETTER_SPACING = '0.7px'
export const LETTER_SPACING_OTP = '8px'

const light = css`
  font-weight: 300;
`
const regular = css`
  font-weight: 400;
`
const bold = css`
  font-weight: 700;
`
const italic = css`
  font-style: italic;
`

const huge = css`
  font-size: 48px;
`
const title = css`
  font-size: 32px;
`
const subtitle = css`
  font-size: 24px;
`
const heading = css`
  font-size: 20px;
`
const big = css`
  font-size: 18px;
`
const medium = css`
  font-size: 16px;
`
const subHeading = css`
  font-size: 14px;
`
const small = css`
  font-size: 12px;
`
const tiny = css`
  font-size: 10px;
`

export const dynamicFontSize = (size = medium, scaleDownValue = 2) => css`
  ${media.max.tablet`${size[0].substring(0, size[0].length - 5) +
    (size[0].substring(size[0].length - 5, size[0].length - 3) - scaleDownValue) +
    'px;'}`};
  ${media.min.desktop`${size}`};
`
export const style = {
  light,
  regular,
  bold,
  italic
}

export const size = {
  title,
  subtitle,
  heading,
  subHeading,
  small,
  medium,
  big,
  huge,
  tiny
}
