// @flow

import type { Shipper, ShipperData } from '@app/types/Shipper'
import configs from 'configs'
import { mockUserInfo } from '@app/constants'
import { rest } from 'msw'
import snake from 'snakecase-keys'

const mask = `${configs.BASE_URL}/:systemId/dp/2.0/shippers/:shipperId`

const { MOCK_CONTACT_NUMBER } = mockUserInfo
export const getShipperByIdApiMswHandler = rest.get(mask, (req, res, ctx) => {
  const { shipperId } = req.params
  const shipper: Shipper = {
    globalId: shipperId,
    id: shipperId,
    isDefault: true,
    isPrepaid: false,
    liaisonAddress: 'Jalan Delman Elok IV no 1',
    liaisonContact: MOCK_CONTACT_NUMBER,
    liaisonEmail: 'tristania.siagian@ninjavan.co',
    liaisonName: 'Ninja Point V3 Automation New',
    liaisonPostcode: '12240',
    name: 'Ninja Point V3 Automation New'
  }
  const data: ShipperData = {
    data: shipper
  }
  return res(ctx.status(200), ctx.json(snake(data)))
})
