/**
 *
 * Row
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { display } from '@app/themes'

const StyledRow = styled.div`
  && {
    display: flex;
    flex-direction: row;
    vertical-align: ${props => props.verticalAlign};
    align-items: ${props => props.alignItems};
    ${props => props.height && `height: ${props.height};`};
    ${props => props.wrap && `flex-wrap: wrap;`};
    ${props => props.full && `width: 100%;`};
    ${props =>
    props.end &&
      `
    justify-content: flex-end;
    align-self: flex-end;
    `};
    ${props =>
    props.start &&
      `
    justify-content: flex-start;
    align-self: flex-start;
    `};
    ${props =>
    props.center &&
      `
    justify-content: center;
    align-self: center;
    `};
    ${props =>
    props.spaceBetween &&
      `
      justify-content: space-between;
    `}

    ${props => display.childMargins('column', props.childMargin)};

    ${props =>
    props.verticalMargin &&
      `
    > * {
      margin-top: ${props => props.verticalMargin}px;
      margin-bottom: ${props => props.verticalMargin}px;
    }
    `};

    ${props => props.equal && `&& { align-items: stretch; > * { flex: 0 1 100%; } }`};
  }
`

const Row = props => <StyledRow {...props} />

Row.propTypes = {
  children: PropTypes.node,
  alignItems: PropTypes.string,
  verticalAlign: PropTypes.string,
  childMargin: PropTypes.number,
  height: PropTypes.string
}

Row.defaultProps = {
  children: 'Row',
  alignItems: 'center',
  verticalAlign: 'middle',
  childMargin: 10
}

export { Row }
