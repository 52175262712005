import { css } from 'styled-components'

export const sizes = {
  desktop: 992,
  tablet: 768,
  mobile: 480
}

const reducer = prop => (accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const pxSize = prop === 'min-width' ? sizes[label] + 1 : sizes[label]
  const emSize = pxSize / 16
  accumulator[label] = (...args) => css`
    @media (${prop}: ${emSize}em) {
      ${css(...args)};
    }
  `
  return accumulator
}
// iterate through the sizes and create a media template
export const media = {
  min: Object.keys(sizes).reduce(reducer('min-width'), {}),
  max: Object.keys(sizes).reduce(reducer('max-width'), {})
}
