// @flow

import React from 'react'
import { injectIntl } from 'react-intl'
import type { Props } from '@app/models/CustomButton.model'
import { trackCustomEvent } from '@app/utils/trackCustomEvent'
import { NVButton } from '@nv/rc-2/Components'

const CustomButton = (props: Props) => {
  const { intl, children, dataTestId, onClick, textId, textValues, ...passThroughProps } = props

  const handleClick = (e, onClickHandler) => {
    e.stopPropagation()
    trackCustomEvent('custombuttonclick', { dataTestId: dataTestId })
    if (typeof onClickHandler === 'function') {
      onClickHandler(e)
    }
  }

  const text = textId ? intl.formatMessage({ id: textId }, textValues) : null

  return (
    <NVButton data-testid={dataTestId} onClick={evt => handleClick(evt, onClick)} {...passThroughProps}>
      {children || text || 'CustomButton'}
    </NVButton>
  )
}

const CustomButton_ = injectIntl(CustomButton)
export { CustomButton_ as CustomButton }
