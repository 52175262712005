// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

export default (country: string, trackingId: string) => {
  const client = getApiClient(apiClients, country)
  const url = `1.0/orders/details/${trackingId}`
  return client.get(url)
}
