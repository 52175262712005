/**
 *
 * Asynchronously loads the component for ShipperSearch
 *
 */

import Loadable from 'react-loadable'

export default Loadable({
  loader: () => import('./index' /* webpackChunkName: "ShipperSearchPage" */),
  loading: () => null
})
