import styled from 'styled-components'

import { colors } from '@app/themes'

export const Divider = styled.div`
  align-self: stretch;
  background-color: ${colors.paleGrey};
  margin: 0px;
  min-height: 2px;
  min-width: 2px;
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}px;`};
  ${props => props.marginTop && `margin-top: ${props.marginTop}px;`};
`
