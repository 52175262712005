import { apiClientsNoTransform } from '@app/services/api/clients'
import { billingZonePayload } from '@app/types/billingZonePayload'
import { BillingZoneResponse } from '@app/types/billingZone'
import type { Response } from '@app/types/Response'

export default (country: string, payload: billingZonePayload): Response<Array<BillingZoneResponse>> => {
  const client = apiClientsNoTransform[country]
  const url = `1.0/dp-billing-zones`
  return client.post(url, payload)
}
