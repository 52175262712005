import Config from 'configs'
import Images from 'images'
import _ from 'lodash'
import spacing from './spacing'
import colors from './colors'
import * as display from './display'
import * as fonts from './fonts'
import { media } from './media'

const ninjaVan = {
  background: `linear-gradient(${colors.NinjaRed}, ${colors.Black})`,
  colors: {
    primary: colors.nvPriRed,
    secondary: colors.nvDarkRed
  },
  favicon: 'favicon.ico',
  images: {
    confirmOrder: Images.ryoWriting,
    firstMile: Images.ryoTyping,
    logo: Images.ninjaVanLogo,
    logoWhite: Images.ninjaVanLogoWhite,
    pin: Images.ninjaVanPin,
    reviewOrder: Images.ryoJumping
  },
  title: 'Ninja Point'
}

const chronoDiali = _.merge(_.cloneDeep(ninjaVan), {
  background: colors.ChronopostBlack,
  colors: {
    primary: colors.ChronopostBlack,
    secondary: colors.ChronopostBlack
  },
  favicon: 'favicon-cd.png',
  images: {
    confirmOrder: Images.chronoDialiPencil,
    firstMile: Images.chronoDialiBox,
    logo: Images.chronoDialiLogo,
    logoWhite: Images.chronoDialiLogoWhite,
    pin: Images.chronoDialiBox,
    reviewOrder: Images.chronoDialiConfirm
  },
  title: 'Chrono Diali'
})

export const getTheme = () => {
  switch (Config.ENVIRONMENT) {
    case 'cd':
      return chronoDiali
    default:
      return ninjaVan
  }
}

export { colors, display, fonts, media, spacing }
