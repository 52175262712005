/*
 *
 * LanguageProvider reducer
 *
 */

import { fromJS } from 'immutable'

import { CHANGE_LOCALE } from './constants'
import { DEFAULT_LOCALE } from '../Base/constants'

const initialState = fromJS({
  locale: DEFAULT_LOCALE
})
function normalizeLocale (locale) {
  switch (locale) {
    case 'sg':
    case 'my':
    case 'ph':
    case 'mm':
      return 'en'
    case 'ma':
      return 'fr'
    case 'vn':
      return 'vi'
    default:
      return locale
  }
}

function languageProviderReducer (state = initialState, action) {
  switch (action.type) {
    case CHANGE_LOCALE:
      return state.set('locale', normalizeLocale(action.locale))
    default:
      return state
  }
}

export default languageProviderReducer
