import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import  {compose}  from 'redux'
import { NVLogo } from '@app/components/Logo'
import Images from 'images'
import { getNavConfigsV2 } from '@app/configs/navConfig'
import {
  HomeIcon, DeliveryIcon, TruckIcon, SettingIcon, BoxIcon, SearchIcon,
  MenuIcon, Divider, Avatar, AngleDownIcon, AngleUpIcon, OutIcon, UIPanel, Tooltip
} from '@nv/react-akira'
import {
  Sider, LogoWrapper, UserNameWrapper, IconWrapper,
  CustomMenu, DividerWrapper, UserNavMenu
} from './styles.js'
import { colors } from '@app/themes'
import commons from '@app/constants/commons'
import { ROUTES } from '@app/utils/constants'

const iconMap = {
  HomeIcon: <HomeIcon />,
  DeliveryIcon: <DeliveryIcon />,
  TruckIcon: <TruckIcon />,
  SettingIcon: <SettingIcon />,
  BoxIcon: <BoxIcon />,
  SearchIcon: <SearchIcon />,
  OutIcon: <OutIcon />,
}

class NavigationSidebarV2 extends React.Component {
  state = {
    isMenuOptionsMinimized: false,
    showUserMenuOptions: false,
    ...getNavConfigsV2({
      country: this.props.country,
      settingType: this.props.settingType,
      isNewDriverHandoverEnabled: this.props.isNewDriverHandoverEnabled
    })
  }

  toggleIsMenuOptionsMinimized = () => {
    const { isMenuOptionsMinimized } = this.state
    const { handleCollapseMenu } = this.props
    handleCollapseMenu(!isMenuOptionsMinimized)
    this.setState({
      isMenuOptionsMinimized: !isMenuOptionsMinimized
    })
  }

  toggleShowUserMenuOptions = () => {
    const { showUserMenuOptions } = this.state
    this.setState({
      showUserMenuOptions: !showUserMenuOptions
    })
  }

  renderIconImage = (iconName) => {
    return iconMap[iconName]
  }

  renderMenuItem(item) {
    const { path, render } = item
    const { isMenuOptionsMinimized } = this.state
    const { selectedKeys, handleClickMenu, intl } = this.props
    return (
      <UIPanel.Item
        startIcon={render?.iconImg && this.renderIconImage(render.iconImg)}
        label={!isMenuOptionsMinimized && render?.label && intl.formatMessage({ id: render.label })}
        isSelected={selectedKeys?.includes(path)}
        onClick={() => { handleClickMenu({ key: path }) }}
        data-key={render.label}
        role="menuitem"
      />
    )
  }

  renderNav(config) {
    return config.map((item, index) => {
      return this.renderMenuItem(item)
    })
  }

  renderUserNavMenu = () => {
    const { userSubNavConfig, showUserMenuOptions } = this.state
    const { dpName } = this.props
    return (
      <UserNavMenu>
        <DividerWrapper>
          <Divider color="dark" className='divider' />
        </DividerWrapper>
        <UserNameWrapper data-testid="user-name-menu-item">
          <Tooltip content={dpName} theme="dark" className='tooltip-container' maxWidth={commons.SIDEBAR_WIDTH}>
            <UIPanel.Item
              startIcon={<Avatar image={Images.ryoThumbsUp} size='sm' />}
              label={dpName}
              isSelected={false}
              endIcon={(showUserMenuOptions) ? <AngleUpIcon size="l" color={colors.white} /> : <AngleDownIcon size="l" color={colors.white} />}
              onClick={this.toggleShowUserMenuOptions}
            />
          </Tooltip>
        </UserNameWrapper>
        {showUserMenuOptions &&
          <CustomMenu>
            <DividerWrapper>
              <Divider color="dark" className='divider' />
            </DividerWrapper>
            {this.renderNav(userSubNavConfig)}
            <DividerWrapper>
              <Divider color="dark" className='divider' />
            </DividerWrapper>
          </CustomMenu>
        }
      </UserNavMenu>
    )
  }

  /**
   * Show new send parcel page when user click on drop off and return navigation item 
   */ 
  
  getNavConfigsV2ForShipperDropOffAkiraDesignEnabled() {
    const { navConfig } = this.state
    const newNavConfig = _.assign([], navConfig)

    const index = _.findIndex(navConfig, (item) => { return item.render.label == "drop_off_and_return"; });
    if (index && newNavConfig[index]) {
      newNavConfig[index].path = ROUTES.sendParcelV2.path
    }
    return newNavConfig;
  }

  render() {
    const { navConfig, isMenuOptionsMinimized } = this.state
    const { collapsed, isDesktop, handleClickLogo, isShipperDropOffRevampEnabled } = this.props
    
    return (
      <Sider
        trigger={null}
        collapsible={!isDesktop}
        collapsed={!isDesktop && collapsed}
        collapsedWidth={commons.SIDEBAR_COLLAPSED_WIDTH}
        width={(isMenuOptionsMinimized) ? commons.MIN_SIDEBAR_WIDTH : commons.SIDEBAR_WIDTH}
      >
        {isDesktop && <LogoWrapper isMenuOptionsMinimized={isMenuOptionsMinimized}>
          <IconWrapper onClick={this.toggleIsMenuOptionsMinimized}>
            <MenuIcon size='l' color='white' data-testid="menu-icon" />
          </IconWrapper>
          {!isMenuOptionsMinimized && <NVLogo width={140} height={40} onClick={handleClickLogo} data-testid="nv-logo" />}
        </LogoWrapper>
        }
        <UIPanel className="w-full top-0 nav-options-container" isDark>
          {isShipperDropOffRevampEnabled ? this.renderNav(this.getNavConfigsV2ForShipperDropOffAkiraDesignEnabled()) : this.renderNav(navConfig)}
          {!isMenuOptionsMinimized && this.renderUserNavMenu()}
        </UIPanel>
      </Sider>
    )
  }
}

NavigationSidebarV2.propTypes = {
  isDesktop: PropTypes.bool,
  collapsed: PropTypes.bool,
  dpName: PropTypes.string,
  selectedKeys: PropTypes.array,
  settingType: PropTypes.array
}

const NavigationSidebar = compose(injectIntl)(NavigationSidebarV2)
export { NavigationSidebar as NavigationSidebarV2 }
