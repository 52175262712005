import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { FormItemWithAddon } from '@app/components/FormItemWithAddon'
import { ScannableInput } from '@app/components/ScannableInput'
import { T } from '@nv/rc/Components'
import { barcodeType } from './constants'
import { ERROR_CODES, ORDER_TYPE, EMPTY_STAMP_ID } from '@app/utils/constants'

class OrderIdFormItem extends React.PureComponent {
  getRequiredRule () {
    const { intl, required } = this.props
    if (required) {
      return {
        required: true,
        message: intl.formatMessage({ id: 'this_field_cannot_be_left_blank' })
      }
    } 
    return {
      required: false,
    }
  }

  // Orders with empty stamp id will not be counted as duplicate and only applicable if optional stamp id is enabled
  // Stamp ID was passed into the component as orderId props, which explains why we have this weird string comparison between orderId and EMPTY_STAMP_ID
  isDuplicate = orderId => {
    const { orderIds } = this.props
    return orderIds.includes(orderId) && orderId !== EMPTY_STAMP_ID
  }

  handleRequestError = (error, callback) => {
    const { intl, type } = this.props
    const errors = []
    if (error) {
      const id =
        error.code === ERROR_CODES.CONFLICT_STAMP_ID_USED ? barcodeType[type].conflict : barcodeType[type].invalid
      errors.push(new Error(intl.formatMessage({ id })))
    }
    callback(errors)
  }

  inputValidator = (type, value, callback) => {
    const { intl, isVerified, requestValidateBarcode, onSuccess, handleInputChange, fieldName, isStampIdOptional} = this.props
    handleInputChange(value, fieldName)

    if (!isVerified) {
      if (this.isDuplicate(value)) {
        callback(new Error(intl.formatMessage({ id: 'order_with_id_already_added' }, { id: value })))
      } else {
        requestValidateBarcode(value, onSuccess, error => this.handleRequestError(error, callback), isStampIdOptional)
      }
    } else {
      callback()
    }
  }

  render () {
    const {
      intl,
      type,
      form: { getFieldDecorator },
      fieldName,
      initialValue,
      disabled,
      onChange,
      label,
      width,
      handleScan,
      title = barcodeType[type].title,
      placeholder = barcodeType[type].placeholder,
      buttonlabel
    } = this.props

    return (
      <FormItemWithAddon label={label && <T id={label} />} width={width}>
        {getFieldDecorator(fieldName, {
          initialValue,
          validateFirst: true,
          validate: [
            {
              trigger: 'onBlur',
              rules: [this.getRequiredRule(), { validator: this.inputValidator }]
            }
          ]
        })(
          <ScannableInput
            disabled={disabled}
            title={intl.formatMessage({ id: title })}
            placeholder={intl.formatMessage({ id: placeholder })}
            buttonlabel={intl.formatMessage({ id: buttonlabel })}
            data-testid={_.kebabCase(title)}
            handleScan={handleScan}
            height={32}
            onChange={onChange}
          />
        )}
      </FormItemWithAddon>
    )
  }
}

OrderIdFormItem.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf([ORDER_TYPE.TRACKING_ID, ORDER_TYPE.STAMP_ID])
}

OrderIdFormItem.defaultProps = {
  buttonlabel: 'scan',
  type: ORDER_TYPE.TRACKING_ID,
  requestValidateBarcode: _.noop,
  handleInputChange: _.noop
}

const OrderIdFormItem_ = injectIntl(OrderIdFormItem)

export { OrderIdFormItem_ as OrderIdFormItem }
