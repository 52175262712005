import { css } from 'styled-components'

/**
 *
 * @param {string} [align]
 * @param {string} [justify]
 * @param {string} [dir]
 * @returns css
 */
const flex = (align = 'flex-start', justify = 'flex-start', dir = 'row') => css`
  display: flex;
  flex-direction: ${dir};
  align-items: ${align};
  justify-content: ${justify};
`

/**
 *
 * @param {string} type
 * @param {number} [margin]
 * @returns css
 */
const childMargins = (type, margin = 10) => css`
  ${type === 'row' &&
    `
    > * {
      margin-bottom: ${margin}px;
    }
    > :last-child {
      margin-bottom: 0;
    }
  `};
  ${type === 'column' &&
    `
    > * {
      margin-right: ${margin}px;
    }
    > :last-child {
      margin-right: 0;
    }
  `};
  ${type === 'row-reverse' &&
    `
    > * {
      margin-bottom: ${margin}px !important;
    }
    > :first-child {
      margin-bottom: 0 !important;
    }
  `};
`
export { flex, childMargins }
