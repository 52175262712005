import styled from 'styled-components'
import { display, colors } from '@app/themes'
import { Text } from '@app/components/Text'

const BulkUploadOrdersFormWrapper = styled.div`
  && {
    font-family: "Noto Sans" !important;
    ${display.childMargins('row')};
  }
`

const FileUploadWrapper = styled.div`
  && {
    .inline-block {
        width: 100%;
      .text-grey-2 {
        font-family: "Noto Sans" !important;
      }
    }
  }
`
const FormItemWrapper = styled.div`
  margin-top: 22px !important;
  &.switch-to-scanning-link {
    margin-top: 30px !important;
  }
  && {
    .custom-dragger-menu-item-link {
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: ${colors.DarkGrayTextColor};
    }
  }
}
`
const StyledText = styled(Text)`
  margin-bottom: 10px;
  color:${colors.DarkGrayTextColor};
  font-size: 12px;
`

export {
    BulkUploadOrdersFormWrapper, 
    FileUploadWrapper,
    FormItemWrapper,
    StyledText
}