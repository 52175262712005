// @flow

export const DATE_FORMAT = {
  YEAR_MONTH_DAY_WITH_TIME_DASHED: 'YYYY-MM-DD HH:mm:ss',
  DAY_MONTH_YEAR_DASHED: 'DD-MM-YYYY',
  YEAR_MONTH_DAY_DASHED: 'YYYY-MM-DD',
  YEAR_MONTH_DASHED: 'YYYY-MM',
  DAY_MONTH_SLASHED: 'DD/MM',
  DAY_MONTH_YEAR_SLASHED: 'DD/MM/YYYY',
  DAY_MONTH_WITH_SHORT_YEAR_SLASHED: 'DD/MM/YY',
  DAY_MONTH_YEAR_HOUR_MINUTES_SLASHED: 'DD/MM/YYYY HH:mm',
  SHORT_MONTH_WITH_SHORT_YEAR: "MMM 'YY",
  DAY_FULL_MONTH_WITH_FULL_YEAR: "DD MMMM YYYY"
}
