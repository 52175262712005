import styled from 'styled-components'
import { display, media } from 'themes'
import { Banner } from '@nv/rc-2/Components'

const UpdateWarningContainer = styled.div`
  && {
    ${media.min.tablet`
      ${display.flex('center', 'flex-start')};
      & > button {
        margin-left: 12px;
      }
      & > span {
        padding: 0.35rem 0;
      }
    `};
  }
`

const BannerWithComponent = styled(Banner)`
  && {
    & > i {
      padding: 0.35rem 0;
    }
  }
`

export { UpdateWarningContainer, BannerWithComponent }
