export default (file, filename, encoding, options = {}) => {
  const blobArray = [file]
  if (encoding) {
    blobArray.splice(0, 0, encoding)
  }
  const blob = new Blob(blobArray, options)
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename)
  } else {
    const a = window.document.createElement('a')
    a.href = window.URL.createObjectURL(blob)
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}
