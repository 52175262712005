// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

import type { Response } from '@app/types/Response'
import type { OrderEventsData } from '@app/types/OrderEventsData'
import type { ErrorData } from '@app/types/ErrorData'

export default (country: string, orderId: number): Promise<Response<OrderEventsData | ErrorData>> => {
  const client = getApiClient(apiClients, country)
  const url = `1.0/orders/${orderId}/events`
  return client.get(url)
}
