/**
 *
 * VerticalSpace
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledVerticalSpace = styled.div`
  height: ${props => props.size}px;
  width: 100%;
`

const VerticalSpace = props => <StyledVerticalSpace {...props} />

VerticalSpace.propTypes = {
  children: PropTypes.node,
  size: PropTypes.number.required
}

VerticalSpace.defaultProps = {
  size: 10
}

export { VerticalSpace }
