// @flow

import { rest } from 'msw'
import configs from 'configs'
import { mockUserInfo } from '@app/constants'

import type { PutOrderData } from '@app/types/Order'

const { MOCK_DP_ID, MOCK_CONTACT_NUMBER, MOCK_SHIPPER_ID } = mockUserInfo

export const putOrdersMswHandler = rest.put(`${configs.BASE_URL}/:systemId/dp/1.1/orders`, (req, res, ctx) => {
  const putOrderData: PutOrderData = []
  req.body.forEach(order => {
    putOrderData.push({
      data: {
        barcode: order.tracking_id,
        contact: MOCK_CONTACT_NUMBER,
        deadline: '2020-12-03',
        dpId: MOCK_DP_ID,
        dropOffOn: '2020-12-03',
        droppedOffAt: '2020-12-03 09:42:13',
        endDate: '2020-12-03',
        fromContact: MOCK_CONTACT_NUMBER,
        fromName: 'Hatin ID',
        id: 717980,
        orderId: 4101680,
        overstayDate: '2020-12-03',
        receivedFrom: 'CUSTOMER',
        releasedAt: '2021-01-22 05:03:11',
        releasedTo: order.released_to,
        shipperId: MOCK_SHIPPER_ID,
        smsNotificationStatus: 'NA',
        startDate: '2020-12-03',
        status: 'RELEASED',
        toName: 'Fakihatin Wafiyah'
      },
      error: null,
      uuid: null
    })
  })
  return res(ctx.status(200), ctx.json(putOrderData))
})
