import { StyledModal, StyledModalText, StyledPrimaryButton, StyledSecondaryButton } from './styles'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import React from 'react'

const LeaveWithoutCheckingInOrdersModal = props => {
  const { intl, handleOnClose, handleRedirection, visible } = props
  return (
  <StyledModal
    visible={visible}
    width={'xs'}
    zIndex={'1000'}
    header={intl.formatMessage({ id: 'leave_without_checking_in_parcel_modal_header' })}
    onClose={handleOnClose}
  >
    <StyledModalText data-testid='modal-content'>
      {intl.formatMessage({ id: 'leave_without_checking_in_parcel_modal_content' })}
    </StyledModalText>
    <StyledPrimaryButton styleType='primary' variant='grey' size='lg'
      data-testid='go-back-to-review-parcels'
      onClick={() => handleOnClose()}>
      {intl.formatMessage({ id: 'go_back_to_review_parcels' })}
    </StyledPrimaryButton>
    <StyledSecondaryButton styleType='secondary' variant='default' size='lg'
      data-testid='leave-without-checking-in'
      onClick={() => handleRedirection()}>
      {intl.formatMessage({ id: 'leave_without_checking_in' })}
    </StyledSecondaryButton>
  </StyledModal>
  )
}

LeaveWithoutCheckingInOrdersModal.propTypes = {
  visible: PropTypes.bool,
  handleOnClose: PropTypes.func.isRequired,
  handleRedirection: PropTypes.func.isRequired
}

LeaveWithoutCheckingInOrdersModal.defaultProps = {
  visible: false
}

const LeaveWithoutCheckingInOrdersModal_ = injectIntl(LeaveWithoutCheckingInOrdersModal)
export { LeaveWithoutCheckingInOrdersModal_ as LeaveWithoutCheckingInOrdersModal }
