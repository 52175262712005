import styled from 'styled-components'
import { Button, Modal } from '@nv/react-akira'
import { Text } from '@app/components/Text'

const StyledModal = styled(Modal)`
	margin: 20px;
`
const StyledModalText = styled(Text)`
  font-size: 14px;
  line-height: 1.43;
`

const StyledButton = styled(Button)`
	height: 50px;
	width: 100%;
`
const StyledPrimaryButton = styled(StyledButton)`
	margin: 30px 0px 20px 0px;
`

const StyledSecondaryButton = styled(StyledButton)`
	margin-bottom: 30px;
`

export { StyledModal, StyledModalText, StyledPrimaryButton, StyledSecondaryButton }