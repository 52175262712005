// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

export const getShipperByIdApi = (country: string, shipperId: number) => {
  const client = getApiClient(apiClients, country)
  const urlString = `2.0/shippers/${shipperId}`
  return client.get(urlString)
}

export const getShipperByExternalIdApi = (country: string, externalShipperId: string) => {
  const client = getApiClient(apiClients, country)
  const urlString = `2.0/shippers/external-ids/${externalShipperId}`
  return client.get(urlString)
}