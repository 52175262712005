/**
 *
 * ResponsiveContainer
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Row } from '@app/components/Row'
import { Column } from '@app/components/Column'
import { injectSize } from '@nv/rc/Components'

const ResponsiveContainer = props =>
  (props.breakpoint !== undefined ? props.breakpoint : props.isDesktop) ? <Row {...props} /> : <Column {...props} />

ResponsiveContainer.propTypes = {
  children: PropTypes.node
}

ResponsiveContainer.defaultProps = {
  children: 'ResponsiveContainer'
}

const ResponsiveContainer_ = injectSize(ResponsiveContainer)
export { ResponsiveContainer_ as ResponsiveContainer }
