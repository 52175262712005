/**
 * @file contains component for manual input PACK order form. Only used in PH.
 */
import { Divider, ManualPackWrapper } from './styles'
import { Form, Input } from 'antd'
import { ORDER_TYPE, PACK_FORM_FIELDS } from '@app/utils/constants'
import React, { useEffect } from 'react'
import { FORM_ITEM_TYPES } from '@app/components/FormItemWrapper/constants'
import { FormItemWrapper } from '@app/components/FormItemWrapper'
import { OrderIdFormItem } from '@app/components/OrderIdFormItem'
import { Props } from '@app/models/PackManualInputForm.model'
import { RecipientDetailsForm } from '@app/components/RecipientDetailsForm'
import _ from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { firstMileCreators } from '@app/containers/FirstMile/redux'
import { injectIntl } from 'react-intl'
import { makeSelectFirstMileOrders } from '@app/containers/FirstMile/selectors'
import { selectCountry } from '@app/containers/Base/selectors'
import { withRouter } from 'react-router-dom'

const InputGroup = Input.Group

export const PackManualInputForm = (props: Props) => {
  const {
    form,
    index,
    fieldsToValidate,
    onValidate,
    resetting,
    parcel,
    updateParcel,
    intl,
    country,
    orders,
    isEdit,
    location,
    requestGetOrder,
    isEditOrderEnabled
  } = props

  useEffect(() => form.resetFields(), [index])

  useEffect(() => {
    if (resetting) {
      form.validateFields(fieldsToValidate, onValidate)
    }
  }, [resetting])

  const handleInputChange = (value, key, checkboxKey) => {
    if (parcel[key] !== value) {
      const updatedParcel = Object.assign({}, parcel)
      // Due to duplicate postal code form fields bug in manual PACK in https://jira.ninjavan.co/browse/DP-3033
      // TODO fix ticket: https://jira.ninjavan.co/browse/DP-6804
      if (key === PACK_FORM_FIELDS.postalCode || key === PACK_FORM_FIELDS.recipientPostCode) {
        _.set(updatedParcel, PACK_FORM_FIELDS.postalCode, value)
        _.set(updatedParcel, PACK_FORM_FIELDS.recipientPostCode, value)
      } else {
        _.set(updatedParcel, key, value)
      }
      checkboxKey && _.set(updatedParcel, checkboxKey, true)
      updateParcel(updatedParcel, index)
    }
  }

  const handleScan = trackingId => {
    requestGetOrder(trackingId, location.pathname, index)
    handleInputChange(trackingId, PACK_FORM_FIELDS.packId)
  }

  const updateTrackingIdVerified = verified => {
    const updatedParcel = Object.assign({}, parcel)
    _.set(updatedParcel, 'trackingId.isVerified', verified)
    updateParcel(updatedParcel, index)
  }

  const handleSelectLocation = (updatedParcel, getLatLng) => {
    const { state, city, district } = updatedParcel.location
    updatedParcel.location = {
      ...updatedParcel.location,
      ...getLatLng(state, city, district)
    }
    updateParcel(updatedParcel, index)
  }

  const trackingIds = _(orders)
    .get('[0].parcels', [])
    .filter((p, idx) => idx !== index)
    .map(p => p.trackingId)

  return (
    <ManualPackWrapper>
      <OrderIdFormItem
        type={ORDER_TYPE.TRACKING_ID}
        form={form}
        label='pack_id'
        width={300}
        required
        disabled={isEdit}
        fieldName='trackingId'
        placeholder='enter_pack_id'
        isVerified={_.get(parcel, 'packId.isVerified', false)}
        initialValue={_.get(parcel, PACK_FORM_FIELDS.packId)}
        handleScan={handleScan}
        handleInputChange={handleInputChange}
        onChange={() => updateTrackingIdVerified(false)}
        onSuccess={() => updateTrackingIdVerified(true)}
        orderIds={trackingIds}
      />

      <InputGroup compact>
        <FormItemWrapper
          form={form}
          type={FORM_ITEM_TYPES.TEXT}
          fieldLabel={intl.formatMessage({ id: 'pack_description' })}
          fieldId={PACK_FORM_FIELDS.description}
          dataTestId={PACK_FORM_FIELDS.description}
          initialValue={_.get(parcel, PACK_FORM_FIELDS.description)}
          required
          placeholder={intl.formatMessage({ id: 'enter_pack_description' })}
          onChange={e => handleInputChange(e.target.value, PACK_FORM_FIELDS.description)}
        />
      </InputGroup>

      <Divider />

      <RecipientDetailsForm
        form={form}
        formFields={PACK_FORM_FIELDS}
        country={country}
        parcel={parcel}
        handleInputChange={handleInputChange}
        isEdit={isEdit}
        isEditOrderEnabled={isEditOrderEnabled}
        orders={orders}
        handleSelectLocation={handleSelectLocation}
        isPack
      />
    </ManualPackWrapper>
  )
}

PackManualInputForm.defaultProps = {
  index: 0,
  parcel: {},
  fieldsToValidate: [],
  country: 'sg',
  orders: []
}

function mapDispatchToProps (dispatch) {
  return {
    dispatch,
    requestGetOrder: (id, path, index) => {
      dispatch(firstMileCreators.requestGetOrder(id, path, index))
    },
    updateParcel: _.throttle((parcel, index) => {
      dispatch(firstMileCreators.updateParcel(parcel, index))
    }, 500)
  }
}

const mapStateToProps = createStructuredSelector({
  country: selectCountry(),
  orders: makeSelectFirstMileOrders()
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(Form.create(), injectIntl, withConnect, withRouter)(PackManualInputForm)
