import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import _ from 'lodash'
import { locationsCreators, locationsTypes } from './redux'
import { dpApi } from '@app/services/api'
import { selectCountry } from '@app/containers/Base/selectors'
import { baseCreators } from '@app/containers/Base/redux'
import { firstMileCreators } from '@app/containers/FirstMile/redux'

const { showLoading, showError } = baseCreators
const { successGetLocations, failGetLocations } = locationsCreators
const { updateParcel } = firstMileCreators
const { REQUEST_GET_LOCATIONS } = locationsTypes

// Populate State/City/District, and L1/L2/L3 addresses if necessary, to populate locations dropdown menu
export function * getLocations (action) {
  const { isQuickAddressFeatureEnabled, l1Address, l2Address } = action
  // updatedParcelDetails is only used to determine whether to update form with parcel details after user clicks button to serach for Quick Address

  yield put(showLoading(true))
  const country = yield select(selectCountry())

  // For Quick Address, fetch State/City/District/L1/L2/L3 from addressing divisions
  // Divisions endpoint returns options for the most granular level, depending on whether l1Address and l2Address are provided
  // e.g if l1Address is provided, response will only include L2 addresses belonging to that L1 address, thus there will be no options to populate L1 address dropdown menu
  // Thus, we need to make up to three requests, depending on whether l1Address and l2Address are provided in order to populate all three dropdown menus
  // Scenarios to consider: Order creation, Editing order, loading consignee info onto form and loading quick address search result onto form
  if (isQuickAddressFeatureEnabled) {
    let shouldUpdateStates = false
    let shouldUpdateCities = false
    let locationsResponse
    let states = []
    let cities = []
    let l1AddressData = []
    let l2AddressData = []
    let l3AddressData = []

    // Fetch L1 addresses
    locationsResponse = yield call(dpApi.getDivisions, country, null, null)
    let { data, error } = locationsResponse.data
    if (!locationsResponse.ok) {
      if (error && error.message) yield put(showError(error.message))
      yield put(failGetLocations)
      yield put(showLoading(false))
      return
    }

    states = _.uniqBy(data, 'l1AddressDisplayValue').map(location => location.l1AddressDisplayValue)
    l1AddressData = _.uniqBy(data, 'l1AddressDisplayValue')
      .map(location => { return { displayValue: location.l1AddressDisplayValue, value: location.l1AddressValue } })

    // Shipper Address service returns L1/L2/L3 addresses from given address string, however, these may include unsupported L1/L2/L3 divisions for delivery and/or pickup
    // DP service only fetches supported divisions when calling Shipper Address service
    // This can be configured by setting include-delivery-unsupported and include-pickup-unsupported query params to true/false when calling Shipper Address service GET /1.0/divisions
    // See https://confluence.ninjavan.co/display/NVE/TDD+for+Address+division+selection+and+smart-paste+in+Shipper+Address#/Divisions%20API/get_1_0_divisions
    // If the parsed L1/L2/L3 address has unsupported divisions, we will not use the Quick Address result and require user to manually enter the fields instead
    // This will prevent users from being able to create orders with unsupported divisions, see DP-7215
    const isL1AddressSupported = (_.findIndex(l1AddressData, (supportedL1Address) => {
      return supportedL1Address?.displayValue === l1Address
    })) !== -1

    // Stop fetching L2/L3 addresses if Quick Address Search L1 address is an unsupported division OR if user has not selected L1
    if (!isL1AddressSupported || (_.isNil(l1Address) && _.isNil(l2Address))) {
      shouldUpdateStates = true
      yield put(successGetLocations(data, states, cities, l1AddressData, l2AddressData, l3AddressData, shouldUpdateStates, shouldUpdateCities))
      yield put(showLoading(false))
      return
    }

    // Fetch L2 addresses
    locationsResponse = yield call(dpApi.getDivisions, country, l1Address, null)
    error = locationsResponse?.data?.error
    if (!locationsResponse.ok) {
      if (error && error.message) yield put(showError(error.message))
      yield put(failGetLocations)
      yield put(showLoading(false))
      return
    }

    data = locationsResponse?.data?.data
    cities = _.uniqBy(data, 'l2AddressDisplayValue').filter(location => !_.isNil(location.l2AddressDisplayValue)).map(location => location.l2AddressDisplayValue)
    l2AddressData = _.uniqBy(data, 'l2AddressDisplayValue')
      .filter(location => !_.isNil(location.l2AddressDisplayValue))
      .map(location => { return { displayValue: location.l2AddressDisplayValue, value: location.l2AddressValue } })

    const isL2AddressSupported = (_.findIndex(l2AddressData, (supportedL2Address) => {
      return supportedL2Address?.displayValue === l2Address
    })) !== -1

    // Stop fetching L3 addresses if Quick Address Search L2 address is an unsupported division OR if user has not selected L2
    if (!isL2AddressSupported || (_.isNil(l2Address))) { // NOTES: this make sense since we don't have L2, so we should populate L2 (city)
      shouldUpdateStates = true
      shouldUpdateCities = true
      yield put(successGetLocations(data, states, cities, l1AddressData, l2AddressData, l3AddressData, shouldUpdateStates, shouldUpdateCities))
      yield put(showLoading(false))
      return
    }

    // Fetch L3 addresses
    locationsResponse = yield call(dpApi.getDivisions, country, l1Address, l2Address)
    error = locationsResponse?.data?.error
    if (!locationsResponse.ok) {
      if (error && error.message) yield put(showError(error.message))
      yield put(failGetLocations)
      yield put(showLoading(false))
      return
    }

    data = locationsResponse?.data?.data
    l3AddressData = _.uniqBy(data, 'l3AddressDisplayValue')
      .filter(location => !_.isNil(location.l3AddressDisplayValue))
      .map(location => { return { displayValue: location.l3AddressDisplayValue, value: location.l3AddressValue } })
    shouldUpdateStates = true
    shouldUpdateCities = true
    yield put(successGetLocations(data, states, cities, l1AddressData, l2AddressData, l3AddressData, shouldUpdateStates, shouldUpdateCities))
    yield put(showLoading(false))
    return
  } else {
    // Fetch State/City/District by from DP Zones
    // DP Zones endpoint returns an array of all possible options for State/City/District
    const locationsResponse = yield call(dpApi.getDpZones, country)
    const { data, error } = locationsResponse.data
    if (locationsResponse.ok) {
      const states = _.uniqBy(data, 'l1Name').map(loc => loc.l1Name)
      const cities = _.uniqBy(data, 'l2Name').map(loc => loc.l2Name)
      yield put(successGetLocations(data, states, cities, [], [], [], true, true))
    } else {
      if (error && error.message) yield put(showError(error.message))
      yield put(failGetLocations)
    }
  }
  yield put(showLoading(false))
}

export default function * locationsData () {
  yield all([takeLatest(REQUEST_GET_LOCATIONS, getLocations)])
}
