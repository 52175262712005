import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'
import { baseTypes } from '@app/containers/Base/redux'
import _ from 'lodash'

export const { Types: locationsTypes, Creators: locationsCreators } = createActions(
  {
    requestGetLocations: ['isQuickAddressFeatureEnabled', 'l1Address', 'l2Address'],
    successGetLocations: ['data', 'states', 'cities', 'l1AddressData', 'l2AddressData', 'l3AddressData', 'shouldUpdateStates', 'shouldUpdateCities'],
    failGetLocations: []
  },
  { prefix: 'Locations/' }
)

export const INITIAL_STATE = fromJS({
  data: [],
  loading: false
})

export const locationsReducer = createReducer(INITIAL_STATE, {
  [locationsTypes.REQUEST_GET_LOCATIONS]: (state, action) => state.set('loading', true),
  [locationsTypes.SUCCESS_GET_LOCATIONS]: (state, action) => {
    const { shouldUpdateStates, shouldUpdateCities } = action

    if (shouldUpdateStates && shouldUpdateCities) {
      return state
        .set('loading', false)
        .set('data', action.data)
        .set('states', action.states)
        .set('cities', action.cities)
        .set('l1AddressData', action.l1AddressData)
        .set('l2AddressData', action.l2AddressData)
        .set('l3AddressData', action.l3AddressData)
    }

    if (shouldUpdateStates) {
      return state
        .set('loading', false)
        .set('data', action.data)
        .set('states', action.states)
        .set('l1AddressData', action.l1AddressData)
        .set('l2AddressData', action.l2AddressData)
        .set('l3AddressData', action.l3AddressData)
    }

    if (shouldUpdateCities) {
      return state
        .set('loading', false)
        .set('data', action.data)
        .set('cities', action.cities)
        .set('l1AddressData', action.l1AddressData)
        .set('l2AddressData', action.l2AddressData)
        .set('l3AddressData', action.l3AddressData)
    }

    return state
      .set('loading', false)
      .set('data', action.data)
      .set('l1AddressData', action.l1AddressData)
      .set('l2AddressData', action.l2AddressData)
      .set('l3AddressData', action.l3AddressData)
  },
  [locationsTypes.FAIL_GET_LOCATIONS]: state => state.set('loading', false),
  [baseTypes.LOGOUT]: () => INITIAL_STATE
})
