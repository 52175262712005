import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'
import _ from 'lodash'

import { OC_STATUS } from '@app/utils/constants'
import { reservations } from '@app/constants'
import { BULK_OC_LIMIT } from '@app/containers/FirstMile/constants'
import { setIn } from '@app/utils/reducerUtils'

const { ORDER_TYPES } = reservations

export const { Types: bulkUploadTypes, Creators: bulkUploadCreators } = createActions(
  {
    // fetch order prices
    requestFetchOrderPrices: [],
    finishFetchOrderPrices: [],

    // bulk post orders
    requestCreatePostOrders: ['orders'],
    successCreatePostOrders: [],
    failCreatePostOrders: [],

    resetSession: [],
    updateCurrentSession: ['session'],
    requestBulkSend: ['trackingIds'],
    finishBulkSend: ['successfulOrders', 'failedOrders'],
    updateBulkPostOrders: ['orders'],
    successGetShippersByIds: ['shippers'],
    successGetReceiptIdsByShipperIds: ['ids'],
    requestTriggerNotifications: ['params'],
    updateSuccessfulShippers: [],
    failGetReceiptId: [],
    successTriggerNotifications: [],
    failTriggerNotifications: [],
    failGenerateReceipt: []
  },
  { prefix: 'BulkUpload/' }
)

export const DEFAULT_ORDER = {
  parcels: [],
  senderDetails: []
}

export const INITIAL_STATE = fromJS({
  loadingStatus: {},
  currentSession: null,
  bulkSendStatus: {
    failedOrders: [],
    successfulOrders: 0
  },
  bulkPost: {
    failedOrders: [],
    ocIndex: BULK_OC_LIMIT - 1,
    orders: [],
    shippers: [],
    successfulOrders: [],
    receiptIds: {},
    processedOrders: [],
    proceedNextPage: false
  }
})

export const toggleLoading = (state, key, value) => {
  return state.setIn(['loadingStatus', key], value)
}

export const incrementBulkOCIndex = state => {
  return state.updateIn(['bulkPost', 'ocIndex'], v => v + 1)
}

export const updateBulkOCOrderStatus = (state, index, status) => {
  const orders = state.getIn(['bulkPost', 'orders'])
  const updatedOrders = orders.set(index, orders.get(index).merge({ status }))
  return setIn(state, ['bulkPost', 'orders'], updatedOrders)
}

export const updateSessionValues = (state, path, updatedValue) => {
  const session = state.get('currentSession')
  switch (session) {
    case ORDER_TYPES.post:
    case ORDER_TYPES.bulkPost:
    case ORDER_TYPES.pack:
    case ORDER_TYPES.send:
    case ORDER_TYPES.return:
      return state.setIn(path, updatedValue)
    default:
      return state
  }
}

export const getBasePath = state => {
  const session = state.get('currentSession')
  switch (session) {
    case ORDER_TYPES.post:
    case ORDER_TYPES.bulkPost:
      return 'postOrder'
    case ORDER_TYPES.send:
      return 'sendOrders'
    case ORDER_TYPES.return:
      return 'returnOrders'
    default:
      return 'packOrder'
  }
}

export const getValueAndPath = (state, key) => {
  const basePath = getBasePath(state)
  const path = key ? [basePath, key] : [basePath]
  const value = state.getIn(path)
  return { value, path }
}

export const bulkUploadReducer = createReducer(INITIAL_STATE, {
  // fetch order prices
  [bulkUploadTypes.REQUEST_FETCH_ORDER_PRICES]: state => {
    return toggleLoading(state, 'bulkPost', true)
  },
  [bulkUploadTypes.FINISH_FETCH_ORDER_PRICES]: state => {
    return toggleLoading(state, 'bulkPost', false)
  },

  // bulk post orders
  [bulkUploadTypes.REQUEST_CREATE_POST_ORDERS]: state => {
    state = toggleLoading(state, 'bulkPost', true)
    return state
  },
  [bulkUploadTypes.SUCCESS_CREATE_POST_ORDERS]: state => {
    state = toggleLoading(state, 'bulkPost', false)
    return state
  },
  [bulkUploadTypes.FAIL_CREATE_POST_ORDERS]: state => {
    state = toggleLoading(state, 'bulkPost', false)
    return state
  },

  [bulkUploadTypes.RESET_SESSION]: () => INITIAL_STATE,
  [bulkUploadTypes.UPDATE_CURRENT_SESSION]: (state, action) => {
    return state.set('currentSession', action.session)
  },
  [bulkUploadTypes.REQUEST_BULK_SEND]: state => {
    state = toggleLoading(state, 'bulkSend', true)
    return state.setIn(['bulkSendStatus', 'successfulOrders'], 0).setIn(['bulkSendStatus', 'failedOrders'], [])
  },
  [bulkUploadTypes.FINISH_BULK_SEND]: (state, action) => {
    state = toggleLoading(state, 'bulkSend', false)
    return state
      .setIn(['bulkSendStatus', 'successfulOrders'], action.successfulOrders)
      .setIn(['bulkSendStatus', 'failedOrders'], action.failedOrders)
  },
  [bulkUploadTypes.UPDATE_BULK_POST_ORDERS]: (state, { orders }) => {
    return setIn(state, ['bulkPost', 'orders'], orders)
  },
  [bulkUploadTypes.SUCCESS_GET_SHIPPERS_BY_IDS]: (state, action) => {
    const { shippers } = action
    return setIn(state, ['bulkPost', 'shippers'], shippers)
  },
  [bulkUploadTypes.SUCCESS_GET_RECEIPT_IDS_BY_SHIPPER_IDS]: (state, action) => {
    const { ids } = action
    return setIn(state, ['bulkPost', 'receiptIds'], ids)
  },
  [bulkUploadTypes.UPDATE_SUCCESSFUL_SHIPPERS]: state => {
    const bulkOCorders = state.getIn(['bulkPost', 'orders']).toJS()
    const shippers = state.getIn(['bulkPost', 'shippers']).toJS()
    const receiptIds = state.getIn(['bulkPost', 'receiptIds']).toJS()

    const successfulBulkPostOrders = bulkOCorders.filter(o => _.get(o, 'status.type') === OC_STATUS.success)
    const successfulShipperIds = _.uniq(successfulBulkPostOrders.map(o => _.get(o, 'shipper_id')))

    const updatedSenders = shippers.filter(shipper =>
      successfulShipperIds.includes(_.get(shipper, 'id', -1).toString()))
    const updatedReceiptId = successfulShipperIds.reduce(
      (updatedReceipts, receiptId) => ({
        ...updatedReceipts,
        [receiptId]: _.get(receiptIds, receiptId)
      }),
      {}
    )
    return state.setIn(['bulkPost', 'shippers'], updatedSenders).setIn(['bulkPost', 'receiptIds'], updatedReceiptId)
  },
  [bulkUploadTypes.FAIL_GET_RECEIPT_ID]: state => {
    return setIn(state, ['bulkPost', 'receiptId'], null)
  },
  [bulkUploadTypes.SUCCESS_TRIGGER_NOTIFICATIONS]: state => {
    return setIn(state, ['bulkPost', 'proceedNextPage'], true)
  }
})
