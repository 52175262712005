/**
 *  Contains utility functions for featurehub
 *  Official Documentation: https://github.com/featurehub-io/featurehub-javascript-sdk
 *  Ninjavan Documentation: https://confluence.ninjavan.co/pages/viewpage.action?pageId=127733107
 */
import {
  EdgeFeatureHubConfig,
  FeatureHubPollingClient,
  Readyness,
  StrategyAttributeCountryName
} from 'featurehub-javascript-client-sdk'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { COUNTRIES } from '@nv/rc/Constants'
import Config from 'configs'
import { injectCountry } from 'components/CountryProvider'
import { DEFAULT_TWO_FA_SETUP_GRACE_PERIOD_IN_DAYS } from './constants'

const FEATUREHUB_API_KEY = Config.FEATUREHUB_SERVER_EVAL_API_KEY
const FEATUREHUB_EDGE_URL = Config.FEATUREHUB_EDGE_URL

// Features are polled at set frequency
// Changes on feature hub will only be propagated:
// (1) after the time set below or,
// (2) if user refreshes page,
// (3) if user logs out and logs in, whichever first.
// For case (1), only upon rerendering on component / page navigation change will the feature change be visible.
const POLLING_FREQUENCY_IN_MS = 1000 * 60 * 5 // 5 minutes
export const FeatureHubContext = createContext(null)

export const FEATUREHUB_FEATURE_KEYS = {
  ENABLE_CORE_GRANULAR_STATUS_FILTERS: 'ENABLE_CORE_GRANULAR_STATUS_FILTERS',
  ENABLE_QUICK_ADDRESS: 'ENABLE_QUICK_ADDRESS',
  ENABLE_OPTIONAL_STAMP_ID: 'STAMP_ID_MANDATORY_ENABLED_SOURCES',
  TWO_FA_SETUP_GRACE_PERIOD_IN_DAYS: 'TWO_FA_SETUP_GRACE_PERIOD_IN_DAYS',
  SUPPORTED_AWB_SIZES_ON_AWB_SERVICE: 'SUPPORTED_AWB_SIZES_ON_AWB_SERVICE',
  ENABLE_PARCEL_COUNT_ACCURACY: 'ENABLE_PARCEL_COUNT_ACCURACY',
  HIDE_LEGACY_SHIPPER_ID: 'HIDE_LEGACY_SHIPPER_ID'
}

/**
 * All feature values for STAMP_ID_MANDATORY_ENABLED_SOURCES_FEATURE_VALUES feature key
 */
export const STAMP_ID_MANDATORY_ENABLED_SOURCES_FEATURE_VALUES = {
  SEND: 'SEND',
  POST: 'POST',
  PACK: 'PACK',
  MANUAL_PACK: 'MANUAL_PACK',
  OPERATOR: 'OPERATOR',
  REDIRECT: 'REDIRECT',
  RESCHEDULE: 'RESCHEDULE',
  SEMI_INTEGRATED_RETURNS: 'SEMI_INTEGRATED_RETURNS',
  FULLY_INTEGRATED_RETURNS: 'FULLY_INTEGRATED_RETURNS',
  UNINTEGRATED_RETURNS: 'UNINTEGRATED_RETURNS',
  FULLY_INTEGRATED_NINJA_COLLECT: 'FULLY_INTEGRATED_NINJA_COLLECT',
  SEMI_INTEGRATED_NINJA_COLLECT: 'SEMI_INTEGRATED_NINJA_COLLECT'
}

/**
 * All features values for SUPPORTED_AWB_SIZES_ON_AWB_SERVICE feature value
 */
export const SUPPORTED_AWB_SIZES_ON_AWB_SERVICE_FEATURE_VALUES = {
  A4: 'A4',
  A6: 'A6',
  Paperang: 'Paperang'
}

/**
 * Injects the FeatureHub client through the React app tree.
 * @param {*} params featurehub url, featurehub api key, polling interval, children, country
 * @returns Featurehub provider
 */
const InnerFeatureHubProvider = ({ pollInterval = POLLING_FREQUENCY_IN_MS, children, country }) => {
  const url = FEATUREHUB_EDGE_URL
  const apiKey = FEATUREHUB_API_KEY
  const fhConfig = useMemo(
    () => {
      if (url && apiKey) {
        return new EdgeFeatureHubConfig(url, apiKey)
      }
    },
    [url, apiKey]
  )
  const [client, setClient] = useState(fhConfig.newContext())

  useEffect(
    () => {
      const listener = async readiness => {
        const ready = readiness === Readyness.Ready
        if (!ready) {
          return
        }
        if (country && url && apiKey) {
          const fhCountry = parseFeaturehubCountry(country)
          setClient(
            await fhConfig
              .newContext()
              .country(fhCountry)
              .build()
          )
        } else {
          setClient(await fhConfig.newContext().build())
        }
      }

      fhConfig.edgeServiceProvider((repo, config) => new FeatureHubPollingClient(repo, config, pollInterval))
      fhConfig.addReadynessListener(listener)
      fhConfig.init()
      return () => fhConfig.close()
    },
    [fhConfig, pollInterval, country]
  )

  return <FeatureHubContext.Provider value={client}>{children}</FeatureHubContext.Provider>
}

// inject country
export const FeatureHubProvider = injectCountry(InnerFeatureHubProvider)

/**
 * Takes in the system id and sets the country on featurehub client.
 * This allows for roll out strategies on a country level.
 * @param {string} systemId
 */
export const parseFeaturehubCountry = systemId => {
  let country
  switch (systemId) {
    case COUNTRIES.SG:
      country = StrategyAttributeCountryName.Singapore
      break
    case COUNTRIES.MY:
      country = StrategyAttributeCountryName.Malaysia
      break
    case COUNTRIES.ID:
      country = StrategyAttributeCountryName.Indonesia
      break
    case COUNTRIES.VN:
      country = StrategyAttributeCountryName.Vietnam
      break
    case COUNTRIES.PH:
      country = StrategyAttributeCountryName.Philippines
      break
    case COUNTRIES.TH:
      country = StrategyAttributeCountryName.Thailand
      break
    case COUNTRIES.MM:
      country = StrategyAttributeCountryName.Myanmar
      break
    case COUNTRIES.MA:
      country = StrategyAttributeCountryName.Morocco
      break
    default:
      country = StrategyAttributeCountryName.Singapore
  }
  return country
}

/**
 * Takes in a featurehub key and returns true if feature is enabled on featurehub,
 * false otherwise.
 * @param {string} key
 * @returns {boolean} whether is feature enabled
 */
export const isFeaturehubFeatureEnabled = (key, fhClient) => {
  return fhClient.getBoolean(key)
}

/**
 * Takes in a featurehub key and returns the corresponding feature values
 * @param {string} key
 * @returns {string} all feature values
 */
export const getFeatureValueString = (key, fhClient) => {
  return fhClient.getString(key)
}

/**
 * Check if stamp ID is allowed to be optional for POST orders
 * @returns {boolean} if stamp id is allowed to be optional for POST orders
 */
export const isStampIdOptional = fhClient => {
  const featureValues = getFeatureValueString(FEATUREHUB_FEATURE_KEYS.ENABLE_OPTIONAL_STAMP_ID, fhClient)
  return !String(featureValues)
    .toUpperCase()
    .includes(STAMP_ID_MANDATORY_ENABLED_SOURCES_FEATURE_VALUES.POST)
}

/**
 * Return the number of DAYS before we block users who has not enable 2FA on their CIF account.
 * @param fhClient
 * @returns {integer}
 */
export const getTwoFAGracePeriodDays = fhClient => {
  const twoFAGracePeriodStr = getFeatureValueString(
    FEATUREHUB_FEATURE_KEYS.TWO_FA_SETUP_GRACE_PERIOD_IN_DAYS,
    fhClient
  )
  const twoFAGracePeriod = parseInt(twoFAGracePeriodStr)

  // if failed to parse, fall back to the default grace period
  if (!twoFAGracePeriod) {
    return DEFAULT_TWO_FA_SETUP_GRACE_PERIOD_IN_DAYS
  }

  return twoFAGracePeriod
}

/**
 * Checks if given AWB size is supported for AWB generation on AWB service
 * @param fhClient
 * @param awbSize
 * @returns true if AWB size is supported
 */
export const isAwbSizeSupportedOnAwbService = (fhClient, awbSize) => {
  const featureValue = getFeatureValueString(FEATUREHUB_FEATURE_KEYS.SUPPORTED_AWB_SIZES_ON_AWB_SERVICE, fhClient)

  // handle initial requests to FH which may return null values
  const supportedAwbSizes = featureValue ? featureValue.trim().split(',') : []
  return supportedAwbSizes.includes(awbSize)
}
