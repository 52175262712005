import React from 'react'
import PropTypes from 'prop-types'
import { CustomModal } from '@app/components/CustomModal'
import { Scanner } from '@app/components/Scanner'
import { colors } from '@app/themes'
import { INPUT_TYPES, NOTIFICATIONS } from '@app/utils/constants'
import { showNotification } from '@app/utils/notify'
import { CustomButton } from '@app/components/CustomButton'
import { InputItem } from './styles'

class ScannableInput extends React.Component {
  state = {
    showScannerModal: false
  }
  handleScan = data => {
    const { handleScan, withNotification } = this.props
    const scanData = (data.codeResult && data.codeResult.code) || data
    this.setState({ showScannerModal: false })
    withNotification && showNotification(scanData, NOTIFICATIONS.SUCCESS)
    handleScan && handleScan(scanData)
  }

  renderCustomButton = () => {
    const { disabled } = this.props
    return (
      <CustomButton
        dataTestId='show-scanner'
        type='tertiary'
        disabled={disabled}
        onClick={() => this.setState({ showScannerModal: true })}
        textId='scan'
      >
        {this.renderScannerModal()}
      </CustomButton>
    )
  }
  renderScannerModal () {
    const { showScannerModal } = this.state
    const { buttonlabel, placeholder, title } = this.props
    if (!showScannerModal) return null
    return (
      <CustomModal
        width={400}
        visible={showScannerModal}
        onCancel={() => this.setState({ showScannerModal: false })}
        title={title}
      >
        <Scanner
          show
          compact
          inputType={INPUT_TYPES.text}
          inputPlaceholder={placeholder}
          buttonLabel={buttonlabel}
          width={400}
          handleScan={this.handleScan}
        />
      </CustomModal>
    )
  }
  render () {
    const { placeholder, height, disabled } = this.props
    return (
      <InputItem
        {...this.props}
        addonAfter={this.renderCustomButton()}
        disabled={disabled}
        height={height}
        placeholder={placeholder}
      />
    )
  }
}

ScannableInput.propTypes = {
  children: PropTypes.node
}

ScannableInput.defaultProps = {
  height: 40,
  buttonColor: colors.ColorWhite,
  buttonBackground: colors.DarkGray
}

export { ScannableInput }
