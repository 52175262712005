import React from 'react'
import { injectIntl } from 'react-intl'
import { fetchVersionApi } from '@app/services/api'
import { Alert, Button } from "@nv/react-akira"
import { UpdateWarningContainer } from './styles'

const UpdateVersionBanner = ({ intl, isDesktop }) => {
  const handleReload = () => {
    window.localStorage.setItem('isOutdated', false)
    let versionData = null
    fetchVersionApi().then(data => {
      versionData = data
    })
    window.localStorage.setItem('localVersion', versionData)
    window.location.reload()
  }
  const warning = (
    <UpdateWarningContainer>
      <span>{intl.formatMessage({ id: 'update_website' })}</span>
      <div className={(isDesktop) ? "ml-2" : "mt-5 mb-4"}>
        <Button
          data-testid='update-version-button'
          styleType="secondary"
          variant="default"
          size={(isDesktop) ? "sm" : "md"}
          onClick={() => handleReload()}
        >
          {intl.formatMessage({ id: 'update_now' })}
        </Button>
      </div>
    </UpdateWarningContainer>
  )
  const isOutdatedVersion = window.localStorage.getItem('isOutdated')
  return JSON.parse(isOutdatedVersion) ? (
    <Alert
      message={warning}
      variant="info"
      className={(isDesktop) ? 'items-center py-3' : 'items-start'}
      dismissible={false} />
  ) : null
}

const UpdateVersionBanner_ = injectIntl(UpdateVersionBanner)
export { UpdateVersionBanner_ as UpdateVersionBanner }
