import styled from 'styled-components'
import { Icon } from 'antd'
import { colors } from '@app/themes'
import { Spin as S } from '@nv/rc/Components'

const Spin = styled(S)`
  margin: auto;
`

const Wrapper = styled.div`
  width: 35px;
  height: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding-left: 10px;
`

const CustomIcon = styled(Icon)`
  && {
    color: ${colors.LoaderGreyColor};
    font-size: 35px;
  }
`

export { Spin, Wrapper, CustomIcon }
