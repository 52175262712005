import { createSelector } from 'reselect'
import { OC_STATUS } from '@app/utils/constants'
import _ from 'lodash'

const selectBulkUploadDomain = state => state.get('bulkUpload').toJS()

const selectCurrentSession = () => createSelector(selectBulkUploadDomain, state => state.currentSession)

const selectBulkUploadLoadingStatus = () =>
  createSelector(selectBulkUploadDomain, firstMileState => firstMileState.loadingStatus)

const selectBulkSendStatus = () =>
  createSelector(selectBulkUploadDomain, firstMileState => firstMileState.bulkSendStatus)

const selectBulkPost = () => createSelector(selectBulkUploadDomain, state => state.bulkPost)

const selectBulkPostOrders = (status = -1) =>
  createSelector(selectBulkPost(), bulkPost => {
    const orders = _.get(bulkPost, 'orders', [])
    return status < 0 ? orders : orders.filter(o => _.get(o, 'oc.status') === status)
  })

const selectBulkPostProcessedOrders = (status = -1) =>
  createSelector(selectBulkPost(), bulkPost => {
    const orders = _.get(bulkPost, 'processedOrders', [])
    return status < 0 ? orders : orders.filter(o => _.get(o, 'oc.status') === status)
  })

const selectBulkPostShippers = () => createSelector(selectBulkPost(), bulkPost => bulkPost.shippers)

const selectBulkPostReceiptIds = () => createSelector(selectBulkPost(), bulkPost => bulkPost.receiptIds)

const selectBulkOCIndex = () => createSelector(selectBulkPost(), bulkPost => bulkPost.ocIndex)

const selectBulkPostProceedNextPage = () => createSelector(selectBulkPost(), bulkPost => bulkPost.proceedNextPage)

const selectIsBulkOCLoading = () =>
  createSelector(selectBulkPostOrders(), orders => !!orders.find(o => _.get(o, 'status.type') === OC_STATUS.processing))

const selectBulkFailedOrders = () => {
  return createSelector(selectBulkPost(), bulkPost => {
    return _.get(bulkPost, 'failedOrders', [])
  })
}

const selectBulkSuccessfulOrders = () => {
  return createSelector(selectBulkPost(), bulkPost => {
    return _.get(bulkPost, 'successfulOrders', [])
  })
}

export {
  selectBulkUploadDomain,
  selectCurrentSession,
  selectBulkUploadLoadingStatus,
  selectBulkSendStatus,
  selectBulkPost,
  selectBulkPostOrders,
  selectBulkPostProcessedOrders,
  selectBulkPostShippers,
  selectBulkPostReceiptIds,
  selectBulkOCIndex,
  selectBulkPostProceedNextPage,
  selectIsBulkOCLoading,
  selectBulkFailedOrders,
  selectBulkSuccessfulOrders
}
