import React from 'react'
import { Bar } from 'react-chartjs-2'
import barChartStyles from './config'
import ChartDataLabels from 'chartjs-plugin-datalabels'

import _ from 'lodash'

export const BarChart = ({ data, stacked }) => {
  const customOptions = {
    stacked: stacked,
    maxLabel: _.get(data, 'maxLabel')
  }
  return <Bar data={data} options={barChartStyles(customOptions)} plugins={[ChartDataLabels]} />
}
