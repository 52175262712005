// @flow

import type { Response } from '@app/types/Response'
import type { ScanOrderData } from '@app/types/ScanOrderData'
import { mockUserInfo } from '@app/constants'

const { MOCK_CONTACT_NUMBER, MOCK_SHIPPER_ID } = mockUserInfo

export default (country: string, trackingId: string): Promise<Response<ScanOrderData>> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        data: {
          data: {
            type: 'TRACKING_ID',
            orderId: 108793447,
            trackingId,
            stampId: null,
            shipperId: MOCK_SHIPPER_ID,
            returnTrackingType: null,
            fromName: 'S-P-KE0VGX99 Shipper',
            fromContact: MOCK_CONTACT_NUMBER,
            fromEmail: 'shipper.parcel.ke0vgx99@ninjavan.co',
            fromAddress1: '19-01 MARINA BAY FINANCIAL CENTRE',
            fromAddress2: 'TOWER 3',
            fromCity: null,
            fromState: null,
            fromPostcode: null,
            fromCountry: null,
            toName: 'Tristania Siagian',
            toEmail: 'ninjavan.qa3@gmail.com',
            toContact: '+6588923644',
            toAddress1: '30 Jalan Kilang Barat',
            toAddress2: 'NVQA V4 HQ',
            toCity: null,
            toState: null,
            toPostcode: null,
            toCountry: null,
            parcelSize: null,
            parcelType: null,
            weight: null,
            instruction: null,
            cost: null
          }
        },
        ok: true
      })
    }, 1000)
  })
}
