import { ORDER_TYPE } from '@app/utils/constants'

export const barcodeType = {
  [ORDER_TYPE.TRACKING_ID]: {
    title: 'scan_tracking_id',
    conflict: 'tracking_id_already_used',
    invalid: 'invalid_tracking_id',
    placeholder: 'tracking_id'
  },
  [ORDER_TYPE.STAMP_ID]: {
    title: 'scan_stamp_id',
    conflict: 'stamp_id_already_used',
    invalid: 'invalid_stamp_id',
    placeholder: 'stamp_id'
  }
}
