import { createGlobalStyle } from 'styled-components'
import { colors } from './themes'
/* eslint no-unused-expressions: 0 */
export const AppGlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  html,
  body {
    height: 100%;
    width: 100%;
    &.disable-overscroll {
      overscroll-behavior: none;
    }
  }

  body {
    font-family: 'Lato', sans-serif;
    padding: 0px;
    margin: 0px;
  }

  body.fontLoaded {
    font-family: 'Lato';
  }

  #app {
    background-color: #fafafa;
    min-height: 100%;
    min-width: 100%;
  }

  p,
  label {
    font-family: 'Lato';
    line-height: 1.5em;
  }
  .material-icons {
    color: #696969;
    padding:5px;
  }
  &&& .ant-input-disabled {
      color: ${colors.MenuItemTextColor};
  }

  &&& .ant-select-disabled .ant-select-selection .ant-select-arrow {
    display: none;
  }
  
  && .toast-notification > span {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    font-size: 18px !important;
  }
`
