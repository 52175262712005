import React from 'react'
import PropTypes from 'prop-types'
import { NVAlert as A } from './styles'
import { Pagination } from '../Pagination'

export const Alert = props => <A {...props} />

Pagination.defaultProps = {
  message: 'text',
  type: 'warning',
  banner: false,
  closable: true
}

Pagination.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  banner: PropTypes.boolean,
  closable: PropTypes.boolean
}
