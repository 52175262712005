import { Form } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { colors } from '@app/themes'
const StyledFormItemWrapper = styled(Form.Item)`
  && {
    width: ${props => props.width}px;
    max-width: 100%;
    ${props => props.centered && `text-align: center;`};
    .ant-form-item-required {
      &:before {
        ${props => props.hideRequiredMark && `display: none !important;`};
      }
    }
    .ant-form-item-label label {
      color: ${colors.LabelTextColor};
    }
    .ant-form-item-control {
      line-height: 0;
    }
    margin: 0 4px 2px 4px;
  }
`
export const FormItem = props => <StyledFormItemWrapper colon={false} {...props} />
