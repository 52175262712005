import styled from 'styled-components'
import { faVideo } from '@fa-pro-solid/faVideo'
import { Icon } from '@app/components/Icon'
import { colors, display } from '@app/themes'

const StyledVideoWrapper = styled.video`
  position: relative;
  object-fit: cover;
  height: ${props => (props.clientHeight ? `${props.clientHeight}px` : '100%')};
  width: ${props => (props.clientWidth ? `${props.clientWidth}px` : '100%')};
  transform: scale(1);
`

const ContentWrapper = styled.div`
  ${display.flex('center', 'center')};
  position: relative;
  overflow: hidden;
  object-fit: cover;
  height: ${props => (props.clientHeight ? `${props.clientHeight}px` : '100%')};
  width: ${props => (props.clientWidth ? `${props.clientWidth}px` : '100%')};
  background: ${colors.semiTransparent};
`

const PlaceholderIcon = styled(Icon).attrs({ icon: faVideo, size: 72 })`
  position: absolute;
`

const StyledScanHighlight = styled.div`
  position: absolute;
  z-index: 99;
  left: ${props => props.left}px;
  top: ${props => props.top}px;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  border: 4px solid yellow;
  display: ${props => (!props.height || !props.width ? 'none' : 'block')};
`

export { StyledVideoWrapper, ContentWrapper, PlaceholderIcon, StyledScanHighlight }
