import React from 'react'
import PropTypes from 'prop-types'

import { ErrorModal } from '@app/components/ErrorModal'
import { T } from '@nv/rc/Components'

class ErrorBoundary extends React.Component {
  state = { hasError: false }

  componentDidCatch () {
    this.setState({ hasError: true })
  }

  render () {
    const { hasError } = this.state
    return hasError ? (
      <ErrorModal
        okTextId={'okay'}
        visible={hasError}
        onOk={() => window.location.reload()}
        message={<T id='something_went_wrong' />}
        okText={<T id='okay' />}
      />
    ) : (
      this.props.children
    )
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node
}

ErrorBoundary.defaultProps = {
  children: 'ErrorBoundary'
}
export { ErrorBoundary }
