import { fromJS } from 'immutable'
import { createActions, createReducer } from 'reduxsauce'
import { reset } from '@app/utils/reducerUtils'

export const { Types: calculatorTypes, Creators: calculatorCreators } = createActions(
  {
    failGetPrice: [],
    requestGetPrice: ['isQuickAddressFeatureEnabled'],
    resetPrice: [],
    resetRecipientCity: [],
    resetRecipientDistrict: [],
    resetRecipientLatLng: [],
    resetSession: [],
    successGetPrice: ['result'],
    updateCod: ['cod'],
    updateDeliveryType: ['deliveryType'],
    updateParcelValue: ['value'],
    updateParcelWeight: ['weight'],
    updateRecipientCity: ['city'],
    updateRecipientDistrict: ['district'],
    updateRecipientLatLng: ['lat', 'lng'],
    updateRecipientState: ['state']
  },
  { prefix: 'Calculator/' }
)

export const INITIAL_STATE = fromJS({
  parcelCodValue: 0,
  deliveryType: '',
  parcelInsuranceValue: 0,
  parcelWeight: 0,
  price: {},
  recipientCity: '',
  recipientDistrict: '',
  recipientLat: null,
  recipientLng: null,
  recipientState: ''
})

export const calculatorReducer = createReducer(INITIAL_STATE, {
  [calculatorTypes.FAIL_GET_PRICE]: state => reset(state, 'price', INITIAL_STATE),
  [calculatorTypes.RESET_PRICE]: state => reset(state, 'price', INITIAL_STATE),
  [calculatorTypes.RESET_RECIPIENT_CITY]: state => reset(state, 'recipientCity', INITIAL_STATE),
  [calculatorTypes.RESET_RECIPIENT_DISTRICT]: state => reset(state, 'recipientDistrict', INITIAL_STATE),
  [calculatorTypes.RESET_RECIPIENT_LAT_LNG]: state => {
    const latKey = 'recipientLat'
    const lngKey = 'recipientLng'
    return state.set(latKey, INITIAL_STATE.get(latKey)).set(lngKey, INITIAL_STATE.get(lngKey))
  },
  [calculatorTypes.RESET_SESSION]: () => INITIAL_STATE,
  [calculatorTypes.SUCCESS_GET_PRICE]: (state, action) => state.set('price', fromJS(action.result)),
  [calculatorTypes.UPDATE_COD]: (state, action) => state.set('parcelCodValue', action.cod),
  [calculatorTypes.UPDATE_DELIVERY_TYPE]: (state, action) => state.set('deliveryType', action.deliveryType),
  [calculatorTypes.UPDATE_PARCEL_VALUE]: (state, action) => state.set('parcelInsuranceValue', action.value),
  [calculatorTypes.UPDATE_PARCEL_WEIGHT]: (state, action) => state.set('parcelWeight', action.weight),
  [calculatorTypes.UPDATE_RECIPIENT_CITY]: (state, action) => state.set('recipientCity', action.city),
  [calculatorTypes.UPDATE_RECIPIENT_DISTRICT]: (state, action) => state.set('recipientDistrict', action.district),
  [calculatorTypes.UPDATE_RECIPIENT_LAT_LNG]: (state, action) => {
    return state.set('recipientLat', action.lat).set('recipientLng', action.lng)
  },
  [calculatorTypes.UPDATE_RECIPIENT_STATE]: (state, action) => state.set('recipientState', action.state)
})
