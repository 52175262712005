const DP_SETTINGS = {
  allowCustomerReturn: 'allowCustomerReturn',
  allowShipperSend: 'allowShipperSend',
  allowCreatePost: 'allowCreatePost',
  allowCreatePack: 'allowCreatePack',
  allowPrintAwb: 'allowPrintAwb',
  canCustomerCollect: 'canCustomerCollect',
  allowViewInsights: 'allowViewInsights',
  allowViewOrderEventsHistory: 'allowViewOrderEventsHistory',
  allowBulkProcessParcel: 'allowBulkProcessParcel',
  canShipperLodgeIn: 'canShipperLodgeIn',
  allowCodService: 'allowCodService',
  isNewDriverHandoverEnabled:'isNewDriverHandoverEnabled'
}

export default {
  DP_SETTINGS
}
