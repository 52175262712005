import styled from 'styled-components'
export const Overlay = styled.div`
  background-color: ${props => props.isNewAkiraDesignsEnabled? 'transparent': 'rgba(0, 0, 0, 0.5)'};
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${props => (props.onTop ? 99999 : 999)};
`
