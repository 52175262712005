import Images from 'images'
import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import _ from 'lodash'

import { NINJA_XPRESS } from '@app/utils/constants'

const StyledLogo = styled.img`
  width: 100%;
  height: 100%;
  padding: ${props => props.padding}px;
  max-width: ${props => props.width}px;
  max-height: ${props => props.height}px;
  object-fit: contain;
  ${props => props.onClick && `cursor: pointer;`};
`

class Logo extends React.Component {
  state = {
    isNinjaXpress: _.includes(window.location.href, NINJA_XPRESS)
  }

  render () {
    const { theme } = this.props
    const { isNinjaXpress } = this.state

    const logo = isNinjaXpress ? Images.ninjaXpressLogo : theme.images.logoWhite
    return <StyledLogo data-testid="nv-logo" src={logo} {...this.props} />
  }
}

Logo.propTypes = {
  padding: PropTypes.number,
  onClick: PropTypes.func
}

const ThemedLogo = withTheme(Logo)

export { ThemedLogo as NVLogo }
