export const ASYNC_REQUEST_STATUS = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  RETRYING: 'RETRYING',
  PARTIALLY_SUCCEEDED: 'PARTIALLY_SUCCEEDED'
}

export const ASYNC_SUB_REQUEST_STATUS = {
  ERROR: 'ERROR',
  SUCCESS: 'SUCCESS'
}
