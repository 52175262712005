import { colors } from '@app/themes'
import { calculatePercentage, dataLabelFormatter } from './utils'

const tooltipsCustomOption = {
  title: calculatePercentage,
  label: () => ''
}

export default ({ stacked, maxLabel }) => ({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    scaleLabel: {
      fontColor: 'red'
    },
    xAxes: [
      {
        stacked: stacked,
        gridLines: false,
        barPercentage: 0.8,
        ticks: {
          padding: 10,
          fontColor: colors.LabelTextColor,
          fontSize: 12,
          fontFamily: 'Lato'
        }
      }
    ],
    yAxes: [
      {
        stacked: stacked,
        gridLines: {
          display: true,
          color: 'transparent',
          drawBorder: true,
          zeroLineColor: colors.Silver
        },
        ticks: {
          display: false,
          beginAtZero: true,
          max: maxLabel * 1.15,
          min: 0
        }
      }
    ]
  },
  legend: {
    display: false
  },
  tooltips: {
    displayColors: false,
    callbacks: { ...(stacked ? tooltipsCustomOption : {}) }
  },
  plugins: {
    datalabels: {
      formatter: stacked ? dataLabelFormatter : null,
      color: colors.MenuItemTextColor,
      font: {
        weight: 'bold'
      },
      anchor: 'end',
      align: 'top'
    }
  }
})
