import { OC_STATUS, sumArrayElements } from '@app/utils/constants'
import _ from 'lodash'
import { createSelector } from 'reselect'
import { reservations } from '@app/constants'

const { ORDER_TYPES } = reservations

export const selectDomain = state => state.get('firstMile').toJS()
export const selectCurrentSession = () => createSelector(selectDomain, state => state.currentSession)
export const selectOrder = () =>
  createSelector(selectDomain, selectCurrentSession(), (state, session) => {
    switch (session) {
      case ORDER_TYPES.post:
      case ORDER_TYPES.bulkPost:
        return state.postOrder
      default:
        return {}
    }
  })
export const selectSenderDetails = () => createSelector(selectOrder(), order => order.senderDetails)
export const selectReceiptId = () => createSelector(selectOrder(), order => order.receiptId)

// Bulk OC
export const selectBulkOC = () => createSelector(selectDomain, state => state.bulkOC)
export const selectBulkOCIndex = () => createSelector(selectBulkOC(), bulkOC => _.get(bulkOC, 'ocIndex'))
export const selectBulkOCOrders = (status = -1) =>
  createSelector(selectBulkOC(), bulkOC => {
    const orders = _.get(bulkOC, 'orders', [])
    return status < 0 ? orders : orders.filter(o => _.get(o, 'status.type') === status)
  })
export const selectIsBulkOCLoading = () =>
  createSelector(selectBulkOCOrders(), orders => !!orders.find(o => _.get(o, 'status.type') === OC_STATUS.processing))

const selectFirstMileDomain = state => state.get('firstMile').toJS()

const makeSelectFirstMileLoadingStatus = () =>
  createSelector(selectFirstMileDomain, firstMileState => firstMileState.loadingStatus)
const makeSelectFirstMileCurrentSession = () =>
  createSelector(selectFirstMileDomain, firstMileState => firstMileState.currentSession)
const makeSelectFirstMileSendOrders = () =>
  createSelector(selectFirstMileDomain, firstMileState => firstMileState.sendOrders)
const makeSelectFirstMilePostOrder = () =>
  createSelector(selectFirstMileDomain, firstMileState => firstMileState.postOrder)
const makeSelectFirstMilePackOrder = () =>
  createSelector(selectFirstMileDomain, firstMileState => firstMileState.packOrder)
const makeSelectFirstMileReturnOrders = () =>
  createSelector(selectFirstMileDomain, firstMileState => firstMileState.returnOrders)
const makeSelectFirstMileShippers = () =>
  createSelector(selectFirstMileDomain, firstMileState => firstMileState.shippers)
const makeSelectFirstMileShippersData = () =>
  createSelector(makeSelectFirstMileShippers(), shippersState => shippersState.data)
const makeSelectFirstMileShippersUpdated = () =>
  createSelector(makeSelectFirstMileShippers(), shippersState => shippersState.updated)
const makeSelectFirstMileOrders = (isAkiraDesignEnabledForShipperDropOff) =>
  createSelector(selectFirstMileDomain, makeSelectFirstMileCurrentSession(), (firstMileState, currentSession) => {
    switch (currentSession) {
      case ORDER_TYPES.post:
      case ORDER_TYPES.bulkPost:
        return [firstMileState.postOrder]
      case ORDER_TYPES.pack:
        return [firstMileState.packOrder]
      case ORDER_TYPES.send:
        return firstMileState.sendOrders
      case ORDER_TYPES.return:
        if (isAkiraDesignEnabledForShipperDropOff) {
          return firstMileState.returnOrders.filter(o => o.isConfirmed)
        }
        return firstMileState.returnOrders
      default:
        return []
    }
  })
const makeSelectShipperSetupStatus = () =>
  createSelector(selectFirstMileDomain, firstMileState => firstMileState.shipperSetupStatus)
const makeSelectFirstMileOrder = () =>
  createSelector(selectFirstMileDomain, makeSelectFirstMileCurrentSession(), (firstMileState, currentSession) => {
    switch (currentSession) {
      case ORDER_TYPES.post:
      case ORDER_TYPES.bulkPost:
        return firstMileState.postOrder
      case ORDER_TYPES.pack:
        return firstMileState.packOrder
      default:
        return {}
    }
  })
const makeSelectFirstMileTotalOrders = (isAkiraDesignEnabledForShipperDropOff) =>
  createSelector(makeSelectFirstMileCurrentSession(), makeSelectFirstMileOrders(), (currentSession, orders) => {
    switch (currentSession) {
      case ORDER_TYPES.send:
        return orders.length
      case ORDER_TYPES.return:
        if (isAkiraDesignEnabledForShipperDropOff) {
          return orders.filter(o => o.isConfirmed).length
        }
        return orders.length
      case ORDER_TYPES.post:
      case ORDER_TYPES.bulkPost:
      case ORDER_TYPES.pack:
        const parcels = _.get(orders, '[0].parcels', [])
        return parcels.length
      default:
        return 0
    }
  })
const makeSelectFirstMileTotalCost = () =>
  createSelector(makeSelectFirstMileCurrentSession(), makeSelectFirstMileOrders(), (currentSession, orders) => {
    switch (currentSession) {
      case ORDER_TYPES.post:
      case ORDER_TYPES.bulkPost:
      case ORDER_TYPES.pack:
        const parcels = _.get(orders, '[0].parcels', [])
        const costs = parcels
          .filter(p => _.get(p, 'oc.status') !== OC_STATUS.failed)
          .map(p => _.get(p, 'cost.total', 0))
        return sumArrayElements(costs)
      default:
        return 0
    }
  })

const makeSelectFirstMileTotalCostWithTax = () =>
  createSelector(makeSelectFirstMileCurrentSession(), makeSelectFirstMileOrders(), (currentSession, orders) => {
    switch (currentSession) {
      case ORDER_TYPES.post:
      case ORDER_TYPES.bulkPost:
      case ORDER_TYPES.pack:
        const parcels = _.get(orders, '[0].parcels', [])
        const costs = parcels
          .filter(parcel => _.get(parcel, 'oc.status') !== OC_STATUS.failed)
          .map(parcel => _.get(parcel, 'cost.totalWithTax', 0))
        return sumArrayElements(costs)
      default:
        return 0
    }
  })

const makeSelectFirstMileTotalDeliveryFee = () =>
  createSelector(makeSelectFirstMileCurrentSession(), makeSelectFirstMileOrders(), (currentSession, orders) => {
    switch (currentSession) {
      case ORDER_TYPES.post:
      case ORDER_TYPES.bulkPost:
        const parcels = _.get(orders, '[0].parcels', [])
        const costs = parcels
          .filter(parcel => _.get(parcel, 'oc.status') !== OC_STATUS.failed)
          .map(parcel => _.get(parcel, 'cost.deliveryFee', 0))
        return sumArrayElements(costs)
      default:
        return 0
    }
  })

const makeSelectFirstMileTotalCod = () =>
  createSelector(makeSelectFirstMileCurrentSession(), makeSelectFirstMileOrders(), (currentSession, orders) => {
    switch (currentSession) {
      case ORDER_TYPES.post:
      case ORDER_TYPES.bulkPost:
        const parcels = _.get(orders, '[0].parcels', [])
        const costs = parcels
          .filter(p => _.get(p, 'oc.status') !== OC_STATUS.failed)
          .map(p => (_.get(p, 'cod') ? _.get(p, 'codValue', 0) : 0))
        return sumArrayElements(costs)
      default:
        return 0
    }
  })
const makeSelectFirstMileTotalCodFee = () =>
  createSelector(makeSelectFirstMileCurrentSession(), makeSelectFirstMileOrders(), (currentSession, orders) => {
    switch (currentSession) {
      case ORDER_TYPES.post:
      case ORDER_TYPES.bulkPost:
        const parcels = _.get(orders, '[0].parcels', [])
        const costs = parcels
          .filter(p => _.get(p, 'oc.status') !== OC_STATUS.failed)
          .map(p => _.get(p, 'cost.cod.amount', 0))
        return sumArrayElements(costs)
      default:
        return 0
    }
  })
const makeSelectFirstMileTotalCodFeeTax = () =>
  createSelector(
    makeSelectFirstMileCurrentSession(),
    makeSelectFirstMileOrders(),
    (currentSession, orders) => {
      switch (currentSession) {
        case ORDER_TYPES.post:
        case ORDER_TYPES.bulkPost:
          const parcels = _.get(orders, '[0].parcels', [])
          const costs = parcels
            .filter(parcel => _.get(parcel, 'oc.status') !== OC_STATUS.failed)
            .map(parcel => _.get(parcel, 'cost.cod.taxAmount', 0))
          return sumArrayElements(costs)
        default:
          return 0
      }
    }
  )

const makeSelectFirstMileTotalCodFeeWithTax = () =>
  createSelector(
    makeSelectFirstMileCurrentSession(),
    makeSelectFirstMileOrders(),
    makeSelectFirstMileTotalCodFee(),
    (currentSession, orders, totalCod) => {
      switch (currentSession) {
        case ORDER_TYPES.post:
        case ORDER_TYPES.bulkPost:
          const parcels = _.get(orders, '[0].parcels', [])
          const costs = parcels
            .filter(p => _.get(p, 'oc.status') !== OC_STATUS.failed)
            .map(p => _.get(p, 'cost.cod.taxAmount', 0))
          return sumArrayElements(costs) + totalCod
        default:
          return 0
      }
    }
  )

const makeSelectFirstMileTotalInsuranceFee = () =>
  createSelector(makeSelectFirstMileCurrentSession(), makeSelectFirstMileOrders(), (currentSession, orders) => {
    switch (currentSession) {
      case ORDER_TYPES.post:
      case ORDER_TYPES.bulkPost:
        const parcels = _.get(orders, '[0].parcels', [])
        const costs = parcels
          .filter(parcel => _.get(parcel, 'oc.status') !== OC_STATUS.failed)
          .map(parcel => _.get(parcel, 'cost.insuranceFee.amount', 0))
        return sumArrayElements(costs)
      default:
        return 0
    }
  })
const makeSelectFirstMileTotalInsuranceFeeTax = () =>
  createSelector(
    makeSelectFirstMileCurrentSession(),
    makeSelectFirstMileOrders(),
    (currentSession, orders) => {
      switch (currentSession) {
        case ORDER_TYPES.post:
        case ORDER_TYPES.bulkPost:
          const parcels = _.get(orders, '[0].parcels', [])
          const costs = parcels
            .filter(parcel => _.get(parcel, 'oc.status') !== OC_STATUS.failed)
            .map(parcel => _.get(parcel, 'cost.insuranceFee.taxAmount', 0))
          return sumArrayElements(costs)
        default:
          return 0
      }
    }
  )

const makeSelectFirstMileFailedOrders = () =>
  createSelector(makeSelectFirstMileCurrentSession(), makeSelectFirstMileOrders(), (currentSession, orders) => {
    switch (currentSession) {
      case ORDER_TYPES.post:
      case ORDER_TYPES.bulkPost:
      case ORDER_TYPES.pack:
        const parcels = _.get(orders, '[0].parcels', [])
        const failedParcels = parcels
          .map((o, index) => {
            o.key = index
            return o
          })
          .filter(p => _.get(p, 'oc.status') === OC_STATUS.failed)
        _.set(orders, '[0].parcels', failedParcels)
        return orders
      default:
        return orders
          .map((o, index) => {
            o.key = index
            return o
          })
          .filter(p => _.get(p, 'oc.status') === OC_STATUS.failed)
    }
  })
const makeSelectFirstMileSuccessfulOrders = () =>
  createSelector(makeSelectFirstMileCurrentSession(), makeSelectFirstMileOrders(), (currentSession, orders) => {
    switch (currentSession) {
      case ORDER_TYPES.post:
      case ORDER_TYPES.bulkPost:
      case ORDER_TYPES.pack:
        const parcels = _.get(orders, '[0].parcels', [])
        const successfulParcels = parcels.filter(o => _.get(o, 'oc.status') === OC_STATUS.success)
        _.set(orders, '[0].parcels', successfulParcels)
        return orders
      default:
        return orders.filter(o => _.get(o, 'oc.status') === OC_STATUS.success)
    }
  })
const makeSelectFirstMileBulkLodgeInStatus = () =>
  createSelector(selectFirstMileDomain, firstMileState => firstMileState.bulkLodgeInStatus)
const makeSelectFirstMileReceiptId = () => createSelector(makeSelectFirstMileOrder(), order => order.receiptId)
const makeSelectFirstMileReturnOrder = () =>
  createSelector(selectFirstMileDomain, firstMileState => firstMileState.returnOrder)
const makeSelectFirstMileSenderDetails = () => createSelector(makeSelectFirstMileOrder(), order => order.senderDetails)
const makeSelectFirstMileGenerateReceiptStatus = () =>
  createSelector(selectFirstMileDomain, firstMileState => firstMileState.generateReceiptFailed)

const makeSelectStartNewOrderTime = () =>
  createSelector(selectFirstMileDomain, firstMileState => firstMileState.startNewOrder)

export {
  selectFirstMileDomain,
  makeSelectFirstMileLoadingStatus,
  makeSelectFirstMileCurrentSession,
  makeSelectFirstMileSendOrders,
  makeSelectFirstMilePostOrder,
  makeSelectFirstMilePackOrder,
  makeSelectFirstMileReturnOrders,
  makeSelectFirstMileShippers,
  makeSelectFirstMileShippersData,
  makeSelectFirstMileShippersUpdated,
  makeSelectFirstMileOrders,
  makeSelectFirstMileOrder,
  makeSelectFirstMileTotalOrders,
  makeSelectFirstMileTotalCost,
  makeSelectFirstMileTotalCostWithTax,
  makeSelectFirstMileFailedOrders,
  makeSelectFirstMileSuccessfulOrders,
  makeSelectFirstMileBulkLodgeInStatus,
  makeSelectFirstMileReceiptId,
  makeSelectFirstMileReturnOrder,
  makeSelectFirstMileSenderDetails,
  makeSelectFirstMileTotalDeliveryFee,
  makeSelectFirstMileTotalCod,
  makeSelectFirstMileTotalCodFee,
  makeSelectFirstMileTotalCodFeeTax,
  makeSelectFirstMileTotalCodFeeWithTax,
  makeSelectFirstMileTotalInsuranceFee,
  makeSelectFirstMileTotalInsuranceFeeTax,
  makeSelectFirstMileGenerateReceiptStatus,
  makeSelectStartNewOrderTime,
  makeSelectShipperSetupStatus
}
