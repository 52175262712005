import { Colors } from '@nv/rc-2/Themes'
import styled from 'styled-components'

export const StyledLink = styled.a`
  && {
    font-weight: bold;
    text-decoration: underline;
    color: ${Colors.N700};
  }
`
