export const MODALS = {
  uploadCSV: 0,
  bulkSendHandover: 1,
  returnOrder: 2,
  bulkOCStatus: 3,
  bulkOCFetchPricing: 4,
  removeParcel: 5,
}

export const BULK_OC_LIMIT = 5

export const ACCEPT_FILE_TYPES = ['CSV', 'EXCEL', 'MS_EXCEL']

export const RETRY_SHIPPER_CREATION_MESSAGE =
  'Please try creating an account again. If this issue persists, please reach out to your account manager.'

export const SHIPPER_CREATION_ASYNC_REQUEST_FAILED_RESPONSE_MESSAGE =
  'Shipper creation not supported:'

export const SHIPPER_CREATION_ERROR_MESSAGE =
  'Shipper creation not supported for'

export const SHIPPER_CREATION_IN_PROCESSING_MESSAGE =
  'We are still processing the registration. Try entering your Shipper\'s phone number in Shipper Search later.'

export const PROCESSING_A6_RECEIPT_ERROR_MESSAGE =
  'Receipt is still being processed. Please try again in a short while.'

export const RECEIPT_PROCESSING_AND_REDIRECT_USER_ERROR_MESSAGE =
  'Your receipt is still being processed. Please go to Order details or History page to try again.'
