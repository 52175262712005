import styled from 'styled-components'

export const CustomLegendGroupContainer = styled.div`
  min-width: 120px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

export const CustomLegend = styled.div`
  padding: 3px 0;
  display: flex;
  width: 100%;
`

export const CustomLegendLabel = styled.span`
  color: #8d8d8d;
  font-size: 10px;
  padding-left: 3px;
`
