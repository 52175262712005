import React from 'react'
import { Text } from '@app/components/Text'
import { CustomModal } from '@app/components/CustomModal'
import { colors, fonts } from '@app/themes'
import { formatNumber } from '@app/utils/OrderUtils'

import { CustomTable, TableContentWrapper } from './styles'

import _ from 'lodash'

export const InfoModal = ({ visible, toggleInfoModal, intl, volumes }) => {
  const parcelVolumes = _.get(volumes, 'parcelVolumes', [])

  const tiers = parcelVolumes.reduce(
    (tiers, parcelType) => ({
      ...tiers,
      [parcelType.type]: parcelType.tiers.map(tier => ({
        title: intl.formatMessage({ id: _.lowerCase(tier.name) }),
        dataIndex: tier.name
      }))
    }),
    {}
  )

  const getTieringRow = parcelType => {
    const tiers = _.get(parcelType, 'tiers', [])
    return [
      tiers.reduce(
        (row, tiering, index) => ({
          ...row,
          [tiering.name]:
            index === tiers.length - 1
              ? intl.formatMessage({ id: 'highest_award_threshold' }, { threshold: formatNumber(tiering.threshold) })
              : intl.formatMessage(
                { id: 'award_threshold' },
                {
                  start: formatNumber(tiering.threshold),
                  end: formatNumber(tiers[index + 1].threshold - 1)
                }
              )
        }),
        {}
      )
    ]
  }

  const tableSource = parcelVolumes.reduce(
    (thresholds, parcelAward) => ({
      ...thresholds,
      [parcelAward.type]: getTieringRow(parcelAward)
    }),
    {}
  )

  return (
    <CustomModal
      visible={visible}
      title={intl.formatMessage({ id: 'mitra_tiering' })}
      onCancel={toggleInfoModal}
      footer={null}
    >
      <TableContentWrapper>
        <article>{intl.formatMessage({ id: 'mitra_explanation' })}</article>
        <section>
          <Text letterSpacing={fonts.LETTER_SPACING} color={colors.LabelTextColor} textId='post+_parcels' />
          <CustomTable pagination={false} columns={tiers.POST_PLUS} dataSource={tableSource.POST_PLUS} />
        </section>
        <section>
          <Text letterSpacing={fonts.LETTER_SPACING} color={colors.LabelTextColor} textId='marketplace_parcels' />
          <CustomTable pagination={false} columns={tiers.MARKETPLACE} dataSource={tableSource.MARKETPLACE} />
        </section>
      </TableContentWrapper>
    </CustomModal>
  )
}
