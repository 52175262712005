/**
 *
 * EmptyListPage
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, display } from '@app/themes'
import { faBoxOpen } from '@fa-pro-light/faBoxOpen'

import { Icon } from 'components/Icon'
import { Text } from 'components/Text'

const StyledEmptyListPage = styled.div`
  ${display.flex('center', 'center', 'column')};
  ${display.childMargins('row', 16)};
  ${props => props.padding && `padding: ${props.padding};`};
  text-align: center;
  width: 100%;
  flex-grow: 1;
`

const EmptyListPage = props => {
  const { icon, message } = props
  return (
    <StyledEmptyListPage {...props}>
      <Icon background={colors.paleGrey} icon={icon} size={48} />
      <Text textId={message} />
    </StyledEmptyListPage>
  )
}

EmptyListPage.propTypes = {
  icon: PropTypes.object,
  message: PropTypes.string
}

EmptyListPage.defaultProps = {
  icon: faBoxOpen,
  message: 'no_data'
}

export { EmptyListPage }
