import React from 'react'
import { CustomIcon, Spin, Wrapper } from './styles'

const antIcon = <CustomIcon type='loading' spin />

const LoadingIndicator = () => (
  <Wrapper>
    <Spin indicator={antIcon} />
  </Wrapper>
)

export default LoadingIndicator
export { LoadingIndicator }
