const CONTACT_DETAILS = 'contactDetails'
const LAST_TRANSACTION = 'lastTransaction'
const NUMBER = 'number'
const SHIPPER_NAME = 'shipperName'
const TRENDS = 'trends'
const VOLUME = 'volume'
const VOLUME_CONTRIBUTION = 'volumeContribution'

const keyToTitleId = {
  [CONTACT_DETAILS]: 'contact_details',
  [LAST_TRANSACTION]: 'last_transaction',
  [SHIPPER_NAME]: 'shipper_name',
  [TRENDS]: 'trends',
  [VOLUME]: 'volume_all_parcels',
  [VOLUME_CONTRIBUTION]: 'contribution_to_total_volume'
}

export const keys = {
  CONTACT_DETAILS,
  LAST_TRANSACTION,
  NUMBER,
  SHIPPER_NAME,
  TRENDS,
  VOLUME,
  VOLUME_CONTRIBUTION
}

export const parcelVolumeCategory = {
  TOTAL_VOLUME: 'total_volume',
  VOLUME_N0: 'volume_n0',
  VOLUME_N1_EARLY_OC: 'volume_n1_early_oc',
  VOLUME_N1_LATE_OC: 'volume_n1_late_oc',
  VOLUME_N2_PLUS: 'volume_n2_plus'
}

export const MENU_KEYS = {
  downloadReport: 'downloadReport'
}

export default {
  keyToTitleId,
  keys
}
