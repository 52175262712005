// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

import type { OauthLoginRequestPayload, LoginResponse } from '@app/types/Login'

export const postOauthLoginApi = (credentials: OauthLoginRequestPayload): Promise<LoginResponse> => {
  const client = getApiClient(apiClients, 'global')
  const url = `/1.0/login/oauth`
  return client.post(url, credentials)
}
