import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'
import { reset, set, setLoading } from '@app/utils/reducerUtils'
import _ from 'lodash'

export const { Types: driverTypes, Creators: driverCreators } = createActions(
  {
    resetSession: [],
    // Job
    requestGetJobs: ['jobType'],
    successGetJobs: ['jobs'],
    failGetJobs: [],

    // Orders
    requestGetOrders: ['id'],
    successGetOrders: ['orders'],
    failGetOrders: [],
    finishGetOrders: [],

    // Handover
    requestHandover: ['selectedIds', 'jobId', 'handoverInfo', 'onHandover', 'isToDriver', 'otpCode'],
    successHandover: [],
    failHandover: []
  },
  { prefix: 'Driver/' }
)

export const INITIAL_STATE = fromJS({
  loading: {},
  jobs: [],
  orders: []
})

export default createReducer(INITIAL_STATE, {
  [driverTypes.RESET_SESSION]: () => INITIAL_STATE,
  // Jobs
  [driverTypes.REQUEST_GET_JOBS]: state => setLoading(state, 'getJobs', true),
  [driverTypes.SUCCESS_GET_JOBS]: (state, action) => {
    state = setLoading(state, 'getJobs', false)
    return set(state, 'jobs', action.jobs)
  },
  [driverTypes.FAIL_GET_JOBS]: state => {
    state = setLoading(state, 'getJobs', false)
    return reset(state, 'jobs', INITIAL_STATE)
  },

  // Orders
  [driverTypes.REQUEST_GET_ORDERS]: state => {
    state = setLoading(state, 'getOrders', true)
    return reset(state, 'orders', INITIAL_STATE)
  },
  [driverTypes.SUCCESS_GET_ORDERS]: (state, action) => {
    const updatedOrdersList = [...state.get('orders').toJS(), ...action.orders]
    const filteredOrders = _.uniqBy(updatedOrdersList, order => order.barcode)
    return state.set('orders', fromJS(filteredOrders))
  },
  [driverTypes.FAIL_GET_ORDERS]: state => reset(state, 'orders', INITIAL_STATE),
  [driverTypes.FINISH_GET_ORDERS]: state => setLoading(state, 'getOrders', false),

  // Handover
  [driverTypes.REQUEST_HANDOVER]: state => setLoading(state, 'handover', true),
  [driverTypes.SUCCESS_HANDOVER]: state => setLoading(state, 'handover', false),
  [driverTypes.FAIL_HANDOVER]: state => setLoading(state, 'handover', false)
})
