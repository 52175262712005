import { notify } from 'react-notify-toast'
import { colors } from '@app/themes'
import { NOTIFICATIONS } from '@app/utils/constants'

const show = notify.createShowQueue()

export function showNotification (message, type, timeout = 2000) {
  const successStyle = { background: colors.Viridian, text: colors.ColorWhite }
  const infoStyle = { background: colors.DarkGray, text: colors.ColorWhite }
  const errorStyle = { background: colors.Rouge, text: colors.ColorWhite }

  switch (type) {
    case NOTIFICATIONS.ERROR:
      show(message, 'custom', timeout, errorStyle)
      break
    case NOTIFICATIONS.INFO:
      show(message, 'custom', timeout, infoStyle)
      break
    case NOTIFICATIONS.SUCCESS:
      show(message, 'custom', timeout, successStyle)
      break
    default:
      show(message, type, timeout)
  }
}
