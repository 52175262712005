// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

import type { SendOrderRequestPayload } from '@app/types/SendOrderRequestPayload'
import type { SendOrderFailureResponse, SendOrderSuccessResponse } from '@app/types/SendOrderResponse'

export default (
  country: string,
  shouldNotifyShipper: boolean = false,
  payload: SendOrderRequestPayload
): Promise<SendOrderSuccessResponse | SendOrderFailureResponse> => {
  const client = getApiClient(apiClients, country)
  const url = `1.2/reservations/send?notify_shipper=${shouldNotifyShipper.toString()}`
  return client.post(url, payload)
}
