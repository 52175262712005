/**
 *
 * Icon
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import { faCheckCircle } from '@fa-pro-solid/faCheckCircle'
import { faTimesCircle } from '@fa-pro-solid/faTimesCircle'

import { Icon as I } from '@nv/rc/Components'

import { colors, display } from '@app/themes'

const IconWrapper = styled.div`
  ${display.flex('center', 'center')};
  ${props =>
    props.background &&
    `
    width: ${props.size * 2.5}px;
    height: ${props.size * 2.5}px;
    background: ${props.background};
    border-radius: 100%;
    margin-right: ${props.wrapperMargin}px;
    ${props.onClick && `cursor: pointer`};
  `};
  ${props => props.onClick && `cursor: pointer`};
`
const StyledIcon = styled(I)`
  && {
    color: ${props => props.color || 'inherit'};
    font-size: ${props => props.size}px;
    margin-right: ${props => props.gap || 0}px;
  }
`

const TYPES = {
  success: {
    icon: faCheckCircle,
    color: colors.emerald
  },
  error: {
    icon: faTimesCircle,
    color: colors.salmon
  }
}

const getIcon = props => {
  const { type, icon, color } = props
  return _.get(TYPES, type, { icon, color })
}

const Icon = props => {
  return (
    <IconWrapper {...props}>
      <StyledIcon spin={props.spin} size={props.size} gap={props.gap} {...getIcon(props)} />
    </IconWrapper>
  )
}

Icon.propTypes = {
  children: PropTypes.node
}

Icon.defaultProps = {
  children: 'Icon'
}

export { Icon }
