import styled from 'styled-components'
import { Dropdown, NVInput } from '@nv/rc/Components'

import { colors, display, media } from '@app/themes'

const StyledSearchHeader = styled.div`
  && {
    padding: ${props => (props.isNewAkiraDesignsEnabled && props.isDesktop) ? '14px 56px;' : '10px 20px;'};
    background: ${props => props.background || colors.white};
    ${media.min.tablet`
      ${display.flex('center', 'center', 'row')};
      ${props => !props.isNewAkiraDesignsEnabled && display.childMargins('column', 16)};
    `}
    ${media.max.tablet`
      ${display.flex('center', 'center', 'column')};
      ${props => !props.isNewAkiraDesignsEnabled && display.childMargins('row', 16)};
    `}
  }
`

const StyledInput = styled(NVInput)`
  && .ant-input {
    text-overflow: ellipsis;
  }
`

const StyledSuggestionsDropdown = styled(Dropdown)`
  + div div div.ant-dropdown {
    padding: 0px 36px 0px 20px;
    width: 100%;
    ${props => props.isNewAkiraDesignsEnabled && props.isDesktop && `padding: 0px 56px;`};
    ${props => props.isNewAkiraDesignsEnabled && !props.isDesktop && `padding: 0px 20px;`};
  }
`

export { StyledSearchHeader, StyledInput, StyledSuggestionsDropdown }
