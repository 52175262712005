import React from 'react'
import PropTypes from 'prop-types'
import { faSearch } from '@fa-pro-light/faSearch'
import { injectSize } from '@nv/rc/Components'
import { Spinner } from '@app/components/Spinner'
import { CustomButton } from '@app/components/CustomButton'
import { Icon } from '@app/components/Icon'
import { colors } from '@app/themes'
import { StyledInput, StyledSearchHeader, StyledSuggestionsDropdown } from './styles'
import { SearchBar } from '@nv/react-akira'

class SearchHeader extends React.Component {
  renderSearchInputField = () => {
    const { searchText, placeholder, onChange, onPressEnter, loading, isNewAkiraDesignsEnabled } = this.props
    return (isNewAkiraDesignsEnabled) ? (
      <SearchBar
        defaultValue={searchText}
        placeholder={placeholder}
        onChange={onChange}
        onKeyUp={onPressEnter}
      />
    ) : (
      <StyledInput
        value={searchText}
        suffix={
          loading ? (
            <Spinner data-testid='search-header-processing-icon' />
          ) : (
            <Icon icon={faSearch} color={colors.whiteFour} data-testid='search-header-icon' />
          )
        }
        placeholder={placeholder}
        onChange={onChange}
        onPressEnter={onPressEnter}
      />
    )
  }

  renderSearch = () => {
    const { searchSuggestions, isNewAkiraDesignsEnabled, isDesktop } = this.props
    if (searchSuggestions) {
      return (
        <StyledSuggestionsDropdown overlay={searchSuggestions} trigger={['click']} isNewAkiraDesignsEnabled={isNewAkiraDesignsEnabled} isDesktop={isDesktop}> 
          {this.renderSearchInputField()}
        </StyledSuggestionsDropdown>
      )
    }
    return this.renderSearchInputField()
  }

  render () {
    const { button, isDesktop } = this.props
    return (
      <StyledSearchHeader {...this.props}>
        {this.renderSearch()}
        {button && (
          <CustomButton
            dataTestId={button.dataTestId || 'unlabelled'}
            type='tertiary'
            iconPosition={isDesktop ? 'left' : 'leftAligned'}
            icon={button.icon}
            textId={button.id}
            onClick={button.onClick}
          />
        )}
      </StyledSearchHeader>
    )
  }
}

SearchHeader.propTypes = {
  children: PropTypes.node
}

const _SearchHeader = injectSize(SearchHeader)
export { _SearchHeader as SearchHeader }
