import styled from 'styled-components'
import { Overlay as O } from '@app/components/Overlay'

const Overlay = styled(O)`
  align-items: center;
  display: flex;
  justify-content: center;
`

export { Overlay }
