// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

import type { PutCustomerCollectOrdersRequestPayload } from '@app/types/PutCustomerCollectOrdersRequest'
import type { PutCustomerCollectOrdersSuccessResponse, PutCustomerCollectOrdersFailureResponse } from '@app/types/PutCustomerCollectOrdersResponse'

export default (
  country: string,
  dpId: string,
  payload: PutCustomerCollectOrdersRequestPayload
): Promise<PutCustomerCollectOrdersSuccessResponse | PutCustomerCollectOrdersFailureResponse> => {
  const client = getApiClient(apiClients, country)
  const url = `1.0/orders/customer-collect?dp_id=${dpId}`
  return client.put(url, payload)
}
