import transit from 'transit-immutable-js'
import { fromJS } from 'immutable'

// White list of keys that need to be persisted
const PERSIST_LIST = [
  ['user', 'loginInfo'],
  ['user', 'dpInfo'],
  ['language', 'locale']
]
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state') //eslint-disable-line
    if (serializedState === null) {
      return undefined
    }
    return fromJS(transit.fromJSON(serializedState))
  } catch (err) {
    return undefined
  }
}

export const saveState = state => {
  try {
    state = persist(state)
    const serializedState = transit.toJSON(state)
    localStorage.setItem('state', serializedState) //eslint-disable-line
  } catch (err) {}
}

// persist() serves as a white list of the items in the store that need to be persisted across time.
// These items are stored in the local storage and hydrates the store every time the app the is launched.
const persist = state => {
  let oldState = loadState()
  if (oldState) {
    PERSIST_LIST.forEach(persistKey => {
      oldState = oldState.setIn(persistKey, state.getIn(persistKey))
    })
    return oldState
  }
  return state
}
