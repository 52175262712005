/**
 *
 * Spinner
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors } from '@app/themes'
import { spin } from '@app/utils/styleUtils'

const StyledSpinner = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border: ${props => props.size / 8}px solid ${props => props.background};
  border-left: ${props => props.size / 8}px solid ${props => props.color};
  border-radius: 50%;
  margin-right: ${props => props.gap}px;
  ${props => props.animate && spin(props.duration)};
`

const Spinner = props => <StyledSpinner {...props} />

Spinner.propTypes = {
  children: PropTypes.node
}

Spinner.defaultProps = {
  background: colors.paleGrey,
  color: colors.greyishBrown,
  duration: 1,
  gap: 0,
  animate: true,
  size: 24
}

export { Spinner }
