import _ from 'lodash'

export const dataLabelFormatter = function (labelItem, ctx) {
  const { datasets } = ctx.chart.data
  const isLastLabel = ctx.datasetIndex === datasets.length - 1
  let sum = 0
  datasets.map(dataset => {
    sum += dataset.data[ctx.dataIndex]
  })
  return isLastLabel ? sum : ''
}

export const calculatePercentage = (tooltipItem, cxt) => {
  // only applicable for datasets with more than 1 group
  if (cxt.datasets.length < 2) {
    return ''
  }
  const categoryIndex = _.get(tooltipItem, '[0].datasetIndex', 0)
  const monthIndex = _.get(tooltipItem, '[0].index', 0)
  const parcelCount = _.get(cxt, ['datasets', categoryIndex, 'data', monthIndex], 0)
  const monthlySum = _.get(cxt, ['sumByMonths', monthIndex], 0)
  const percentage = Math.trunc((parcelCount / monthlySum) * 100)

  const displayedText = percentage + '%, ' + parcelCount + ' Parcels'
  return displayedText
}
