// @flow

import moment from 'moment'
import { COUNTRIES } from '@nv/rc/Constants'

export function generateRequestId (): string {
  const dpId: string = window.localStorage.getItem('dpId') || 0
  const timestamp = moment()
    .utc()
    .format('YYYYMMDD-HHmmss')
  const random = Math.random()
    .toString()
    .substring(2)
  return `npv3-${dpId}-${timestamp}-${random}`.substring(0, 32)
}

export function getCountries (): string[] {
  return Object.values(COUNTRIES).map(stringify)
}

export function isFieldDisabled (country: string, countryCheck: string, param: boolean): boolean {
  return !(country === countryCheck || param)
}

// https://flow.org/en/docs/lang/refinements/
export function stringify (value: mixed) {
  switch (typeof value) {
    case 'boolean':
    case 'number':
      return value.toString()
    case 'object':
      return JSON.stringify(value)
    case 'string':
      return value
    default:
      return ''
  }
}
