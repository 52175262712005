// @flow

import { rest } from 'msw'
import snake from 'snakecase-keys'
import configs from 'configs'
import { mockUserInfo } from '@app/constants'

import type { LoginInfo, LoginInfoData } from '@app/types/LoginInfo'

const { MOCK_PARTNER_ID, MOCK_DP_MS_ID, MOCK_DP_USER_ID, MOCK_DP_NAME, MOCK_DP_SHORT_NAME, MOCK_SHIPPER_ID, MOCK_CONTACT_NUMBER} = mockUserInfo
const mask = `${configs.BASE_URL}/:systemId/dp/1.0/dps/logged-in`

export const getLoginInfoApiMswHandler = rest.get(mask, (req, res, ctx) => {
  const { systemId } = req.params
  const loginInfo: LoginInfo = {
    acceptedParcelSizes: null,
    actualMaxCapacity: 10,
    address1: 'Marina Boulevard Marina Bay Financial Centre Tower 2',
    address2: '6 Shenton Way Singapore 068809',
    allowBulkProcessParcel: true,
    allowCodService: true,
    allowCreatePack: true,
    allowCreatePost: true,
    allowPrintAwb: true,
    allowCustomerReturn: true,
    allowManualPackOc: false,
    allowShipperSend: true,
    allowViewInsights: true,
    allowViewOrderEventsHistory: true,
    canCustomerCollect: true,
    canShipperLodgeIn: true,
    city: 'Singapore',
    computedMaxCapacity: 1,
    contact: MOCK_CONTACT_NUMBER,
    country: 'SG',
    custom: {},
    cutoffHour: null,
    directions: "Update for testing dpmsId then make sure it's not changing anything",
    distance: null,
    dpServiceType: 'FRANCHISEE',
    dpmsId: MOCK_DP_MS_ID,
    driverCollectionMode: 'CONFIRMATIONCODE',
    externalStoreId: 'FKNP0001',
    floorNumber: '12',
    globalShipperId: null,
    hubId: 101,
    id: MOCK_DP_USER_ID,
    isActive: true,
    isHyperlocal: false,
    isNinjaWarehouse: false,
    isPublic: true,
    latitude: 1.2909416,
    legacyShipperId: MOCK_SHIPPER_ID,
    longitude: 103.8142602,
    maxParcelStayDuration: 2,
    name: MOCK_DP_NAME,
    openingHours: {
      monday: [{ startTime: '08:00:00', endTime: '21:00:00' }],
      tuesday: [{ startTime: '08:00:00', endTime: '21:00:00' }],
      wednesday: [{ startTime: '08:00:00', endTime: '21:00:00' }],
      thursday: [{ startTime: '08:00:00', endTime: '21:00:00' }],
      friday: [{ startTime: '08:00:00', endTime: '21:00:00' }],
      saturday: [{ startTime: '08:00:00', endTime: '21:00:00' }],
      sunday: [{ startTime: '08:00:00', endTime: '21:00:00' }]
    },
    operatingHours: null,
    packsSoldHere: false,
    partnerId: MOCK_PARTNER_ID,
    partnerName: null,
    postalCode: '159654',
    routeGroupIds: [],
    serviceType: null,
    shipperId: MOCK_SHIPPER_ID,
    shortName: MOCK_DP_SHORT_NAME,
    slots: null,
    systemId,
    type: 'SHOP',
    unitNumber: '12'
  }
  const loginInfoData: LoginInfoData = {
    data: loginInfo
  }
  return res(ctx.status(200), ctx.json(snake(loginInfoData)))
})
