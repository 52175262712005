import _ from 'lodash'

export const startTimeCache = {}

export const isRequestAction = event => new RegExp('(.*?)(REQUEST)(.*?)+').test(event)

export const isFinishAction = event => new RegExp('(.*?)(FINISH|SUCCESS|FAIL)(.*?)+').test(event)

export const reduxTrackingMiddleware = () => next => action => {
  try {
    if (!window.dataLayer) return next(action)
    const event = action.type
    const eventName = event.replace(/(FINISH_|REQUEST_|SUCCESS_|FAIL_)/g, '')

    if (isRequestAction(event)) {
      startTimeCache[eventName] = new Date().getTime()
      return next(action)
    }

    if (isFinishAction(event)) {
      const requestStartTime = _.get(startTimeCache, eventName)
      if (!requestStartTime) return next(action)

      window.dataLayer.push({
        event,
        request_duration: Date.now() - requestStartTime
      })
    }
    return next(action)
  } catch (_) {
    return next(action)
  }
}
