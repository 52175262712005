import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import { CustomModal as Modal } from '../CustomModal'
import { fonts } from '@app/themes'
import { Colors } from '@nv/rc-2/Themes'

const CustomModal = styled(Modal)`
  .ant-modal-title {
    ${fonts.size.medium};
  }
  .ant-modal-body {
    padding: 20px;
    ${fonts.size.subHeading};
  }
`

class ErrorModal extends React.Component {
  render () {
    const { intl, message, title, shouldTranslate } = this.props
    const translatedTitle = (title && shouldTranslate) ? intl.formatMessage({ id: title }) : title
    return (
      <CustomModal
        {...this.props}
        headerBackground={Colors.P600}
        headerColor={Colors.N000}
        maskClosable={false}
        title={(translatedTitle) ? translatedTitle : intl.formatMessage({ id: 'error' })}
        top={20}
      >
        {(shouldTranslate) ? intl.formatMessage({ id: message }) : message}
      </CustomModal>
    )
  }
}

ErrorModal.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
  shouldTranslate: PropTypes.bool,
  title: PropTypes.string,
}

ErrorModal.defaultProps = {
  children: 'ErrorModal',
  message: 'error',
  shouldTranslate: false,
  title: 'error'
}

const ErrorModal_ = injectIntl(ErrorModal)
export { ErrorModal as ErrorModalTest }
export { ErrorModal_ as ErrorModal }
