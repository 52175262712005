import React from 'react'
import PropTypes from 'prop-types'
import { Dropdown, Menu } from 'antd'
import _ from 'lodash'
import { faCloudUpload } from '@fa-pro-solid/faCloudUpload'
import { faSpinner } from '@fa-pro-solid/faSpinner'
import { CustomButton } from '@app/components/CustomButton'
import { Text } from '@app/components/Text'
import { Link } from '@app/components/Link'
import { Icon } from '@app/components/Icon'
import { colors } from '@app/themes'
import { StyledCustomDragger, UploadWrapper } from './styles'

const MenuItem = Menu.Item

class CustomDragger extends React.Component {
  renderDropDown () {
    const { loading, samples } = this.props
    if (!samples || !samples.length) return null

    const menu = (
      <Menu>
        {samples.map((s, index) => (
          <MenuItem key={index}>
            <Link dataTestId={`custom-dragger-menu-item-link-${index}`} href={_.get(s, 'link')} download>
              <Text data-testid={`custom-dragger-menu-item-${index}`} textId={_.get(s, 'title')} />
            </Link>
          </MenuItem>
        ))}
      </Menu>
    )
    return (
      <Dropdown disabled={loading} overlay={menu} trigger={['click']}>
        <CustomButton textId='download_file_template' type='tertiary' dataTestId='custom-dragger-dropdown' />
      </Dropdown>
    )
  }
  render () {
    const { icon, iconBackground, iconSize, iconColor, mainText, subText, loading } = this.props
    return (
      <UploadWrapper data-testid='custom-dragger'>
        <StyledCustomDragger {...this.props}>
          <Icon
            background={iconBackground}
            color={iconColor}
            icon={!loading ? icon : faSpinner}
            size={iconSize}
            spin={loading}
          />
          <Text data-testid='custom-dragger-main-text' type='secondary' size='big' textId={mainText} />
          <Text data-testid='custom-dragger-sub-text' type='tertiary' size='subHeading' textId={subText} />
        </StyledCustomDragger>
        {this.renderDropDown()}
      </UploadWrapper>
    )
  }
}

CustomDragger.propTypes = {
  children: PropTypes.node
}

CustomDragger.defaultProps = {
  icon: faCloudUpload,
  iconSize: 32,
  iconColor: colors.white,
  iconBackground: colors.emerald
}

export { CustomDragger }
