import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'
import { reset, set, setLoading } from 'utils/reducerUtils'
import { baseTypes } from '@app/containers/Base/redux'

export const { Types: collectTypes, Creators: collectCreators } = createActions(
  {
    // Orders
    requestGetOrders: [],
    successGetOrders: ['orders'],
    failGetOrders: [],

    // Customer Orders
    requestGetCustomerOrders: ['otp', 'onSuccess', 'onFailed'],
    successGetCustomerOrders: ['orders'],
    failGetCustomerOrders: [],

    // SMS
    requestResendOtp: ['trackingId', 'messages'],

    // Customer Collect SMS
    requestResendCustomerCollectOtp: ['toContact', 'onSuccess', 'onFailed'],
    successResendCustomerCollectOtp: [],
    failResendCustomerCollectOtp: [],

    // Handover
    requestHandover: ['trackingId', 'otp', 'onSuccess'],
    successHandover: ['data'],
    failHandover: [],

     // Customer Handover
     requestCustomerOrdersHandover: ['trackingIds', 'onSuccess', 'onFailed'],
     successCustomerOrdersHandover: ['data'],
     failCustomerOrdersHandover: []
  },
  { prefix: 'CustomerCollect/' }
)

export const INITIAL_STATE = fromJS({
  loading: {},
  orders: [],
  customerOrders: [],
  handoverInfo: {},
  customerOrdersHandoverInfo: {},
  customerCollectOtpResentTimeStamp: null
})

export default createReducer(INITIAL_STATE, {
  // Orders
  [collectTypes.REQUEST_GET_ORDERS]: state => setLoading(state, 'getOrders', true),
  [collectTypes.SUCCESS_GET_ORDERS]: (state, action) => {
    state = setLoading(state, 'getOrders', false)
    return set(state, 'orders', action.orders)
  },
  [collectTypes.FAIL_GET_ORDERS]: state => {
    state = setLoading(state, 'getOrders', false)
    return reset(state, 'orders', INITIAL_STATE)
  },

  // New Customer Collect Orders
  [collectTypes.REQUEST_GET_CUSTOMER_ORDERS]: state => setLoading(state, 'getCustomerOrders', true),
  [collectTypes.SUCCESS_GET_CUSTOMER_ORDERS]: (state, action) => {
    state = setLoading(state, 'getCustomerOrders', false)
    return set(state, 'customerOrders', action.orders)
  },
  [collectTypes.FAIL_GET_CUSTOMER_ORDERS]: state => {
    state = setLoading(state, 'getCustomerOrders', false)
    return reset(state, 'customerOrders', INITIAL_STATE)
  },

  // Handover
  [collectTypes.REQUEST_HANDOVER]: state => setLoading(state, 'handover', true),
  [collectTypes.SUCCESS_HANDOVER]: (state, action) => {
    state = setLoading(state, 'handover', false)
    return set(state, 'handoverInfo', action.data)
  },
  [collectTypes.FAIL_HANDOVER]: state => {
    state = setLoading(state, 'handover', false)
    return reset(state, 'handoverInfo', INITIAL_STATE)
  },

  // Customer Collect Orders Handover
  [collectTypes.REQUEST_CUSTOMER_ORDERS_HANDOVER]: state => setLoading(state, 'customerOrdersHandover', true),
  [collectTypes.SUCCESS_CUSTOMER_ORDERS_HANDOVER]: (state, action) => {
    state = setLoading(state, 'customerOrdersHandover', false)
    return set(state, 'customerOrdersHandoverInfo', action.data)
  },
  [collectTypes.FAIL_CUSTOMER_ORDERS_HANDOVER]: state => {
    state = setLoading(state, 'customerOrdersHandover', false)
    return reset(state, 'handoverInfo', INITIAL_STATE)
  },

  //Resend Customer Collect OTP
  [collectTypes.REQUEST_RESEND_CUSTOMER_COLLECT_OTP]: state => setLoading(state, 'resendCustomerCollectOtp', true),
  [collectTypes.SUCCESS_RESEND_CUSTOMER_COLLECT_OTP]: (state) => {
    const currentTimeStamp = new Date()
    state = setLoading(state, 'resendCustomerCollectOtp', false)
    window.localStorage.setItem('customerCollectOtpResentTimeStamp', currentTimeStamp)
    return set(state, 'customerCollectOtpResentTimeStamp', currentTimeStamp)
  },
  [collectTypes.FAIL_RESEND_CUSTOMER_COLLECT_OTP]: state => {
    state = setLoading(state, 'resendCustomerCollectOtp', false)
    return reset(state, 'customerCollectOtpResentTimeStamp', INITIAL_STATE)
  },

  [baseTypes.LOGOUT]: () => INITIAL_STATE
})
