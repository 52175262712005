/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import { addLocaleData } from 'react-intl'
import enLocaleData from 'react-intl/locale-data/en'
import idLocaleData from 'react-intl/locale-data/id'
import thLocaleData from 'react-intl/locale-data/th'
import zhLocaleData from 'react-intl/locale-data/zh'
import msLocaleData from 'react-intl/locale-data/ms'
import frLocaleData from 'react-intl/locale-data/fr'
import viLocaleData from 'react-intl/locale-data/vi'

import { DEFAULT_LOCALE } from './containers/Base/constants'

import enTranslationMessages from './translations/en.json'
import idTranslationMessages from './translations/id.json'
import thTranslationMessages from './translations/th.json'
import zhTranslationMessages from './translations/zh_CN.json'
import msTranslationMessages from './translations/ms.json'
import frTranslationMessages from './translations/fr.json'
import viTranslationMessages from './translations/vi.json'

addLocaleData(enLocaleData)
addLocaleData(idLocaleData)
addLocaleData(thLocaleData)
addLocaleData(zhLocaleData)
addLocaleData(msLocaleData)
addLocaleData(frLocaleData)
addLocaleData(viLocaleData)
export const appLocales = ['en', 'id', 'th', 'zh', 'ms', 'fr', 'vi']

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages) : {}
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key]
    return Object.assign(formattedMessages, { [key]: formattedMessage })
  }, {})
}

export const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  id: formatTranslationMessages('id', idTranslationMessages),
  th: formatTranslationMessages('th', thTranslationMessages),
  zh: formatTranslationMessages('zh', zhTranslationMessages),
  ms: formatTranslationMessages('ms', msTranslationMessages),
  fr: formatTranslationMessages('fr', frTranslationMessages),
  vi: formatTranslationMessages('vi', viTranslationMessages)
}
