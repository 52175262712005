import styled from 'styled-components'
import { Text } from '@app/components/Text'
import { colors, display } from 'themes'

const ConfirmCodModalContent = styled.div`
  padding: 20px;
  ${display.flex('center', 'flex-start', 'column')};
`
const CodAmountText = styled(Text)`
  color: ${colors.SecondaryColor};
  font-size: 24px;
  font-weight: bold;
  margin-top: 22px;
  margin-bottom: 36px;
`

export { ConfirmCodModalContent, CodAmountText }
