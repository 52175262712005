// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

import type { PutOrderPayload, PutOrderResponse } from '@app/types/Order'

export const putOrdersApi = (country: string, payload: PutOrderPayload): PutOrderResponse => {
  const client = getApiClient(apiClients, country)
  const url = `1.1/orders`
  return client.put(url, payload)
}
