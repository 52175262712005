import { colors, display, fonts, media, spacing } from '@app/themes'
import { Text } from '@app/components/Text'
import styled from 'styled-components'

const Title = styled.div`
  ${fonts.size.medium};
  ${fonts.style.bold};
`

const Divider = styled.hr`
  border: 1px solid ${colors.paleGrey};
  margin: ${spacing.medium}px -${spacing.medium}px;
`

const InputWrapper = styled.div`
  && {
    ${display.flex('flex-end', 'center')};
    width: 340px;
    max-width: 100%;
    .ant-form-item {
      margin: 0;
    }
    > *:first-child {
      flex-grow: 1;
      margin-right: 10px;
    }
  }

  ${media.max.tablet`
  && {
    align-items: flex-end;
  }
`};
`

const RecipientDetailsWrapper = styled.div`
 && {
    ${display.childMargins('row')};
  }
`

const StyledSubOption = styled(Text)`
  letter-spacing: 1px;
  color: ${colors.LabelTextColor};
  ${fonts.size.small};
  ${fonts.style.light};
  white-space: break-spaces;
`
export { Title, Divider, InputWrapper, StyledSubOption, RecipientDetailsWrapper }
