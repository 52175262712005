import { fromJS } from 'immutable'
import _ from 'lodash'

// To add dot notation support for immutable
function getPath (path) {
  return _.isString(path) ? path.split('.') : path
}

export function set (state, key, value) {
  return state.set(key, fromJS(value))
}

export function setIn (state, path, value) {
  return state.setIn(getPath(path), fromJS(value))
}

export function update (state, key, value) {
  return state.update(key, v => v.merge(value))
}

export function updateIn (state, path, value) {
  return state.updateIn(getPath(path), v => (v ? v.merge(value) : fromJS(value)))
}

export function reset (state, key, initialState) {
  return state.set(key, initialState.get(key))
}

export function setLoading (state, key, value) {
  return updateIn(state, 'loading', { [key]: value })
}
