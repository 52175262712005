import { NVInput } from '@nv/rc/Components'
import { FormItem as FI } from '@app/components/FormItem'
import { Text } from '@app/components/Text'
import { Icon } from '@app/components/Icon'
import { colors, display, fonts, media } from 'themes'
import styled, { css } from 'styled-components'

const FormItem = styled(FI)`
  && {
    width: 100%;
    height: 100%;
    .ant-form-item-control-wrapper {
      height: 100%;
    }
    .ant-form-item-control {
      height: 100%;
    }
    .ant-input {
      height: 36px;
      max-height: 100%;
    }
    .has-success > .ant-form-explain {
      color: ${colors.emerald};
    }
    .has-error > .ant-form-explain,
    .has-error > .ant-form-item-children > .ant-form-item-children-icon {
      color: ${colors.salmon};
    }
    .is-validating > .ant-form-item-children > .ant-form-item-children-icon {
      color: ${colors.greyishBrown};
    }
  }
`

const defaultStyle = css`
  ${media.min.tablet`
    ${display.flex('stretch', 'space-around')};
    ${display.childMargins('column', 20)};
  `};
  ${media.max.tablet`
    ${display.flex('stretch', 'flex-start', 'column')};
    ${display.childMargins('row', 20)};
  `};
`
const compactStyle = css`
  ${display.flex('stretch', 'space-around', 'column')};
  ${display.childMargins('row', 8)};
`
const compactInputStyle = css`
  ${display.flex('stretch', 'space-between')};
  ${display.childMargins('column', 8)};
  width: 100%;
`
const defaultInputStyle = css`
  ${display.flex('center', 'center', 'column')};
  ${display.childMargins('row')};
  .ant-input {
    text-align: center;
  }
`

const Wrapper = styled.div`
  text-align: center;
  flex: 1;
  ${props => (props.compact ? compactStyle : defaultStyle)};
`
const ComponentWrapper = styled.div`
  ${display.flex('center', 'flex-start', 'column')};
  ${display.childMargins('row', 8)};
  flex: 1;
`
const SquareWrapper = styled(ComponentWrapper)`
  overflow: hidden;
  ${props => props.rounded && `border-radius: 8px`};
  ${display.flex('stretch', 'center')};
  ${props => props.bordered && `border: 1px solid ${colors.whiteFour};`};
  position: relative;
  flex: 1;
  width: 100%;
  max-width: ${props => props.width}px;
  ${props =>
    !props.compact &&
    `
    &:after {
      content: '';
      display: block;
      ${!props.noMinHeight && `padding-bottom: 100%;`};
    }
  `};
`
const InputWrapper = styled.div`
  flex: 1;
  ${props => (props.compact ? compactInputStyle : defaultInputStyle)};
`
const ScannerPlaceholder = styled.div`
  padding: 8px;
  background: ${colors.menu};
  color: white;
  flex: 1;
  cursor: pointer;
`
const PlaceholderBorder = styled.div`
  border: 2px dashed ${colors.white};
  width: 100%;
  height: 100%;
  ${display.flex('center', 'center', 'column')};
  ${display.childMargins('row', 16)};
`
const ScannerOverlay = styled.div`
  box-sizing: border-box;
  z-index: 99;
  border: 40px solid ${colors.semiTransparent};
  border-top-width: 80px;
  border-bottom-width: 80px;
  height: 100%;
  width: 100%;
  position: absolute;
`
const ScannerLine = styled.div`
  z-index: 10;
  position: absolute;
  left: 5%;
  top: 50%;
  width: 90%;
  height: 1px;
  background: ${colors.nvPriRed};
`
const ScannerInputHeader = styled.div`
  text-align: left;
  width: 100%;
  max-width: 480px;
  padding-bottom: 12px;
  & span {
    font-weight: bold;
  }
`

const FlashIcon = styled(Icon).attrs({ color: colors.white })`
  position: absolute;
  z-index: 300;
  top: 0;
  right: 0;
  padding: 20px;
`
const StatusOverlay = styled.div`
  ${display.flex('stretch', 'flex-start', 'column')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid ${props => props.color};
  border-radius: 8px;
  z-index: 400;
  overflow: hidden;
`
const StatusMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  border-bottom: 2px solid ${props => props.border};
  background: ${props => props.background};
`
const IconContainer = styled.div`
  ${display.flex('center', 'center')} width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
`
const Caption = styled(Text).attrs({
  color: colors.white,
  textStyle: 'bold'
})`
  z-index: 200;
  position: absolute;
  top: 30px;
  ${fonts.size.subHeading};
  text-align: center;
  width: 100%;
`

const StyledNVInput = styled(NVInput)`
  background-color: #eff3f6;
  &::placeholder {
    color: #a9aeb4;
  }
`

export {
  FormItem,
  compactInputStyle,
  defaultInputStyle,
  ComponentWrapper,
  SquareWrapper,
  InputWrapper,
  ScannerPlaceholder,
  PlaceholderBorder,
  ScannerOverlay,
  ScannerLine,
  ScannerInputHeader,
  FlashIcon,
  StatusOverlay,
  StatusMessage,
  IconContainer,
  Caption,
  Wrapper,
  StyledNVInput
}
