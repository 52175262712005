/**
 * This component is the skeleton around the actual pages and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { ROUTES, isEqual } from '@app/utils/constants'
import React, { Fragment } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { baseCreators, baseReducer } from './redux'
import { defaultComponent, getRoutesConfig } from '@app/configs/routesConfig'
import {
  selectAccessToken,
  selectCountry,
  selectDpId,
  selectDpName,
  selectDpServiceType,
  selectDpSettings,
  selectError,
  selectInfo,
  selectIsLoggedIn,
  selectIsNewDriverHandoverEnabled,
  selectIsNewAkiraDesignsEnabled,
  selectIsShipperDropOffRevampEnabled,
  selectShipperId,
  selectShowLoading,
  selectShowProcessingLodgeInModal,
  selectShowLeaveWithoutCheckingInParcelModal
} from './selectors'
import {
  makeSelectFirstMileOrders,
} from '@app/containers/FirstMile/selectors'
import { withTheme } from 'styled-components'
import { CountryProvider } from '@app/components/CountryProvider'
import { CustomModal } from '@app/components/CustomModal'
import { ErrorBoundary } from '@app/components/ErrorBoundary'
import { ErrorModal } from '@app/components/ErrorModal'
import { Button, ToastProvider } from '@nv/react-akira'
import Images from 'images'
import FastClick from 'fastclick'
import { FeatureHubProvider } from '@app/utils/featurehubUtils'
import { HeaderComponent } from '@app/components/HeaderComponent'
import { Helmet } from 'react-helmet'
import { Layout } from 'antd'
import { NavigationSidebar } from '@app/components/NavigationSidebar'
import Notifications from 'react-notify-toast'
import { NvApi } from '@nv/rc/Services'
import { Overlay } from '@app/components/Overlay'
import { OverlayLoader } from '@app/components/OverlayLoader'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { awbReducer as awbUploaderReducer } from '@app/containers/AwbUploader/redux'
import awbUploaderSaga from '@app/containers/AwbUploader/saga'
import { bulkUploadReducer } from '@app/reducers/BulkUpload'
import bulkUploadSaga from '@app/sagas/BulkUpload'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import firstMileSaga from '@app/containers/FirstMile/saga'
import { getMappedPartnerType } from './utils'
import { injectIntl } from 'react-intl'
import injectReducer from '@app/utils/injectReducer'
import injectSaga from '@app/utils/injectSaga'
import { injectSize } from '@nv/rc/Components'
import { mixpanelTrackVisitedPage } from '@app/utils/mixpanelUtils'
import { push } from 'react-router-redux'
import saga from './saga'
import { selectLocale } from '@app/containers/LanguageProvider/selectors'
import { trackCustomEvent } from '@app/utils/trackCustomEvent'
import { BaseLayout, ContentLayout, CustomContent, ButtonsContainer, ButtonsContainerMobile, StyledImg, BlockButton, StyledButton, StyledModal, CustomParagraphText, CustomSubparagraphText } from './styles'
import  { LeaveWithoutCheckingInOrdersModal } from '@app/components/LeaveWithoutCheckingInOrdersModal'

export class Base extends React.Component {
  state = {
    hideMenu: true,
    hideOrderSearch: true,
    selectedKeys: [],
    collapsedMenu: false
  }

  componentDidMount() {
    const { accessToken, language, location, dpServiceType, country, dpId, dpName } = this.props
    NvApi.setAuthToken(accessToken)
    NvApi.setLanguage(language)
    FastClick.attach(document.body)
    mixpanelTrackVisitedPage(location.pathname)
    this.updateSelectedKeys(_.get(location, 'pathname'))
    this.performRouting()
    // Sends user attributes(language, country, partner_type, dp_id, and dp_name) to Hotjar.
    window.hj &&
      window.hj('identify', null, {
        language: language,
        country: country,
        partner_type: getMappedPartnerType(dpServiceType),
        dp_id: dpId,
        dp_name: dpName
      })
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const previousLocation = _.get(prevProps, 'location.pathname')
    const currentLocation = _.get(location, 'pathname')
    const isLocationChanged = !isEqual(currentLocation, previousLocation)
    if (currentLocation && isLocationChanged) {
      mixpanelTrackVisitedPage(currentLocation)
      window.scrollTo(0, 0)
      this.updateSelectedKeys(currentLocation)
    }
    this.performRouting()
  }

  toggleMenu = () => this.setState({ hideMenu: !this.state.hideMenu })
  closeMenu = () => this.setState({ hideMenu: true })
  handleCollapseMenu = (isCollapsed) => this.setState({ collapsedMenu: isCollapsed })
  toggleHideOrderSearch = () => this.setState({ hideOrderSearch: !this.state.hideOrderSearch })

  updateSelectedKeys = key => {
    //Once we decided to disable the scroll down refresh in all the pages. we can remove applying/removing disable-overscroll css to body and html elements from here.
    var rootElement = document.getElementsByTagName('html')[0]
    document.body.classList.remove('disable-overscroll')
    rootElement.classList.remove('disable-overscroll')
    let selectedKeys
    switch (key) {
      case ROUTES.newOrder.path:
      case ROUTES.reviewOrder.path:
      case ROUTES.confirmOrder.path:
      case ROUTES.scanParcel.path:
        selectedKeys = [ROUTES.sendParcel.path]
        break
      case ROUTES.collect.path:
      case ROUTES.customerCollect.path:
      case ROUTES.customerCollectReview.path:
      case ROUTES.customerCollectSuccess.path:
        selectedKeys = [ROUTES.collect.path]
        break
      case ROUTES.newScanParcel.path:
        rootElement.classList.add('disable-overscroll')
        document.body.classList.add('disable-overscroll')
      case ROUTES.bulkUpload.path:
      case ROUTES.newConfirmOrder.path:
        selectedKeys = [ROUTES.sendParcelV2.path]
        break
      default:
        selectedKeys = [key]
    }
    this.setState({ selectedKeys })
  }
  performRouting = () => {
    const { history, location, isLoggedIn } = this.props
    const currentLocation = _.get(location, 'pathname')
    const onLoginPage = isEqual(currentLocation, ROUTES.login.path)
    const onValidateLoginPage = isEqual(currentLocation, ROUTES.validateLogin.path)
    const onSetup2FAPage = isEqual(currentLocation, ROUTES.setup2FAForm.path)

    if (!isLoggedIn && !onValidateLoginPage && !onSetup2FAPage) {
      !onLoginPage && history.replace(ROUTES.login.path)
    } else if (onLoginPage) {
      history.replace(ROUTES.home.path)
    }
  }

  getRoutes = () => {
    const routesConfig = getRoutesConfig({
      country: this.props.country,
      settingType: this.props.dpSettings
    })
    return (
      <Switch>
        {routesConfig.map((route, index) => {
          if (route.disabled) return null
          const Component = _.get(route, 'component', defaultComponent.main)
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              render={props => {
                this.debouncedPageViewEvent()
                return <Component {...props} {...route.props} isHideOrderSearch={this.state.hideOrderSearch} isCollapsedMenu={this.state.collapsedMenu} />
              }}
            />
          )
        })}
      </Switch>
    )
  }
  addHelmet() {
    const { theme } = this.props
    return (
      <Helmet defaultTitle={theme.title} titleTemplate={`%s - ${theme.title}`}>
        <meta content={theme.title} name='description' />
        <link href={theme.favicon} rel='icon' />
      </Helmet>
    )
  }

  renderOverlay = () => {
    const { isDesktop, isLoggedIn, isNewAkiraDesignsEnabled } = this.props
    const { hideMenu } = this.state
    if (isLoggedIn && !hideMenu && !isDesktop) {
      return <Overlay onClick={this.closeMenu} isNewAkiraDesignsEnabled={isNewAkiraDesignsEnabled} />
    }
  }
  renderErrorModal() {
    const { error, clearError, location } = this.props
    if (isEqual(_.get(location, 'pathname'), ROUTES.bulkUpload.path)) {
      return
    }
    const errorMessage = (error?.message) ? error.message : error
    
    return <ErrorModal
      visible={!!error} onCancel={clearError}
      message={errorMessage}
      title={error?.title ?? null}
      shouldTranslate={error?.shouldTranslate ?? false}
    />
  }
  renderInfoModal() {
    const { intl, info, history, clearInfo } = this.props
    if (_.isEmpty(info)) {
      return
    }
    return (
      <CustomModal
        title={intl.formatMessage({ id: info.title })}
        type='success'
        message={intl.formatMessage({ id: info.message })}
        visible={!_.isEmpty(info)}
        onCancel={() => {
          clearInfo()
          history.push(info.redirectPage)
        }}
      />
    )
  }

  renderLeaveWithoutCheckingInModal = () => {
    const { showLeaveWithoutCheckingInParcelModal, closeLeaveWithoutParcelModal, closeLeaveWithoutParcelModalAndNavigateToOtherPage } = this.props
    return (
      <LeaveWithoutCheckingInOrdersModal 
        visible={showLeaveWithoutCheckingInParcelModal === "show"}
        handleOnClose={closeLeaveWithoutParcelModal}
        handleRedirection={closeLeaveWithoutParcelModalAndNavigateToOtherPage}
      />)
  }

  clearProcessingLodgeInModal = (path) => {
    const { clearShowProcessingLodgeInModal, history } = this.props
    clearShowProcessingLodgeInModal()
    history.push(path)
  }

  renderProcessingLodgeInModalButtonDesktopView = () => {
    const { intl } = this.props
    return (<ButtonsContainer>
      <StyledButton styleType="secondary" variant="default" size="lg" onClick={() => this.clearProcessingLodgeInModal(ROUTES.home.path)} >
        {intl.formatMessage({ id: "back_to_home" })}
      </StyledButton>
      <Button styleType="primary" variant="grey" size="lg" onClick={() => this.clearProcessingLodgeInModal(ROUTES.history.path)}>
        {intl.formatMessage({ id: "go_to_parcel_tracking" })}
      </Button>
    </ButtonsContainer>)
  }

  renderProcessingLodgeInModalButtonMobileView = () => {
    const { intl } = this.props
    return (<ButtonsContainerMobile>
      <BlockButton styleType="primary" variant="grey" size="lg" onClick={() => this.clearProcessingLodgeInModal(ROUTES.home.path)} >
        {intl.formatMessage({ id: "go_to_parcel_tracking" })}
      </BlockButton>
      <BlockButton styleType="secondary" variant="default" size="lg" onClick={() => this.clearProcessingLodgeInModal(ROUTES.history.path)}>
        {intl.formatMessage({ id: "back_to_home" })}
      </BlockButton>
    </ButtonsContainerMobile>)
  }

  renderProcessingLodgeInModal = () => {
    const { intl, showProcessingLodgeInModal, clearShowProcessingLodgeInModal } = this.props
    return (<StyledModal
      header={intl.formatMessage({ id: "lodge_in_processing_title" })}
      onClose={() => { clearShowProcessingLodgeInModal() }}
      width="xs"
      zIndex={'1000'}
      visible={showProcessingLodgeInModal}
    >
      <StyledImg src={Images.ryoSorry} />
      <CustomParagraphText size="subHeading"> {intl.formatMessage({ id: "lodge_in_processing_message_paragraph" })} </CustomParagraphText>
      <CustomSubparagraphText size="small"> {intl.formatMessage({ id: "lodge_in_processing_message_subparagraph" })}</CustomSubparagraphText>
      {window.innerWidth > 550 ? this.renderProcessingLodgeInModalButtonDesktopView() : this.renderProcessingLodgeInModalButtonMobileView()}
    </StyledModal>)
  }

  renderLayout() {
    const { logout, country, isDesktop, isLoggedIn, dpName, location, updateRoute, dpSettings, isNewDriverHandoverEnabled, isNewAkiraDesignsEnabled, orders, showLeaveWithoutCheckingInParcelModal, showLeaveWithoutParcelModal, closeLeaveWithoutParcelModal, closeLeaveWithoutParcelModalAndNavigateToOtherPage } = this.props
    const { hideMenu, selectedKeys, hideOrderSearch } = this.state
    const showHeader = !isDesktop && !isEqual( _.get(location, 'pathname'), ROUTES.scanCustomerCollectOtp.path)
    const isOnScanParcelMobilePage = !isDesktop && isEqual( _.get(location, 'pathname'), ROUTES.newScanParcel.path);

    if (!isLoggedIn) {
      return <Layout>{this.renderContent()}</Layout>
    } else {
      return (
        
        <CountryProvider country={country}>
          <FeatureHubProvider>
            <BaseLayout hasSider isOnScanParcelMobilePage={isOnScanParcelMobilePage}>
              <NavigationSidebar
                isDesktop={isDesktop}
                collapsed={hideMenu}
                selectedKeys={selectedKeys}
                country={country}
                dpName={dpName}
                location={location}
                updateRoute={updateRoute}
                logout={logout}
                leaveWithoutParcelModalState = {showLeaveWithoutCheckingInParcelModal}
                showLeaveWithoutParcelModal= {showLeaveWithoutParcelModal}
                closeLeaveWithoutParcelModal={closeLeaveWithoutParcelModal}
                closeLeaveWithoutParcelModalAndNavigateToOtherPage= {closeLeaveWithoutParcelModalAndNavigateToOtherPage}
                closeMenu={this.closeMenu}
                handleCollapseMenu={this.handleCollapseMenu}
                settingType={dpSettings}
                isNewDriverHandoverEnabled={isNewDriverHandoverEnabled}
                isNewAkiraDesignsEnabled={isNewAkiraDesignsEnabled}
                reviewParcelOrders={orders}
                isShipperDropOffRevampEnabled={this.props.isShipperDropOffRevampEnabled}
              />
              {showHeader &&
                <HeaderComponent
                  toggleMenu={this.toggleMenu}
                  updateRoute={updateRoute}
                  location={location}
                  toggleHideOrderSearch={this.toggleHideOrderSearch}
                  isNewAkiraDesignsEnabled={isNewAkiraDesignsEnabled}
                  isHideOrderSearch={hideOrderSearch}
                  reviewParcelOrders={orders}
                  showLeaveWithoutParcelModal={showLeaveWithoutParcelModal}
                />
              }
              <ContentLayout withHeader= {showHeader} id='mainLayout'>{this.renderContent(showHeader, isOnScanParcelMobilePage)}</ContentLayout>
            </BaseLayout>
          </FeatureHubProvider>
        </CountryProvider>
      )
    }
  }
  renderContent(showHeader, isOnScanParcelMobilePage) {
    const { isLoggedIn } = this.props
    const withHeader = isLoggedIn && showHeader
    return (
      <CustomContent withHeader={withHeader} isOnScanParcelMobilePage={isOnScanParcelMobilePage}>
        <ToastProvider>
          {this.renderOverlay()}
          {this.getRoutes()}
        </ToastProvider>
      </CustomContent>
    )
  }

  render() {
    const { showLoading, dpId } = this.props
    return (
      <Fragment>
        {this.addHelmet()}
        {this.renderErrorModal()}
        {this.renderInfoModal()}
        {this.renderProcessingLodgeInModal()}
        {this.renderLeaveWithoutCheckingInModal()}
        {showLoading && <OverlayLoader />}
        <Notifications options={{ zIndex: 9999 }} />
        <ErrorBoundary key={dpId}>{this.renderLayout()}</ErrorBoundary>
      </Fragment>
    )
  }

  debouncedPageViewEvent = _.debounce(() => {
    trackCustomEvent('pageview')
    trackCustomEvent('optimize.activate')
  }, 100)
}

Base.propTypes = {
  isLoggedIn: PropTypes.bool,
  showLoading: PropTypes.bool
}

const mapStateToProps = createStructuredSelector({
  accessToken: selectAccessToken(),
  isLoggedIn: selectIsLoggedIn(),
  country: selectCountry(),
  dpId: selectDpId(),
  dpName: selectDpName(),
  dpServiceType: selectDpServiceType(),
  shipperId: selectShipperId(),
  error: selectError(),
  showLoading: selectShowLoading(),
  language: selectLocale(),
  dpSettings: selectDpSettings(),
  info: selectInfo(),
  isNewDriverHandoverEnabled: selectIsNewDriverHandoverEnabled(),
  isNewAkiraDesignsEnabled: selectIsNewAkiraDesignsEnabled(),
  isShipperDropOffRevampEnabled: selectIsShipperDropOffRevampEnabled(),
  showProcessingLodgeInModal: selectShowProcessingLodgeInModal(),
  showLeaveWithoutCheckingInParcelModal: selectShowLeaveWithoutCheckingInParcelModal(),
  orders: makeSelectFirstMileOrders(),
})

export function mapDispatchToProps(dispatch) {
  return {
    updateRoute: path => dispatch(push(path)),
    logout: () => dispatch(baseCreators.logout()),
    clearError: () => dispatch(baseCreators.showError('')),
    clearInfo: () => dispatch(baseCreators.showInfo({})),
    clearShowProcessingLodgeInModal: () => dispatch(baseCreators.showProcessingLodgeInModal(false)),
    showLeaveWithoutParcelModal: () => dispatch(baseCreators.showLeaveWithoutCheckingInParcelModal('show')),
    closeLeaveWithoutParcelModal: () => dispatch(baseCreators.showLeaveWithoutCheckingInParcelModal('close')),
    closeLeaveWithoutParcelModalAndNavigateToOtherPage: () => dispatch(baseCreators.showLeaveWithoutCheckingInParcelModal('navigate'))
  }
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)
const withReducer = injectReducer({ key: 'user', reducer: baseReducer })
const withSaga = injectSaga({ key: 'user', saga })
const withFirstMileSaga = injectSaga({ key: 'firstMile', saga: firstMileSaga })
const withBulkUploadReducer = injectReducer({ key: 'bulkUpload', reducer: bulkUploadReducer })
const withBulkUploadSaga = injectSaga({ key: 'bulkUpload', saga: bulkUploadSaga })
const withAwbUploaderSaga = injectSaga({ key: 'airwayBills', saga: awbUploaderSaga })
const withAwbUploaderReducer = injectReducer({ key: 'airwayBills', reducer: awbUploaderReducer })
export default compose(
  withRouter,
  withReducer,
  withAwbUploaderReducer,
  withBulkUploadReducer,
  withSaga,
  withFirstMileSaga,
  withAwbUploaderSaga,
  withBulkUploadSaga,
  withConnect,
  injectSize,
  withTheme,
  injectIntl
)(Base)
