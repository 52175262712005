// @flow

import { rest } from 'msw'
import snake from 'snakecase-keys'
import configs from 'configs'

import type { LodgeInStatusData } from '@app/types/SendOrderResponse'

const mask = `${configs.BASE_URL}/:systemId/dp/1.2/reservations/send`

export const postSendOrderApiMswHandler = rest.post(mask, (req, res, ctx) => {
  const data: LodgeInStatusData = {
    data: { statusId: 123 }
  }

  return res(ctx.status(200), ctx.json(snake(data)))
})
