// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

import type { ShipperSetupPayload } from '@app/types/ShipperSetupPayload'

export default (country: string, globalShipperId: number, payload: ShipperSetupPayload) => {
  const client = getApiClient(apiClients, country)
  const urlString = `1.2/shippers/${globalShipperId}/setup`
  return client.put(urlString, payload)
}
