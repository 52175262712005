// @flow

import { rest } from 'msw'

export const getManifestMswHandler = rest.get('http://localhost:3000/manifest.json', (req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      name: 'Ninja Point v3',
      icons: [
        {
          src: 'icon-72x72.png',
          sizes: '72x72',
          type: 'image/png'
        },
        {
          src: 'icon-96x96.png',
          sizes: '96x96',
          type: 'image/png'
        },
        {
          src: 'icon-128x128.png',
          sizes: '128x128',
          type: 'image/png'
        },
        {
          src: 'icon-144x144.png',
          sizes: '144x144',
          type: 'image/png'
        },
        {
          src: 'icon-150x150.png',
          sizes: '150x150',
          type: 'image/png'
        },
        {
          src: 'icon-192x192.png',
          sizes: '192x192',
          type: 'image/png'
        },
        {
          src: 'icon-384x384.png',
          sizes: '384x384',
          type: 'image/png'
        }
      ]
    })
  )
})
