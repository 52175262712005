// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

import type { DpJobsResponse } from '@app/types/DpJob'

export const getDpJobApi = (country: string, type: string): DpJobsResponse => {
  const client = getApiClient(apiClients, country)
  const url = `1.0/dp-job`
  return client.get(url, { type })
}
