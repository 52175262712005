import styled from 'styled-components'
import { colors, display, fonts } from '@app/themes'

const AwardTierBlock = styled.div`
  padding: 20px;
  &:first-child {
    padding-top: 0;
  }
  & > header {
    ${display.flex('stretch', 'space-between')};
    & > span {
      &:first-child {
        color: ${colors.SecondaryColor};
      }
      &:last-child {
        color: #2f2d2d;
        ${fonts.style.bold}
      }
      letter-spacing: ${fonts.LETTER_SPACING};
      text-transform: uppercase;
    }
  }
  ${props => (props.border ? 'border-bottom: 3px #f3f3f3 solid;' : null)};
`

const ProgressWrapper = styled.div`
  width: 100%;
`

export { AwardTierBlock, ProgressWrapper }
