import styled from 'styled-components'
import { Alert } from '@nv/rc/Components'

export const NVAlert = styled(Alert)`
  && {
    .ant-alert-close-icon {
      top: 5px;
    }
  }
`
