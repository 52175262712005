// @flow

import _ from 'lodash'

export const getMappedPartnerType = (dpServiceType: string): string => {
  const mapping = {
    FRANCHISEE: 'ASN',
    RETAIL_POINT_NETWORK: 'RPN'
  }
  return _.get(mapping, dpServiceType, '')
}
