import React from 'react'
import { T } from '@nv/rc/Components'
import styled from 'styled-components'
import { Slider } from 'antd'

const StyledProgressBar = styled(Slider)`
  && {
    & .ant-slider-rail {
      height: 8px;
    }
    &.ant-slider-disabled {
      cursor: pointer;
    }
    & > .ant-slider-track {
      background-color: ${props => props.barColor} !important;
      height: 8px;
    }
    & > .ant-slider-step {
      & > .ant-slider-dot {
        height: 16px;
        width: 16px;
        top: -4px;

        &:nth-child(-n + ${props => props.currentTierIndex + 1}) {
          background-color: ${props => props.barColor};
          border: none;
          cursor: pointer;
        }
      }
    }
    & > .ant-slider-mark {
      & > :first-child {
        transform: translateX(0px);
      }
      & > :last-child {
        transform: translateX(-50px);
      }
    }
    & > .ant-slider-handle {
      border: none;
      width: auto;
      border-radius: 0;
      transform: none;
    }
  }
`

const styledLabel = text => ({
  style: {
    color: '#9c9c9c',
    marginTop: '15px',
    cursor: 'default',
    fontSize: '12px',
    textTransform: 'uppercase'
  },
  label: <T id={text} />
})

export { StyledProgressBar, styledLabel }
