// @flow

import { POPULAR_STATES } from '@app/constants/countries'
import _ from 'lodash'
import Config from 'configs'
import { isEqual } from '@app/utils/constants'

export const splitPopularAndOtherLists = (
  country: ?string,
  states: string[]
): {
  popular: string[],
  other: string[]
} => {
  if (!states) {
    return {
      popular: [],
      other: []
    }
  }
  if (!country) {
    return {
      popular: [],
      other: states
    }
  }

  const popularStates: string[] = (POPULAR_STATES[country.toLowerCase()] || []).filter(x => states.includes(x))

  if (popularStates.length < 1) {
    return {
      popular: [],
      other: states
    }
  }

  const filteredStates = states.filter(x => !popularStates.includes(x))

  return {
    popular: popularStates,
    other: filteredStates
  }
}

export function getLatLng (locations, state, city = '', district = '', flag = null) {
  if (!locations) return null

  const location = locations.find(loc => {
    if (Config.ENVIRONMENT === 'cd') {
      return isEqual(_.get(loc, 'l2Name'), city)
    }
    const l1Name = isEqual(_.get(loc, 'l1Name'), state)
    const l2Name = isEqual(_.get(loc, 'l2Name'), city)
    const l3Name = isEqual(_.get(loc, 'l3Name'), district)
    return flag ? l1Name && l2Name && l3Name : l1Name || l2Name || l3Name
  })
  return { lat: _.get(location, 'latitude', null), lng: _.get(location, 'longitude', null) }
}
