export const MOCK_SUCCESS_PERFORMANCE_RESPONSE = {
  data: {
    data: {
      last_updated_at: '19/10/2021 16:01',
      types: [
        {
          month: '2021-10',
          volumes: [
            {
              type: 'POST_PLUS',
              volume: 903,
              lodge_in_volume_n0: 514,
              lodge_in_volume_n1_early_oc: 94,
              lodge_in_volume_n1_late_oc: 0,
              lodge_in_volume_n2_plus: 0,
              ninja_pickup_volume_n0: 275,
              ninja_pickup_volume_n1_to_n2: 4,
              ninja_pickup_volume_n2_to_n3: 0,
              ninja_pickup_volume_n4_plus: 0
            },
            {
              type: 'MARKETPLACE',
              volume: 604,
              lodge_in_volume_n0: 251,
              lodge_in_volume_n1_early_oc: 67,
              lodge_in_volume_n1_late_oc: 0,
              lodge_in_volume_n2_plus: 0,
              ninja_pickup_volume_n0: 369,
              ninja_pickup_volume_n1_to_n2: 16,
              ninja_pickup_volume_n2_to_n3: 5,
              ninja_pickup_volume_n4_plus: 2
            }
          ]
        },
        {
          month: '2021-09',
          volumes: [
            {
              type: 'POST_PLUS',
              volume: 807,
              lodge_in_volume_n0: 0,
              lodge_in_volume_n1_early_oc: 0,
              lodge_in_volume_n1_late_oc: 0,
              lodge_in_volume_n2_plus: 0,
              ninja_pickup_volume_n0: 264,
              ninja_pickup_volume_n1_to_n2: 6,
              ninja_pickup_volume_n2_to_n3: 0,
              ninja_pickup_volume_n4_plus: 30
            },
            {
              type: 'MARKETPLACE',
              volume: 466,
              lodge_in_volume_n0: 0,
              lodge_in_volume_n1_early_oc: 0,
              lodge_in_volume_n1_late_oc: 0,
              lodge_in_volume_n2_plus: 0,
              ninja_pickup_volume_n0: 345,
              ninja_pickup_volume_n1_to_n2: 1,
              ninja_pickup_volume_n2_to_n3: 0,
              ninja_pickup_volume_n4_plus: 7
            }
          ]
        },
        {
          month: '2021-08',
          volumes: [
            {
              type: 'POST_PLUS',
              volume: 1392,
              lodge_in_volume_n0: 0,
              lodge_in_volume_n1_early_oc: 0,
              lodge_in_volume_n1_late_oc: 0,
              lodge_in_volume_n2_plus: 0,
              ninja_pickup_volume_n0: 541,
              ninja_pickup_volume_n1_to_n2: 54,
              ninja_pickup_volume_n2_to_n3: 39,
              ninja_pickup_volume_n4_plus: 176
            },
            {
              type: 'MARKETPLACE',
              volume: 644,
              lodge_in_volume_n0: 0,
              lodge_in_volume_n1_early_oc: 0,
              lodge_in_volume_n1_late_oc: 0,
              lodge_in_volume_n2_plus: 0,
              ninja_pickup_volume_n0: 554,
              ninja_pickup_volume_n1_to_n2: 3,
              ninja_pickup_volume_n2_to_n3: 3,
              ninja_pickup_volume_n4_plus: 3
            }
          ]
        }
      ]
    }
  }
}

export const MOCK_FAIL_PERFORMANCE_RESPONSE = {
  ok: false,
  data: {
    error: {
      message: 'fail to get volumes by performance type'
    }
  }
}

export default (volume, dpId, startDate, endDate) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({ ...MOCK_SUCCESS_PERFORMANCE_RESPONSE })
    }, 1000)
  })
}
