import Config from 'configs'
import mixpanel from 'mixpanel-browser'
import _ from 'lodash'
import { isEqual } from '@app/utils/constants'
import { ROUTES } from '@app/utils/constants'

// Mixpanel configuration
export const MIXPANEL_PROJECT_TOKEN = Config.MIXPANEL_PROJECT_TOKEN
export const MIXPANEL_DEBUG_MODE = Config.ENVIRONMENT !== 'prod'

// Add all event names here (refer to event names from mixpanel tracking plan)
// Event names are case-sensitive
export const MIXPANEL_EVENT_NAMES = {
  VISITED_PAGE: 'Visited Page',
  LOGGED_IN: 'Logged In',
  VERIFIED_DRIVERS_OTP_QR_CODE: "Verified Driver's OTP/QR Code",
  CLICKED_ON_REGISTER_SHIPPER: 'Clicked on Register Shipper',
  ATTEMPTED_SHIPPER_REGISTRATION: 'Attempted Shipper Registration',
  COMPLETED_SHIPPER_REGISTRATION: 'Completed Shipper Registration',
  ABANDONED_SHIPPER_REGISTRATION: 'Abandoned Shipper Registration',
  VIEWED_UPLOAD_PARCELS_METHOD: 'Viewed Upload Parcels Method',
  CONFIRMED_PARCEL_HANDOVER: 'Confirmed Parcel Handover',
  BULK_CREATE_ORDERS: 'Bulk Create Orders',
  DOWNLOADED_CSV_ERRONEOUS_ORDERS: 'Downloaded CSV Erroneous Orders',
  CLICKED_INDIVIDUALLY_SCAN_PARCELS: 'Clicked Individually Scan Parcels',
  MANUALLY_SUBMITTED_TRACKING_STAMP_ID: 'Manually Submitted Tracking/Stamp ID',
  PROCEEDED_FROM_SCANNING_TRACKING_STAMP_ID: 'Proceeded from Scanning Tracking/Stamp ID',
  SEARCHED_FOR_SHIPPER: 'Searched for Shipper',
  ATTEMPTED_ORDER_CREATION: 'Attempted Order Creation',
  VIEWED_REVIEW_PAGE: 'Viewed Review Page',
  COMPLETED_ORDER_CREATION: 'Completed Order Creation',
  CLICKED_ON_TO_DRIVER: 'Clicked on To Driver',
  CLICKED_ON_FROM_DRIVER: 'Clicked on From Driver',
  CLICKED_ON_PRINT_AWB_AFTER_OC: 'Clicked on Print AWB after OC',
  CLICKED_ON_PRINT_AWB_ON_HOMEPAGE: 'Clicked on Print AWB on Homepage',
  UPLOADED_CSV_AWB_TEMPLATE: 'Uploaded CSV AWB Template',
  CLICKED_ON_PRINT_RECEIPTS: 'Clicked on Print Receipts',
  ABANDONED_ORDER_CREATION: 'Abandoned Order Creation',
  CLICKED_ON_SAVE_FOR_LATER: 'Clicked on Save For Later',
  EDITED_ORDERS: 'Edited Orders',
  BULK_SELECTED_PARCELS_TO_HANDOVER_TO_DRIVER: 'Bulk Selected Parcels to Handover to Driver',
  PROCEEDED_WITH_MISSING_PARCELS: 'Proceeded with Missing Parcels',
  INDIVIDUAL_UPLOAD_OF_TRACKING_ORDERS: 'Individual Upload of Tracking ID',
  REVIEWED_PARCEL_LODGE_IN: 'Reviewed Parcel Lodge-in',
  COMPLETED_PARCEL_LODGE_IN: 'Completed Parcel Lodge-in',
  CLICKED_ON_SEARCH_RECIPIENT: 'Clicked on Search Recipient',
  ADDED_NEW_RECIPIENT_DETAILS: 'Added new Recipient Details',
  CLEARED_ALL_RECIPIENT_FIELDS: 'Cleared all Recipient Fields',
  CLICKED_ON_SMARTPASTE_ADDRESS_FIELD:'Clicked Search on Smartpaste Address Field',
  VIEW_ALL_PARCELS_TO_THE_DRIVER_FROM_HOMEPAGE:'View all parcels to the driver from homepage',
  VIEW_ALL_PARCELS_TO_THE_CUSTOMER_FROM_HOMEPAGE:'View all parcels to the customer from the homepage',
  VIEW_ALL_PARCELS_THAT_ARE_IN_STORE: 'View all parcels that are in store',
  VIEW_ALL_EXPIRED_PARCELS_FROM_HOMEPAGE: 'View expired parcels from the homepage',
  REMOVE_PARCEL_FROM_SCANNED_PARCELS_LIST:'Remove a parcel from parcel scanned list',
  COMPLETED_DROP_OFF_PROCESS: 'Complete the scan and or manual input drop-off process'
}

// Add all event property names here
// Event property names are case-sensitive
export const MIXPANEL_PROPERTY_NAMES = {
  PAGE_NAME: 'Page Name',
  SYSTEM_ID: 'System ID',
  DP_ID: 'DP ID',
  DP_NAME: '$name',
  PRODUCT: 'Product',
  FIRST_LOGIN_DATE: 'First Login Date',
  CURRENCY_OF_PARCEL: 'Currency of Parcel',
  TOTAL_COST_OF_PARCEL: 'Total Cost of Parcel',
  NUMBER_OF_PARCELS_CREATED: 'Number of Parcels Created',
  NUMBER_OF_ORDERS_CREATED: 'Number of Orders Created',
  NUMBER_OF_ORDER_CREATION_ATTEMPTS: 'Number of Order Creation Attempts',
  PARCEL_TRACKING_ID: 'Parcel Tracking ID',
  PARCEL_LODGE_IN_OUTCOME: 'Parcel Lodge-in Outcome',
  GENERATED_AIRWAY_BILL_OUTCOME: 'Generated Airway Bill Outcome',
  AIRWAY_BILL_PRINT_FORMAT: 'Airway Bill Print Format',
  FIRST_ORDER_CREATED_COMPLETION_DATE: 'First Order Created Completion Date',
  LAST_COMPLETED_ORDER_DATE: 'Last Completed Order Date',
  HANDOVER_FLOW: 'Handover Flow',
  INPUT_DRIVERS_OTP_OUTCOME: "Input Driver's OTP Outcome",
  SHIPPER_SEARCH_OUTCOME: 'Shipper Search Outcome',
  ENTRY_POINT: 'Entry Point',
  FAILED_LODGED_IN_PARCELS: 'Backend failed to lodge-in parcels',
  SUCCESSFULLY_LODGED_IN_PARCELS: 'Successfully lodged-in parcels',
  MANUALLY_ENTERED_PARCELS: 'Successfully manual input parcels',
  TRANSACTION_DURATION: 'Transaction duration',
  INPUT_TRACKING_STAMP_ID_OUTCOME: 'Input Tracking/Stamp ID Outcome',
  NUMBER_OF_PARCELS_HANDED_OVER_TO_DRIVER: 'Number of Parcels Handed over to Driver',
  NUMBER_OF_PARCELS_RECEIVED_FROM_DRIVER: 'Number of Parcels Received from Driver',
  OTP_CODE: 'OTP code',
  FIRST_SHIPPER_CREATED_DATE: 'First Shipper Created Date',
  NUMBER_OF_SHIPPERS_CREATED: 'Number of Shippers Created',
  SHOP_NAME_FIELD_FILLED: 'Shop Name Field Filled',
  MOBILE_NUMBER_FIELD_FILLED: 'Mobile Number Field Filled',
  NAME_FIELD_FILLED: 'Name Field Filled',
  EMAIL_ADDRESS_FIELD_FILLED: 'Email Address Field Filled',
  ADDRESS_FIELD_FILLED: 'Address Field Filled',
  POSTAL_CODE_FIELD_FILLED: 'Postal Code Field Filled',
  STATE_FIELD_FILLED: 'State Field Filled',
  DISTRICT_FIELD_FILLED: 'District Field Filled',
  CITY_FIELD_FILLED: 'City Field Filled',
  PARCEL_WEIGHT_FIELD_FILLED: 'Parcel Weight Field Filled',
  PARCEL_DESCRIPTION_FIELD_FILLED: 'Parcel Description Field Filled',
  INSURANCE_FEE_FIELD_FILLED: 'Insurance Fee Field Filled',
  COD_AMOUNT_FIELD_FILLED: 'COD Amount Field Filled',
  RECIPIENT_NAME_FIELD_FILLED: "Recipient's Name Field Filled",
  RECIPIENT_NUMBER_FIELD_FILLED: "Recipient's Number Field Filled",
  RECIPIENT_EMAIL_FIELD_FILLED: "Recipient's Email Field Filled",
  RECIPIENT_ADDRESS_FIELD_FILLED: "Recipient's Address Field Filled",
  RECIPIENT_POSTAL_CODE_FIELD_FILLED: "Recipient's Postal Code Field Filled",
  SHIPPING_FEE_FIELD_FILLED: 'Shipping Fee Field Filled',
  NUMBER_OF_PARCELS_ADDED: 'Number of Parcels Added',
  OC_FLOW: 'OC Flow',
  STAMP_ID: 'Stamp ID',
  DP_JOB_ID: 'DP Job ID',
  NUMBER_OF_PARCELS_REVIEWED: 'Number of Parcels Reviewed',
  NUMBER_OF_UNSELECTED_TO_DRIVER_PARCELS: 'Number of Unselected to driver Parcels',
  NUMBER_OF_UNSELECTED_TO_DP_PARCELS: 'Number of Unselected to DP Parcels',
  NUMBER_OF_PARCELS_LODGED_IN: 'Number of Parcels Lodged-in',
  FIRST_LODGED_IN_ORDER_DATE: 'First Lodged-in Order Date',
  LIFETIME_NUMBER_OF_PARCELS_LODGED_IN: 'Lifetime Number of Parcels Lodged-in',
  LAST_LODGED_IN_ORDER_DATE: 'Last Lodged-in Order Date',
  CSV_UPLOAD_OUTCOME: 'CSV Upload Outcome',
  NUMBER_OF_ORDERS_UPLOADED: 'Number of Orders Uploaded',
  NUMBER_OF_UNIQUE_SHIPPERS_PER_BULK_UPLOAD_ORDER: 'Number of Unique Shippers per Bulk Upload Order',
  BULK_FLOW: 'Bulk Flow',
  LIST_OF_ERRORS: 'List of Errors',
  RECIPIENT_SEARCH_OUTCOME: 'Recipient Search Outcome',
  NUMBER_OF_RECIPIENT_DETAILS_RESULTS: 'Number of Recipient Details Results',
  ADDRESS_SEARCH_OUTCOME: 'Search Outcome',
  ADDRESS_FIELDS_POPULATED:'Address Fields Populated'
}

// Add all constants here
export const SUCCESS = 'SUCCESS'
export const FAIL = 'FAIL'
export const PRODUCT_NAME = 'PUDO'
export const ENTRY_POINT_SHIPPER_SEARCH = 'Shipper Search'
export const ENTRY_POINT_ORDER_CREATION = 'Order Creation'
export const ENTRY_POINT_SCAN_QR_CODE = 'Scan QR Code'
export const ENTRY_POINT_INSERT_DRIVERS_OPT_CODE = "Insert Driver's OTP Code"
export const ENTRY_POINT_EDIT_ORDER = "Edit Order"
export const ENTRY_POINT_EDIT_STAGING = "Edit Staging"

export const HANDOVER_FLOW_FROM_DRIVER = 'From Driver'
export const HANDOVER_FLOW_TO_DRIVER = 'To Driver'

export const ORDER_TYPE_POST = 'POST'
export const ORDER_TYPE_SEND = 'SEND'
export const ENTRY_POINT_FILE_UPLOAD = 'Click switch to scanning method'
export const ENTRY_POINT_DROP_OFF_OPTIONS = 'Click the scanning method'

// Add all helper methods for each event here
// Naming convention: mixpanelTrack<event name in camel case>
// https://developer.mixpanel.com/docs/javascript-full-api-reference
export const mixpanelTrackVisitedPage = location => {
  const currentPageURL = Config.WEB_BASE_URL + location
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.PAGE_NAME]: currentPageURL
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.VISITED_PAGE, mixpanelProperties)
}

export const mixpanelTrackLoggedIn = (dpId, systemId, dpName) => {
  mixpanel.register({
    [MIXPANEL_PROPERTY_NAMES.DP_ID]: dpId,
    [MIXPANEL_PROPERTY_NAMES.SYSTEM_ID]: systemId,
    [MIXPANEL_PROPERTY_NAMES.PRODUCT]: PRODUCT_NAME
  })
  mixpanel.track(MIXPANEL_EVENT_NAMES.LOGGED_IN)
  mixpanel.people.set_once([MIXPANEL_PROPERTY_NAMES.FIRST_LOGIN_DATE], new Date())
  mixpanel.people.set_once([MIXPANEL_PROPERTY_NAMES.DP_ID], dpId)
  mixpanel.people.set_once([MIXPANEL_PROPERTY_NAMES.DP_NAME], dpName)
}

export const mixpanelTrackClickedOnToDriver = () => {
  mixpanel.track(MIXPANEL_EVENT_NAMES.CLICKED_ON_TO_DRIVER)
}

export const mixpanelTrackClickedOnFromDriver = () => {
  mixpanel.track(MIXPANEL_EVENT_NAMES.CLICKED_ON_FROM_DRIVER)
}

export const mixpanelTrackViewedReviewPage = (totalCost, currency) => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.CURRENCY_OF_PARCEL]: currency,
    [MIXPANEL_PROPERTY_NAMES.TOTAL_COST_OF_PARCEL]: totalCost
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.VIEWED_REVIEW_PAGE, mixpanelProperties)
}

export const mixpanelTrackVerifiedDriversOTPOrQRCode = (entryPoint, handoverFlow, isSuccess) => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.ENTRY_POINT]: entryPoint,
    [MIXPANEL_PROPERTY_NAMES.HANDOVER_FLOW]: handoverFlow,
    [MIXPANEL_PROPERTY_NAMES.INPUT_DRIVERS_OTP_OUTCOME]: isSuccess ? 'SUCCESS' : 'FAIL'
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.VERIFIED_DRIVERS_OTP_QR_CODE, mixpanelProperties)
}

export const mixpanelTrackClickedIndividuallyScanParcels = () => {
  mixpanel.track(MIXPANEL_EVENT_NAMES.CLICKED_INDIVIDUALLY_SCAN_PARCELS)
}

export const mixpanelTrackManuallySubmittedTrackingStampId = isSuccess => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.INPUT_TRACKING_STAMP_ID_OUTCOME]: isSuccess ? 'SUCCESS' : 'FAIL'
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.MANUALLY_SUBMITTED_TRACKING_STAMP_ID, mixpanelProperties)
}

export const mixpanelTrackProceededFromScanningTrackingStampId = OCFlow => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.OC_FLOW]: OCFlow
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.PROCEEDED_FROM_SCANNING_TRACKING_STAMP_ID, mixpanelProperties)
}

export const mixpanelTrackSearchedForShipper = (entryPoint, isSuccess) => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.ENTRY_POINT]: entryPoint,
    [MIXPANEL_PROPERTY_NAMES.SHIPPER_SEARCH_OUTCOME]: isSuccess ? 'SUCCESS' : 'FAIL'
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.SEARCHED_FOR_SHIPPER, mixpanelProperties)
}

export const mixpanelTrackCompletedOrderCreation = numberOfParcels => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.NUMBER_OF_PARCELS_CREATED]: numberOfParcels
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.COMPLETED_ORDER_CREATION, mixpanelProperties)
  mixpanel.people.set_once(MIXPANEL_PROPERTY_NAMES.FIRST_ORDER_CREATED_COMPLETION_DATE, new Date())
  mixpanel.people.set({
    [MIXPANEL_PROPERTY_NAMES.LAST_COMPLETED_ORDER_DATE]: new Date()
  })
  mixpanel.people.increment({
    [MIXPANEL_PROPERTY_NAMES.NUMBER_OF_ORDERS_CREATED]: 1
  })
}

export const mixpanelTrackClickedOnPrintAWBAfterOC = (paperSize, isSuccess) => {
  mixpanel.track(MIXPANEL_EVENT_NAMES.CLICKED_ON_PRINT_AWB_AFTER_OC, {
    [MIXPANEL_PROPERTY_NAMES.GENERATED_AIRWAY_BILL_OUTCOME]: isSuccess ? 'SUCCESS' : 'FAIL',
    [MIXPANEL_PROPERTY_NAMES.AIRWAY_BILL_PRINT_FORMAT]: paperSize
  })
}

export const mixpanelTrackIndividualUploadOfTrackingID = (trackingId, lodgeInStatus) => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.PARCEL_TRACKING_ID]: trackingId,
    [MIXPANEL_PROPERTY_NAMES.PARCEL_LODGE_IN_OUTCOME]: lodgeInStatus ? 'SUCCESS' : 'FAIL'
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.INDIVIDUAL_UPLOAD_OF_TRACKING_ORDERS, mixpanelProperties)
}

export const mixpanelTrackClickedOnRegisterShipper = entryPoint => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.ENTRY_POINT]: entryPoint
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.CLICKED_ON_REGISTER_SHIPPER, mixpanelProperties)
}

export const mixpanelTrackAttemptedShipperRegistration = (
  isShopNameFilled,
  isMobileNumberFilled,
  isNameFilled,
  isEmailFilled,
  isAddressFilled,
  isPostalCodeFilled
) => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.SHOP_NAME_FIELD_FILLED]: isShopNameFilled,
    [MIXPANEL_PROPERTY_NAMES.MOBILE_NUMBER_FIELD_FILLED]: isMobileNumberFilled,
    [MIXPANEL_PROPERTY_NAMES.NAME_FIELD_FILLED]: isNameFilled,
    [MIXPANEL_PROPERTY_NAMES.EMAIL_ADDRESS_FIELD_FILLED]: isEmailFilled,
    [MIXPANEL_PROPERTY_NAMES.ADDRESS_FIELD_FILLED]: isAddressFilled,
    [MIXPANEL_PROPERTY_NAMES.POSTAL_CODE_FIELD_FILLED]: isPostalCodeFilled
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.ATTEMPTED_SHIPPER_REGISTRATION, mixpanelProperties)
}

export const mixpanelTrackCompletedShipperRegistration = () => {
  mixpanel.track(MIXPANEL_EVENT_NAMES.COMPLETED_SHIPPER_REGISTRATION)
  mixpanel.people.set_once(MIXPANEL_PROPERTY_NAMES.FIRST_SHIPPER_CREATED_DATE, new Date())
  mixpanel.people.increment({ [MIXPANEL_PROPERTY_NAMES.NUMBER_OF_SHIPPERS_CREATED]: 1 })
}

export const mixpanelTrackAttemptedOrderCreation = (
  stampId,
  numberOfParcels,
  isStateFilled,
  isDistrictFilled,
  isCityFilled,
  isParcelWeightFilled,
  isParcelDescriptionFilled,
  isInsuranceFeeFilled,
  isCODAmountFilled,
  isRecipientNameFilled,
  isRecipientNumberFilled,
  isRecipientEmailFilled,
  isRecipientAddressFilled,
  isRecipientPostalCodeFilled
) => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.STAMP_ID]: stampId,
    [MIXPANEL_PROPERTY_NAMES.NUMBER_OF_PARCELS_ADDED]: numberOfParcels,
    [MIXPANEL_PROPERTY_NAMES.STATE_FIELD_FILLED]: isStateFilled,
    [MIXPANEL_PROPERTY_NAMES.DISTRICT_FIELD_FILLED]: isDistrictFilled,
    [MIXPANEL_PROPERTY_NAMES.CITY_FIELD_FILLED]: isCityFilled,
    [MIXPANEL_PROPERTY_NAMES.PARCEL_WEIGHT_FIELD_FILLED]: isParcelWeightFilled,
    [MIXPANEL_PROPERTY_NAMES.PARCEL_DESCRIPTION_FIELD_FILLED]: isParcelDescriptionFilled,
    [MIXPANEL_PROPERTY_NAMES.INSURANCE_FEE_FIELD_FILLED]: isInsuranceFeeFilled,
    [MIXPANEL_PROPERTY_NAMES.COD_AMOUNT_FIELD_FILLED]: isCODAmountFilled,
    [MIXPANEL_PROPERTY_NAMES.RECIPIENT_NAME_FIELD_FILLED]: isRecipientNameFilled,
    [MIXPANEL_PROPERTY_NAMES.RECIPIENT_NUMBER_FIELD_FILLED]: isRecipientNumberFilled,
    [MIXPANEL_PROPERTY_NAMES.RECIPIENT_EMAIL_FIELD_FILLED]: isRecipientEmailFilled,
    [MIXPANEL_PROPERTY_NAMES.RECIPIENT_ADDRESS_FIELD_FILLED]: isRecipientAddressFilled,
    [MIXPANEL_PROPERTY_NAMES.RECIPIENT_POSTAL_CODE_FIELD_FILLED]: isRecipientPostalCodeFilled
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.ATTEMPTED_ORDER_CREATION, mixpanelProperties)
  mixpanel.people.increment({ [MIXPANEL_PROPERTY_NAMES.NUMBER_OF_ORDER_CREATION_ATTEMPTS]: Number(numberOfParcels) })
}

export const mixpanelTrackConfirmedParcelHandover = (numberOfParcels, jobId, handoverFlow, otpCode) => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.DP_JOB_ID]: jobId,
    [MIXPANEL_PROPERTY_NAMES.HANDOVER_FLOW]: handoverFlow,
    [MIXPANEL_PROPERTY_NAMES.OTP_CODE]: otpCode
  }

  if (handoverFlow == HANDOVER_FLOW_FROM_DRIVER) {
    mixpanelProperties[MIXPANEL_PROPERTY_NAMES.NUMBER_OF_PARCELS_RECEIVED_FROM_DRIVER] = numberOfParcels
  }
  if (handoverFlow == HANDOVER_FLOW_TO_DRIVER) {
    mixpanelProperties[MIXPANEL_PROPERTY_NAMES.NUMBER_OF_PARCELS_HANDED_OVER_TO_DRIVER] = numberOfParcels
  }

  mixpanel.track(MIXPANEL_EVENT_NAMES.CONFIRMED_PARCEL_HANDOVER, mixpanelProperties)
}

export const mixpanelTrackBulkSelectedParcelsToHandoverToDriver = (jobId, handoverFlow) => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.DP_JOB_ID]: jobId,
    [MIXPANEL_PROPERTY_NAMES.HANDOVER_FLOW]: handoverFlow
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.BULK_SELECTED_PARCELS_TO_HANDOVER_TO_DRIVER, mixpanelProperties)
}

export const mixpanelTrackProceededWithMissingParcels = (jobId, handoverFlow, numberOfUnselectedParcels) => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.DP_JOB_ID]: jobId,
    [MIXPANEL_PROPERTY_NAMES.HANDOVER_FLOW]: handoverFlow
  }

  if (isEqual(handoverFlow, HANDOVER_FLOW_TO_DRIVER)) {
    mixpanelProperties[MIXPANEL_PROPERTY_NAMES.NUMBER_OF_UNSELECTED_TO_DRIVER_PARCELS] = numberOfUnselectedParcels
  } else {
    mixpanelProperties[MIXPANEL_PROPERTY_NAMES.NUMBER_OF_UNSELECTED_TO_DP_PARCELS] = numberOfUnselectedParcels
  }

  mixpanel.track(MIXPANEL_EVENT_NAMES.PROCEEDED_WITH_MISSING_PARCELS, mixpanelProperties)
}

export const mixpanelTrackReviewedParcelLodgeIn = numberOfParcels => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.NUMBER_OF_PARCELS_REVIEWED]: numberOfParcels
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.REVIEWED_PARCEL_LODGE_IN, mixpanelProperties)
}

export const mixpanelTrackCompletedParcelLodgeIn = numberOfParcelsLodgedIn => {
  const currentDate = new Date()
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.NUMBER_OF_PARCELS_LODGED_IN]: numberOfParcelsLodgedIn
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.COMPLETED_PARCEL_LODGE_IN, mixpanelProperties)
  mixpanel.people.increment({
    [MIXPANEL_PROPERTY_NAMES.LIFETIME_NUMBER_OF_PARCELS_LODGED_IN]: Number(numberOfParcelsLodgedIn)
  })
  mixpanel.people.set_once(MIXPANEL_PROPERTY_NAMES.FIRST_LODGED_IN_ORDER_DATE, currentDate)
  mixpanel.people.set({ [MIXPANEL_PROPERTY_NAMES.LAST_LODGED_IN_ORDER_DATE]: currentDate })
}

export const mixpanelTrackBulkCreateOrders = (uploadOutcome, numberOfOrdersUploaded, numberOfUniqueShippers) => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.CSV_UPLOAD_OUTCOME]: uploadOutcome,
    [MIXPANEL_PROPERTY_NAMES.NUMBER_OF_ORDERS_UPLOADED]: numberOfOrdersUploaded,
    [MIXPANEL_PROPERTY_NAMES.NUMBER_OF_UNIQUE_SHIPPERS_PER_BULK_UPLOAD_ORDER]: numberOfUniqueShippers
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.BULK_CREATE_ORDERS, mixpanelProperties)
}

export const mixpanelTrackDownloadedCSVErroneousOrders = (orderType, errorList) => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.BULK_FLOW]: orderType,
    [MIXPANEL_PROPERTY_NAMES.LIST_OF_ERRORS]: errorList
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.DOWNLOADED_CSV_ERRONEOUS_ORDERS, mixpanelProperties)
}

export const mixpanelTrackClickedOnSmartPasteAddressField = (addressSearchOutcome, addressFieldPopulated) => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.ADDRESS_SEARCH_OUTCOME]: addressSearchOutcome,
    [MIXPANEL_PROPERTY_NAMES.ADDRESS_FIELDS_POPULATED]: addressFieldPopulated
  } 
  mixpanel.track(MIXPANEL_EVENT_NAMES.CLICKED_ON_SMARTPASTE_ADDRESS_FIELD, mixpanelProperties)
}

// Mixpanel utility methods for parsing and converting data types

export const parseAndSendMixpanelTrackAttemptedShipperRegistration = formValues => {
  const { liaisonAddress, liaisonContact, liaisonEmail, liaisonName, liaisonPostcode, name } = formValues?.senderDetails

  const isShopNameFilled = !(_.isNil(name) || name === '')
  const isMobileNumberFilled = !(_.isNil(liaisonContact) || liaisonContact === '')
  const isNameFilled = !(_.isNil(liaisonName) || liaisonName === '')
  const isEmailFilled = !(_.isNil(liaisonEmail) || liaisonEmail === '')
  const isAddressFilled = !(_.isNil(liaisonAddress) || liaisonAddress === '')
  const isPostalCodeFilled = !(_.isNil(liaisonPostcode) || liaisonPostcode === '')

  mixpanelTrackAttemptedShipperRegistration(
    isShopNameFilled,
    isMobileNumberFilled,
    isNameFilled,
    isEmailFilled,
    isAddressFilled,
    isPostalCodeFilled
  )
}

export const parseAndSendMixpanelTrackAttemptedOrderCreation = parcels => {
  const stampIds = []
  const numberOfParcels = parcels?.length ?? 0

  let isStateFilled = null
  let isDistrictFilled = null
  let isCityFilled = null
  let isParcelWeightFilled = null
  let isParcelDescriptionFilled = null
  let isInsuranceFeeFilled = null
  let isCODAmountFilled = null
  let isRecipientNameFilled = null
  let isRecipientNumberFilled = null
  let isRecipientEmailFilled = null
  let isRecipientAddressFilled = null
  let isRecipientPostalCodeFilled = null

  // for each field, if it's not filled for any of the parcels, send false to mixpanel
  // set default to null
  // validation rules:
  // 1) if null or true, can change to either true or false
  // 2) if false, no change
  parcels.forEach(parcel => {
    const stampId = parcel?.stampId?.id
    stampId && stampIds.push(stampId)

    isStateFilled = (_.isNull(isStateFilled) || isStateFilled) && !_.isUndefined(parcel?.location?.state)
    isDistrictFilled = (_.isNull(isDistrictFilled) || isDistrictFilled) && !_.isUndefined(parcel?.location?.district)
    isCityFilled = (_.isNull(isCityFilled) || isCityFilled) && !_.isUndefined(parcel?.location?.city)

    isParcelWeightFilled = (_.isNull(isParcelWeightFilled) || isParcelWeightFilled) && !_.isUndefined(parcel?.weight)
    isParcelDescriptionFilled =
      (_.isNull(isParcelDescriptionFilled) || isParcelDescriptionFilled) && !_.isUndefined(parcel?.description)

    isInsuranceFeeFilled =
      (_.isNull(isInsuranceFeeFilled) || isInsuranceFeeFilled) && !_.isUndefined(parcel?.insuredValue)
    isCODAmountFilled = (_.isNull(isCODAmountFilled) || isCODAmountFilled) && !_.isUndefined(parcel?.codValue)

    isRecipientNameFilled =
      !_.isNull(parcel?.recipientDetails?.name) && !_.isUndefined(parcel?.recipientDetails?.name) && (parcel?.recipientDetails?.name !== "")
    isRecipientNumberFilled =
      !_.isNull(parcel?.recipientDetails?.contact) && !_.isUndefined(parcel?.recipientDetails?.contact) && (parcel?.recipientDetails?.contact !== "")
    isRecipientEmailFilled =
      !_.isNull(parcel?.recipientDetails?.email) && !_.isUndefined(parcel?.recipientDetails?.email) && (parcel?.recipientDetails?.email !== "")
    isRecipientAddressFilled =
      !_.isNull(parcel?.recipientDetails?.address) && !_.isUndefined(parcel?.recipientDetails?.address) && (parcel?.recipientDetails?.address !== "")

    isRecipientPostalCodeFilled =
      !_.isNull(parcel?.recipientDetails?.postCode) && !_.isUndefined(parcel?.recipientDetails?.postCode) && (parcel?.recipientDetails?.postCode !== "")
  })

  mixpanelTrackAttemptedOrderCreation(
    stampIds,
    numberOfParcels,
    isStateFilled,
    isDistrictFilled,
    isCityFilled,
    isParcelWeightFilled,
    isParcelDescriptionFilled,
    isInsuranceFeeFilled,
    isCODAmountFilled,
    isRecipientNameFilled,
    isRecipientNumberFilled,
    isRecipientEmailFilled,
    isRecipientAddressFilled,
    isRecipientPostalCodeFilled
  )
}

export const mixpanelTrackClickedOnSearchRecipient = (entryPoint, recipientSearchOutcome, numberOfRecipients = 0) => {
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.ENTRY_POINT]: entryPoint,
    [MIXPANEL_PROPERTY_NAMES.RECIPIENT_SEARCH_OUTCOME]: recipientSearchOutcome,
    [MIXPANEL_PROPERTY_NAMES.NUMBER_OF_RECIPIENT_DETAILS_RESULTS]: numberOfRecipients
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.CLICKED_ON_SEARCH_RECIPIENT, mixpanelProperties)
}

export const mixpanelTrackClearedAllRecipientFields  = () => {
  mixpanel.track(MIXPANEL_EVENT_NAMES.CLEARED_ALL_RECIPIENT_FIELDS)
}

export const mixpanelTrackClickedOnViewAllParcelsToTheCustomerFromHomePage = () => {
  mixpanel.track(MIXPANEL_EVENT_NAMES.VIEW_ALL_PARCELS_TO_THE_CUSTOMER_FROM_HOMEPAGE)
}

export const mixpanelTrackClickedOnViewAllParcelsToTheDriverFromHomePage = () => {
  mixpanel.track(MIXPANEL_EVENT_NAMES.VIEW_ALL_PARCELS_TO_THE_DRIVER_FROM_HOMEPAGE)
}

export const mixpanelTrackClickedOnViewAllParcelsThatAreInStore = () => {
  mixpanel.track(MIXPANEL_EVENT_NAMES.VIEW_ALL_PARCELS_THAT_ARE_IN_STORE)
}

export const mixpanelTrackClickedOnViewAllExpiredParcelsFromHomepage = () => {
  mixpanel.track(MIXPANEL_EVENT_NAMES.VIEW_ALL_EXPIRED_PARCELS_FROM_HOMEPAGE)
}

// Mixpanel events for new shipper drop-off flow
export const mixpanelTrackRemoveParcelFromScannedParcelsList = () => {
  mixpanel.track(MIXPANEL_EVENT_NAMES.REMOVE_PARCEL_FROM_SCANNED_PARCELS_LIST)
}

export const mixpanelTrackCompletedDropOffProcess = (entryPoint, failedParcelsCount, successfulParcelsCount, manualInputParcelsCount, transactionDuration) => {
  const currentDate = new Date()
  const mixpanelProperties = {
    [MIXPANEL_PROPERTY_NAMES.ENTRY_POINT]: (entryPoint === ROUTES.bulkUpload.path) ? ENTRY_POINT_FILE_UPLOAD : ENTRY_POINT_DROP_OFF_OPTIONS,
    [MIXPANEL_PROPERTY_NAMES.FAILED_LODGED_IN_PARCELS]: failedParcelsCount,
    [MIXPANEL_PROPERTY_NAMES.SUCCESSFULLY_LODGED_IN_PARCELS]: successfulParcelsCount,
    [MIXPANEL_PROPERTY_NAMES.MANUALLY_ENTERED_PARCELS]: manualInputParcelsCount,
    [MIXPANEL_PROPERTY_NAMES.TRANSACTION_DURATION]: transactionDuration
  }
  mixpanel.track(MIXPANEL_EVENT_NAMES.COMPLETED_DROP_OFF_PROCESS, mixpanelProperties)
  mixpanel.people.increment({
    [MIXPANEL_PROPERTY_NAMES.LIFETIME_NUMBER_OF_PARCELS_LODGED_IN]: Number(successfulParcelsCount)
  })
  mixpanel.people.set_once(MIXPANEL_PROPERTY_NAMES.FIRST_LODGED_IN_ORDER_DATE, currentDate)
  mixpanel.people.set({ [MIXPANEL_PROPERTY_NAMES.LAST_LODGED_IN_ORDER_DATE]: currentDate })
}