/*
 * This component connects the redux state language locale to the IntlProvider component
 * and i18n messages (loaded from `app/translations`)
 */

import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/id'
import 'moment/locale/ms'
import 'moment/locale/th'
import 'moment/locale/vi'
import 'moment/locale/zh-cn'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale-provider/en_US'
import idID from 'antd/lib/locale-provider/id_ID'
import thTH from 'antd/lib/locale-provider/th_TH'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import msMY from 'antd/lib/locale-provider/ms_MY'
import viVN from 'antd/lib/locale-provider/vi_VN'
import frFR from 'antd/lib/locale-provider/fr_FR'
import { createStructuredSelector } from 'reselect'
import { IntlProvider } from 'react-intl'

import { selectLocale } from './selectors'

export class LanguageProvider extends React.PureComponent {
  // eslint-disable-line react/prefer-stateless-function
  render () {
    const locales = {
      en: enUS,
      fr: frFR,
      id: idID,
      ms: msMY,
      th: thTH,
      vi: viVN,
      zh: zhCN
    }
    moment.locale(this.props.locale)
    return (
      <ConfigProvider locale={locales[this.props.locale] || locales.en}>
        <IntlProvider
          locale={this.props.locale}
          key={this.props.locale}
          messages={this.props.messages[this.props.locale]}
          onError={this.ignoreError}
        >
          {React.Children.only(this.props.children)}
        </IntlProvider>
      </ConfigProvider>
    )
  }

  // ignore since missing message error is very spammy & expected to happen since
  // translation files are only downloaded during build/deployment time
  ignoreError () {}
}

LanguageProvider.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.element.isRequired
}

const mapStateToProps = createStructuredSelector({
  locale: selectLocale()
})

export default connect(mapStateToProps)(LanguageProvider)
