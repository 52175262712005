import { ROUTES } from '@app/utils/constants'
import { isAccepted, isRestricted } from '@app/utils/settingUtils'
import NotFoundPage from '@app/containers/NotFoundPage/Loadable'
import ValidateOauthUserPage from '@app/containers/ValidateOauthUserPage/Loadable'
import Collect from '@app/containers/Collect/Loadable'
import Settings from '@app/containers/Settings/Loadable'
import Login from '@app/containers/Login/Loadable'
import Setup2FAForm from '@app/containers/Setup2FAForm/Loadable'
import NewOrder from '@app/containers/NewOrder/Loadable'
import ReviewOrder from '@app/containers/ReviewOrder/Loadable'
import ConfirmOrder from '@app/containers/ConfirmOrder/Loadable'
import ConfirmOrderV2 from '@app/containers/ConfirmOrderV2/Loadable'
import EditErrorOrder from '@app/containers/EditErrorOrder/Loadable'
import DriverHandover from '@app/containers/DriverHandover/Loadable'
import DriverHandoverV2 from '@app/containers/DriverHandoverV2/Loadable'
import OrderDetails from '@app/containers/OrderDetails/Loadable'
import History from '@app/containers/History/Loadable'
import HomePage from '@app/containers/HomePage/Loadable'
import ShipperSearch from '@app/containers/ShipperSearch/Loadable'
import ShipperCreate from '@app/containers/ShipperCreation/Loadable'
import DeliveryRateCalculator from '@app/containers/DeliveryRateCalculator/Loadable'
import BusinessAnalytics from '@app/pages/BusinessAnalytics/Loadable'
import ScanParcelPage from '@app/pages/ScanParcelPage/Loadable'
import SendParcelPage from '@app/pages/SendParcelPage/Loadable'
import ReviewBulkOrder from '@app/containers/ReviewBulkOrder/Loadable'
import ScanParcelPageV2 from '@app/pages/ScanParcelPageV2/Loadable'
import SendParcelPageV2 from '@app/pages/SendParcelPageV2/Loadable'
import BulkUploadOrders from '@app/containers/BulkUploadOrders'
import CustomerCollect from '@app/pages/CustomerCollect/Loadable'

export const defaultComponent = {
  main: NotFoundPage
}

export const routes = [
  {
    ...ROUTES.home,
    component: HomePage
  },
  {
    ...ROUTES.settings,
    component: Settings
  },
  {
    ...ROUTES.history,
    component: History
  },
  {
    ...ROUTES.login,
    component: Login
  },
  {
    ...ROUTES.collect,
    component: Collect
  },
  {
    ...ROUTES.customerCollect,
    component: CustomerCollect
  },
  {
    ...ROUTES.customerCollectReview,
    component: CustomerCollect
  },
  {
    ...ROUTES.customerCollectSuccess,
    component: CustomerCollect
  },
  {
    ...ROUTES.driverHandover,
    component: DriverHandoverV2
  },
  {
    ...ROUTES.toDriver,
    component: DriverHandover,
    props: {
      isToDriver: true
    }
  },
  {
    ...ROUTES.fromDriver,
    component: DriverHandover,
    props: {
      isToDriver: false
    }
  },
  {
    ...ROUTES.sendParcel,
    component: SendParcelPage
  },
  {
    ...ROUTES.sendParcelV2,
    component: SendParcelPageV2
  },
  {
    ...ROUTES.newOrder,
    component: NewOrder
  },
  {
    ...ROUTES.reviewOrder,
    component: ReviewOrder
  },
  {
    ...ROUTES.reviewBulkOrder,
    component: ReviewBulkOrder
  },
  {
    ...ROUTES.confirmOrder,
    component: ConfirmOrder
  },
  {
    ...ROUTES.newConfirmOrder,
    component: ConfirmOrderV2
  },
  {
    ...ROUTES.editErrorOrder,
    component: EditErrorOrder
  },
  {
    ...ROUTES.scanParcel,
    component: ScanParcelPage
  },
  {
    ...ROUTES.newScanParcel,
    component: ScanParcelPageV2
  },
  {
    ...ROUTES.editOrder,
    component: NewOrder,
    props: {
      isEdit: true
    }
  },
  {
    ...ROUTES.orderDetails,
    component: OrderDetails
  },
  {
    ...ROUTES.rateCalculator,
    component: DeliveryRateCalculator
  },
  {
    ...ROUTES.shipperSearch,
    component: ShipperSearch
  },
  {
    ...ROUTES.businessAnalytics,
    component: BusinessAnalytics
  },
  {
    ...ROUTES.shipperCreate,
    component: ShipperCreate
  },
  {
    ...ROUTES.validateLogin,
    component: ValidateOauthUserPage
  },
  {
    ...ROUTES.setup2FAForm,
    component: Setup2FAForm
  },
  {
    ...ROUTES.bulkUpload,
    component: BulkUploadOrders
  },
  {
    path: '**',
    component: NotFoundPage
  }
]

export const getRoutesConfig = info => {
  return routes.filter(item => {
    const { accepted, rejected } = item
    return isAccepted(accepted, info) && !isRestricted(rejected, info)
  })
}
