import React from 'react'
import _ from 'lodash'
import { Progress } from 'antd'

import { colors } from '@app/themes'
import { Icon } from '@app/components/Icon'
import { Text } from '@app/components/Text'
import { Row } from '@app/components/Row'

import { ProgressContainer } from './styles'

const ProgressBar = props => {
  const { progressPercentage, fileName, textId, icon } = props
  return (
    <ProgressContainer data-testid='progress-container'>
      <Row>
        <Icon icon={icon} color={colors.greyishBrown} />
        <Text
          type='secondary'
          size='subHeading'
          textId={textId}
          values={{ label: fileName, percent: _.toString(progressPercentage) }}
        />
      </Row>
      <Progress percent={progressPercentage} showInfo={false} strokeColor={colors.emerald} strokeWidth={4} />
    </ProgressContainer>
  )
}

export { ProgressBar }
