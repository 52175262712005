/**
 *
 * LocationsProvider
 *
 */

import React, { Fragment, useEffect, useState } from 'react'
import Config from 'configs'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import _ from 'lodash'

import { isEqual } from '@app/utils/constants'
import injectSaga from '@app/utils/injectSaga'
import injectReducer from '@app/utils/injectReducer'

import saga from './saga'
import { locationsCreators, locationsReducer } from './redux'
import { selectLocationsCities, selectLocationsData, selectLocationsLoading, selectLocationsStates } from './selectors'
import { selectCountry } from '@app/containers/Base/selectors'

/**
 * Higher order component used to force re-render LocationsProvider
 * when feature flag prop is updated in parent component. This can happen
 * if feature hub client wasn't initialized before LocationsProvider was mounted.
 */
const FeatureFlagWrapper = (props) => {
  const { locations, requestGetLocations, isQuickAddressFeatureEnabled, children } = props

  const [forceUpdate, setForceUpdate] = useState(isQuickAddressFeatureEnabled)

  useEffect(() => {
    !(locations && locations.length) && !_.isNil(isQuickAddressFeatureEnabled) && requestGetLocations(isQuickAddressFeatureEnabled, null, null)
    setForceUpdate(isQuickAddressFeatureEnabled)
  }, [])

  useEffect(() => {
    !_.isNil(isQuickAddressFeatureEnabled) && requestGetLocations(isQuickAddressFeatureEnabled, null, null)
    setForceUpdate(isQuickAddressFeatureEnabled)
  }, [isQuickAddressFeatureEnabled])

  return <Fragment key={forceUpdate?.toString?.()}>{children}</Fragment> // use React key to re-mount the component, which leads to the children being re-mounted
}
export class LocationsProvider extends React.Component {
  getL2Names = state => {
    const { locations, cities, isQuickAddressFeatureEnabled } = this.props
    if (Config.ENVIRONMENT === 'cd') {
      return locations ? locations.map(loc => loc.l2Name) : []
    }

    if (isQuickAddressFeatureEnabled && locations && state) {
      if (cities && cities.length !== 0) {
        return cities
      }
      return []
    }

    let l2Names = []
    if (locations && state) {
      l2Names = locations.filter(loc => loc.l2Name && isEqual(loc.l1Name, state)).map(loc => loc.l2Name)
    }
    return _.uniq(l2Names)
  }
  getL3Names = (state, city) => {
    const { locations, isQuickAddressFeatureEnabled } = this.props
    let districts = []
    if (isQuickAddressFeatureEnabled && locations && state && city) {
      districts = locations
        .filter(location => location.l3AddressDisplayValue && isEqual(location.l1AddressDisplayValue, state) && isEqual(location.l2AddressDisplayValue, city))
        .map(location => location.l3AddressDisplayValue)
      return _.uniq(districts)
    }

    if (locations && state && city) {
      districts = locations
        .filter(loc => loc.l3Name && isEqual(loc.l1Name, state) && isEqual(loc.l2Name, city))
        .map(loc => loc.l3Name)
    }
    return _.uniq(districts)
  }
  getLatLng = (state, city = '', district = '', flag = null) => {
    const { locations } = this.props
    if (!locations) return null

    const location = locations.find(loc => {
      if (Config.ENVIRONMENT === 'cd') {
        return isEqual(_.get(loc, 'l2Name'), city)
      }
      const l1Name = isEqual(_.get(loc, 'l1Name'), state)
      const l2Name = isEqual(_.get(loc, 'l2Name'), city)
      const l3Name = isEqual(_.get(loc, 'l3Name'), district)
      return flag ? l1Name && l2Name && l3Name : l1Name || l2Name || l3Name
    })
    return { lat: _.get(location, 'latitude', null), lng: _.get(location, 'longitude', null) }
  }

  // Utility function for components to trigger a fetch of L1/L2/L3 addresses for quick addresses
  getLocations = (l1Address, l2Address) => {
    const { requestGetLocations, isQuickAddressFeatureEnabled } = this.props
    if (isQuickAddressFeatureEnabled) {
      requestGetLocations(isQuickAddressFeatureEnabled, l1Address, l2Address)
    }
  }

  render () {
    const { country, children, ...rest } = this.props
    const props = {
      ...rest,
      country,
      getLatLng: this.getLatLng,
      getL2Names: this.getL2Names,
      getL3Names: this.getL3Names,
      getLocations: this.getLocations
    }

    return (
      <Fragment>
        <FeatureFlagWrapper {...props}>
          {children(props)}
        </FeatureFlagWrapper>
      </Fragment>
    )
  }
}

LocationsProvider.propTypes = {
  dispatch: PropTypes.func.isRequired
}

LocationsProvider.defatulProps = {
  location: []
}

function mapDispatchToProps (dispatch) {
  const { requestGetLocations } = locationsCreators
  return {
    dispatch,
    requestGetLocations: (isQuickAddressFeatureEnabled, l1Address, l2Address) => {
      dispatch(requestGetLocations(isQuickAddressFeatureEnabled, l1Address, l2Address))
    }
  }
}
const mapStateToProps = createStructuredSelector({
  loading: selectLocationsLoading(),
  locations: selectLocationsData(),
  states: selectLocationsStates(),
  cities: selectLocationsCities(),
  country: selectCountry()
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withSaga = injectSaga({ key: 'locations', saga })
const withReducer = injectReducer({ key: 'locations', reducer: locationsReducer })

export default compose(withConnect, withSaga, withReducer)(LocationsProvider)
