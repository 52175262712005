/**
 *
 * Dot
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors } from '@app/themes'

const StyledDot = styled.span`
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  border-radius: 50%;
  background-color: ${props => props.color};
  display: inline-block;
`

const Dot = props => <StyledDot {...props} />

Dot.propTypes = {
  children: PropTypes.node
}

Dot.defaultProps = {
  size: 8,
  color: colors.emerald
}

export { Dot }
