import { DELIVERY_TYPES } from '@app/utils/constants'

const DEFAULT_ORDER = {
  parcels: []
}

const DEFAULT_POST_PARCEL = {
  weight: 1,
  deliveryType: DELIVERY_TYPES.STANDARD,
  stampId: {
    isVerified: false
  }
}
const DEFAULT_PACK_PARCEL = {
  // Hard-code pack total cost to 0
  cost: {
    total: 0.0
  }
}

export { DEFAULT_ORDER, DEFAULT_POST_PARCEL, DEFAULT_PACK_PARCEL }
