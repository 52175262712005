// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

import type { DpJobOrdersResponse } from '@app/types/DpJobOrder'

const getDpJobOrdersApi = (country: string, jobId: number, offsetId: number, limit: number): DpJobOrdersResponse => {
  const client = getApiClient(apiClients, country)
  const url = `1.0/job-orders`
  return client.get(url, { job_id: jobId, offset_id: offsetId, limit })
}

export { getDpJobOrdersApi }
