import styled from 'styled-components'

const StyledSubText = styled.div`
  && {
    font-weight: ${props => props.fontWeight || 'normal'};
    ${props => props.fontSize};
    color: ${props => props.color};
    background-color: ${props => props.backgroundColor};
    padding: ${props => props.padding}px;
    ${props =>
    props.ellipsis &&
      `
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    `};
    text-align: ${props => props.textAlign || 'left'};
    border-radius: 4px;
    text-transform: ${props => props.allCaps && 'uppercase'};
  }
`

export { StyledSubText }
