import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'
import { reset, set, updateIn } from 'utils/reducerUtils'
import { baseTypes } from 'containers/Base/redux'

export const { Types: awbTypes, Creators: awbCreators } = createActions(
  {
    updateItems: ['items'],
    resetItems: [],
    requestPrintAwb: ['index', 'paperSize'],
    successPrintAwb: ['index'],
    failPrintAwb: ['index']
  },
  {
    prefix: 'Awb/'
  }
)

export const INITIAL_STATE = fromJS({
  items: []
})

const setAwbProcessing = (state, index, processing) => {
  return updateIn(state, ['items', index], { processing })
}

export const awbReducer = createReducer(INITIAL_STATE, {
  [baseTypes.LOGOUT]: () => INITIAL_STATE,

  [awbTypes.UPDATE_ITEMS]: (state, { items }) => set(state, 'items', items),
  [awbTypes.RESET_ITEMS]: state => reset(state, 'items', INITIAL_STATE),
  [awbTypes.REQUEST_PRINT_AWB]: (state, { index }) => setAwbProcessing(state, index, true),
  [awbTypes.SUCCESS_PRINT_AWB]: (state, { index }) => setAwbProcessing(state, index, false),
  [awbTypes.FAIL_PRINT_AWB]: (state, { index }) => setAwbProcessing(state, index, false)
})
