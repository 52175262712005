import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'
import { parcelVolumeCategory } from '@app/constants/shipperAnalytics'

export const INITIAL_STATE = fromJS({
  fields: {
    currentShipperId: 0,
    dateFrom: '',
    dateTill: '',
    parcelVolumeCategory: parcelVolumeCategory.TOTAL_VOLUME
  },
  shipperVolumeTrends: {},
  shipperVolumes: [],
  analyticsReport: {}
})

export const { Types: shipperAnalyticsTypes, Creators: shipperAnalyticsCreators } = createActions(
  {
    failGetShipperVolumeTrends: ['shipperId'],
    failGetShipperVolumes: [],
    requestGetShipperVolumes: [],
    requestGetShipperVolumeTrends: ['shipperId'],
    reset: [],
    successGetShipperVolumeTrends: ['shipperId', 'volumeTrends'],
    successGetShipperVolumes: ['shipperVolumes'],
    updateCurrentShipperId: ['shipperId'],
    updateDateRange: ['dateFrom', 'dateTill'],
    updateParcelVolumeCategory: ['parcelVolumeCategory'],
    requestGetShipperAnalyticsReport: ['shipperId'],
    successGetShipperAnalyticsReport: ['analyticsReport'],
    failGetShipperAnalyticsReport: []
  },
  {
    prefix: 'ShipperAnalytics/'
  }
)

export default createReducer(INITIAL_STATE, {
  [shipperAnalyticsTypes.FAIL_GET_SHIPPER_VOLUME_TRENDS]: (state, action) => {
    return state.setIn(['shipperVolumeTrends', action.shipperId], fromJS([]))
  },
  [shipperAnalyticsTypes.FAIL_GET_SHIPPER_VOLUMES]: (state, action) => {
    return state.set('shipperVolumes', fromJS([]))
  },
  [shipperAnalyticsTypes.RESET]: state => {
    return state
      .setIn(['fields', 'currentShipperId'], 0)
      .set('shipperVolumeTrends', fromJS({}))
      .set('shipperVolumes', fromJS([]))
  },
  [shipperAnalyticsTypes.SUCCESS_GET_SHIPPER_VOLUME_TRENDS]: (state, action) => {
    return state.setIn(['shipperVolumeTrends', action.shipperId], fromJS(action.volumeTrends), {})
  },
  [shipperAnalyticsTypes.SUCCESS_GET_SHIPPER_VOLUMES]: (state, action) => {
    return state.set('shipperVolumes', fromJS(action.shipperVolumes))
  },
  [shipperAnalyticsTypes.UPDATE_CURRENT_SHIPPER_ID]: (state, action) => {
    return state.setIn(['fields', 'currentShipperId'], action.shipperId)
  },
  [shipperAnalyticsTypes.UPDATE_DATE_RANGE]: (state, action) => {
    return state.setIn(['fields', 'dateFrom'], action.dateFrom).setIn(['fields', 'dateTill'], action.dateTill)
  },
  [shipperAnalyticsTypes.UPDATE_PARCEL_VOLUME_CATEGORY]: (state, action) => {
    return state.setIn(['fields', 'parcelVolumeCategory'], action.parcelVolumeCategory)
  },
  [shipperAnalyticsTypes.SUCCESS_GET_SHIPPER_ANALYTICS_REPORT]: (state, action) =>
    state.set('analyticsReport', action.analyticsReport),
  [shipperAnalyticsTypes.FAIL_GET_SHIPPER_ANALYTICS_REPORT]: (state, action) =>
    state.set('analyticsReport', action.analyticsReport)
})
