/**
 *
 * NotificationModal
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { H1 } from '../H1'
import { CustomModal } from '../CustomModal'
import { T } from '@nv/rc/Components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { colors, display, fonts, media } from '@app/themes'

const StyledNotificationModal = styled(CustomModal)`
  .ant-modal-body {
    ${display.flex('center', 'space-around', 'column')};
    padding: 40px;

    svg {
      color: ${props => props.iconColor};
    }

    ${media.max.mobile`padding: 20px;`};
  }
`

const MainText = styled(H1)`
  color: ${colors.Rouge};
  text-align: center;
  ${fonts.size.subtitle};
  ${media.max.mobile`${fonts.size.heading}`};
`

const Description = styled.div`
  color: ${colors.LabelTextColor};
  text-align: center;
  ${fonts.size.medium};
`
const ActionText = styled(Description)`
  cursor: pointer;
  ${fonts.size.heading};
  ${media.max.mobile`${fonts.size.big}`};

  &:hover {
    text-decoration: underline;
  }
`
const NotificationModal = props => {
  return (
    <StyledNotificationModal {...props} headerColor={colors.DarkGray} headerBackground='white'>
      <FontAwesomeIcon icon={props.icon} size={props.iconSize} />
      <MainText>
        <T id={props.mainText} />
      </MainText>
      {props.description && (
        <Description>
          <T id={props.description} />
        </Description>
      )}
      {props.actionText && (
        <ActionText onClick={props.onClickAction}>
          <T id={props.actionText} />
        </ActionText>
      )}
      {props.children}
    </StyledNotificationModal>
  )
}

NotificationModal.propTypes = {
  children: PropTypes.node,
  mainText: PropTypes.string,
  actionText: PropTypes.string,
  height: PropTypes.number,
  onClickAction: PropTypes.func
}

NotificationModal.defaultProps = {
  children: null,
  icon: '',
  iconColor: colors.Viridian,
  iconSize: '9x',
  mainText: '',
  actionText: '',
  height: 450,
  width: 350
}

export { NotificationModal }
