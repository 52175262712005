import PropTypes from 'prop-types'
import React from 'react'
import H from 'react-highlight-words'

import { colors } from '@app/themes'

const Highlighter = props => {
  return (
    <H
      autoEscape
      highlightStyle={{
        backgroundColor: colors.Apricot
      }}
      searchWords={props.searchWords}
      textToHighlight={props.textToHighlight}
      {...props}
    />
  )
}

Highlighter.propTypes = {
  searchWords: PropTypes.arrayOf(PropTypes.string).isRequired,
  textToHighlight: PropTypes.string.isRequired
}

export { Highlighter }
