// @flow

import { rest } from 'msw'
import snake from 'snakecase-keys'
import configs from 'configs'
import { mockUserInfo } from '@app/constants'

import type { LodgeInData } from '@app/types/SendOrderResponse'

const mask = `${configs.BASE_URL}/:systemId/dp/1.0/lodge-in/:statusId`
const {  MOCK_DP_ID } = mockUserInfo

export const getLodgeInByStatusIdHandler = rest.get(mask, (req, res, ctx) => {
  const { statusId } = req.params

  const data: LodgeInData = {
    data: {
      id: statusId,
      dpId: MOCK_DP_ID,
      status: 'PROCESSED',
      successfulOrders: 1,
      failedOrders: []
    }
  }

  return res(ctx.status(200), ctx.json(snake(data)))
})
