// @flow

import { Colors as nvColors } from '@nv/rc/Themes'

export const chronoDiali = {
  ChronopostBlack: '#414042',
  ChronopostBlue: '#009adf',
  ChronopostDarkBlue: '#0060a2',
  LightGrey: '#e6e7e8',
  MidGrey: '#808285',
  WarmGrey: '#cac4be'
}

export const colors = {
  PrimaryColor: 'white',
  SecondaryColor: '#4d4d4d',
  GrayTextColor: '#5c6166',
  DarkGrayTextColor: '#4c4c4c',
  BackgroundGrayColor: '#bdbdbd',
  CardBorderGray: '#c5c4c4',
  SecondaryBorderColor: '#5a5a5a',
  LabelTextColor: '#8d8d8d',
  SecondaryThemeColor: '#ffc667',
  DullGray: '#3a3a3a',
  ColorWhite: 'white',
  NinjaRed: '#C2002f',
  NinjaPink: '#F7F7F7',
  DisabledGray: '#D3D3D3',
  SecondaryButtonTextColor: '#808080',
  MenuItemBackgroundColor: '#C2002f',
  SelectedMenuItemBackgroundColor: '#C2002f',
  MenuItemTextColor: '#585858',
  SelectedMenuItemTextColor: 'white',
  MenuItemHoverColor: '#d3d3d3',
  CardBorderColor: '#e8e8e8',
  LoaderGreyColor: '#494d50'
}

export const newColors = {
  Black: '#000000',
  Rouge: '#b01628',
  DarkGray: '#5c6166', // GrayTextColor
  LightGray: '#e2e2e2',
  FadedPink: '#e8c3c7',
  LightPink: '#fbe2e2',
  PaleGray: '#f7f9fa',
  Silver: '#d0d4d9',
  Apricot: '#ffc667', // SecondaryThemeColor
  Viridian: '#218380',
  BrownishGray: '#676767',
  FadedOrange: '#ed6a5a',
  Yellow: '#fffd08',
  Green: '#29ad32',
  Brown: '#9d5d24',
  Purple: '#9013fe',
  Pink: '#f0377f',
  Blue: '#1467e8',
  FadedGray: '#9d9d9d',
  DullGray: '#848484',
  BluishGray: '#e6eaed',
  semiTransparent: 'rgba(0, 0, 0, 0.5)',
  emeraldLight: '#ecf9f5',
  emeraldGreen: '#47c798'
}

export default { ...chronoDiali, ...colors, ...newColors, ...nvColors }
