import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Layout } from 'antd'
import { faBars } from '@fa-pro-solid/faBars'
import { Icon } from '@nv/rc/Components'

import { NVLogo } from '@app/components/Logo'

import _ from 'lodash'
import { ROUTES, isEqual } from '@app/utils/constants'
import { colors, display } from '@app/themes'
import commons from '@app/constants/commons'
import { MenuIcon, SearchIcon } from '@nv/react-akira'

const Header = Layout.Header

const CustomHeader = styled(Header)`
  && {
    user-select: none;
    background-color: ${colors.menu};
    width: 100%;
    position: fixed;
    top: 0;
    padding: 0;
    ${`height: ${commons.HEADER_HEIGHT}px`};
  }
`
const HeaderWrapper = styled.div`
  ${display.flex('center', 'center')};
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
`
const IconWrapper = styled.div`
  ${display.flex('center', 'center')};
  width: 60px;
  height: 60px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
`
const HamburgerMenuIcon = styled(Icon)`
  margin: 0 auto;
`

export const NewIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    background: ${props => (!props.isHideOrderSearch ? `${colors.warmGrey} !important` : 'none')};
  }
`

class HeaderComponent extends React.Component {
  /**
   * Show leave without checking in your parcel modal if orders are scanned into review order panel
  */
  backToHomePage = () => {
    const { reviewParcelOrders, updateRoute, showLeaveWithoutParcelModal, location } = this.props
    if (reviewParcelOrders.length > 0 && isEqual( _.get(location, 'pathname'), ROUTES.newScanParcel.path)) {
      showLeaveWithoutParcelModal()
      return
    } 
    updateRoute(ROUTES.home.path)
  }
  
  render () {
    const { toggleMenu, toggleHideOrderSearch, isNewAkiraDesignsEnabled, location, isHideOrderSearch } = this.props
    const isHomePage = isEqual( _.get(location, 'pathname'), ROUTES.home.path)
    return isNewAkiraDesignsEnabled ? (
      <CustomHeader>
        <HeaderWrapper>
          <NewIconWrapper onClick={toggleMenu}>
            <MenuIcon size='l' color='white' data-testid="menu-icon" />
          </NewIconWrapper>
          <NVLogo onClick={this.backToHomePage} />
          { isHomePage && <NewIconWrapper onClick={toggleHideOrderSearch} isHideOrderSearch={isHideOrderSearch}>
              <SearchIcon size='l' color='white' data-testid="search-icon" /> 
            </NewIconWrapper>
          }
        </HeaderWrapper>
      </CustomHeader>
    ) : (
      <CustomHeader>
        <HeaderWrapper>
          <IconWrapper onClick={toggleMenu}>
            <HamburgerMenuIcon color={colors.pinkishGrey} icon={faBars} type='medium' data-testid="menu-icon" />
          </IconWrapper>
          <NVLogo onClick={this.backToHomePage} />
        </HeaderWrapper>
      </CustomHeader>
    )
  }
}

HeaderComponent.propTypes = {
  updateRoute: PropTypes.func,
  toggleMenu: PropTypes.func
}

export { HeaderComponent }
