// @flow

import React from 'react'
import styled from 'styled-components'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import { injectSize } from '@nv/rc/Components'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt'
import { faCommentAlt } from '@fortawesome/pro-solid-svg-icons/faCommentAlt'

import { ResponsiveContainer } from '@app/components/ResponsiveContainer'
import { Row } from '@app/components/Row'
import { Column } from '@app/components/Column'
import { IconWithText } from '@app/components/IconWithText'
import { SubText } from '@app/components/SubText'
import { CustomButton } from '@app/components/CustomButton'
import { colors, fonts, media } from '@app/themes'

import type { Props, State } from '@app/models/Event.model'

const StyledEvent = styled.div`
  ${media.min.desktop`margin: 0 40px;`};
  align-items: stretch;
`
const Oval = styled.div`
  && {
    background: ${props => (props.current ? colors.Green : colors.BackgroundGrayColor)};
    border: 1px solid ${props => (props.current ? colors.Green : colors.BackgroundGrayColor)};
    border-radius: 50%;
    width: 12px;
    height: ${props => (props.isMobile ? '12px' : '14px')};
    margin: 0 auto !important;
    z-index: 99;
  }
`
const DashedLine = styled.div`
  && {
    height: 100%;
    margin: 0 auto;
    ${media.max.tablet`margin-bottom: -20px !important;`};
    width: 2px;
    border: 1px dashed ${colors.BackgroundGrayColor};
  }
`

const CustomColumn = styled(Column)`
  && {
    ${props => props.width && ` width: ${props.width};`};
    ${props =>
    props.maxWidth &&
      `
      max-width: ${props.maxWidth}px;
      word-break: break-word;
    `};
    ${props =>
    props.event &&
      `
      padding: 20px;
      border: 1px solid ${colors.paleGrey};
      margin-bottom: 20px;
    `};

    ${props => props.isMobile && `margin-top: 10px;`};
  }
`

class Event extends React.Component<Props, State> {
  state = {
    hideDetails: true
  }

  renderEventDetailRow = (textContent, icon) => {
    if (textContent) {
      return <IconWithText icon={icon} textContent={textContent} />
    }
    return null
  }

  renderEventDetails = (event, isMobile) => {
    const { driverName, scanLocation, hubName, comment } = event
    const { hideDetails } = this.state

    if (!driverName && !scanLocation && !hubName && !comment) {
      return null
    }

    // if it's mobile then check state and render button
    if (isMobile && hideDetails) {
      return (
        <CustomButton
          textId={'view_more'}
          dataTestId='view-more-event-details'
          onClick={() => this.setState({ hideDetails: !hideDetails })}
        />
      )
    }
    // TODO: DC - Hiding driver contact for the first release of CSM
    return (
      <CustomColumn
        isMobile={isMobile}
        width={'100%'}
        event
        flexShrink={0.8}
        flexGrow={1}
        flexBasis={'0%'}
        full={!isMobile}
        start
      >
        {this.renderEventDetailRow(driverName, faUser)}
        {/* {this.renderEventDetailRow(driverContact, faPhone)} */}
        {this.renderEventDetailRow(scanLocation, faMapMarkerAlt)}
        {this.renderEventDetailRow(hubName, faMapMarkerAlt)}
        {this.renderEventDetailRow(comment, faCommentAlt)}
      </CustomColumn>
    )
  }

  renderEventProgress = full => {
    const { current, isMobile } = this.props

    return (
      <CustomColumn full={full} marginTop={5} flexBasis={isMobile ? '20px' : '120px'} alignItems={'stretch'}>
        <Oval isMobile={isMobile} current={current} />
        <DashedLine />
      </CustomColumn>
    )
  }

  render () {
    const { event, isMobile, intl } = this.props
    return (
      <StyledEvent>
        <Row equal alignItems={'stretch'} start verticalAlign={'top'}>
          {isMobile && this.renderEventProgress()}
          <ResponsiveContainer breakpoint={!isMobile} style={{ marginBottom: '10px' }}>
            <CustomColumn width={'200px'} horizontalMargin={5}>
              <SubText fontSize={fonts.dynamicFontSize(fonts.size.medium, 2)}>
                {event.index + 1 + '. ' + intl.formatMessage({ id: event.eventName })}
              </SubText>
              <SubText color={colors.whiteFour} fontSize={fonts.dynamicFontSize(fonts.size.medium, 2)}>
                {moment.unix(event.eventTime / 1000).format('DD MMM, hh:mm a')}
              </SubText>
              <SubText fontSize={fonts.dynamicFontSize(fonts.size.medium, 2)}>{event.eventDuration}</SubText>
            </CustomColumn>
            {!isMobile && this.renderEventProgress(true)}
            {this.renderEventDetails(event, isMobile)}
          </ResponsiveContainer>
        </Row>
      </StyledEvent>
    )
  }
}

const Event_ = compose(injectSize, injectIntl)(Event)
export { Event_ as Event }
