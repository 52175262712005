import styled from 'styled-components'
import { colors, display } from '@app/themes'

const VolumeHeader = styled.div`
  ${display.flex()}
  padding-top: 20px;
`

const NumberLabel = styled.h1`
  color: ${colors.SecondaryColor};
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 1px;
  padding: 5px 0;
  margin: 0;
`

export { VolumeHeader, NumberLabel }
