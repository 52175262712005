import { createActions, createReducer } from 'reduxsauce'
import { set, setLoading } from '@app/utils/reducerUtils'
import { fromJS } from 'immutable'

export const { Types: parcelOverviewTypes, Creators: parcelOverviewCreators } = createActions(
  {
    updateParcelOverviewFields: ['fields'],
    requestGetOrdersReport: ['fields'],
    failGetOrdersReport: ['ordersReport']
  },
  { prefix: 'ParcelOverview/' }
)

export const INITIAL_STATE = fromJS({
  fields: {},
  orders: [],
  loading: false,
  totalOrders: 0,
  printLoading: {},
  ordersReport: {}
})

export const parcelOverviewReducer = createReducer(INITIAL_STATE, {
  [parcelOverviewTypes.FAIL_GET_ORDERS_REPORT]: (state, action) => {
    setLoading(state, 'ordersReport', false)
    return set(state, 'ordersReport', action.ordersReport)
  },
  [parcelOverviewTypes.UPDATE_PARCEL_OVERVIEW_FIELDS]: (state, action) => set(state, 'fields', action.fields)
})
