// @flow

import type { PostOrderRequestPayload } from '@app/types/PostOrderRequestPayload'
import type { PostOrderAsyncRequestPayload } from '@app/types/PostOrderAsyncRequestPayload'
import type { PostOrderResponse } from '@app/types/PostOrderResponse'
import type { Response } from '@app/types/Response'
import { apiClientsNoTransform } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

export default (country: string, payload: PostOrderRequestPayload): Response<Array<PostOrderResponse>> => {
  const client = getApiClient(apiClientsNoTransform, country)
  const url = `1.2/reservations/post`
  return client.post(url, payload)
}

export const createPostOrderWithOptionalStampId =  (country: string, payload: PostOrderRequestPayload): Response<Array<PostOrderResponse>> => {
  const client = getApiClient(apiClientsNoTransform, country)
  const url = `1.3/reservations/post`
  return client.post(url, payload)
}

export const createPostOrderAsyncApi = (country: string, payload: PostOrderAsyncRequestPayload): Response<Array<PostOrderResponse>> => {
  const client = getApiClient(apiClientsNoTransform, country)
  const url = `1.1/reservations/post`
  return client.post(url, payload)
}

