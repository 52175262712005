import React from 'react'
import { Text } from '@app/components/Text'
import { AwardProgressBar } from '@app/components/AwardProgressBar'
import { formatNumber } from '@app/utils/OrderUtils'
import { colors, fonts } from '@app/themes'

import { PROGRESS_BAR_COLORS } from './constants'
import { AwardTierBlock, ProgressWrapper } from './styles'

import { T } from '@nv/rc/Components'
import _ from 'lodash'

export const AwardTier = ({ title, type, numParcels, border = false, levels }) => {
  const currentLevel = _.findLast(levels, level => level.threshold <= numParcels)
  const currentBarColor = _.get(PROGRESS_BAR_COLORS, [type])
  return (
    <AwardTierBlock border={border}>
      <header>
        <Text
          textAlign='right'
          letterSpacing={fonts.LETTER_SPACING}
          color={colors.LabelTextColor}
          size='subHeading'
          textId={_.toLower(title)}
        />
        <T id={currentLevel.name} />
      </header>
      <Text textAlign='right' letterSpacing={fonts.LETTER_SPACING} color={colors.LabelTextColor} size='subHeading'>
        {formatNumber(numParcels)} <T id='parcels' />
      </Text>
      <ProgressWrapper>
        <AwardProgressBar currentValue={numParcels} levels={levels} barColor={currentBarColor} />
      </ProgressWrapper>
    </AwardTierBlock>
  )
}
