import _ from 'lodash'
import { reservations } from '@app/constants'

const { ORDER_TYPES } = reservations

export const POST_CSV_HEADER_INDEX = 0
export const POST_CSV_FIELDS = {
  shipper_id: 'SHIPPER ID',
  stamp_id: 'STAMP ID',
  to_name: 'NAME',
  to_address1: 'ADDRESS',
  to_email: 'EMAIL',
  to_contact: 'CONTACT',
  to_postcode: 'POSTCODE',
  weight: 'WEIGHT',
  delivery_type: 'DELIVERY TYPE',
  delivery_instruction: 'COMMENTS',
  pickup_instruction: 'DESCRIPTION',
  cod: 'CASH ON DELIVERY',
  insured_value: 'INSURED VALUE'
}

export const POST_CSV_FIELDS_WITH_EXTERNAL_SHIPPER_ID = {
  external_shipper_id: 'EXTERNAL SHIPPER ID',
  stamp_id: 'STAMP ID',
  to_name: 'NAME',
  to_address1: 'ADDRESS',
  to_email: 'EMAIL',
  to_contact: 'CONTACT',
  to_postcode: 'POSTCODE',
  weight: 'WEIGHT',
  delivery_type: 'DELIVERY TYPE',
  delivery_instruction: 'COMMENTS',
  pickup_instruction: 'DESCRIPTION',
  cod: 'CASH ON DELIVERY',
  insured_value: 'INSURED VALUE'
}

export const POST_CSV_FIELDS_WITH_L1 = {
  ...POST_CSV_FIELDS,
  l1_id: 'PROVINCE'
}

export const UPLOAD_FILE_TYPES = {
  CSV: {
    acceptFormat: '.csv',
    type: 'text/csv'
  },
  EXCEL: {
    acceptFormat: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  },
  MS_EXCEL: {
    acceptFormat: 'application/vnd.ms-excel',
    type: 'application/vnd.ms-excel'
  }
}

export const getAcceptedFileTypes = fileTypes => {
  const acceptFormats = fileTypes.map(fileType => _.get(UPLOAD_FILE_TYPES, [fileType, 'acceptFormat'], ''))
  return _.join(acceptFormats, ',')
}

// CSV Parser
export const firstMileSheetParser = (cb, data, isL1AddressSupported, isHideLegacyShipperIdEnabled = false) => {
  const type = getOrderType(data, isL1AddressSupported, isHideLegacyShipperIdEnabled)
  _.isFunction(cb) && cb(type, data)
}

const getOrderType = (data, isL1AddressSupported, isHideLegacyShipperIdEnabled) => {
  if (isValidLodgeInSheet(data)) return ORDER_TYPES.send
  if (isValidPostSheet(data, isL1AddressSupported, isHideLegacyShipperIdEnabled)) return ORDER_TYPES.bulkPost
}

// CSV Validators
function isValidLodgeInSheet (data) {
  return !data.find(item => item.length > 1)
}
function isValidPostSheet (data, isL1AddressSupported, isHideLegacyShipperIdEnabled) {
  const header = _.get(data, POST_CSV_HEADER_INDEX, [])
  const expectedHeader = (isL1AddressSupported) ? Object.values(POST_CSV_FIELDS_WITH_L1) : (isHideLegacyShipperIdEnabled)? Object.values(POST_CSV_FIELDS_WITH_EXTERNAL_SHIPPER_ID) : Object.values(POST_CSV_FIELDS)

  if (header.length !== expectedHeader.length) {
    return false
  } else {
    return _.difference(expectedHeader, header).length === 0
  }
}
export function isValidAwbCSV (data) {
  return !data.find(item => item.length > 1)
}

export function getPositionToFieldMap (header, isL1AddressSupported, isHideLegacyShipperIdEnabled=false) {
  const inverted = (isL1AddressSupported) ? _.invert(POST_CSV_FIELDS_WITH_L1)
    : (isHideLegacyShipperIdEnabled) ? _.invert(POST_CSV_FIELDS_WITH_EXTERNAL_SHIPPER_ID) : _.invert(POST_CSV_FIELDS)
  return header.reduce((acc, h, index) => {
    _.set(acc, index, _.get(inverted, h))
    return acc
  }, {})
}
