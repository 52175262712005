/**
 *
 * CustomCheckableTag
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NVTag } from '@nv/rc/Components'

import { colors } from '@app/themes'

const StyledCustomCheckableTag = styled(NVTag.CheckableTag)`
  && {
    border-color: ${colors.silver};
    height: 36px;
    line-height: 36px;
    padding: 0 16px;
  }

  &&:hover {
    border-color: ${colors.nvPriRed};
    color: ${colors.nvPriRed};
    cursor: pointer;
  }

  &&&:active {
    background-color: ${colors.salmonCream};
  }

  &&.ant-tag-checkable-checked {
    border-color: ${colors.nvPriRed};
    color: ${colors.nvPriRed};
    background-color: ${colors.salmonLight};
  }
`

const CustomCheckableTag = props => <StyledCustomCheckableTag {...props} />

CustomCheckableTag.propTypes = {
  children: PropTypes.node
}

CustomCheckableTag.defaultProps = {
  children: 'CustomCheckableTag'
}

export { CustomCheckableTag }
