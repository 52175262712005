import styled from 'styled-components'
import { Pagination as P } from 'antd'

const Pagination = styled(P)`
  & .ant-pagination-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .anticon {
    vertical-align: 0.125em;
  }
`

export { Pagination }
