import React from 'react'
import PropTypes from 'prop-types'
import { Pagination as P } from './styles'
import { commons } from '@app/constants'

const { PAGINATION_PAGE_SIZE } = commons

const Pagination = props => <P {...props} />

Pagination.defaultProps = {
  defaultPageSize: PAGINATION_PAGE_SIZE,
  onChange: () => {},
  total: 0,
  current: 1
}

Pagination.propTypes = {
  defaultPageSize: PropTypes.number,
  onChange: PropTypes.func,
  total: PropTypes.number,
  current: PropTypes.number
}

export { Pagination }
