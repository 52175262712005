// @flow
import React, { PureComponent } from 'react'

import { Props } from '@app/models/LocationFormItem.model'
import Config from 'configs'
import { Select } from 'antd'
import LocationsProvider from '@app/containers/LocationsProvider'
import { FormItem } from '@app/components/FormItem'
import { InputItem } from '@app/components/InputItem'
import { SelectItem } from '@app/components/SelectItem'
import { Divider } from '@app/components/Divider'
import { splitPopularAndOtherLists } from '@app/utils/locationsUtils'
import { COUNTRIES, POST_FORM_FIELDS } from '@app/utils/constants'

import { injectIntl } from 'react-intl'
import _ from 'lodash'
import {FEATUREHUB_FEATURE_KEYS, FeatureHubContext, isFeaturehubFeatureEnabled } from '@app/utils/featurehubUtils'

const Option = Select.Option

class LocationFormItem extends PureComponent<Props> {
  createDropDown = (key, initialValue, list, onSelect, options = {}) => {
    const { width, translate, label, required, disabled } = options
    const { intl, form } = this.props
    const { getFieldDecorator } = form
    const defaultValue = intl.formatMessage({
      id: _.snakeCase(label || `select ${key}`)
    })
    if (!list || !list.length) return null

    return (
      <FormItem label={defaultValue} width={width || 300} key={key} required>
        {getFieldDecorator(key, {
          initialValue,
          rules: [
            {
              required,
              message: intl.formatMessage({ id: 'please_select_an_item' })
            }
          ]
        })(
          <SelectItem
            disabled={disabled}
            placeholder={defaultValue}
            showSearch
            filterOption={this.handleFilterDropDown}
            onSelect={onSelect}
            required
          >
            {list.map((item, index) => {
              if (!item) {
                return (
                  <Option key={index} disabled>
                    <Divider />
                  </Option>
                )
              }
              return (
                <Option key={index} value={item}>
                  {translate ? intl.formatMessage({ id: _.snakeCase(item) }) : item}
                </Option>
              )
            })}
          </SelectItem>
        )}
      </FormItem>
    )
  }

  handleFilterDropDown = (inputValue, option) => {
    if (!_.get(inputValue, 'toLowerCase')) {
      return false
    }
    if (!_.get(option, 'props.children.toLowerCase')) {
      return false
    }
    return option.props.children.toLowerCase().includes(inputValue.toLowerCase())
  }

  // Renders disabled locations dropdown menu with given value
  renderLocationInput = (key, location, value) => {
    const { form, intl } = this.props
    const { getFieldDecorator } = form
    if (value) {
      return (
        <FormItem width={300} label={intl.formatMessage({ id: location })}>
          {getFieldDecorator(key, {
            initialValue: value
          })(<InputItem placeholder={intl.formatMessage({ id: location })} disabled />)}
        </FormItem>
      )
    }
  }

  handleSelectLocation = (value, key, getLatLng, getLocations) => {
    const { parcel, formFields, onSelectLocation, form } = this.props

    // Keys: location.state/location.city/location.district
    const updatedParcel = Object.assign({}, parcel, _.set(parcel, key, value))

    switch (key) {
      case formFields.l1Label:
        // Reset City and District for both redux store and form
        _.set(updatedParcel, formFields.l2Label, undefined)
        _.set(updatedParcel, formFields.l3Label, undefined)
        form.setFieldsValue({ [POST_FORM_FIELDS.l2Label]: undefined, [POST_FORM_FIELDS.l3Label]: undefined })

        // Set L1 address and reset L2/L3 addresses (For Quick Address)
        _.set(updatedParcel, formFields.l1AddressDisplayValue, value)
        _.set(updatedParcel, formFields.l2AddressDisplayValue, null)
        _.set(updatedParcel, formFields.l3AddressDisplayValue, null)

        getLocations(updatedParcel.location.state, null)
        break
      case formFields.l2Label:
        // Reset District  for both redux store and form
        _.set(updatedParcel, formFields.l3Label, undefined)
        form.setFieldsValue({ [POST_FORM_FIELDS.l3Label]: undefined })

        // Set L2 address and reset L3 address (For Quick Address)
        _.set(updatedParcel, formFields.l2AddressDisplayValue, value)
        _.set(updatedParcel, formFields.l3AddressDisplayValue, null)

        getLocations(updatedParcel.location.state, updatedParcel.location.city)
        break

      case formFields.l3Label:
        // Set L3 address (For Quick Address)
        _.set(updatedParcel, formFields.l3AddressDisplayValue, value)
        break
    }

    onSelectLocation(updatedParcel, getLatLng)
  }

  renderLocationsDropDown = locationProps => {
    const { formFields, locationNames, shouldDisableLocationsDropdownMenu, isQuickAddressFeatureEnabled } = this.props
    const { parcel, states, getLatLng, getL2Names, getL3Names, country, getLocations } = locationProps

    const l1Val = _.get(parcel, formFields.l1Label)
    const l2Val = _.get(parcel, formFields.l2Label)
    const l3Val = _.get(parcel, formFields.l3Label)

    // Originally, the L1/L2/L3 fields were referred to as State/City/District respectively
    // As part of Quick Address build, we will display their labels according to their respective country
    const getLabel = (key, country, isQuickAddressFeatureEnabled) => {
      if (isQuickAddressFeatureEnabled) {
        switch (key) {
          case 'state':
            switch (country) {
              case COUNTRIES.ID:
              case COUNTRIES.TH:
              case COUNTRIES.VN:
                return `select_province`
              case COUNTRIES.MY:
              default:
                return `select_state`
            }

          case 'city':
            switch (country) {
              case COUNTRIES.TH:
              case COUNTRIES.VN:
                return `select_district`
              case COUNTRIES.MY:
              case COUNTRIES.ID:
              default:
                return `select_city`
            }

          case 'district':
            switch (country) {
              case COUNTRIES.ID:
              case COUNTRIES.TH:
                return `select_subdistrict`
              case COUNTRIES.VN:
                return `select_ward`
              default:
                return `select_district`
            }
        }
      } else {
        return `select_${key}`
      }
    }

    // Determine whether to render enabled/disabled dropdown menus to allow user to modify the State/City/District fields
    // 1) Creation of normal/staging POST orders: Enabled
    // 2) Editing existing POST orders for
    //    - ID/PH/MY: Enabled
    //    - TH: Disabled
    // 3) Editing staging POST orders: Disabled
    if (shouldDisableLocationsDropdownMenu) {
      if (Config.ENVIRONMENT === 'cd') {
        return [this.renderLocationInput(formFields.l2Label, locationNames[1], l2Val)]
      }
      return [
        this.renderLocationInput(formFields.l1Label, getLabel(locationNames[0], country, isQuickAddressFeatureEnabled), l1Val),
        this.renderLocationInput(formFields.l2Label, getLabel(locationNames[1], country, isQuickAddressFeatureEnabled), l2Val),
        this.renderLocationInput(formFields.l3Label, getLabel(locationNames[2], country, isQuickAddressFeatureEnabled), l3Val)
      ]
    }

    // Generate a handler function when location is selected
    const onSelect = key => value => this.handleSelectLocation(value, key, getLatLng, getLocations)

    const { popular, other } = splitPopularAndOtherLists(country, states)
    const statesWithNullAsBorder = popular.length > 0 ? [...popular, null, ...other] : other

    const l1Dropdown = this.createDropDown(
      formFields.l1Label,
      l1Val,
      statesWithNullAsBorder,
      onSelect(formFields.l1Label),
      {
        label: getLabel(locationNames[0], country, isQuickAddressFeatureEnabled),
        required: true,
        disabled: shouldDisableLocationsDropdownMenu
      }
    )
    const l2Dropdown = this.createDropDown(
      formFields.l2Label, 
      l2Val,
      getL2Names(l1Val),
      onSelect(formFields.l2Label), {
        label: getLabel(locationNames[1], country, isQuickAddressFeatureEnabled),
        required: true,
        disabled: shouldDisableLocationsDropdownMenu
      }
    )
    const l3Dropdown = this.createDropDown(
      formFields.l3Label,
      l3Val,
      getL3Names(l1Val, l2Val),
      onSelect(formFields.l3Label),
      {
        label: getLabel(locationNames[2], country, isQuickAddressFeatureEnabled),
        required: true,
        disabled: shouldDisableLocationsDropdownMenu
      }
    )
    if (Config.ENVIRONMENT === 'cd') {
      return [l2Dropdown]
    }
    return [l1Dropdown, l2Dropdown, l3Dropdown]
  }

  render () {
    const { form, parcel } = this.props
    const isQuickAddressFeatureEnabled = isFeaturehubFeatureEnabled(FEATUREHUB_FEATURE_KEYS.ENABLE_QUICK_ADDRESS, this.context)

    return (
      <LocationsProvider form={form} parcel={parcel} isQuickAddressFeatureEnabled={isQuickAddressFeatureEnabled}>
        {this.renderLocationsDropDown}
      </LocationsProvider>
    )
  }
}

LocationFormItem.defaultProps = {
  parcel: {},
  locationNames: ['province', 'city', 'district'],
  onSelectLocation: _.noop
}

const LocationFormItem_ = injectIntl(LocationFormItem)
LocationFormItem.contextType = FeatureHubContext
export { LocationFormItem_ as LocationFormItem }
