// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

/**
 * Get the response status of the given async request id. This request should be polled since
 * the processing time for the request is unknown. The result is available in the `response_message`
 * field when `status=SUCCEEDED`. If `status=FAILED`, `response_message` will contain the error details
 */
export const getAsyncRequestApi = (country: string, asyncRequestId: number): any => {
  const client = getApiClient(apiClients, country)
  const url = `1.0/async-requests/${asyncRequestId}`
  return client.get(url)
}
