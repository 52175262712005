import _ from 'lodash'
import { isValidNumber } from 'libphonenumber-js/custom'
import metadata from 'libphonenumber-js/metadata.full.json'

export const phoneNumberValidator = (rule, value, callback) => {
  const INVALID_CHARACTERS_REGEX = /^0+|\+|\s|-/
  const { country, message } = rule
  const errors = []
  if (!isValidNumber(value, _.upperCase(country), metadata) || value.match(INVALID_CHARACTERS_REGEX)) {
    errors.push(new Error(message))
  }
  callback(errors)
}
