import React from 'react'
import { LoadingIndicator } from '@app/components/LoadingIndicator'
import { Overlay } from './styles'

export class OverlayLoader extends React.Component {
  render () {
    return (
      <Overlay>
        <LoadingIndicator />
      </Overlay>
    )
  }
}
