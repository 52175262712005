import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'
import { SHIPPER_CREATE_PAGE } from '@app/utils/constants'

export const INITIAL_STATE = fromJS({
  newShipper: {},
  shipperCreateSteps: {
    steps: [SHIPPER_CREATE_PAGE.ACCOUNT_CREATE],
    stepIndex: 0
  }
})

export const { Types: shipperCreationTypes, Creators: shipperCreationCreators } = createActions(
  {
    requestCreateShipper: ['shipper', 'options'],
    successCreateShipper: ['newShipper'],
    failCreateShipper: ['newShipper'],
    requestUploadShipperAccountProof: ['shipperId', 'file', 'afterUpload'],
    successUploadShipperAccountProof: [],
    failUploadShipperAccountProof: [],
    requestUpdateShipper: ['shipperId', 'updatedFields', 'callback'],
    successUpdateShipper: [],
    failUpdateShipper: [],
    goNextStep: [],
    reset: [],
    addSteps: ['steps']
  },
  {
    prefix: 'ShipperCreate/'
  }
)

export default createReducer(INITIAL_STATE, {
  [shipperCreationTypes.SUCCESS_CREATE_SHIPPER]: (state, action) => {
    return state.set('newShipper', fromJS(action.newShipper))
  },
  [shipperCreationTypes.ADD_STEPS]: (state, action) => {
    return state.setIn(['shipperCreateSteps', 'steps'], action.steps)
  },
  [shipperCreationTypes.GO_NEXT_STEP]: (state, action) => {
    const currentStep = state.getIn(['shipperCreateSteps', 'stepIndex'])
    return state.setIn(['shipperCreateSteps', 'stepIndex'], currentStep + 1)
  },
  [shipperCreationTypes.RESET]: () => INITIAL_STATE
})
