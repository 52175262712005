import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { display } from '@app/themes'

const StyledColumn = styled.div`
  && {
    display: flex;
    flex-direction: column;
    ${props => props.equal && `&& { align-items: stretch; > * { flex: 0 1 100%; } }`};
    ${props => props.flexGrow && `flex-grow: ${props.flexGrow};`};
    ${props => props.flexShrink && `flex-shrink: ${props.flexShrink};`};
    ${props => props.flexBasis && `flex-basis: ${props.flexBasis};`};
    ${props => props.minHeight && `min-height: ${props.minHeight};`};
    ${props => props.verticalAlign && `vertical-align: ${props.verticalAlign};`};
    ${props => props.full && `height: 100%;`};
    ${props =>
    props.end &&
      `
      align-items: flex-end;
      align-self: flex-end;
    `};
    ${props =>
    props.start &&
      `
      align-items: flex-start;
      align-self: flex-start;
    `};
    ${props =>
    props.center &&
      `
      align-self: center;
    `};
    ${props => display.childMargins('row', props.childMargin)};
    ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}px;`};
    ${props => props.marginRight && `margin-right: ${props.marginRight}px;`};
    ${props =>
    props.horizontalMargin &&
      `
      margin-left: ${props.horizontalMargin}px;
      margin-right: ${props.horizontalMargin}px;
    `};
  }
  ${props => props.alignItems && `&& { align-items: ${props.alignItems}; }`};
  ${props => props.justifyContent && `&& { justify-content: ${props.justifyContent}; }`};
  ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}px`};
`

const Column = props => <StyledColumn {...props} />

Column.propTypes = {
  children: PropTypes.node,
  childMargin: PropTypes.number
}

Column.defaultProps = {
  children: 'Column',
  childMargin: 10
}

export { Column }
