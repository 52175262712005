/**
 *
 * SubText
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { colors, fonts } from '@app/themes'
import { StyledSubText } from './styles'

const SubText = props => {
  const { children, ...restProps } = props
  return <StyledSubText {...restProps}>{children}</StyledSubText>
}

SubText.propTypes = {
  children: PropTypes.node,
  fontWeight: PropTypes.oneOf(['normal', 'thin', 'bold']),
  fontSize: PropTypes.oneOf(Object.values(fonts.size)),
  color: PropTypes.oneOf(Object.values(colors)),
  backgroundColor: PropTypes.oneOf(Object.values(colors)),
  padding: PropTypes.number,
  responsive: PropTypes.bool
}

SubText.defaultProps = {
  children: 'SubText'
}

export { SubText }
