// @flow

import _ from 'lodash'
import type { PricingInfoResponse } from '@app/types/PricingInfoResponse'

function calculateDeliveryFee (pricingInfoResponse: PricingInfoResponse): number {
  const deliveryFee: number = getDeliveryFeeAmount(pricingInfoResponse)
  const deliveryTax: number = getDeliveryFeeTaxAmount(pricingInfoResponse)
  return deliveryFee + deliveryTax
}

function calculateInsurance (pricingInfoResponse: PricingInfoResponse) {
  const insuranceFee: number = getInsuranceFeeAmount(pricingInfoResponse)
  const insuranceTax: number = getInsuranceFeeTaxAmount(pricingInfoResponse)
  return insuranceFee + insuranceTax
}

function calculateTotal (pricingInfoResponse: PricingInfoResponse): number {
  const totalWithTax: number = getTotalWithTax(pricingInfoResponse)

  const codFee: number = getCodFeeAmount(pricingInfoResponse)
  const codTax: number = getCodFeeTaxAmount(pricingInfoResponse)
  const codFeePlusTax: number = codFee + codTax

  return totalWithTax - codFeePlusTax
}

function getCodFeeAmount (pricingInfoResponse: PricingInfoResponse): number {
  if (!_.get(pricingInfoResponse, 'result.cod_fee.amount')) return 0
  return pricingInfoResponse.result.cod_fee.amount
}

function getCodFeeTaxAmount (pricingInfoResponse: PricingInfoResponse): number {
  if (!_.get(pricingInfoResponse, 'result.cod_fee.tax_amount')) return 0
  return pricingInfoResponse.result.cod_fee.tax_amount
}

function getDeliveryFeeAmount (pricingInfoResponse: PricingInfoResponse): number {
  if (!_.get(pricingInfoResponse, 'result.delivery_fee.amount')) return 0
  return pricingInfoResponse.result.delivery_fee.amount
}

function getDeliveryFeeTaxAmount (pricingInfoResponse: PricingInfoResponse): number {
  if (!_.get(pricingInfoResponse, 'result.delivery_fee.tax_amount')) return 0
  return pricingInfoResponse.result.delivery_fee.tax_amount
}

function getInsuranceFeeAmount (pricingInfoResponse: PricingInfoResponse): number {
  if (!_.get(pricingInfoResponse, 'result.insurance_fee.amount')) return 0
  return pricingInfoResponse.result.insurance_fee.amount
}

function getInsuranceFeeTaxAmount (pricingInfoResponse: PricingInfoResponse): number {
  if (!_.get(pricingInfoResponse, 'result.insurance_fee.tax_amount')) return 0
  return pricingInfoResponse.result.insurance_fee.tax_amount
}

function getTotalWithTax (pricingInfoResponse: PricingInfoResponse): number {
  if (!_.get(pricingInfoResponse, 'result.total_with_tax')) return 0
  return pricingInfoResponse.result.total_with_tax
}

export default {
  calculateDeliveryFee,
  calculateInsurance,
  calculateTotal,
  getCodFeeAmount,
  getCodFeeTaxAmount,
  getDeliveryFeeAmount,
  getDeliveryFeeTaxAmount,
  getInsuranceFeeAmount,
  getInsuranceFeeTaxAmount,
  getTotalWithTax
}
