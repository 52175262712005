// @flow

import React, { Fragment } from 'react'
import { injectIntl } from 'react-intl'
import _ from 'lodash'
import { compose } from 'redux'
import onClickOutside from 'react-onclickoutside'
import { faCamera } from '@fa-pro-solid/faCamera'
import { faCheck } from '@fa-pro-solid/faCheck'
import { faTimes } from '@fa-pro-solid/faTimes'
import { faBolt } from '@fa-pro-solid/faBolt'
import { faBolt as faBoltLight } from '@fa-pro-light/faBolt'
import { OtpInput, injectSize } from '@nv/rc/Components'

import { CombinedScanner } from 'components/CombinedScanner'
import { CustomButton } from '@app/components/CustomButton'
import { Icon } from 'components/Icon'
import { Text } from 'components/Text'

import { colors } from 'themes'
import { INPUT_TYPES, NOTIFICATIONS } from 'utils/constants'
import { showNotification } from 'utils/notify'
import { hasTorch } from 'utils/mediaUtils'
import { injectCountry } from '@app/components/CountryProvider'
import { Spinner } from '@app/components/Spinner'
import { CAMERA_TIMEOUT_IN_MS } from './constants'
import {
  Caption,
  ComponentWrapper,
  FlashIcon,
  FormItem,
  IconContainer,
  InputWrapper,
  PlaceholderBorder,
  ScannerInputHeader,
  ScannerLine,
  ScannerOverlay,
  ScannerPlaceholder,
  SquareWrapper,
  StatusMessage,
  StatusOverlay,
  StyledNVInput,
  Wrapper
} from './styles'

import type { Props, State } from './index.model'

class ScannerComponent extends React.Component<Props, State> {
  state = {
    text: '',
    hasTorch: hasTorch(),
    torch: false,
    showScanner: !this.props.isMobile || this.props.show
  }
  cameraTimer = null

  // Lifecycle
  componentDidMount () {
    this.state.showScanner && this.setCameraOffTimer()
  }
  componentWillUnmount () {
    clearTimeout(this.cameraTimer)
  }

  // SCANNER
  enableScanner = () => {
    const { handleEnableScanner } = this.props
    _.isFunction(handleEnableScanner) && handleEnableScanner()
    this.setCameraOffTimer()
    this.setState({ showScanner: true })
  }
  disableScanner = () => {
    clearTimeout(this.cameraTimer)
    this.setState({ showScanner: false })
  }
  setCameraOffTimer = () => {
    this.cameraTimer && clearTimeout(this.cameraTimer)
    this.cameraTimer = setTimeout(() => {
      const { intl } = this.props
      this.disableScanner()
      showNotification(intl.formatMessage({ id: 'camera_shut_due_to_inactivity' }), NOTIFICATIONS.INFO)
    }, CAMERA_TIMEOUT_IN_MS)
  }
  toggleTorch = () => {
    const torch = !this.state.torch
    this.setState({ torch })
  }
  handleClickOutside = () => this.disableScanner()
  handleScan = (data: any) => {
    const { handleScan } = this.props
    _.isFunction(handleScan) && handleScan(data)
    this.setCameraOffTimer()
  }
  handleError = (err: { message: string }) => {
    const { handleError } = this.props
    _.isFunction(handleError) && handleError(err)
    this.setCameraOffTimer()
  }
  renderStatusOverlay (type: string, message: string) {
    const icons = {
      success: faCheck,
      error: faTimes
    }
    const background = {
      success: colors.emeraldLight,
      error: colors.salmonLight
    }
    const border = {
      success: colors.emerald,
      error: colors.salmon
    }
    const icon = _.get(icons, type)
    const backgroundColor = _.get(background, type)
    const borderColor = _.get(border, type)
    return (
      <StatusOverlay color={borderColor}>
        {message && (
          <StatusMessage background={backgroundColor} border={borderColor}>
            <Text textId={message} />
          </StatusMessage>
        )}
        <IconContainer>
          <Icon icon={icon} background={borderColor} color={colors.white} size={24} />
        </IconContainer>
      </StatusOverlay>
    )
  }
  renderOverlay () {
    const { scanStatus, loading, caption } = this.props
    const type = _.get(scanStatus, 'type')
    if (loading) {
      return (
        <Fragment>
          <IconContainer>
            <Spinner size={36} />
          </IconContainer>
          <Caption textId={caption || 'processing_please_wait'} id={caption || 'processing_please_wait'} />
          <ScannerOverlay />
        </Fragment>
      )
    }
    switch (type) {
      case 'success':
      case 'error':
        return this.renderStatusOverlay(type, _.get(scanStatus, 'message'))
      default:
        return (
          <Fragment>
            <ScannerLine />
            <Caption
              id={caption || 'place_barcode_on_the_red_line'}
              textId={caption || 'place_barcode_on_the_red_line'}
            />
            <ScannerOverlay />
          </Fragment>
        )
    }
  }

  renderScanner () {
    const { compact, disabledCaption, enableScanner } = this.props
    const { torch, hasTorch, showScanner } = this.state
    if (showScanner && enableScanner) {
      return (
        <div style={{ display: 'flex', flex: 1, border: '1px solid gray', borderRadius: '8px' }}>
          {hasTorch && <FlashIcon size={16} icon={torch ? faBolt : faBoltLight} onClick={this.toggleTorch} />}
          {this.renderOverlay()}
          <CombinedScanner onError={this.handleError} onDetected={this.handleScan} />
        </div>
      )
    } else {
      return (
        <ScannerPlaceholder compact={compact} onClick={this.enableScanner}>
          <PlaceholderBorder>
            <Icon background={colors.white} color={colors.menu} icon={faCamera} size={24} />
            <Text textId={disabledCaption || 'click_here_to_scan_code'} />
          </PlaceholderBorder>
        </ScannerPlaceholder>
      )
    }
  }

  // INPUT
  handleTextInputChange = (e: any) => this.setState({ text: e.target.value })
  handleInputSubmit = (data: any) => {
    const { handleScan, handleInputSubmit } = this.props
    const { text } = this.state
    const inputData = text || data

    if (_.isFunction(handleInputSubmit)) {
      handleInputSubmit(inputData)
    } else if (_.isFunction(handleScan)) {
      handleScan(inputData)
    }
    text && this.setState({ text: '' })
  }

  // This is only used for POST OC form when user click on add parcel button 
  // Allows user to proceed if stamp id is empty/not provided and optional stamp id is supported
  handleInputSubmitWithNoStampId = () => {
    const { handleScan, handleInputSubmit } = this.props
    const { text } = this.state

    if (_.isFunction(handleInputSubmit)) {
      handleInputSubmit(text)
    } else if (_.isFunction(handleScan)) {
      handleScan(text)
    }    
    text && this.setState({ text: '' })
  }

  renderInput () {
    const { inputType } = this.props
    switch (inputType) {
      case INPUT_TYPES.otp:
        return this.renderOTPInput()
      case INPUT_TYPES.text:
        return this.renderTextInput()
    }
  }
  renderOTPInput () {
    const { intl, isMobile, buttonLabel } = this.props
    const size = isMobile ? 'small' : 'default'
    return (
      <OtpInput
        size={size}
        buttonLabel={intl.formatMessage({ id: buttonLabel })}
        onContinuePressed={this.handleInputSubmit}
        onClick={this.disableScanner}
      />
    )
  }
  renderTextInput () {
    const { intl, buttonLabel, inputPlaceholder, compact, loading, inputStatus, isStampIdMandatory } = this.props
    const { text } = this.state
    const message = _.get(inputStatus, 'message')
    const status = _.get(inputStatus, 'type')
    return (
      <InputWrapper compact={compact}>
        <FormItem hasFeedback validateStatus={status} help={message}>
          <StyledNVInput
            size={compact ? 'default' : 'large'}
            value={text}
            placeholder={inputPlaceholder && intl.formatMessage({ id: inputPlaceholder })}
            onChange={this.handleTextInputChange}
            onPressEnter={this.handleInputSubmit}
            onFocus={this.disableScanner}
            data-testId='scanner-input'
          />
        </FormItem>
        {isStampIdMandatory ?
          <CustomButton
          textId={buttonLabel}
          dataTestId={`${buttonLabel}-order-id`}
          loading={loading}
          disabled={!text}
          type='secondary'
          onClick={this.handleInputSubmit}
          /> : 
          <CustomButton
            textId={buttonLabel}
            dataTestId={`${buttonLabel}-order-id`}
            loading={loading}
            type='secondary'
            onClick={this.handleInputSubmitWithNoStampId}
          />
        } 
      </InputWrapper>
    )
  }

  render () {
    const { width, compact, scannerHeader, inputHeader, isDesktop, noMinHeight } = this.props
    const ScannerComponent = noMinHeight ? 'div' : ComponentWrapper
    return (
      <Wrapper {...this.props}>
        <ComponentWrapper>
          {scannerHeader && <Text type='title' textId={scannerHeader} />}
          <SquareWrapper noMinHeight={noMinHeight} rounded width={width}>
            {this.renderScanner()}
          </SquareWrapper>
        </ComponentWrapper>
        {!compact && <Text type='title' textTransform='uppercase' color={colors.nvPriRed} textId='or' />}
        <ScannerComponent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {inputHeader && (
            <ScannerInputHeader>
              <Text
                type={compact ? 'secondary' : 'title'}
                size={isDesktop ? 'medium' : 'subHeading'}
                textId={inputHeader}
              />
            </ScannerInputHeader>
          )}
          <SquareWrapper noMinHeigh={noMinHeight} width={width} compact={compact} bordered={!compact}>
            {this.renderInput()}
          </SquareWrapper>
        </ScannerComponent>
      </Wrapper>
    )
  }
}

ScannerComponent.defaultProps = {
  enableScanner: true
}

const Scanner = compose(injectSize, injectIntl, injectCountry, onClickOutside)(ScannerComponent)

export { ScannerComponent, Scanner }
