// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

import type { LoginInfoResponse } from '@app/types/LoginInfo'

export const getLoginInfoApi = (country: string): Promise<LoginInfoResponse> => {
  const client = getApiClient(apiClients, country)
  const url = `1.0/dps/logged-in`
  return client.get(url)
}
