import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'
import { set, setLoading } from '@app/utils/reducerUtils'
import { commons } from '@app/constants'

const { PAGINATION_PAGE_SIZE } = commons

export const { Types: paymentOverviewTypes, Creators: paymentOverviewCreators } = createActions(
  {
    updatePaymentOverviewFields: ['fields'],
    requestGetPaymentOverview: ['fields'],
    successGetPaymentOverview: ['data', 'statistics', 'filters'],
    failGetPaymentOverview: ['paymentOverview'],
    requestGetPaymentReport: ['fields'],
    failGetPaymentReport: ['paymentReport'],
    successGetPaymentReport: ['paymentReport']
  },
  { prefix: 'PaymentOverview/' }
)

export const INITIAL_STATE = fromJS({
  fields: {},
  paymentOverview: {},
  loading: {},
  paymentReport: {}
})

export const paymentOverviewReducer = createReducer(INITIAL_STATE, {
  [paymentOverviewTypes.UPDATE_PAYMENT_OVERVIEW_FIELDS]: (state, action) => set(state, 'fields', action.fields),
  [paymentOverviewTypes.REQUEST_GET_PAYMENT_OVERVIEW]: state => setLoading(state, 'getPaymentOverview', true),
  [paymentOverviewTypes.SUCCESS_GET_PAYMENT_OVERVIEW]: (state, action) => {
    return state
      .setIn(['data'], fromJS(action.data))
      .setIn(['statistics'], fromJS(action.statistics))
      .setIn(['filters'], fromJS(action.filters))
      .setIn(['totalPages'], fromJS(Math.ceil(action.statistics.totalOrderCount / PAGINATION_PAGE_SIZE)))
      .setIn(['loading', 'getPaymentOverview'], false)
  },
  [paymentOverviewTypes.FAIL_GET_PAYMENT_OVERVIEW]: (state, action) => {
    return state
      .setIn(['paymentOverview'], fromJS(action.paymentOverview))
      .setIn(['loading', 'getPaymentOverview'], false)
  },
  [paymentOverviewTypes.SUCCESS_GET_PAYMENT_REPORT]: (state, action) => {
    setLoading(state, 'paymentReport', false)
    return set(state, 'paymentReport', action.paymentReport)
  },
  [paymentOverviewTypes.FAIL_GET_PAYMENT_REPORT]: (state, action) => {
    setLoading(state, 'paymentReport', false)
    return set(state, 'paymentReport', action.paymentReport)
  }
})
