/**
 *
 * LabeledText
 *
 */

import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import { Text } from '@app/components/Text'
const LabeledText = props => {
  const { dynamicFontDiff, textStyle, size, labelSize, label, labelId, textId, text, ...rest } = props
  return (
    <div {...rest}>
      <Text type='label' dynamicFontDiff={dynamicFontDiff} size={labelSize} textId={labelId}>
        {label}
      </Text>
      <Text textStyle={textStyle} size={size} textId={textId} data-testid={_.kebabCase(labelId)}>
        {text}
      </Text>
    </div>
  )
}

LabeledText.defaultProps = {
  size: 'subHeading',
  labelSize: 'small'
}
LabeledText.propTypes = {
  dynamicFontDiff: PropTypes.number
}
export { LabeledText }
