import React from 'react'
import { Icon, Tooltip } from 'antd'
import { injectIntl } from 'react-intl'

import { CustomLegend, CustomLegendGroupContainer, CustomLegendLabel } from './styles'

const CustomLegendGroup = ({ intl, levels }) => (
  <CustomLegendGroupContainer>
    {levels.map(level => (
      <Tooltip placement='left' title={intl.formatMessage({ id: level.definition })}>
        <CustomLegend>
          <Icon type='info-circle' theme='filled' style={{ color: level.color }} />
          <CustomLegendLabel>{level.label}</CustomLegendLabel>
        </CustomLegend>
      </Tooltip>
    ))}
  </CustomLegendGroupContainer>
)

const CustomLegendGroup_ = injectIntl(CustomLegendGroup)
export { CustomLegendGroup_ as CustomLegendGroup }
