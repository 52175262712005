export const OC_SAMPLES = {
  lodgeIn: {
    csv: 'csv/lodge_in_sample.csv',
    excel: 'excel/lodge_in_sample.xlsx'
  },
  post: {
    csv: 'csv/post_sample.csv',
    excel: 'excel/post_sample.xlsx'
  },
  postWithExternalShipperId: {
    //As part of DP-8009 we are allowing the post OC with external shipper id as well.
    csv: 'csv/post_sample_v2.csv',
    excel: 'excel/post_sample_v2.xlsx'
  },
  postWithAddressLevels: {
    //As part of DP-7888 allowing post OC with address levels for TH only. 
    th: {
      csv: 'csv/post_sample_v1_th.csv',
      excel: 'excel/post_sample_v1_th.xlsx'
    }
  }
}

export const FILE_TYPES = ['CSV', 'EXCEL', 'MS_EXCEL']
export const FILE_SAMPLES = [
  {
    title: 'download_lodge_in_csv_template',
    link: getStorageLink(OC_SAMPLES.lodgeIn.csv)
  },
  {
    title: 'download_lodge_in_excel_template',
    link: getStorageLink(OC_SAMPLES.lodgeIn.excel)
  },
  {
    title: 'download_post_csv_template',
    link: getStorageLink(OC_SAMPLES.post.csv)
  },
  {
    title: 'download_post_excel_template',
    link: getStorageLink(OC_SAMPLES.post.excel)
  }
]

export const LODGE_IN_STATUS_REQUEST = {
  PROCESSING: 'PROCESSING',
  PROCESSED: 'PROCESSED'
}

export const POLL_DELAY_MS = 1000

export const RETRY_DELAY_MS = 2000

export const MAX_POLL_COUNT = 5

export const MAX_FAILURE_RETRY = 9

export function getStorageLink (filePath, config = __CONFIG__) {
  return `${config.STORAGE_URL}/${filePath}`
}