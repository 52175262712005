/**
 *
 * ContentWrapper
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { colors, display, media } from '@app/themes'
import NVBackgroundImage from "@app/images/homepage-background.svg"
import NVDropOffAndReturnBackgroundImage from "@app/images/drop-off-and-return-background.svg"

import { Text } from '@app/components/Text'
import commons from '@app/constants/commons'

const StyledContentWrapper = styled.div`
  && {
    position: relative;
    ${display.flex('stretch', 'flex-start', 'column')};
    ${display.childMargins('row', 8)};
    background: ${props => props.background || colors.white};
    ${props => props.showDefaultBackgroundImage && `background: url(${NVBackgroundImage}) no-repeat bottom fixed`};
    ${props => props.showDropOffAndReturnBackgroundImage && `background: url(${NVDropOffAndReturnBackgroundImage}) no-repeat bottom fixed`};
    ${props => props.backgroundImage && `background: url(${ props.backgroundImage}) no-repeat top fixed; mix-blend-mode: multiply;`};
    background-position-x: 0px;
    flex: 1;
    width: 100%;
    overflow: auto;
    margin-bottom: ${props => props.footer}px;
    ${media.min.tablet`
      ${props => props.padded && `padding: 20px`};
      ${props => !props.backgroundImage && `background-size: contain`};
    `}
    ${media.max.tablet`
      ${props => props.padded && `padding: 8px 16px`};
      ${props => !props.backgroundImage && `background-size: contain`};
    `};
    ${media.min.desktop`
      background-position-x: ${commons.SIDEBAR_WIDTH}px;
      background-size: auto;
    `};
    ${media.min.desktop`
      background-position-x: ${props => props.isCollapsedMenu ? `${commons.MIN_SIDEBAR_WIDTH}px` : `center`};
      ${props => props.backgroundImage && `background-size: contain`};
      ${props => props.padded && `padding: 20px 20px 0px`};
    `};
    ${props => props.isUsingAkiraDesign && `font-family: Noto Sans; color: #4c4c4c;`};
  }
`

const PageWrapper = styled.div`
  flex: 1;
  ${display.flex('stretch', 'flex-start', 'column')};
  ${props => !props.noMinHeight && `min-height: 100%`};
  margin: 0 auto;
  width: ${props => props.width}px;
  max-width: 100%;
  ${props => props.showDefaultBackgroundImage && !props.padded && `min-height: 100%`};
`
const HeaderWrapper = styled.div`
  && {
    ${display.flex('stretch', 'space-between')};
    ${display.childMargins('column')};
    padding: 10px 20px;
    background: ${colors.white};
    .ant-input-affix-wrapper,
    .ant-input {
      flex: 1;
    }
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.12), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  }
`

const ContentWrapper = props => {
  const { width, footer, background, header, title, children, padded, noMinHeight, showDefaultBackgroundImage, showDropOffAndReturnBackgroundImage, backgroundImage, isUsingAkiraDesign, isCollapsedMenu } = props
  return (
    <PageWrapper noMinHeight={noMinHeight} width={width}  showDefaultBackgroundImage={showDefaultBackgroundImage} padded={padded}>
      {header && <HeaderWrapper>{header}</HeaderWrapper>}
      <StyledContentWrapper padded={padded} footer={footer} background={background} showDefaultBackgroundImage={showDefaultBackgroundImage} showDropOffAndReturnBackgroundImage={showDropOffAndReturnBackgroundImage}  backgroundImage={backgroundImage}  isUsingAkiraDesign={isUsingAkiraDesign} isCollapsedMenu={isCollapsedMenu}>
        {title && <Text data-testid='pageTitle' type='title' textId={title} />}
        {children}
      </StyledContentWrapper>
    </PageWrapper>
  )
}

ContentWrapper.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
  noMinHeight: PropTypes.bool,
  isUsingAkiraDesign: PropTypes.bool
}

ContentWrapper.defaultProps = {
  padded: true,
  isUsingAkiraDesign: false
}

export { ContentWrapper }
