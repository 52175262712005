// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

import type { Response } from '@app/types/Response'
import type { ReceiptIdData } from '@app/types/ReceiptIdData'

export default (country: string): Promise<Response<ReceiptIdData>> => {
  const client = getApiClient(apiClients, country)
  const url = `2.0/receipts`
  return client.post(url)
}
