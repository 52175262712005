// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

import type { OrderCollectFailureResponse, OrderCollectSuccessResponse } from '@app/types/OrderCollectResponse'

export default (country: string): OrderCollectSuccessResponse | OrderCollectFailureResponse => {
  const client = getApiClient(apiClients, country)
  const url = `1.0/orders/collect`
  return client.get(url)
}
