// keep until this is merged (https://github.com/ant-design/ant-design/pull/12888)
// export only what we need

// fills
export { default as CaretDownFill } from '@ant-design/icons/lib/fill/CaretDownFill'
export { default as CaretUpFill } from '@ant-design/icons/lib/fill/CaretUpFill'
export { default as CheckCircleFill } from '@ant-design/icons/lib/fill/CheckCircleFill'
export { default as CloseCircleFill } from '@ant-design/icons/lib/fill/CloseCircleFill'
export { default as ExclamationCircleFill } from '@ant-design/icons/lib/fill/ExclamationCircleFill'
export { default as InfoCircleFill } from '@ant-design/icons/lib/fill/InfoCircleFill'
export { default as StarFill } from '@ant-design/icons/lib/fill/StarFill'
export { default as FilterFill } from '@ant-design/icons/lib/fill/FilterFill'

// outlines
export { default as BarsOutline } from '@ant-design/icons/lib/outline/BarsOutline'
export { default as CalendarOutline } from '@ant-design/icons/lib/outline/CalendarOutline'
export { default as CaretDownOutline } from '@ant-design/icons/lib/outline/CaretDownOutline'
export { default as CaretUpOutline } from '@ant-design/icons/lib/outline/CaretUpOutline'
export { default as CheckCircleOutline } from '@ant-design/icons/lib/outline/CheckCircleOutline'
export { default as CheckOutline } from '@ant-design/icons/lib/outline/CheckOutline'
export { default as CloseCircleOutline } from '@ant-design/icons/lib/outline/CloseCircleOutline'
export { default as CloseOutline } from '@ant-design/icons/lib/outline/CloseOutline'
export { default as DeleteOutline } from '@ant-design/icons/lib/outline/DeleteOutline'
export { default as DownOutline } from '@ant-design/icons/lib/outline/DownOutline'
export { default as ExclamationCircleOutline } from '@ant-design/icons/lib/outline/ExclamationCircleOutline'
export { default as EyeOutline } from '@ant-design/icons/lib/outline/EyeOutline'
export { default as FileOutline } from '@ant-design/icons/lib/outline/FileOutline'
export { default as FilterOutline } from '@ant-design/icons/lib/outline/FilterOutline'
export { default as FolderOpenOutline } from '@ant-design/icons/lib/outline/FolderOpenOutline'
export { default as FolderOutline } from '@ant-design/icons/lib/outline/FolderOutline'
export { default as InfoCircleOutline } from '@ant-design/icons/lib/outline/InfoCircleOutline'
export { default as LeftOutline } from '@ant-design/icons/lib/outline/LeftOutline'
export { default as LoadingOutline } from '@ant-design/icons/lib/outline/LoadingOutline'
export { default as LockOutline } from '@ant-design/icons/lib/outline/LockOutline'
export { default as PaperClipOutline } from '@ant-design/icons/lib/outline/PaperClipOutline'
export { default as PictureOutline } from '@ant-design/icons/lib/outline/PictureOutline'
export { default as PlusOutline } from '@ant-design/icons/lib/outline/PlusOutline'
export { default as RedoOutline } from '@ant-design/icons/lib/outline/RedoOutline'
export { default as RightOutline } from '@ant-design/icons/lib/outline/RightOutline'
export { default as SearchOutline } from '@ant-design/icons/lib/outline/SearchOutline'
export { default as UpOutline } from '@ant-design/icons/lib/outline/UpOutline'
export { default as UserOutline } from '@ant-design/icons/lib/outline/UserOutline'
