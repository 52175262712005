import { colors, display, spacing } from '@app/themes'
import styled from 'styled-components'

const Divider = styled.hr`
  border: 1px solid ${colors.paleGrey};
  margin: ${spacing.medium}px -${spacing.medium}px;
`
const ManualPackWrapper = styled.div`
  && {
    ${display.childMargins('row')};
  }
`

export { Divider, ManualPackWrapper }
