// @flow

import _ from 'lodash'
import { NvApiConfig } from '@nv/rc/Configs'
import { COUNTRIES } from '@nv/rc/Constants'
import { NvApi } from '@nv/rc/Services'

import { generateRequestId, getCountries } from './commonUtils'

const DEFAULT_COUNTRY = COUNTRIES.SG
const TIMEOUT_IN_MS = 75000

export function generateApiClients (service: string) {
  const apiClients = {
    global: createApiClientWithInterceptor({ service, timeout: TIMEOUT_IN_MS })
  }

  const apiClientsNoTransform = {
    global: createApiClientWithInterceptor({ service, timeout: TIMEOUT_IN_MS, transform: false })
  }

  getCountries().forEach(country => {
    apiClients[country] = createApiClientWithInterceptor({ country, service, timeout: TIMEOUT_IN_MS })
    apiClientsNoTransform[country] = createApiClientWithInterceptor({
      country,
      service,
      transform: false,
      timeout: TIMEOUT_IN_MS
    })
  })

  return { apiClients, apiClientsNoTransform }
}

export function getApiClient (clients: Object[], country: string): Object {
  const DEFAULT_CLIENT = _.get(clients, DEFAULT_COUNTRY)
  return _.get(clients, country, DEFAULT_CLIENT)
}

export function parseResponse (response: Object) {
  // needed because _.get function does not return default value if the value is null
  return _.get(response, 'data') || {}
}

function createApiClientWithInterceptor (config) {
  const apiClient = NvApi.create(config).api

  // intercept request
  apiClient.axiosInstance.interceptors.request.use(
    config => {
      config.headers[NvApiConfig.REQUEST_ID_HEADER] = generateRequestId()
      config.metadata = { startTime: new Date() }
      return config
    },
    error => Promise.reject(error)
  )

  // intercept response
  apiClient.axiosInstance.interceptors.response.use(
    response => response,
    error => {
      error.config.metadata.endTime = new Date()
      error.duration = error.config.metadata.endTime - error.config.metadata.startTime
      return Promise.reject(error)
    }
  )

  return apiClient
}
