import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { CustomModal } from '@app/components/CustomModal'
import { CustomButton } from '@app/components/CustomButton'
import { Text } from '@app/components/Text'
import { FormatOrderCostWithCurrencyCode } from 'utils/OrderUtils'
import { CodAmountText, ConfirmCodModalContent } from '@app/components/ConfirmCodModal/styles'
import { MODALS } from '@app/containers/Collect/constants'

export const ConfirmCodModal = props => {
  const { intl, country, showModal, closeModal, selectedRecord, handleConfirmCod } = props
  return (
    <CustomModal
      title={intl.formatMessage({ id: 'collect_cash_upon_handover' })}
      visible={showModal === MODALS.confirmCod}
      onCancel={closeModal}
    >
      <ConfirmCodModalContent>
        <Text
          textId='collect_confirm_cod_message'
          values={{ id: _.get(selectedRecord, 'trackingId', null), name: _.get(selectedRecord, 'toName', null) }}
        />
        <CodAmountText>{FormatOrderCostWithCurrencyCode(country, _.get(selectedRecord, 'cod', 0.0))}</CodAmountText>
        <CustomButton
          dataTestId='confirmCodButton'
          textId='confirm'
          size='small'
          type='primary'
          onClick={handleConfirmCod}
        />
      </ConfirmCodModalContent>
    </CustomModal>
  )
}

ConfirmCodModal.propTypes = {
  showModal: PropTypes.number,
  closeModal: PropTypes.func,
  country: PropTypes.string,
  selectedRecord: PropTypes.object,
  handleConfirmCod: PropTypes.func
}

ConfirmCodModal.defaultProps = {
  showModal: null,
  closeModal: () => {},
  country: '',
  selectedRecord: {},
  handleConfirmCod: () => {}
}

export default ConfirmCodModal
