/**
 *
 * Asynchronously loads the component for EditErrorOrder
 *
 */

import Loadable from 'react-loadable'
import React from 'react'
import LoadingIndicator from '@app/components/LoadingIndicator'

export default Loadable({
  loader: () => import('./index'),
  loading: () => <LoadingIndicator />
})
