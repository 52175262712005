/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { fromJS } from 'immutable'
import { combineReducers } from 'redux-immutable'
import { LOCATION_CHANGE } from 'react-router-redux'

import { baseReducer } from '@app/containers/Base/redux'
import languageProviderReducer from 'containers/LanguageProvider/reducer'
import { firstMileReducer } from '@app/containers/FirstMile/redux'
import { locationsReducer } from '@app/containers/LocationsProvider/redux'
import { parcelOverviewReducer } from '@app/containers/ParcelOverview/redux'
import { paymentOverviewReducer } from '@app/reducers/paymentOverview'
import { bulkUploadReducer } from '@app/reducers/BulkUpload'
import { calculatorReducer } from '@app/containers/DeliveryRateCalculator/redux'
import { shipperSearchReducer } from '@app/containers/ShipperSearch/redux'
import { scannerWithCounterReducer } from '@app/reducers/ScannerWithCounter'
import {
  collectReducer,
  driverHandoverReducer,
  orderDetailsReducer,
  parcelOverviewReducer as parcelOverviewReducerNew,
  performanceDetailsReducer,
  shipperAnalyticsReducer,
  volumeByTypeReducer
} from '@app/reducers/index'

// Initial routing state
const routeInitialState = fromJS({
  location: null
})

/**
 * Merge route into the global application state
 */
function routeReducer (state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        location: action.payload
      })
    default:
      return state
  }
}

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer (injectedReducers) {
  return combineReducers({
    route: routeReducer,
    user: baseReducer,
    language: languageProviderReducer,
    customerCollect: collectReducer,
    firstMile: firstMileReducer,
    bulkUpload: bulkUploadReducer,
    locations: locationsReducer,
    driver: driverHandoverReducer,
    parcelOverview: parcelOverviewReducer,
    paymentOverview: paymentOverviewReducer,
    orderDetails: orderDetailsReducer,
    calculator: calculatorReducer,
    shipperSearch: shipperSearchReducer,
    scannerWithCounter: scannerWithCounterReducer,
    performanceDetails: performanceDetailsReducer,
    shipperAnalytics: shipperAnalyticsReducer,
    volumeByType: volumeByTypeReducer,
    parcelOverviewNew: parcelOverviewReducerNew,
    ...injectedReducers
  })
}
