/**
 *
 * CountryProvider
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import hoistNonReactStatics from 'hoist-non-react-statics'

const InjectCountryContext = React.createContext({
  country: 'sg'
})

class CountryProvider extends React.Component {
  state = {
    country: 'sg'
  }

  static getDerivedStateFromProps (props, state) {
    const { country } = props
    if (country) {
      return {
        country
      }
    }
    return null
  }

  render () {
    return <InjectCountryContext.Provider {...this.props} value={{ ...this.state }} />
  }
}

const injectCountry = Component => {
  const Sized = props => {
    return (
      <InjectCountryContext.Consumer>
        {({ country }) => <Component {...props} country={country} />}
      </InjectCountryContext.Consumer>
    )
  }
  return hoistNonReactStatics(Sized, Component)
}

CountryProvider.propTypes = {
  children: PropTypes.node
}

CountryProvider.defaultProps = {
  children: 'CountryProvider'
}

export { CountryProvider }
export { injectCountry }
