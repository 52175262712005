// @flow

import { rest } from 'msw'
import configs from 'configs'

import type { DpJobOrdersData } from '@app/types/DpJobOrder'

export const getDpJobOrdersMswHandler = rest.get(`${configs.BASE_URL}/:id/dp/1.0/job-orders`, (req, res, ctx) => {
  const { id } = req.params
  const jobId: number = req.url.searchParams.get('job_id')
  const dpJobOrdersData: DpJobOrdersData = {
    data: [
      {
        barcode: 'NVIDNINJA000000001',
        customerUnlockCode: '123456',
        dpJobId: jobId,
        driverFlow: null,
        externalReferenceId: null,
        id: 13322356,
        isOverstayed: false,
        isSwappable: false,
        orderId: 4097132,
        status: 'PENDING',
        systemId: id
      },
      {
        barcode: 'NVIDNINJA000000002',
        customerUnlockCode: null,
        dpJobId: jobId,
        driverFlow: null,
        externalReferenceId: null,
        id: 13322356,
        isOverstayed: false,
        isSwappable: false,
        orderId: 4097132,
        status: 'PENDING',
        systemId: id
      },
      {
        barcode: 'NVIDNINJA000000003',
        customerUnlockCode: null,
        dpJobId: jobId,
        driverFlow: null,
        externalReferenceId: null,
        id: 13322356,
        isOverstayed: true,
        isSwappable: false,
        orderId: 4097132,
        status: 'PENDING',
        systemId: id
      }
    ]
  }
  return res(ctx.status(200), ctx.json(dpJobOrdersData))
})
