import styled from 'styled-components'
import { InputItem as Input } from '../InputItem'

const InputItem = styled(Input)`
  height: ${props => props.height}px;
  && {
    &.ant-input-group-addon {
      padding: 0px;
      border: 0;
    }
  }
`
export { InputItem }
