import { getDpJobOrdersMswHandler } from './handlers/getDpJobOrdersMswHandler'
import { getDpJobsMswHandler } from './handlers/getDpJobsMswHandler'
import { getFaviconMswHandler } from './handlers/getFaviconMswHandler'
import { getGoogleAnalyticsMswHandler } from './handlers/getGoogleAnalyticsMswHandler'
import { getLoginInfoApiMswHandler } from './handlers/getLoginInfoApiMswHandler'
import { getManifestMswHandler } from './handlers/getManifestMswHandler'
import { getShipperByIdApiMswHandler } from './handlers/getShipperByIdApiMswHandler'
import { postCreatePostOrderApiMswHandler } from './handlers/postCreatePostOrderApiMswHandler'
import { postLoginApiMswHandler } from './handlers/postLoginApiMswHandler'
import { postSendOrderApiMswHandler } from './handlers/postSendOrderApiMswHandler'
import { postSentryApiMswHandler } from './handlers/postSentryApiMswHandler'
import { putOrdersMswHandler } from './handlers/putOrdersMswHandler'
import { getLodgeInByStatusIdHandler } from './handlers/getLodgeInByStatusIdHandler'

export const handlers = [
  getDpJobsMswHandler,
  getDpJobOrdersMswHandler,
  getShipperByIdApiMswHandler,
  putOrdersMswHandler,
  getLoginInfoApiMswHandler,
  postLoginApiMswHandler,
  postSendOrderApiMswHandler,
  postCreatePostOrderApiMswHandler,
  getGoogleAnalyticsMswHandler,
  getFaviconMswHandler,
  getManifestMswHandler,
  postSentryApiMswHandler,
  getLodgeInByStatusIdHandler
]
