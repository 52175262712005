// @flow

import { rest } from 'msw'
import snake from 'snakecase-keys'
import configs from 'configs'
import { mockUserInfo } from '@app/constants'

import type { Login, LoginData } from '@app/types/Login'

const { MOCK_PARTNER_ID, MOCK_DP_ID, MOCK_DP_USER_ID, MOCK_DP_NAME } = mockUserInfo

export const postLoginApiMswHandler = rest.post(`${configs.BASE_URL}/global/dp/1.0/login`, (req, res, ctx) => {
  const { systemId } = req.params
  const login: Login = {
    accessToken: '30TOHu1HMV86LOIDConfzILQTo0vGgqL',
    active: true,
    admin: false,
    dpId: MOCK_DP_ID,
    dpName: MOCK_DP_NAME,
    dpPartnerId: MOCK_PARTNER_ID,
    dpUserId: MOCK_DP_USER_ID,
    expirationDate: '2030-02-16T20:30:34Z',
    systemId
  }
  const loginData: LoginData = {
    data: login
  }
  return res(ctx.status(200), ctx.json(snake(loginData)))
})
