// @flow

import React from 'react'
import { injectIntl } from 'react-intl'
import { BrowserMultiFormatReader, NotFoundException } from '@zxing/library'
import _ from 'lodash'
import { compose } from 'redux'
import { showNotification } from '@app/utils/notify'
import { NOTIFICATIONS } from '@app/utils/constants'
import { ContentWrapper, PlaceholderIcon, StyledScanHighlight, StyledVideoWrapper } from './styles'
import { trackCustomEvent } from '@app/utils/trackCustomEvent'

import type { Props, State } from './index.model'

export default class CombinedScanner extends React.Component<Props, State> {
  state = {
    codeScanner: null
  }
  videoRef = React.createRef()

  componentDidMount () {
    this.initScanner()
  }

  componentWillUnmount () {
    this.resetScanner()
  }
  /**
   * Resets the scanner and updates the component state.
   */
  resetScanner = () => {
    const { codeScanner } = this.state
    if (codeScanner) {
      codeScanner.reset()
      this.setState({ codeScanner: null })
    }
  }

  render () {
    return (
      <ContentWrapper>
        <PlaceholderIcon />
        <StyledScanHighlight />
        <StyledVideoWrapper ref={this.videoRef} id='scanner' playsInline muted autoPlay />
      </ContentWrapper>
    )
  }

  initScanner = () => {
    const { onDetected, intl } = this.props
    const codeScanner = new BrowserMultiFormatReader()
    this.setState({ codeScanner })

    return codeScanner
      .decodeFromVideoDevice(null, this.videoRef.current, (result, error) => {
        //Trigger a scanner reset when no video element is available to scan the QR code.
        if(this.videoRef.current === null) {
          this.resetScanner()
        }
        if (result) {
          const scanned = _.get(result, 'text', null)
          onDetected(scanned)
        }
        if (error && !(error instanceof NotFoundException)) {
          this.handleError(error)
        }
      })
      .catch(error => {
        // show an informative message when user denies camera permission
        if (_.toLower(error.message) === 'permission denied') {
          showNotification(intl.formatMessage({ id: 'please_allow_access_to_camera' }), NOTIFICATIONS.ERROR)
        }
      })
  }

  handleError = (error: any) => {
    const { onError, intl } = this.props
    trackCustomEvent('scannererror', { error: error.toString() })
    if (onError) {
      showNotification(intl.formatMessage({ id: 'there_was_an_error_while_turning_on_camera' }), NOTIFICATIONS.ERROR)
      onError(error)
    }
  }
}

const CC = compose(injectIntl)(CombinedScanner)
export { CC as CombinedScanner }
