import styled from 'styled-components'
import React from 'react'
import { Input } from 'antd'
const StyledInputItem = styled(Input)`
  && {
    .ant-input:focus {
      border-color: #2f4f4f;
      outline: none !important;
      -webkit-box-shadow: 0 0 0 2px rgb(211, 211, 211);
      box-shadow: 0 0 0 2px rgb(211, 211, 211);
    }
    .ant-input:hover {
      border-color: #2f4f4f;
    }
  }
`

export const InputItem = props => <StyledInputItem autocomplete='nope' {...props} />
