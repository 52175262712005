import React from 'react'
import { T } from '@nv/rc/Components'
import { CustomModal } from '@app/components/CustomModal'
import { MondalContent } from './styles'
import { injectIntl } from 'react-intl'

const ShipperSetupStatusModal = props => {
  const { intl, visibility, closeModal, onOk } = props
  return (
    <CustomModal
      visible={visibility}
      okText={intl.formatMessage({ id: 'retry' })}
      cancelText={intl.formatMessage({ id: 'cancel' })}
      okTextId={'create_prepaid_account'}
      onCancel={() => closeModal()}
      onOk={() => onOk()}
      width='350px'
    >
      <MondalContent>
        <T id='shipper_setup_error_message_1' />
        <br />
        <T id='shipper_setup_error_message_2' />
      </MondalContent>
    </CustomModal>
  )
}

const ShipperSetupStatusModal_ = injectIntl(ShipperSetupStatusModal)
export { ShipperSetupStatusModal_ as ShipperSetupStatusModal }
