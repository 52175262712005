// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

import type { PutCollectOrderRequestPayload } from '@app/types/PutCollectOrderRequest'
import type { PutCollectOrderFailureResponse, PutCollectOrderSuccessResponse } from '@app/types/PutCollectOrderResponse'

export default (
  country: string,
  trackingId: string,
  payload: PutCollectOrderRequestPayload
): Promise<PutCollectOrderSuccessResponse | PutCollectOrderFailureResponse> => {
  const client = getApiClient(apiClients, country)
  const url = `1.0/collect/${trackingId}`
  return client.put(url, payload)
}
