import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'
import { set } from 'utils/reducerUtils'

export const { Types: trackingOrdersSearchTypes, Creators: trackingOrdersSearchCreators } = createActions(
  {
    requestGetTrackingOrders: ['query'],
    successGetTrackingOrders: ['orders'],
    failGetTrackingOrders: [],
    reset: []
  },
  {
    prefix: 'TrackingOrdersSearch/'
  }
)

export const INITIAL_STATE = fromJS({
  trackingOrders: []
})

export default createReducer(INITIAL_STATE, {
  [trackingOrdersSearchTypes.REQUEST_GET_TRACKING_ORDERS]: state => {
    return state.setIn(['trackingOrders'], []).setIn(['loading', 'getTrackingOrders'], true)
  },
  [trackingOrdersSearchTypes.SUCCESS_GET_TRACKING_ORDERS]: (state, action) => {
    return state.setIn(['trackingOrders'], fromJS(action.orders)).setIn(['loading', 'getTrackingOrders'], false)
  },
  [trackingOrdersSearchTypes.FAIL_GET_TRACKING_ORDERS]: (state, action) => {
    return state.setIn(['trackingOrders'], []).setIn(['loading', 'getTrackingOrders'], false)
  },
  [trackingOrdersSearchTypes.RESET]: (state, action) => {
    return set(state, 'trackingOrders', [])
  }
})
