import { apiClientsNoTransform } from '@app/services/api/clients'
import type { ConsigneeInfoPayload } from '@app/types/ConsigneeInfoPayload'
import type { Response } from '@app/types/Response'
import type { ConsigneeInfoResponse } from '@app/types/ConsigneeInfo'

export default (country: string, payload: ConsigneeInfoPayload): Response<Array<ConsigneeInfoResponse>> => {
  const client = apiClientsNoTransform[country]
  const url = `1.0/consignees`
  return client.get(url, payload)
}
