import { css } from 'styled-components'
import { media } from '@app/themes'

export const hideForDesktop = hide => css`
  display: ${hide ? 'none !important' : 'block !important'};
  ${media.max.tablet`display: ${!hide ? 'none !important' : 'block !important'};`};
`
export function spin (duration = 1) {
  return css`
    animation: spin ${duration}s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `
}

export function wave (color1, color2) {
  return css`
    display: block;
    position: relative;
    overflow: hidden;
    background: linear-gradient(${color1} 0%, ${color1} 60%, ${color2} 60%, ${color2} 100%);
    z-index: 0;

    &:before {
      content: '';
      display: block;
      position: absolute;
      border-radius: 100%;
      width: 100%;
      height: 100%;
      background: ${color1};
      left: -35%;
      top: -25%;
      z-index: 1;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      border-radius: 100%;
      width: 100%;
      height: 100%;
      background: ${color2};
      right: -35%;
      top: 46%;
      z-index: 1;
    }
  `
}
