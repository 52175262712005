/**
 *
 * Clickable
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledClickable = styled.div`
  cursor: pointer;
  user-select: none;
`

const Clickable = props => <StyledClickable {...props} />

Clickable.propTypes = {
  children: PropTypes.node
}

Clickable.defaultProps = {
  children: 'Clickable'
}

export { Clickable }
