import moment from 'moment'
import { DATE_FORMAT } from '@app/constants/dates'

export function formatDate (date, format = DATE_FORMAT.DAY_MONTH_YEAR_DASHED) {
  if (!date) return null
  return moment(date)
    .format(format)
    .toString()
}

export function getFormattedToday (format = DATE_FORMAT.DAY_MONTH_YEAR_DASHED) {
  return moment().format(format)
}
