import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { faCheckCircle } from '@fa-pro-solid/faCheckCircle'
import { faExclamationCircle } from '@fa-pro-solid/faExclamationCircle'
import { faTimesCircle } from '@fa-pro-solid/faTimesCircle'
import { NotificationModal } from '@app/components/NotificationModal'
import { CustomButton } from '@app/components/CustomButton'
import { colors } from '@app/themes'
import { Modal } from '@nv/react-akira'
import Images from 'images'

import {
  Description,
  StyledModal,
  StyledDownloadErrorsButton,
  StyledDownloadErrorsModalContainer,
  StyledErrorAccountManagerHelpText,
  StyledErrorDescription
} from './styles'

const BulkSendStatusModal = props => {
  const { failedOrderCount, isAkiraDesignForShipperDropOffEnabled } = props
  
  if (isAkiraDesignForShipperDropOffEnabled && failedOrderCount > 0) {
    return renderDownloadErrorsModal(props)
  }

  return renderOldSuccessOrFailureModal(props)
}

const renderDownloadErrorsModal = props => {
  const { intl, closeModal, handleDownloadFailedOrders } = props
  const { title, icon, description, testId } = getBulkUploadModalProps(props)

  return (
    <StyledModal
      visible
      width={'xs'}
      zIndex={'1000'}
      header={title}
      onClose={() => closeModal(props)}
    >
      <StyledDownloadErrorsModalContainer>
        <img src={icon} />
        <StyledErrorDescription data-testid='bulk-send-error-modal-description'>
          <div data-testid={testId}>
            {description}
          </div>
        </StyledErrorDescription>
        <StyledErrorAccountManagerHelpText>
          {intl.formatMessage({ id: 'bulk_upload_lodge_in_error_account_manager_help' })}
        </StyledErrorAccountManagerHelpText>
        <StyledDownloadErrorsButton
          styleType={'primary'}
          variant={'grey'}
          data-testid='download-btn'
          size={'lg'}
          onClick={() => handleDownloadFailedOrders(props)}
        >
          {intl.formatMessage({ id: 'download_errors' })}
        </StyledDownloadErrorsButton>
      </StyledDownloadErrorsModalContainer>
    </StyledModal>
  )
}

const renderOldSuccessOrFailureModal = props => {
  const { intl, visible, successOrderCount, failedOrderCount, closeModal, handleDownloadFailedOrders } = props
  const { icon, iconColor, message } = getModalProps(props)
  return (
    <NotificationModal
      visible={visible}
      mainText={message}
      icon={icon}
      iconColor={iconColor}
      onCancel={() => closeModal(props)}
    >
      <Description data-testid='description'>
        {renderCountData(successOrderCount, 'successful-lodge-in', 'successful_lodge_in', intl)}
        {renderCountData(failedOrderCount, 'failed-lodge-in', 'failed_lodge_in', intl)}
      </Description>
      {failedOrderCount > 0 && (
        <CustomButton
          dataTestId='download-btn'
          textId='download_erroneous_orders'
          size='large'
          type='tertiary'
          onClick={() => handleDownloadFailedOrders(props)}
        />
      )}
    </NotificationModal>
  )
}

const renderCountData = (count, testId, message, intl) =>
  count > 0 ? (
    <div data-testid={testId}>
      {count} {intl.formatMessage({ id: message })}
    </div>
  ) : null

const getModalProps = props => {
  const { successOrderCount, failedOrderCount } = props
  if (successOrderCount > 0 && failedOrderCount <= 0) {
    return fetchModalAttributes(faCheckCircle, colors.Viridian, 'lodge_in_completed')
  } else if (successOrderCount > 0 && failedOrderCount > 0) {
    return fetchModalAttributes(faExclamationCircle, colors.apricot, 'lodge_in_completed')
  } else {
    return fetchModalAttributes(faTimesCircle, colors.nvPriRed, 'lodge_in_failed')
  }
}

const fetchModalAttributes = (icon, iconColor, message) => {
  return {
    icon: icon,
    iconColor: iconColor,
    message: message
  }
}

const getBulkUploadModalProps = props => {
  const { successOrderCount, failedOrderCount, intl } = props
  if (successOrderCount <= 0 && failedOrderCount > 0) {
    return {
      icon: Images.ryoBlock,
      title: intl.formatMessage({ id: 'bulk_upload_lodge_in_error_header' }),
      description: intl.formatMessage({ id: 'bulk_upload_lodge_in_error_description' }, { failedOrderCount: failedOrderCount }),
      testId: 'failed-lodge-in'
    }
  } else if (successOrderCount > 0 && failedOrderCount > 0) {
    return {
      icon: Images.ryoNotice,
      title: intl.formatMessage({ id: 'bulk_upload_lodge_in_partial_success_error_header' }, { failedOrderCount: failedOrderCount }),
      description: intl.formatMessage({ id: 'bulk_upload_lodge_in_partial_success_error_description' }, { successOrderCount: successOrderCount }),
      testId: 'successful-lodge-in'
    }
  }
}

BulkSendStatusModal.propTypes = {
  successOrderCount: PropTypes.number,
  failedOrderCount: PropTypes.number,
  visible: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  handleDownloadFailedOrders: PropTypes.func.isRequired
}

BulkSendStatusModal.defaultProps = {
  successOrderCount: 0,
  failedOrderCount: 0,
  visible: false
}

const BulkSendStatusModal_ = injectIntl(BulkSendStatusModal)
export { BulkSendStatusModal_ as BulkSendStatusModal }
