/**
 *
 * CustomHeader
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row as R } from '@app/components/Row'
import { Icon } from '@app/components/Icon'
import { Text } from '@app/components/Text'
import { spacing } from '@app/themes'
import { faAngleLeft } from '@fa-pro-regular/faAngleLeft'
import { Clickable } from '../Clickable'
import { withRouter } from 'react-router-dom'
import { injectSize } from '@nv/rc/Components'
import { compose } from 'redux'

const StyledCustomHeader = styled(R)`
  padding: 10px 20px;
  margin-bottom: 6px;
  box-shadow: 0 2px 6px 0 rgba(204, 204, 204, 0.5);
`

class CustomHeader extends React.Component {
  renderBackButton = () => {
    const { displayBack, history, onBack } = this.props
    if (displayBack) {
      return (
        <Clickable onClick={() => (onBack ? onBack() : history.goBack())}>
          <Icon icon={faAngleLeft} gap={spacing.medium} size={25} />
        </Clickable>
      )
    }
  }

  renderText = () => {
    const { textId, text, isDesktop } = this.props
    if (textId) {
      return (
        <Text
          textId={textId}
          size={isDesktop ? 'subtitle' : 'heading'}
          textStyle='bold'
          data-testid='customHeaderTitle'
        />
      )
    }
    return (
      <Text size={isDesktop ? 'subtitle' : 'heading'} textStyle='bold'>
        {text}
      </Text>
    )
  }

  render () {
    return (
      <StyledCustomHeader {...this.props}>
        {this.renderBackButton()}
        {this.renderText()}
      </StyledCustomHeader>
    )
  }
}

CustomHeader.propTypes = {
  children: PropTypes.node,
  displayBack: PropTypes.bool,
  textId: PropTypes.string,
  text: PropTypes.string
}

CustomHeader.defaultProps = {
  children: 'CustomHeader'
}

const CustomHeader_ = compose(withRouter, injectSize)(CustomHeader)
export { CustomHeader_ as CustomHeader }
