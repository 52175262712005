export default {
  xsmall: 4,
  small: 8,
  medium: 16,
  large: 24,
  xlarge: 32,
  xxlarge: 40,
  xxxlarge: 48,
  xxxxlarge: 56
}
