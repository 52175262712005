import styled from 'styled-components'
import { Text } from '@app/components/Text'
import { Table } from '@app/components/Table'
import { colors, fonts } from '@app/themes'

const StyledText = styled(Text)`
  letter-spacing: ${fonts.LETTER_SPACING};
  ${props => props.fontSize || fonts.size.small}
  color: ${colors.LabelTextColor};
  text-align: ${props => props.textAlign};
`

const CustomTable = styled(Table)`
  & .ant-table-thead {
    & > tr > th > div {
      text-transform: capitalize;
    }
  }LocationFormItem
`

const TableContentWrapper = styled.div`
  padding: 20px;
  color: ${colors.LabelTextColor};
  & > section {
    padding-top: 15px;
    & .ant-table-body > table > .ant-table-tbody > tr > td {
      color: ${colors.LabelTextColor};
      border-bottom: none;
    }
  }
`

export { StyledText, TableContentWrapper, CustomTable }
