import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'
import { customerCollectOrdersPayload } from '@app/types/customerCollectOrdersPayload'

import type { OrderCollectFailureResponse, OrderCollectSuccessResponse } from '@app/types/OrderCollectResponse'

export default (country: string, payload: customerCollectOrdersPayload): OrderCollectSuccessResponse | OrderCollectFailureResponse => {
  const client = getApiClient(apiClients, country)
  const url = `1.0/orders/customer-collect/search`
  return client.post(url, payload)
}
