// @flow

import type { PostOrder, PostOrderResponse } from '@app/types/PostOrderResponse'
import _ from 'lodash'
import configs from 'configs'
import { rest } from 'msw'

const mask = `${configs.BASE_URL}/:systemId/dp/1.2/reservations/post`

export const postCreatePostOrderApiMswHandler = rest.post(mask, (req, res, ctx) => {
  const data: Array<PostOrderResponse> = _.get(req.body, 'reservations', []).map((reservation, index) => {
    const barcode = _.get(reservation, 'stamp_id')
    const id = index + 1

    // orders that end with even number will be successful
    const success = parseInt(barcode[barcode.length - 1]) % 2 === 0

    if (success) {
      const postOrder: PostOrder = {
        reservation: {
          barcode,
          stamp_id: barcode,
          pricing_info: {
            info: {
              script_id: 1001,
              script_version: 1002,
              script_version_id: 1003
            },
            result: {
              cod_fee: {
                amount: 1004,
                tax_amount: 1005
              },
              delivery_fee: {
                amount: 1006.005,
                tax_amount: 1007.005
              },
              handling_fee: {
                amount: 1008,
                tax_amount: 1009
              },
              insurance_fee: {
                amount: 1010,
                tax_amount: 1011
              },
              total_tax: 1012.005,
              total_with_tax: 1013
            }
          }
        },
        receipt: {
          id,
          url: 'https://www.ninjavan.co/cover.png'
        }
      }
      return {
        data: postOrder,
        error: null
      }
    } else {
      return {
        data: null,
        error: {
          error_details: {
            error: {
              code: '1337',
              details: [],
              message: 'error-message',
              request_id: 'request-id',
              title: 'error-title'
            }
          },
          stamp_id: barcode
        }
      }
    }
  })
  return res(ctx.status(200), ctx.json(data))
})
