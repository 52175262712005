import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { CustomButton } from '@app/components/CustomButton'

import { Icon } from '@app/components/Icon'
import { Text } from '@app/components/Text'

import { colors } from '@app/themes'
import { InfoIcon, InfoModal, StyledCustomModal, TitleWrapper } from './styles'
import { ICONS } from './constants'

class CustomModal extends React.Component {
  handleCancel = evt => {
    const { onCancel } = this.props
    if (onCancel) {
      evt.stopPropagation()
      onCancel()
    }
  }

  renderFooter () {
    const { okText, cancelText, onOk, cancelIcon, okIcon, okTextId, cancelTextId, loading } = this.props
    if (okText || cancelText) {
      return (
        <Fragment>
          {cancelText && (
            <CustomButton
              type='tertiary'
              textId={cancelTextId}
              onClick={this.handleCancel}
              dataTestId={_.kebabCase(cancelTextId)}
            >
              {cancelIcon && <Icon icon={cancelIcon} gap={10} />}
              {cancelText}
            </CustomButton>
          )}
          {okText && (
            <CustomButton
              textId={okTextId}
              type='primary'
              onClick={onOk}
              dataTestId={_.kebabCase(okTextId)}
              disabled={loading}
            >
              {okIcon && <Icon icon={okIcon} gap={10} />}
              {okText}
            </CustomButton>
          )}
        </Fragment>
      )
    }
    return null
  }
  renderInfoModal () {
    const { title, message, ...rest } = this.props
    return (
      <InfoModal {...rest} onCancel={this.handleCancel} footer={null}>
        <TitleWrapper>
          {this.renderInfoIcon()}
          <Text textStyle='bold' size='big'>
            {title}
          </Text>
        </TitleWrapper>
        <Text color={colors.warmGrey}>{message}</Text>
      </InfoModal>
    )
  }
  renderInfoIcon () {
    const { customIconStyle, type } = this.props
    if (customIconStyle) {
      const { customIcon, customColor, background } = customIconStyle
      return <InfoIcon icon={customIcon} color={customColor} background={background} gap={0} size={16} />
    } else {
      const { icon, color } = _.get(ICONS, type, ICONS.success)
      return <Icon icon={icon} color={color} gap={16} size={36} />
    }
  }
  render () {
    const { visible, type, ...rest } = this.props
    if (!visible) return null
    switch (type) {
      case 'success':
      case 'error':
        return this.renderInfoModal()
      default:
        return (
          <StyledCustomModal {...rest} visible={visible} onCancel={this.handleCancel} footer={this.renderFooter()} />
        )
    }
  }
}

CustomModal.propTypes = {
  children: PropTypes.node
}

CustomModal.defaultProps = {
  children: 'CustomModal',
  okTextId: 'ok',
  cancelTextId: 'cancel'
}

export { CustomModal }
