// @flow

import type { PutCollectOrderRequestPayload } from '@app/types/PutCollectOrderRequest'
import type { PutCollectOrderFailureResponse, PutCollectOrderSuccessResponse } from '@app/types/PutCollectOrderResponse'
import { mockUserInfo } from '@app/constants'

const { MOCK_CONTACT_NUMBER, MOCK_SHIPPER_ID } = mockUserInfo

export default (
  country: string,
  trackingId: string,
  payload: PutCollectOrderRequestPayload
): Promise<PutCollectOrderSuccessResponse | PutCollectOrderFailureResponse> => {
  return new Promise(resolve => {
    const successResponse: PutCollectOrderSuccessResponse = {
      data: {
        data: {
          barcode: 'NIVDA708084047259152384',
          collectBy: '2020-05-09',
          collectEndDate: '2020-05-10 00:00:00',
          contact: MOCK_CONTACT_NUMBER,
          deadline: '2020-05-08',
          deadlineAction: 'RTS',
          dpId: payload.dpId,
          dropOffOn: '2020-05-08',
          droppedOffAt: '2020-05-07 22:36:45',
          endDate: '2020-05-10',
          fromContact: MOCK_CONTACT_NUMBER,
          fromName: 'Fakihatin Wafiyah',
          id: 535128,
          orderId: 83620150,
          overstayDate: '2020-05-11',
          receivedFrom: 'DRIVER',
          releasedAt: '2020-12-18 02:55:23',
          releasedTo: 'CUSTOMER',
          rtsAt: '2020-05-10 16:00:02',
          shipperId: MOCK_SHIPPER_ID,
          smsNotificationStatus: 'SENT',
          startDate: '2020-05-08',
          status: 'RELEASED',
          toName: 'Cantika Nabila',
          token: '5c855499-830e-4517-9e54-49a3457a8d95'
        }
      },
      ok: true
    }

    const failureResponse: PutCollectOrderFailureResponse = {
      data: {
        error: {
          code: '137831',
          details: [],
          message: 'Unlock code mismatch',
          requestId: 'npv3-8355-20201218-025359-487204',
          title: 'CF_UNLOCK_CODE_MISMATCH'
        }
      },
      ok: false
    }

    setTimeout(() => {
      if (payload.customerUnlockCode === '123456') {
        resolve(successResponse)
      } else {
        resolve(failureResponse)
      }
    }, 1000)
  })
}
