/**
 *
 * GridComponent
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { LabeledText as LT } from '@app/components/LabeledText'
import _ from 'lodash'
import { compose } from 'redux'
import { injectSize } from '@nv/rc/Components'
import { Row } from '@app/components/Row'
import { Column } from '@app/components/Column'
import { display } from '@app/themes'

const StyledGridComponent = styled.div`
  ${display.childMargins('row', 16)};
  ${props =>
    props.borderColor &&
    `
    border: 1px solid ' ${props => props.borderColor};
    padding: 10px;`};
`

const LabeledText = styled(LT)`
  && {
    flex: 1 1 100% !important;
  }
`

class GridComponent extends React.Component {
  render () {
    const { grid, borderColor, isMobile } = this.props
    const ResponsiveContainer = isMobile ? Column : Row
    return (
      <StyledGridComponent borderColor={borderColor}>
        {_.map(grid, (row, i) => {
          return (
            <ResponsiveContainer key={i} full alignItems={'flex-start'}>
              {_.map(row, (column, j) => {
                if (column.text !== undefined && column.text !== null) {
                  return <LabeledText key={j} dynamicFontDiff={4} size={'medium'} labelSize={'medium'} {...column} />
                }
                return <LabeledText key={j} />
              })}
            </ResponsiveContainer>
          )
        })}
      </StyledGridComponent>
    )
  }
}

GridComponent.propTypes = {
  children: PropTypes.node,
  grid: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        text: PropTypes.string
      })
    )
  ),
  type: PropTypes.string,
  dynamicFontDiff: PropTypes.number
}

GridComponent.defaultProps = {
  children: 'GridComponent',
  type: 'row'
}

const GridComponent_ = compose(injectSize)(GridComponent)
export { GridComponent_ as GridComponent }
