import styled from 'styled-components'
import { display } from '@app/themes'

const BulkUploadOrdersWrapper = styled.div`
  && {
    font-family: "Noto Sans" !important;
    ${display.childMargins('row')};
  }
`

export {
    BulkUploadOrdersWrapper
}