/**
 *
 * DetailCard
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { CustomCard } from '@app/components/CustomCard'
import { Icon } from '@app/components/Icon'

import { colors, display, fonts, media } from '@app/themes'
import { Text } from '@app/components/Text'
import { faHandHoldingBox } from '@fa-pro-light/faHandHoldingBox'

const bodyStyles = {
  default: css`
    ${display.flex('stretch', 'center', 'column')};
  `,
  compact: css`
    ${display.flex('stretch', 'space-between')};
  `
}

const labelStyles = {
  default: css`
    ${display.childMargins('row')};
  `,
  compact: css`
    ${display.childMargins('column')};
    ${display.flex('center', 'center')};
  `
}
const StyledDetailCard = styled(CustomCard)`
  && {
    max-width: 100%;
    width: ${props => props.width}px;
    .ant-card-body {
      ${props => bodyStyles[props.type]};
    }
    ${media.max.tablet`align-self: center`};
  }
`
const LabelWrapper = styled.div`
  flex: 1;
  text-align: center;
  padding: 8px;
  ${props => labelStyles[props.type]};
`
const MainWrapper = styled.div`
  ${display.flex('center', 'center')};
  ${fonts.size.heading};
  ${fonts.style.bold};
  background: ${colors.aliceBlue};
  padding: 8px;
  word-break: break-word;
  width: 30%;
`

const DetailCard = props => {
  const { type, icon, iconBackground, iconSize, label, main, ...rest } = props
  return (
    <StyledDetailCard type={type} padding={0} {...rest}>
      <LabelWrapper type={type}>
        {icon && <Icon background={iconBackground} size={iconSize} icon={icon} />}
        <Text textTransform='capitalize' color={colors.pinkishGrey} textId={label} />
      </LabelWrapper>
      <MainWrapper data-testId='detail-main-content'>{main}</MainWrapper>
    </StyledDetailCard>
  )
}

DetailCard.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  main: PropTypes.any.isRequired,
  iconBackground: PropTypes.string,
  iconSize: PropTypes.number
}

DetailCard.defaultProps = {
  type: 'default',
  iconSize: 16,
  label: '',
  main: 0,
  icon: faHandHoldingBox
}

export { DetailCard }
