import styled from 'styled-components'
import { Modal } from 'antd'
import { Icon } from '@app/components/Icon'
import { colors, display, fonts } from '@app/themes'

const StyledCustomModal = styled(Modal)`
  max-width: 95%;

  && {
    width: ${props => props.width}px !important;
    margin: 0 auto;
    top: ${props => props.top}px;
  }

  .ant-modal-header {
    background-color: ${props => props.headerBackground || colors.aliceBlue};
  }

  .ant-modal-title,
  .ant-modal-close {
    color: ${props => props.headerColor || colors.greyishBrown};
    ${fonts.style.bold};
  }

  .ant-modal-close-x {
    ${display.flex('center', 'center')};
  }

  .ant-modal-body {
    height: ${props => props.height}px;
    max-height: 100vh;
    padding: ${props => props.padding || 0}px;
    overflow: auto;
  }

  .ant-modal-footer {
    ${display.flex('center', 'flex-end')};
    background: ${colors.aliceBlue};
  }
`

const InfoModal = styled(Modal)`
  && {
    width: 360px !important;

    .ant-modal-body {
      padding: 60px 40px;
    }
  }
`
const TitleWrapper = styled.div`
  ${display.flex('center', 'center')};
  margin-bottom: 24px;
`

const InfoIcon = styled(Icon)`
  margin-right: 8px;
`

export { StyledCustomModal, InfoModal, TitleWrapper, InfoIcon }
