const startTimeCache = {}

export const cacheStartTime = eventName => {
  startTimeCache[eventName] = new Date().getTime()
}

export const getCachedStartTime = eventName => {
  return startTimeCache[eventName]
}

export default {
  cacheStartTime,
  getCachedStartTime
}
