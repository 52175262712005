// @flow

const RESERVATION_SOURCES = {
  ecoPhase1: 'ECO_PHASE_1',
  ecoPhase2: 'ECO_PHASE_2',
  semiTagging: 'SEMI_TAGGING',
  redirect: 'REDIRECT',
  reschedule: 'RESCHEDULE',
  operator: 'OPERATOR',
  send: 'SEND',
  post: 'POST',
  fullyIntegratedReturns: 'FULLY_INTEGRATED_RETURNS',
  semiIntegratedReturns: 'SEMI_INTEGRATED_RETURNS',
  unintegratedReturns: 'UNINTEGRATED_RETURNS',
  pack: 'PACK',
  fullyIntegratedNinjaCollect: 'FULLY_INTEGRATED_NINJA_COLLECT',
  semiIntegratedNinjaCollect: 'SEMI_INTEGRATED_NINJA_COLLECT'
}

const CUSTOMER_COLLECTION_RESERVATION_SOURCES = [
  RESERVATION_SOURCES.fullyIntegratedNinjaCollect,
  RESERVATION_SOURCES.semiIntegratedNinjaCollect,
  RESERVATION_SOURCES.operator,
  RESERVATION_SOURCES.redirect,
  RESERVATION_SOURCES.reschedule
]

const RETURN_RESERVATION_SOURCES = [
  RESERVATION_SOURCES.fullyIntegratedReturns,
  RESERVATION_SOURCES.semiIntegratedReturns,
  RESERVATION_SOURCES.unintegratedReturns
]

const ORDER_TYPES = {
  post: 'POST',
  bulkPost: 'BULK_POST',
  send: 'SEND',
  pack: 'PACK',
  manual_pack: 'MANUAL_PACK',
  collect: 'COLLECT',
  return: 'RETURN'
}

export default {
  RESERVATION_SOURCES,
  CUSTOMER_COLLECTION_RESERVATION_SOURCES,
  ORDER_TYPES,
  RETURN_RESERVATION_SOURCES
}
