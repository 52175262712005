// @flow

import { apiClientsNoTransform } from '@app/services/api/clients'
import { DELIVERY_TYPES } from '@app/utils/constants'
import type { PostShipperPriceAsyncApiResponse } from '@app/types/PostShipperPriceAsyncApiResponse'

// This is currently used on rate caculator page as we are moving away from legacy shipper id 
export const postShipperPriceAsyncApiWithGlobalShipperId = (
  country: string,
  globalShipperId: number,
  payload: any
): PostShipperPriceAsyncApiResponse => {
  const client = apiClientsNoTransform[country]
  const url = `2.2/shippers/${globalShipperId}/price`

  // this is needed since pricing engine expects the underscore
  if (payload?.delivery_type === 'NEXTDAY') {
    payload.delivery_type = DELIVERY_TYPES.NEXT_DAY
  }

  return client.post(url, payload)
}
