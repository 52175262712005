import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { Table as T } from 'antd'

import { injectSize } from '@nv/rc/Components'

import { LoadingIndicator } from '@app/components/LoadingIndicator'
import { EmptyListPage } from '@app/components/EmptyListPage'
import { injectCountry } from '@app/components/CountryProvider'
import { colors, display, fonts } from '@app/themes'
import { isAccepted, isRestricted } from '@app/utils/settingUtils'

const StyledTable = styled(T)`
  && {
    overflow-x: scroll;
    width: ${props => props.width}px;

    .ant-table-placeholder {
      padding: 100px 20px;
    }

    tr > * {
      border-color: ${props => props.borderColor} !important;
    }

    tr > th {
      background: ${props => props.headerBackground || colors.paleGrey};
      color: ${props => props.headerColor || colors.greyishBrown};
      ${fonts.style.bold};
      ${fonts.size.medium};
    }

    tr > td {
      background: ${props => props.background || colors.white};
      color: ${props => props.color || colors.greyishBrown};
      ${props => props.onRow && `cursor: pointer`};
    }
    .ant-table-column-sorters {
      ${display.flex('center', 'flex-start')};
    }
    .ant-table-column-sorter {
      position: unset;
      height: unset;
      margin-left: 10px;
    }
  }
`

class Table extends React.Component {
  getTableColumns = () => {
    const { columns, country } = this.props
    const info = { country }
    return columns.filter(column => !isRestricted(column.rejected, info) && isAccepted(column.accepted, info))
  }

  renderMobile () {
    const { renderMobile, loading, dataSource, emptyMessage, emptyIcon } = this.props
    if (loading) {
      return <LoadingIndicator />
    } else if (dataSource.length <= 0) {
      return <EmptyListPage message={emptyMessage} padding='48px 0' icon={emptyIcon} />
    } else if (typeof renderMobile === 'function') {
      return renderMobile(this.props)
    } else {
      return renderMobile
    }
  }

  render () {
    const { tableSize, loading, isDesktop, renderMobile, size, ...rest } = this.props
    if (renderMobile) {
      if (!isDesktop) {
        return this.renderMobile()
      }
    }
    return <StyledTable {...rest} columns={this.getTableColumns()} size={tableSize} loading={loading} />
  }
}

Table.defaultProps = {
  columns: []
}

Table.propTypes = {
  children: PropTypes.node
}

const _Table = compose(injectSize, injectCountry)(Table)
export { _Table as Table }
