import styled from 'styled-components'
import { Select } from 'antd'
export const SelectItem = styled(Select)`
  width: 100%;
  &&& .ant-select-selection:focus {
    border-color: #2f4f4f;
    outline: none !important;
    -webkit-box-shadow: 0 0 0 2px rgb(211, 211, 211);
    box-shadow: 0 0 0 2px rgb(211, 211, 211);
  }
`
