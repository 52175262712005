import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'
import { set, setLoading } from 'utils/reducerUtils'

export const INITIAL_STATE = fromJS({
  loading: {},
  volumes: {}
})

export const { Types: performanceDetailsTypes, Creators: performanceDetailsCreators } = createActions(
  {
    requestGetVolumes: ['startDate', 'endDate'],
    successGetVolumes: ['volumes'],
    failGetVolumes: []
  },
  { prefix: 'PerformanceDetails/' }
)

export default createReducer(INITIAL_STATE, {
  [performanceDetailsTypes.REQUEST_GET_VOLUMES]: state => setLoading(state, 'getVolumes', true),
  [performanceDetailsTypes.SUCCESS_GET_VOLUMES]: (state, action) => {
    state = setLoading(state, 'getVolumes', false)
    return set(state, 'volumes', action.volumes)
  },
  [performanceDetailsTypes.FAIL_GET_VOLUMES]: state => {
    state = setLoading(state, 'getVolumes', false)
    return set(state, 'volumes', INITIAL_STATE)
  }
})
