import { OC_STATUS } from '@app/utils/constants'
import _ from 'lodash'

// trackCustomEvent to google analytics
// Deprecated since we moved to mixpanel. This implementation has somehow caused some flows
// to hang and blocking our operations and functionality, hence hardcoded to enableGA = false;
// e.g: https://jira.ninjavan.co/browse/TS-28396
export const trackCustomEvent = (eventName, variables = {}) => {
  const enableGA = false
  if (enableGA && window.dataLayer) {
    window.dataLayer.push({
      ...variables,
      event: eventName
    })
  }
}

export const trackClickEvent = (variables = {}) => {
  trackCustomEvent('click', variables)
}

export const trackPostOrderStatusEvent = (event, orders, isRequestSuccess) => {
  const successOrders = isRequestSuccess ? orders.filter(o => _.get(o, 'oc.status') === OC_STATUS.success) : []
  const failedOrders = isRequestSuccess ? orders.filter(o => _.get(o, 'oc.status') === OC_STATUS.failed) : orders
  trackCustomEvent(event, { successOrders: successOrders.length, failedOrders: failedOrders.length })
}
