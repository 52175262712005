// @flow
/**
 * @file contains wrapper for NVInput components of type TEXT, NUMBER, MOBILE, MOBILE_SEARCH, QUICK_ADDRESS_SEARCH
 * returns a wrapped FormItem
 */
import { COUNTRY_DATA_KEYS, getCountryData, isEqual } from '@app/utils/constants'
import { Col, Row } from 'antd'
import { CustomButton } from '@app/components/CustomButton'
import { FORM_ITEM_TYPES } from './constants'
import { FormItem } from '@app/components/FormItem'
import { Icon } from '@app/components/Icon'
import { NVInput } from '@nv/rc/Components'
import type { Props } from '@app/models/FormItemWrapper.model'
import React from 'react'
import _ from 'lodash'
import { faSearch } from '@fa-pro-regular/faSearch'
import { injectIntl } from 'react-intl'
import { phoneNumberValidator } from '@app/utils/phoneNumberUtils'

const FormItemWrapper = (props: Props) => {
  const {
    form,
    fieldId,
    fieldLabel,
    width,
    type,
    placeholder,
    onChange,
    disabled,
    component,
    min,
    addonBefore,
    dataTestId,
    country,
    required,
    intl,
    initialValue,
    rules,
    onPressEnter,
    precision,
    handleSearch,
    loading
  } = props
  const { getFieldDecorator } = form

  const getComponent = type => {
    const { TEXT, NUMBER, MOBILE, MOBILE_SEARCH, QUICK_ADDRESS_SEARCH } = FORM_ITEM_TYPES
    switch (type) {
      case TEXT:
        return (
          <NVInput
            placeholder={placeholder}
            data-testid={_.kebabCase(dataTestId)}
            onChange={onChange}
            disabled={disabled}
          />
        )
      case NUMBER:
        return (
          <NVInput
            type='number'
            disabled={disabled}
            data-testid={_.kebabCase(dataTestId)}
            min={min || 0}
            precision={precision || 0}
            placeholder={placeholder}
            onChange={onChange}
            addonBefore={addonBefore}
          />
        )
      case MOBILE:
        return (
          <NVInput
            data-testid={_.kebabCase(dataTestId)}
            onChange={onChange}
            onPressEnter={onPressEnter}
            addonBefore={getCountryData(country, COUNTRY_DATA_KEYS.phonePrefix)}
            placeholder={placeholder}
          />
        )
      case MOBILE_SEARCH:
        return (
          <MobileSearchInput
            handleSearch={handleSearch}
            loading={loading}
            dataTestId={dataTestId}
            placeholder={placeholder}
            country={country}
          />
        )
      case QUICK_ADDRESS_SEARCH:
        return (
          <QuickAddressSearchInput
            handleSearch={handleSearch}
            loading={loading}
            dataTestId={dataTestId}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
          />
        )
      // TODO: add SELECT_ITEM after refactoring LocationFormItem
      default:
        return component
    }
  }

  const getRules = () => {
    const { MOBILE, MOBILE_SEARCH } = FORM_ITEM_TYPES
    const consolidatedRules = []
    if (required) {
      consolidatedRules.push({
        required: required,
        message: intl.formatMessage({ id: 'this_field_cannot_be_left_blank' })
      })
    }

    if (rules) {
      consolidatedRules.push(...rules)
    }

    if (isEqual(type, MOBILE) || isEqual(type, MOBILE_SEARCH)) {
      consolidatedRules.push({
        validator: phoneNumberValidator,
        message: intl.formatMessage({ id: 'enter_valid_phone_number' }),
        country: country
      })
    }
    return consolidatedRules
  }

  return (
    <FormItem label={fieldLabel} width={width || 300} {...props}>
      {getFieldDecorator(fieldId, {
        initialValue: initialValue,
        rules: getRules()
      })(getComponent(type))}
    </FormItem>
  )
}

const FormItemWrapper_ = injectIntl(FormItemWrapper)
export { FormItemWrapper_ as FormItemWrapper }

const MobileSearchInput = (props: Props) => {
  const {
    placeholder,
    onChange,
    dataTestId,
    country,
    handleSearch,
    loading,
    value
  } = props
  return (
    <Row>
      <Col span={22}>
        <NVInput
          data-testid={_.kebabCase(dataTestId)}
          onChange={onChange}
          onPressEnter={handleSearch}
          addonBefore={getCountryData(country, COUNTRY_DATA_KEYS.phonePrefix)}
          placeholder={placeholder}
          value={value}
        />
      </Col>

      <Col span={2}>
        <CustomButton
          textId='icon-faSearch'
          iconPosition='leftAligned'
          type='tertiary'
          dataTestId='button-search-contact-number'
          size='small'
          style={{marginLeft: '8px'}}
          loading={loading}
          onClick={handleSearch}
        >
          <Icon icon={faSearch} />
        </CustomButton>
      </Col>
    </Row>
  )
}

const QuickAddressSearchInput = props => {
  const {
    placeholder,
    onChange,
    dataTestId,
    handleSearch,
    loading,
    disabled,
    value
  } = props

  return (
    <Row>
      <Col span={22}>
        <NVInput
          data-testid={_.kebabCase(dataTestId)}
          onChange={onChange}
          onPressEnter={handleSearch}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
        />
      </Col>

      <Col span={2}>
        <CustomButton
          textId='icon-faSearch'
          iconPosition='leftAligned'
          type='tertiary'
          dataTestId='button-quick-address-search'
          size='small'
          style={{marginLeft: '8px'}}
          loading={loading}
          onClick={handleSearch}
          disabled={disabled}
        >
          <Icon icon={faSearch} />
        </CustomButton>
      </Col>
    </Row>
  )
}
