/**
 *
 * CustomWebcam
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import _ from 'lodash'
import { faCameraAlt } from '@fa-pro-regular/faCameraAlt'
import { Icon, T, WebcamCapture } from '@nv/rc/Components'
import { CustomModal } from '@app/components/CustomModal'
import { ImagePreview } from '@app/components/ImagePreview'
import uuid from 'uuid/v1'
import { colors, display } from '@app/themes'
import { NOTIFICATIONS, convertB64ToGzipAndHandlePreviewConfirm } from '@app/utils/constants'
import { showNotification } from '@app/utils/notify'

const Placeholder = styled.div`
  margin: 0 auto;
  ${display.flex('center', 'center', 'column')};
  text-align: center;
  height: 200px;
  width: 200px;
  border: 2px dashed ${colors.paleGrey};
  color: ${colors.pinkishGrey};
  cursor: pointer;
  &:hover {
    transition: 0.3s;
    border-color: ${colors.pinkishGrey};
  }
`

class CustomWebcam extends React.Component {
  state = {
    showCameraModal: false,
    temporaryImage: null
  }
  showCameraModal = () => {
    this.setState({ showCameraModal: true })
  }
  closeCameraModal = () => {
    const { onCancel } = this.props
    this.setState({ showCameraModal: false })
    onCancel && onCancel()
  }

  handleWebcamError = err => {
    const { intl } = this.props
    const defaultMessage = intl.formatMessage({ id: 'unable_to_get_camera_access' })
    const errorMessage = _.get(err, 'message', defaultMessage)
    showNotification(errorMessage, NOTIFICATIONS.ERROR)
  }
  handleTakePicture = data => {
    this.setState({ temporaryImage: data, showCameraModal: false })
  }
  handlePreviewConfirm = () => {
    const { handlePreviewConfirm, handlePreviewImage, index } = this.props
    const { temporaryImage } = this.state
    handlePreviewImage && handlePreviewImage(temporaryImage)
    handlePreviewConfirm &&
      temporaryImage &&
      convertB64ToGzipAndHandlePreviewConfirm(handlePreviewConfirm, uuid() + '.png', temporaryImage, 'image/png', index)
    this.setState({ temporaryImage: null, showCameraModal: false })
  }
  handleRetakePicture = () => {
    const { index, handleRetakePicture } = this.props
    handleRetakePicture && handleRetakePicture(index)
    this.setState({ temporaryImage: null, showCameraModal: true })
  }
  renderPlaceholder () {
    const { actionText } = this.props
    return (
      <Placeholder onClick={this.showCameraModal}>
        <Icon color={colors.pinkishGrey} gap={1} icon={faCameraAlt} type='big' />
        <T id={actionText} />
        {this.renderCameraModal()}
      </Placeholder>
    )
  }
  renderCameraModal () {
    const { showCameraModal } = this.state
    const { intl, actionText, showCamera } = this.props
    if (!showCameraModal && !showCamera) return null
    return (
      <CustomModal
        title={intl.formatMessage({ id: actionText })}
        visible={showCameraModal || showCamera}
        onCancel={this.closeCameraModal}
        width={400}
      >
        <WebcamCapture
          width={400}
          height={400}
          onCapture={this.handleTakePicture}
          onError={this.handleWebcamError}
          buttonText={intl.formatMessage({ id: 'capture' })}
        />
      </CustomModal>
    )
  }
  renderPreview () {
    const { intl, image, previewOnly } = this.props
    const { temporaryImage } = this.state
    return (
      <ImagePreview
        previewOnly={previewOnly}
        image={image || temporaryImage}
        showPreview={!image}
        okText={intl.formatMessage({ id: 'confirm' })}
        cancelText={intl.formatMessage({ id: 'retake_picture' })}
        cancelIcon={faCameraAlt}
        onOk={this.handlePreviewConfirm}
        onCancel={this.handleRetakePicture}
      />
    )
  }
  render () {
    const { image } = this.props
    const { temporaryImage } = this.state
    return !(image || temporaryImage) ? this.renderPlaceholder() : this.renderPreview()
  }
}

CustomWebcam.propTypes = {
  children: PropTypes.node
}

CustomWebcam.defaultProps = {
  children: 'CustomWebcam'
}

const CustomWebcam_ = injectIntl(CustomWebcam)
export { CustomWebcam as CustomWebcamTest }
export { CustomWebcam_ as CustomWebcam }
