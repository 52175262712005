import React, { Fragment } from 'react'
import { T } from '@nv/rc/Components'
import _ from 'lodash'
import { formatNumber } from '@app/utils/OrderUtils'

import { StyledProgressBar, styledLabel } from './styles'

const getPosition = (currentValue, levels) => {
  const highestLevel = _.last(levels)
  const currentPoint = Math.round((currentValue / highestLevel.threshold) * 100)
  return currentPoint > 100 ? 100 : currentPoint
}

const getCurrentTiering = (currentValue, levels) => {
  const currentLevelId = _.findLastIndex(levels, level => level.threshold <= currentValue)
  return {
    currentTiering: _.get(levels, [currentLevelId]),
    currentTieringIndex: currentLevelId
  }
}

const getGoal = (currentTieringIndex, levels) => {
  const nextTieringIndex = currentTieringIndex + 1 === levels.length ? currentTieringIndex : currentTieringIndex + 1
  return _.get(levels, [nextTieringIndex])
}

export const AwardProgressBar = ({ currentValue, levels, barColor }) => {
  const currentPoint = getPosition(currentValue, levels)
  const marks = levels.reduce((marks, level) => {
    let currentPercentage = getPosition(level.threshold, levels)
    return { ...marks, [currentPercentage]: styledLabel(level.name) }
  }, {})

  const { currentTieringIndex } = getCurrentTiering(currentValue, levels)
  const tooltipFormatter = () => {
    const nextGoal = getGoal(currentTieringIndex, levels)
    const nextGoalCount = nextGoal.threshold < currentValue ? 0 : nextGoal.threshold - currentValue
    return (
      <Fragment>
        {formatNumber(nextGoalCount)} <T id={'left_to_reach_level'} /> <T id={nextGoal.name} />
      </Fragment>
    )
  }

  return (
    <StyledProgressBar
      defaultValue={currentPoint}
      currentTierIndex={currentTieringIndex}
      tipFormatter={tooltipFormatter}
      barColor={barColor}
      marks={marks}
      disabled
    />
  )
}
