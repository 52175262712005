import styled from 'styled-components'
import { Upload } from 'antd'
import { display } from '@app/themes'

const UploadWrapper = styled.div`
  ${display.flex('flex-end', 'center', 'column')};
  ${display.childMargins('row', 16)};
  padding: 16px;
  width: 100%;
  height: 100%;
  align-items: center;
`
const StyledCustomDragger = styled(Upload.Dragger)`
  && {
    width: 100%;
    .ant-upload > .ant-upload-drag-container {
      ${display.flex('center', 'center', 'column')};
      ${display.childMargins('row', 16)};
      padding: 24px;
      background: ${props => props.background};
    }
  }
`

export { UploadWrapper, StyledCustomDragger }
