import { createActions, createReducer } from 'reduxsauce'
import { fromJS } from 'immutable'

export const INITIAL_STATE = fromJS({
  pages: {},
  statistics: {},
  totalPages: 1,
  currentPageNumber: 1,
  isLoadingFromFirstPage: false,
  filters: []
})

export const { Types: parcelOverviewTypes, Creators: parcelOverviewCreators } = createActions(
  {
    requestGetParcelOverviewReservations: ['from', 'to', 'query', 'filters', 'isCoreGranularStatusFiltersEnabled'],
    successGetParcelOverviewReservations: ['reservations', 'statistics', 'filters', 'page'],
    failGetParcelOverviewReservations: [],
    updateTotalPages: ['totalPages'],
    updateIsLoadingFromFirstPage: ['isLoadingFromFirstPage'],
    updateCurrentPageNumber: ['currentPageNumber'],
    reset: []
  },
  {
    prefix: 'ParcelOverview/'
  }
)

export default createReducer(INITIAL_STATE, {
  [parcelOverviewTypes.REQUEST_GET_PARCEL_OVERVIEW_RESERVATIONS]: state => {
    return state.setIn(['loading', 'getParcelOverview'], true)  
  },
  [parcelOverviewTypes.SUCCESS_GET_PARCEL_OVERVIEW_RESERVATIONS]: (state, action) => {
    const reservations = action.reservations || []
    const statistics = action.statistics || {}
    const filters = action.filters || []
    const pageNumber = action.page || 1
    return state
      .setIn(['pages', pageNumber], fromJS(reservations))
      .setIn(['statistics'], fromJS(statistics))
      .setIn(['filters'], fromJS(filters))
      .setIn(['loading', 'getParcelOverview'], false)
  },
  [parcelOverviewTypes.FAIL_GET_PARCEL_OVERVIEW_RESERVATIONS]: (state, action) => {
    return state.setIn(['loading', 'getParcelOverview'], false)
  },
  [parcelOverviewTypes.UPDATE_TOTAL_PAGES]: (state, action) => {
    const totalPages = action.totalPages
    return state.setIn(['totalPages'], totalPages)
  },
  [parcelOverviewTypes.UPDATE_IS_LOADING_FROM_FIRST_PAGE]: (state, action) => {
    return state.setIn(['isLoadingFromFirstPage'], action.isLoadingFromFirstPage)
  },
  [parcelOverviewTypes.UPDATE_CURRENT_PAGE_NUMBER]: (state, action) => {
    return state.setIn(['currentPageNumber'], action.currentPageNumber)
  },
  [parcelOverviewTypes.RESET]: (state, action) => {
    return state
      .setIn(['pages'], fromJS({}))
      .setIn(['statistics'], fromJS({}))
      .setIn(['filters'], fromJS([]))
      .setIn(['totalPages'], 1)
      .setIn(['currentPageNumber'], 1)
  }
})
