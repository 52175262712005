import _ from 'lodash'

 /**
   * Retrieve the following order payment related details:
   * - Delivery Fee (inclusive of taxes)
   * - Insurance Fee (exclusive of taxes)
   * - COD Fee (exclusive of taxes)
   * - Total taxes for insurance and COD: codFee taxAmount + insurancefee taxAmount
   * - Total Fee (grand total of all fees and taxes)
   */

export const calculatePrice = asyncRequestResponseMessage => {
  const data = JSON.parse(asyncRequestResponseMessage)
  const insurance = _.get(data, 'result.insurance_fee.amount', 0) + _.get(data, 'result.insurance_fee.tax_amount', 0)
  const deliveryFee = _.get(data, 'result.delivery_fee.amount', 0) + _.get(data, 'result.delivery_fee.tax_amount', 0)
  const totalTax = _.get(data, 'result.total_tax', 0)

  const cod = {
    amount: _.get(data, 'result.cod_fee.amount', 0),
    taxAmount: _.get(data, 'result.cod_fee.tax_amount', 0)
  }
  const insuranceFee = {
    amount: _.get(data, 'result.insurance_fee.amount', 0),
    taxAmount: _.get(data, 'result.insurance_fee.tax_amount', 0)
  }
  const totalVatForInsuranceAndCod = cod.taxAmount + insuranceFee.taxAmount
  
  const price = {
    total: _.get(data, 'result.total_with_tax', 0) - (cod.amount + cod.taxAmount),
    totalTax,
    insurance,
    deliveryFee,
    cod,
    insuranceFee,
    totalWithTax: _.get(data, 'result.total_with_tax', 0),
    totalVatForInsuranceAndCod
  }
  return price
}

export const isShipperSourcePudoOrNullOrEmpty = shipperSource => {
  const shipperSourceUpperCase = _.toUpper(shipperSource)
  return (shipperSourceUpperCase === "PUDO" || shipperSourceUpperCase === "NULL" || _.isEmpty(shipperSourceUpperCase))
}

