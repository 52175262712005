// @flow

import { rest } from 'msw'
import moment from 'moment'
import configs from 'configs'

import { DATE_FORMAT } from '@app/constants/dates'

import type { DpJobsData } from '@app/types/DpJob'

export const getDpJobsMswHandler = rest.get(`${configs.BASE_URL}/:id/dp/1.0/dp-job`, (req, res, ctx) => {
  const { dpId, id } = req.params
  const type: string = req.url.searchParams.get('type')
  const dpJobsData: DpJobsData = {
    data: 
      {
        date: moment().format(DATE_FORMAT.YEAR_MONTH_DAY_DASHED),
        dpId,
        driverFlow: null,
        id: 907735,
        orders: null,
        qrCode: null,
        status: 'ACTIVE',
        systemId: id,
        type: type === 'collect' ? 'COLLECT' : 'DROP_OFF',
        unlockCodeIn: '000000',
        unlockCodeOut: '999999'
      }
  }
  return res(ctx.status(200), ctx.json(dpJobsData))
})
