/**
 *
 * NavigationSidebar
 *
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Layout, Menu } from 'antd'
import _ from 'lodash'

import { Icon as I } from '@nv/rc/Components'

import { Dot } from '@app/components/Dot'
import { Text } from '@app/components/Text'
import { NVLogo } from '@app/components/Logo'

import { getNavConfigs } from '@app/configs/navConfig'

import { colors, display, media } from '@app/themes'
import { NavigationSidebarV2 } from '@app/components/NavigationSidebarV2'
import { ROUTES, isEqual } from '@app/utils/constants'
import commons from '@app/constants/commons'
import { mixpanelTrackClickedOnToDriver, mixpanelTrackClickedOnFromDriver } from '@app/utils/mixpanelUtils'

const Sider = styled(Layout.Sider)`
  && {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    background: ${colors.menu};
    z-index: 1000;
    ${media.max.mobile` height:100%; position: fixed; `};
    .ant-layout-sider-children {
      ${display.flex('flex-start', 'stretch', 'column')};
    }
  }
`

const CustomMenu = styled(Menu)`
  && {
    ${display.flex('flex-start', 'stretch', 'column')};
    user-select: none;
    background: ${colors.menu};
    border: 0 !important;
  }
`
const TopMenu = styled(CustomMenu)`
  flex-grow: 1;
`

const SubMenu = styled(Menu.SubMenu)`
  && {
    width: 100%;
    color: ${colors.pinkishGrey} !important;

    .ant-menu-item > * {
      padding-left: 12px !important;
    }

    &.ant-menu-submenu-active {
      background: ${colors.warmGrey} !important;
      color: ${colors.pinkishGrey} !important;
    }

    .ant-menu-submenu-title {
      color: inherit !important;

      &:hover {
        color: inherit !important;
        .ant-menu-submenu-arrow::before,
        .ant-menu-submenu-arrow::after {
          background: ${colors.white} !important;
        }
      }
    }
  }
`
const MenuItem = styled(Menu.Item)`
  && {
    ${display.flex('center')};
    width: 100%;
    margin: 0 !important;
    padding: 12px 24px !important;
    height: 44px;
    color: ${colors.pinkishGrey} !important;
    background: ${colors.menu} !important;

    &::after {
      display: none;
    }

    &.ant-menu-item-selected {
      background: ${colors.warmGrey} !important;
      color: ${colors.white} !important;
    }

    &.ant-menu-item-active {
      background: ${colors.warmGrey} !important;
      color: ${colors.white} !important;
    }

    &.ant-menu-item-disabled {
      background: inherit !important;
      color: ${colors.warmGrey} !important;
    }
  }
`
const Divider = styled(Menu.Divider)`
  && {
    background: ${colors.pinkishGrey};
    margin: 0 !important;
  }
`

const NameWrapper = styled.div`
  ${display.flex('center', 'center')};
  width: 100%;
  background: ${colors.black};
  padding: 20px;
`
const LabelWrapper = styled.div`
  ${display.flex('center')};
  ${display.childMargins('column')};
`
const Icon = styled(I)`
  color: inherit;
`
class NavigationSidebar extends React.Component {
  state = {
    ...getNavConfigs({
      country: this.props.country,
      settingType: this.props.settingType,
      isNewDriverHandoverEnabled: this.props.isNewDriverHandoverEnabled
    }),
    redirectedTo: ""
  }
  
  // This is used to the show leave without checking in your parcel modal when users are on /scan-parcels page and they click on navigation bar menu
  componentDidUpdate(prevProps) {
    const { logout } = this.props

    if (!prevProps.leaveWithoutParcelModalState !== this.props.leaveWithoutParcelModalState && this.props.leaveWithoutParcelModalState === 'navigate') {
      this.goTo(this.state.redirectedTo, true)
      isEqual(this.state.redirectedTo, ROUTES.login.path) && logout()
      this.props.closeLeaveWithoutParcelModal()
    }
  }

  setRedirectedTo = path => this.setState({ redirectedTo: path })

  /**
   * This is used to redirect users to another page 
   * @param {*} path 
   * @param {*} shouldRedirect 
   * @returns 
   */
  goTo = (path, shouldRedirect = false) => {
    const { closeMenu, updateRoute, showLeaveWithoutParcelModal, reviewParcelOrders, logout } = this.props
    if (reviewParcelOrders.length > 0 && window.location.pathname === ROUTES.newScanParcel.path && !shouldRedirect ) {
      this.setRedirectedTo(path)
      showLeaveWithoutParcelModal()
      return
    } 
    isEqual(path, ROUTES.login.path) && logout()
    updateRoute(path)
    closeMenu()
  }

  handleClickLogo = () => this.goTo(ROUTES.home.path)
  handleClickMenu = ({ key }) => {
    isEqual(key, ROUTES.toDriver.path) && mixpanelTrackClickedOnToDriver()
    isEqual(key, ROUTES.fromDriver.path) && mixpanelTrackClickedOnFromDriver()
    this.goTo(key, false)
  }

  renderLabel (render) {
    const { label, icon, dot } = render
    return (
      <LabelWrapper>
        {dot && <Dot size={10} color={dot} />}
        {icon && <Icon icon={icon} />}
        {label && <Text textId={label} />}
      </LabelWrapper>
    )
  }
  renderSubMenu (item) {
    const { path, render, children } = item
    return (
      <SubMenu key={path + Math.random()} title={this.renderLabel(render)}>
        {children.map(c => this.renderMenuItem(c))}
      </SubMenu>
    )
  }
  renderMenuItem (item) {
    const { path, render, disabled, hide, children } = item
    if (hide) {
      return null
    }
    if (children && children.length) {
      const shouldRenderSubmenu = children.filter(c => !_.get(c, 'hide')).length > 0
      return shouldRenderSubmenu && this.renderSubMenu(item)
    } else {
      return (
        <MenuItem disabled={disabled} key={path}>
          {this.renderLabel(render)}
        </MenuItem>
      )
    }
  }
  renderNav (config) {
    return config.map((item, index) => {
      const { divider } = item
      if (divider) {
        return <Divider key={index} />
      } else {
        return this.renderMenuItem(item)
      }
    })
  }

  render () {
    const { navConfig, bottomNavConfig } = this.state
    const { dpName, collapsed, isDesktop, selectedKeys, isNewAkiraDesignsEnabled, handleCollapseMenu } = this.props

    return isNewAkiraDesignsEnabled ? (
      <NavigationSidebarV2
        handleClickMenu={this.handleClickMenu}
        handleClickLogo={this.handleClickLogo}
        handleCollapseMenu={this.handleCollapseMenu}
        {...this.props}
      />
    ) : (
      <Sider
        trigger={null}
        collapsible={!isDesktop}
        collapsed={!isDesktop && collapsed}
        collapsedWidth={commons.SIDEBAR_COLLAPSED_WIDTH}
        width={commons.SIDEBAR_WIDTH}
      >
        <NVLogo padding={30} height={120} onClick={this.handleClickLogo} />
        <NameWrapper>
          <Text color={colors.white} size='big' textTransform='uppercase'>
            {dpName}
          </Text>
        </NameWrapper>
        <TopMenu mode='inline' selectedKeys={selectedKeys} onClick={this.handleClickMenu}>
          {this.renderNav(navConfig)}
        </TopMenu>
        <CustomMenu mode='inline' selectedKeys={selectedKeys} onClick={this.handleClickMenu}>
          {this.renderNav(bottomNavConfig)}
        </CustomMenu>
      </Sider>
    )
  }
}

NavigationSidebar.propTypes = {
  isDesktop: PropTypes.bool,
  collapsed: PropTypes.bool,
  dpName: PropTypes.string,
  selectedKeys: PropTypes.array,
  settingType: PropTypes.array
}

export { NavigationSidebar }