
import faker from 'faker'

const MOCK_PARTNER_ID = faker.random.number()
const MOCK_DP_ID = faker.random.number()
const MOCK_DP_MS_ID = faker.random.number()
const MOCK_DP_USER_ID = faker.random.number()
const MOCK_SHIPPER_ID = faker.random.number()
const MOCK_GLOBAL_SHIPPER_ID = faker.random.number()
const MOCK_CONTACT_NUMBER = '+62123456789'
const MOCK_ADDRESS = "3 Kay Siang Rd, Singapore 248923"
const MOCK_DP_NAME = "Test DP Name"
const MOCK_DP_SHORT_NAME = "DPName"
const MOCK_USER_NAME = "Test_User_Name"

export default { 
  MOCK_PARTNER_ID,
  MOCK_DP_ID,
  MOCK_DP_MS_ID,
  MOCK_SHIPPER_ID,
  MOCK_DP_USER_ID,
  MOCK_CONTACT_NUMBER,
  MOCK_ADDRESS,
  MOCK_DP_NAME, 
  MOCK_DP_SHORT_NAME,
  MOCK_USER_NAME,
  MOCK_GLOBAL_SHIPPER_ID
}