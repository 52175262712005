import styled from 'styled-components'
import { Layout } from 'antd'
import { colors, display, media } from '@app/themes'
import commons from '@app/constants/commons'

export const Sider = styled(Layout.Sider)`
  && {
    font-family: Noto Sans;
    position: relative;
    position: ${props => (props.collapsible) ? 'fixed' : 'relative'};
    top:${props => (props.collapsible) ? commons.HEADER_HEIGHT : '0'}px;    
    background: ${colors.black};
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1000;
    .nav-options-container {
      a.items-center {
        padding-left: 20px !important;
      }
    }
  }
`

export const CustomMenu = styled.div`
  width: 100%
  && {
    ${display.flex('flex-start', 'stretch', 'column')};
    user-select: none;
    border: 0 !important;
  }
`

export const LogoWrapper = styled.div`
  ${display.flex('center', 'flex-start')};
  width: 100%;
  background: ${colors.black};
  padding: 10px !important;
`
export const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
      background: ${colors.warmGrey} !important;
  }
`

export const DividerWrapper = styled.div`
  ${display.flex('center', 'flex-start')};
  width: 100%;
  padding: 0 20px;
  ${display.childMargins('column')};
  && .divider {
    width: 100%
  }
`

export const UserNavMenu = styled.div`
  padding: 20px 0 0 0 !important;
  height: auto !important;
  margin: 0 !important;
  width: 100%;
  flex-grow: 1;
  white-space: break-spaces !important;
  &:active {
    background: transparent !important;
  }
`

export const UserNameWrapper = styled.div`
  && {
    .items-center{
      width: 100%;
      div:last-child {
        display: block;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        width: fit-content;
        text-align: left;
      }
    } 
  }
  > div:first-child {
    width: 100%;
  }
`