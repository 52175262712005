// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

import type { Response } from '@app/types/Response'
import type { ScanOrderData } from '@app/types/ScanOrderData'

export default (country: string, trackingId: string): Promise<Response<ScanOrderData>> => {
  const client = getApiClient(apiClients, country)
  const url = `2.0/orders/scan/${trackingId}`
  return client.get(url)
}
