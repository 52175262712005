// @flow

import { apiClients } from '@app/services/api/clients'
import { getApiClient } from '@app/utils/apiUtils'

import type { SendOrderFailureResponse, SendOrderSuccessResponse } from '@app/types/SendOrderResponse'

export default (country: string, statusId: string): Promise<SendOrderSuccessResponse | SendOrderFailureResponse> => {
  const client = getApiClient(apiClients, country)
  const url = `1.0/lodge-in/${statusId}`
  return client.get(url)
}
