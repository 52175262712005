import { fromJS } from 'immutable'
import _ from 'lodash'
import { createActions, createReducer } from 'reduxsauce'
import { reservations } from '@app/constants'
import { DEFAULT_ORDER, DEFAULT_PACK_PARCEL, DEFAULT_POST_PARCEL } from './constants'

const { ORDER_TYPES } = reservations

export const { Types: scannerWithCounterTypes, Creators: scannerWithCounterCreators } = createActions(
  {
    requestGetOrder: ['id', 'path', 'index', 'onSuccess', 'onFailure'],
    updateCurrentSession: ['session'],
    successGetSendOrder: ['order'],
    successGetPostParcel: ['stampId', 'index'],
    successGetPackParcel: ['trackingId', 'index'],
    successGetReturnOrder: ['order'],
    failGetOrder: []
  },
  { prefix: 'ScannerWithCounter/' }
)

export const INITIAL_STATE = fromJS({
  loadingStatus: {},
  currentSession: null,
  sendOrders: [],
  postOrder: Object.assign({}, DEFAULT_ORDER),
  packOrder: Object.assign({}, DEFAULT_ORDER),
  returnOrders: []
})

export const toggleLoading = (state, key, value) => {
  return state.setIn(['loadingStatus', key], value)
}
export const getBasePath = state => {
  const session = state.get('currentSession')
  switch (session) {
    case ORDER_TYPES.post:
    case ORDER_TYPES.bulkPost:
      return 'postOrder'
    case ORDER_TYPES.send:
      return 'sendOrders'
    case ORDER_TYPES.return:
      return 'returnOrders'
    default:
      return 'packOrder'
  }
}
export const getValueAndPath = (state, key) => {
  const basePath = getBasePath(state)
  const path = key ? [basePath, key] : [basePath]
  const value = state.getIn(path)
  return { value, path }
}
export const updateSessionValues = (state, path, updatedValue) => {
  const session = state.get('currentSession')
  switch (session) {
    case ORDER_TYPES.post:
    case ORDER_TYPES.bulkPost:
    case ORDER_TYPES.pack:
    case ORDER_TYPES.send:
    case ORDER_TYPES.return:
      return state.setIn(path, updatedValue)
    default:
      return state
  }
}

export const scannerWithCounterReducer = createReducer(INITIAL_STATE, {
  [scannerWithCounterTypes.REQUEST_GET_ORDER]: state => toggleLoading(state, 'getOrder', true),
  [scannerWithCounterTypes.UPDATE_CURRENT_SESSION]: (state, action) => {
    return state.set('currentSession', action.session)
  },
  [scannerWithCounterTypes.SUCCESS_GET_SEND_ORDER]: (state, action) => {
    const sendOrders = state.get('sendOrders')
    state = toggleLoading(state, 'getOrder', false)
    return state.set('sendOrders', [...sendOrders, action.order])
  },
  [scannerWithCounterTypes.SUCCESS_GET_PACK_PARCEL]: (state, action) => {
    const { trackingId, index } = action
    state = toggleLoading(state, 'getOrder', false)
    const { value: parcels, path } = getValueAndPath(state, 'parcels')
    if (index >= 0 && parcels.length) {
      _.set(parcels[index], 'trackingId', trackingId)
      return updateSessionValues(state, path, [...parcels])
    } else {
      const newParcel = Object.assign({}, DEFAULT_PACK_PARCEL, { trackingId })
      return updateSessionValues(state, path, [...parcels, { ...newParcel }])
    }
  },
  [scannerWithCounterTypes.SUCCESS_GET_POST_PARCEL]: (state, action) => {
    const { stampId, index } = action
    state = toggleLoading(state, 'getOrder', false)
    const { value: parcels, path } = getValueAndPath(state, 'parcels')
    if (index >= 0 && parcels.length) {
      _.set(parcels[index], 'stampId', stampId)
      return updateSessionValues(state, path, [...parcels])
    } else {
      const newParcel = Object.assign({}, DEFAULT_POST_PARCEL, { stampId: { id: stampId, isVerified: true } })
      return updateSessionValues(state, path, [...parcels, { ...newParcel }])
    }
  },
  [scannerWithCounterTypes.SUCCESS_GET_RETURN_ORDER]: (state, action) => {
    const { order } = action
    const returnOrders = state.get('returnOrders')
    state = toggleLoading(state, 'getOrder', false)
    const newOrder = Object.assign({}, order, { stampId: { id: order.stampId, isVerified: true } })
    return state.set('returnOrders', [...returnOrders, newOrder])
  },
  [scannerWithCounterTypes.FAIL_GET_ORDER]: state => toggleLoading(state, 'getOrder', false)
})
