import _ from 'lodash'

export const getHistoryPagePaymentStub = (country, page, pageSize, fromDate, toDate, query, filters) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        data: {
          ...historyPagePaymentResponseStub()
        },
        ok: true
      })
    }, 50)
  })
}

export const historyPagePaymentResponseStub = (orderCount = 101) => {
  return {
    data: _.range(orderCount)
      .map(ignored => _.random(sendPostPackOrders.length - 1))
      .map(idx => sendPostPackOrders[idx]),
    statistics: {
      totalOrderCount: orderCount,
      totalCodCollected: 143,
      totalDeliveryFeeReceived: 9000
    },
    filters: [
      {
        name: 'POST',
        count: 1
      }
    ]
  }
}

const sendPostPackOrders = [
  {
    orderId: 3891091,
    trackingId: 'NVIDNINJA000143258',
    stampId: null,
    status: 'Pending',
    dpGranularStatus: 'HANDOVER_COMPLETED',
    granularStatus: 'Pending Pickup at Distribution Point',
    fromName: 'Marie Regal',
    fromContact: '+628123456789',
    toName: 'Fadhil',
    toContact: '+628123456782',
    lastDdnt: {
      instruction: null,
      endTime: '2020-03-02T15:00:00Z'
    },
    pricingInfo: {
      total: 9000,
      insuranceFee: 0,
      invoicedAmountDetails: {
        totalWithTax: 9000,
        totalTax: 89.1089999999999946567186270840466022491455078125,
        codFee: {
          amount: 123,
          taxAmount: 20
        },
        insuranceFee: {
          amount: 0,
          taxAmount: 0
        },
        deliveryFee: {
          amount: 8910.89099999999962165020406246185302734375,
          taxAmount: 89.1089999999999946567186270840466022491455078125
        },
        handlingFee: {
          amount: 0,
          taxAmount: 0
        }
      }
    },
    source: 'POST',
    receiptId: 139477,
    cod: null,
    releasedToCustomer: false,
    codCollected: null,
    inboundDate: '2020-02-27'
  },
  {
    orderId: 3891091,
    trackingId: 'NINIDNINJA053446558',
    stampId: null,
    status: 'Pending',
    dpGranularStatus: 'HANDOVER_COMPLETED',
    granularStatus: 'Pending Pickup at Distribution Point',
    fromName: 'Marie Regal',
    fromContact: '+628123456789',
    toName: 'Fadhil',
    toContact: '+628123456782',
    lastDdnt: {
      instruction: null,
      endTime: '2020-03-02T15:00:00Z'
    },
    pricingInfo: {
      total: 9000,
      insuranceFee: 0,
      invoicedAmountDetails: {
        totalWithTax: 9000,
        totalTax: 89.1089999999999946567186270840466022491455078125,
        codFee: {
          amount: 123,
          taxAmount: 20
        },
        insuranceFee: {
          amount: 0,
          taxAmount: 0
        },
        deliveryFee: {
          amount: 8910.89099999999962165020406246185302734375,
          taxAmount: 89.1089999999999946567186270840466022491455078125
        },
        handlingFee: {
          amount: 0,
          taxAmount: 0
        }
      }
    },
    source: 'PACK',
    receiptId: 143277,
    cod: null,
    releasedToCustomer: false,
    codCollected: null,
    inboundDate: '2020-03-27'
  },
  {
    orderId: 3891091,
    trackingId: 'NVIDNINJA000146458',
    stampId: null,
    status: 'Pending',
    dpGranularStatus: 'HANDOVER_COMPLETED',
    granularStatus: 'Pending Pickup at Distribution Point',
    fromName: 'Marie Regal',
    fromContact: '+628123456789',
    toName: 'Fadhil',
    toContact: '+628123456782',
    lastDdnt: {
      instruction: null,
      endTime: '2020-03-02T15:00:00Z'
    },
    pricingInfo: {
      total: 9000,
      insuranceFee: 0,
      invoicedAmountDetails: {
        totalWithTax: 9000,
        totalTax: 89.1089999999999946567186270840466022491455078125,
        codFee: {
          amount: 123,
          taxAmount: 20
        },
        insuranceFee: {
          amount: 0,
          taxAmount: 0
        },
        deliveryFee: {
          amount: 8910.89099999999962165020406246185302734375,
          taxAmount: 89.1089999999999946567186270840466022491455078125
        },
        handlingFee: {
          amount: 0,
          taxAmount: 0
        }
      }
    },
    source: 'SEND',
    receiptId: 135477,
    cod: null,
    releasedToCustomer: true,
    codCollected: null,
    inboundDate: '2020-02-28'
  }
]
