/**
 *
 * IconWithText
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Row } from '@app/components/Row'
import { Icon } from '@app/components/Icon'
import { SubText } from '@app/components/SubText'
import { colors, fonts } from '@app/themes'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'

const IconWrapper = styled(Icon)`
  ${props => !props.noPadding && `padding-top: 5px;`};
  ${props => props.absolute && `position: absolute;`};
`
const IconWithText = props => (
  <Row
    alignItems={'flex-start'}
    start
    verticalMargin={5}
    childMargin={props.secondaryIcon && props.onlyIcon ? 0 : 10}
    data-testid={props.testId}
  >
    {props.icon ? <IconWrapper noPadding={props.noPadding} icon={props.icon} color={props.iconColor} /> : null}
    {props.secondaryIcon ? (
      <IconWrapper absolute noPadding={props.noPadding} icon={props.secondaryIcon} color={props.iconColor} />
    ) : null}
    {!props.onlyIcon ? (
      <SubText color={props.color} fontSize={props.fontSize} fontWeight={props.fontWeight}>
        {props.textId ? props.intl.formatMessage({ id: props.textId }) : props.textContent}
      </SubText>
    ) : null}
  </Row>
)

IconWithText.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  iconColor: PropTypes.string,
  secondaryIcon: PropTypes.node,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  textContent: PropTypes.string,
  textId: PropTypes.string
}

IconWithText.defaultProps = {
  children: 'IconWithText',
  icon: null,
  iconColor: colors.MenuItemTextColor,
  secondaryIcon: null,
  color: colors.MenuItemTextColor,
  fontSize: fonts.size.medium,
  fontWeight: 'normal',
  textContent: 'textContent',
  textId: null
}

const IconWithText_ = compose(injectIntl)(IconWithText)
export { IconWithText_ as IconWithText }
export { IconWithText as IconWithTextTest }
